import React, { Component } from "react";
import FactCheckerSupervisor from "./components/factCheckSupervisor/factCheckSupervisor";
// import axios from "axios";
// import api, { getBase } from "./../api";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: this.props.allUsers,
      searchData: this.props.searchData,
      clearFilter: 0,
      checkedBoxes: false
    };
  }
  componentWillReceiveProps(nextprops) {
    if (nextprops.searchData !== this.state.searchData) {
      this.setState({ searchData: nextprops.searchData })
    }
    if (nextprops.checkedBoxes !== this.state.checkedBoxes) {
      this.setState({ checkedBoxes: nextprops.checkedBoxes })
    }
  }
  componentDidMount() {
    // axios
    //   .get(getBase(api.USERS), {
    //     headers: { Authorization: "Token " + localStorage.token },
    //   })
    //   .then((res) => {
    //     this.setState({ users: res.data.data });
    //   });
  }

  render() {
    const { users, searchData } = this.state;
    return (
      <>
        <FactCheckerSupervisor
          users={users}
          pointVal={1}
          {...this.props}
          onHandleUserChange={this.props.onHandleUserChange}
          onHandleCheckBox={this.props.onHandleCheckBox}
          onHandleClearFilters={this.props.onHandleClearFilters}
          clearFilter={this.props.clearFilter}
          searchData={searchData}
          onHandleDeleteFilterItem={this.props.onHandleDeleteFilterItem}
          onHandleRangeChange={this.props.onHandleRangeChange}
        />
      </>
    );
  }
}

export default App;
