import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import api, { getBase } from "./../api";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import "./index.css";
import Createdclaim from "./Createdclaim";
import Card from "@material-ui/core/Card";
// import { createBrowserHistory } from "history";
import { connect } from "react-redux";

class CreateClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      claim: "",
      userId: "",
      content: "",
      claim_source: "",
      claimCreated: false,
      state: "",
      countWords: "",
      isValidClaim: false,
      loading: false,
      textClaim: true,
      imageurl: "",
      image: null,
      errors: [],
      imageFile: "",
    };
  }
  fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      let img = window.URL.createObjectURL(event.target.files[0]);
      this.setState({ imageurl: img });
      this.setState({ imageFile: event.target.files[0] });
    } else {
      toast.error(
        "Image could not be uploaded, check to see if it is the correct format",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
    if (!fileInput) {
      toast.error(
        "Please uploade image",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

    }
  }

  // UNSAFE_componentWillReceiveProps() {
  //   // const history = createBrowserHistory();
  //   // let { claimCreated } = this.state;
  //   // let { state } = history.location;
  //   // if (state === "newclaim") {
  //   //   if (claimCreated) {
  //   //     this.setState({ claimCreated: false });
  //   //   }
  //   // }
  // }

  countWords = () => {
    const { claim } = this.state;
    let s = claim;
    let v = "";
    s = s.replace(/(^\s*)|(\s*$)/gi, "");
    s = s.replace(/[ ]{2,}/gi, " ");
    s = s.replace(/\n /, "\n");
    v = s.split(" ").length;
    this.setState({ countWords: v });
    if (v <= 4) {
      this.setState({ isValidClaim: true });
    } else {
      this.setState({ isValidClaim: false });
      this.createClaim();
    }
  };

  createClaim = () => {
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    const { claim, textClaim, imageFile } = this.state;
    // this.setState({ loading: true });
    let user = JSON.parse(localStorage.user);
    if (!imageFile && !textClaim) {
      toast.error("Please upload image", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({ loading: false });
    }

    else if (!textClaim) {
      var data1 = new FormData();
      data1.append("image", imageFile, imageFile.name);
      data1.append("user_id", `${user.id}`);
      data1.append("message", `${claim}`);
      data1.append("source", "dashboard");
      axios
        .post(getBase(api.CREATE_CLAIM), data1, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: "Token " + localStorage.token,
          },
        })
        .then((res) => {
          this.setState({
            claim: "",
            user: "",
            // loading: false,
            claimCreated: true,
          });
          toast.success("Claim Sent", { position: toast.POSITION.TOP_RIGHT });
        })
        .catch((err) => {
          console.log(err);
          // this.setState({ loading: false });
          toast.error("failed to add claim", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      this.setState({ loading: true });
      let user = JSON.parse(localStorage.user);
      const { claim } = this.state;
      axios
        .post(
          getBase(api.CREATE_CLAIM, claim),
          {
            content: claim,
            type: "body",
            claim_source: "dashboard",
            user_id: `${user.id}`,
          },
          { headers: { Authorization: "Token " + localStorage.token } }
        )
        .then((res) => {
          this.setState({
            claim: "",
            user: "",
            // loading: false,
            claimCreated: true,
          });
          toast.success("Claim Sent", { position: toast.POSITION.TOP_RIGHT });
        })
        .catch((err) => {
          console.log(err);
          // this.setState({ loading: false });
          toast.error("failed to add claim", {
            position: toast.POSITION.TOP_RIGHT,
          });

        });

    }
  };
  nextPath(path) {
    this.setState({ textClaim: false });
    this.props.history.push(path);
  }
  nextPath1(path) {
    this.setState({ textClaim: true });
    this.props.history.push(path);
  }

  render() {
    const { claim, claimCreated, textClaim } = this.state;
    return (
      <>
        {!claimCreated && (
          <>
            <div>
              <div className="heading-contanier">
                <h2>Create a new claim</h2>
              </div>
              <div className="title-contanier">
                <Typography style={{ fontWeight: 600 }}>
                  Choose how you would like to submit your claim
                </Typography>
              </div>
              <div style={{ marginLeft: "30px", marginTop: "20px" }}>
                <Grid item style={{ display: "flex", marginRight: "5%" }}>
                  <Button
                    fullWidth={false}
                    size="medium"
                    variant={textClaim ? "contained" : "outlined"}
                    style={{
                      minWidth: "fit-content",
                      marginRight: "3%",
                      height: 35,
                      textTransform: "none",
                    }}
                    color="primary"
                    onClick={() => this.nextPath1("/create/claim")}
                  >
                    Write your own claim
                  </Button>

                  <Button
                    fullWidth={false}
                    size="medium"
                    variant={!textClaim ? "contained" : "outlined"}
                    style={{
                      minWidth: "fit-content",
                      marginRight: "3%",
                      height: 35,
                      textTransform: "none",
                    }}
                    color="primary"
                    onClick={() => this.nextPath("/create/claim")}
                  >
                    Upload a file
                  </Button>
                </Grid>
              </div>
              <div>
                <Grid
                  container
                  style={{ marginLeft: "30px", marginTop: "20px" }}
                >
                  {textClaim ? (
                    <Grid item xs={12}>
                      <Typography
                        style={{ fontWeight: 600 }}
                        className="textfield"
                        variant="body2"
                        gutterBottom
                      >
                        Write your own claim
                      </Typography>
                      <TextField
                        value={claim}
                        onChange={(e) =>
                          this.setState({ claim: e.target.value })
                        }
                        id="outlined-full-width"
                        placeholder=" "
                        className="textfiled"
                        style={{
                          marginLeft: 0,
                          backgroundColor: "white",
                          width: "90%",
                        }}
                        multiline
                        rows="7"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Typography
                        style={{ fontWeight: 600 }}
                        className="textfield"
                        variant="body2"
                        gutterBottom
                      >
                        Upload an image
                      </Typography>
                      <Grid item xs={12} style={{ display: "flex" }}>
                        <Grid item xs={4}>
                          <Card
                            id="card"
                            style={{
                              backgroundColor: "white",
                              width: "100%",
                              height: "100%",
                            }}
                            rows="2"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            {!this.state.imageurl ? (
                              <div className="card-section">
                                <img
                                  width="50"
                                  src={require("../assets/uploadimg.png")}
                                  alt=""
                                />
                                <div>
                                  <div>
                                    <label
                                      style={{
                                        width: "100%",
                                        cursor: "pointer",
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        textAlign: "center",
                                      }}
                                    >
                                      Drop your image here
                                      <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="image"
                                        onChange={(e) => {
                                          this.fileChangedHandler(e);
                                        }}
                                        required
                                      />
                                    </label>
                                  </div>
                                  or
                                </div>
                                <a
                                  style={{
                                    color: "#3360F3",
                                    fontWeight: "100",
                                  }}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={"//images.logically.ai/"}
                                >
                                  {" "}
                                  Browse{" "}
                                </a>
                              </div>
                            ) : (
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  maxHeight: 350,
                                }}
                                src={this.state.imageurl}
                                alt=""
                              ></img>
                            )}
                          </Card>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            id="outlined-full-width"
                            // value={claim}
                            onChange={(e) =>
                              this.setState({ claim: e.target.value })
                            }
                            placeholder=" write your claim here"
                            className="textfiled"
                            style={{
                              marginLeft: 20,
                              width: "90%",
                              backgroundColor: "white",
                            }}
                            multiline
                            rows="7"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </div>
              {this.state.isValidClaim && (
                <div>
                  <small style={{ color: "red", marginLeft: "30px" }}>
                    Claim should alteast contain 5 words
                  </small>
                </div>
              )}
              <div style={{ marginLeft: "30px", marginTop: "20px" }}>
                <Grid item style={{ display: "flex", marginRight: "5%" }}>
                  <Button
                    fullWidth={false}
                    size="medium"
                    variant={"contained"}
                    style={{
                      minWidth: "fit-content",
                      marginRight: "3%",
                      height: 35,
                      textTransform: "none",
                    }}
                    color="secondary"
                    onClick={this.countWords}
                  // onClick={() => {
                  //   if (this.state.textClaim) {
                  //     this.countWords();
                  //   } else {
                  //     this.createClaim();
                  //   }
                  // }}
                  >
                    Create claim
                  </Button>
                  <Button
                    className="a"
                    onClick={() => this.nextPath("/")}
                    fullWidth={false}
                    size="medium"
                    variant={"outlined"}
                    style={{
                      minWidth: "fit-content",
                      marginRight: "3%",
                      height: 35,
                      textTransform: "none",
                    }}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </Grid>
              </div>
            </div>
          </>
        )}
        {claimCreated && <Createdclaim />}
      </>
    );
  }
}

export default connect()(CreateClaim);
