import { Button, TextField } from '@material-ui/core';
import React, { Component } from 'react'

class ClaimAppearance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: this.props.claimAppearanceSingleItem.url
        }
    }
    handleOnChange = (e) => {
        let obj = { [e.target.name]: e.target.value }
        this.setState(obj)
    }
    sendToParent = (e) => {
        let obj = { [e.target.name]: e.target.value, "@type": "CreativeWork" }
        this.props.handleOnChange(obj)
    }
    render() {
        const { url } = this.state
        return (
            <div style={{ display: 'flex', marginTop: 16, marginBottom: 16, alignItems: 'center', gap: 24 }}>
                <div style={{ width: 500 }}>
                    <TextField
                        label='Claim appearance'
                        variant='outlined'
                        fullWidth
                        name='url'
                        value={url}
                        onChange={this.handleOnChange}
                        onBlur={this.sendToParent}
                    />

                </div>
                <Button color='secondary' onClick={this.props.handleRemoveClaimAppearance}>Remove</Button>
            </div>
        )
    }
}

export default ClaimAppearance;