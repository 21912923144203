import React, { Component } from 'react';
import Claim from './components/ClaimReceived/Claim';
import DetailClaimReceived from './components/DetailClaimReceived/DetailClaimReceived';
import SideBar from './components/sideBar/sideBar';
import NotesEditor from './components/NotesEditors/NotesEditors';
import Judgement from './components/Judgement/Judgement';
import { Grid } from '@material-ui/core';
import axios from 'axios';
import api, { getBase } from './../api';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Avatar from '@material-ui/core/Avatar';
import * as firebase from 'firebase';
import Complaints from './components/complaints/Complaints';
import moment from 'moment';
import { db } from '../firebaseConfig';
import { connect } from 'react-redux';
// import { green } from '@material-ui/core/colors';
import { getVerdictAndPosition } from '../utils/language';
import SEO from './components/Seo/SEO';

let config = {
  apiKey: 'AIzaSyBlQ53tZTHTjxY1SKcyV1p3w4zrfGiDd0U',
  authDomain: 'logically-dd101.firebaseapp.com',
  databaseURL: 'https://logically-dd101.firebaseio.com',
  projectId: 'logically-dd101',
  storageBucket: 'logically-dd101.appspot.com',
  messagingSenderId: '897764926083',
  appId: '1:897764926083:web:b98e9dad712370d822f1b6',
  measurementId: 'G-Z736LXFP67',
};
const app3 = firebase.initializeApp(
  {
    ...config,
    databaseURL: 'https://logically-dd101-f4b65.firebaseio.com/',
  },
  'app3'
);
const db2 = firebase.database(app3);

const styles = (theme) => ({
  paper: {
    backgroundColor: '#f8f8f8',
    width: 'auto',
    paddingLeft: 10,
    marginTop: 10,
    paddingTop: 5,
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  papers: {
    backgroundColor: '#f8f8f8',
    width: 'auto',
    paddingLeft: 7,
    paddingTop: 5,
    paddingBottom: 5,
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
    marginTop: 15,
    boxShadow: 'none',
  },
  root: {
    fontSize: 14,
    flexGrow: 1,
  },
  para: {
    fontSize: 15,
  },
  fontFamily: ['Roboto'].join(','),
  '&:focus': {
    borderRadius: 4,
    borderColor: '#80bdff',
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
  },
  icon: {
    position: 'relative',
    top: 5,
    right: 4,
  },
  title: {
    marginTop: 5,
    marginLeft: 5,
    textTransform: 'none',
  },
  text: {
    color: '#FFFFFF',
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoadFailed: false,
      data: false,
      loading: this.props.loading,
      users: [],
      backup: false,
      User_Details: '',
      dataff: '',
      userdataff: '',
      publisherImages: [],
      randomNumber: 0,

      lockStat: { isLocked: false, username: '', role: '' },
      inputType: '',
      lastUser: false,
      offline: this.props.offline,
      verdictPosition: 0
    };
  }

  checkLock = (claims) => {
    const currentId = this.props.match.params.id;
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (claims) {
      const { [currentId]: currentClaim } = claims;
      if (currentClaim) {
        if (
          currentClaim.role === 'factchecker' &&
          currentUser.profile.fields.role === 'factchecker'
        ) {
          if (currentClaim.username !== currentUser.username) {
            const now = moment();
            const lockTime = moment(currentClaim.timeStamp);
            const diff = now.diff(lockTime, 'minutes');
            if (diff < 20) {
              this.setState({
                lockStat: {
                  isLocked: true,
                  username: currentClaim.username,
                  role: currentClaim.role,
                },
              });
            }
          }
        }
      }
    } else {
      this.setState({ lockStat: { isLocked: false, username: '', role: '' } });
    }
  };

  fetchFinished = (data) => {
    const { id } = this.props.match.params;
    const cachedData = localStorage.getItem(id);
    if (cachedData) {
      const parsedCache = JSON.parse(cachedData);
      this.setState({ data: parsedCache });
    }
  };

  componentDidMount() {
    this.props.dispatch({ type: 'SET_LOADING', loading: true });
    db2.ref('Semaphore').on('value', (snap) => {
      const claims = snap.val();
      this.checkLock(claims);
    });
    this.setState({ lastUser: JSON.parse(localStorage.getItem('user')) }); // Handle Logout edge case
    if (this.props.match.params.id.length > 10) {
      axios
        .get(getBase(api.CLAIM + this.props.match.params.id))
        .then((res) => {
          if (res.data.data.reference_urls) {
            this.setState({
              data: { ...res.data.data, verdictPosition: getVerdictAndPosition(res.data.data.lang_code, res.data.data.verdict).position },
              backup: JSON.stringify(res.data.data)
            });
            this.props.dispatch({ type: 'SET_LOADING', loading: false });
            this.getPublisherImage(res.data.data);
            let ref = 'Users/' + this.state.data.user_id + '/user_profile';
            let dataf = '';
            db.ref(ref).once('value', (snapshot) => {
              dataf = snapshot.val();
              this.setState({
                dataff: dataf,
                userdataff: dataf.username,
                news_location: dataf.news_location,
              });
              axios
                .get(
                  'http://35.231.51.175:8000/api/get_user_email?username=' +
                  this.state.userdataff
                )
                .then((res) => {
                  // console.log('firebase user details', res.data.email);
                  this.setState({ emailid: res.data.email });
                });
            });
          } else {
            this.props.dispatch({ type: 'SET_LOADING', loading: false });
          }
        })
        .catch((err) => {
          this.props.dispatch({ type: 'SET_LOADING', loading: false });
          !this.props.offline && this.setState({ dataLoadFailed: true });
        });
    } else if (this.props.match.params.id.length <= 9) {
      axios
        .get(
          getBase(
            api.CLAIM + this.props.match.params.id + '?type=image&limit=100'
          )
        )
        .then((res) => {
          if (res.data.data) {
            this.setState({
              data: { ...res.data.data, verdictPosition: getVerdictAndPosition(res.data.data.lang_code, res.data.data.verdict).position },
              backup: JSON.stringify(res.data.data)
            });
            this.props.dispatch({ type: 'SET_LOADING', loading: false });
            this.getPublisherImage(res.data.data);
            let ref = 'Users/' + this.state.data.user_id + '/user_profile';
            let dataf = '';
            db.ref(ref).once('value', (snapshot) => {
              dataf = snapshot.val();

              this.setState({
                dataff: dataf,
                userdataff: dataf.username,
                news_location: dataf.news_location,
              });
              axios
                .get(
                  'http://35.231.51.175:8000/api/get_user_email?username=' +
                  this.state.userdataff
                )
                .then((res) => {
                  console.log('firebase user details', res.data.email);
                  this.setState({ emailid: res.data.email });
                });
            });
          } else {
            this.props.dispatch({ type: 'SET_LOADING', loading: false });
          }
        })
        .catch((err) => {
          this.props.dispatch({ type: 'SET_LOADING', loading: false });
          !this.props.offline &&
            this.setState({
              dataLoadFailed: true,
            });
        });
    }
    axios
      .get(getBase(api.USERS), {
        headers: { Authorization: 'Token ' + localStorage.token },
      })
      .then((res) => {
        this.setState({ users: res.data.data });
        this.fetchFinished(res.data.data);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.inputType !== this.state.inputType) {
      this.updateSemaphore('update');
    }
  }

  componentWillUnmount() {
    this.updateSemaphore('delete');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.offline !== this.state.offline) {
      this.setState({ offline: nextProps.offline });
    }
    if (!nextProps.offline && this.state.offline && !this.state.data) {
      this.componentDidMount();
    }
  }

  updateSemaphore = (type) => {
    const { id } = this.props.match.params;
    const { lastUser } = this.state;
    const timeStamp = new Date();
    const localUser = JSON.parse(localStorage.getItem('user'));
    const user = localUser ? localUser : lastUser;
    const semaphoreData = {
      timeStamp: timeStamp,
      username: user.username,
      role: user.profile.fields.role,
    };
    let updates = {};
    updates[`/Semaphore/${id}`] = type === 'update' ? semaphoreData : null;
    db2.ref().update(updates);
  };

  updateData = (data, inputType) => {
    const { id } = this.props.match.params;
    console.log('updateData', data, inputType)
    localStorage.setItem(id, JSON.stringify(data));
    this.setState({ data, inputType }, () => {
      console.log('updateData', data)
    });
  };

  handleSave = () => { };
  handleClick = (title) => {
    this.setState((state) => ({ [title]: !state[title] }));
  };

  handleRefChange = () => {
    const { data } = this.state;
    this.getPublisherImage(data);
  };

  getPublisherImage = async (data) => {
    let publisherImages = [];
    const iconStyles = {
      width: '30px',
      height: '30px',
      borderRadius: 0,
    };
    for (const item of data.editor_reference_urls) {
      const cancelTokenSrc = axios.CancelToken.source();
      setTimeout(() => cancelTokenSrc.cancel(), 5000);
      const reqUrl =
        'https://cors-anywhere-dot-corenlp-processing.appspot.com/https://www.google.com/s2/favicons?domain=' +
        item.url
          .replace('http://', '')
          .replace('https://', '')
          .split(/[/?#]/)[0];
      try {
        // const { data } = await axios.get(reqUrl, {
        //   cancelToken: cancelTokenSrc.token,
        // });
        let image = (
          <img
            src={
              'https://cors-anywhere-dot-corenlp-processing.appspot.com/https://www.google.com/s2/favicons?domain=' +
              item.url
                .replace('http://', '')
                .replace('https://', '')
                .split(/[/?#]/)[0]
            }
            alt="test"
            style={{ margin: '0 auto', width: '30px', height: '30px' }}
          />
        );
        // if (data.substring(1, 4) !== 'PNG') {
        image = <Avatar style={iconStyles}>{item.source_name[0]}</Avatar>;
        // }
        publisherImages.push({ item, image });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Image Retrieval cancelled due to timeout');
          const image = (
            <Avatar style={iconStyles}>{item.source_name[0]}</Avatar>
          );
          publisherImages.push({ item, image });
        } else {
          console.log(error);
        }
      }
    }
    this.setState({ publisherImages });
  };

  render() {
    const {
      dataLoadFailed,
      dataff,
      emailid,
      news_location,
      publisherImages,
      lockStat,
      loading,
    } = this.state;
    const data = !this.props.offline
      ? this.state.data
      : this.props.location.state.dataFromCard;
    let user = JSON.parse(localStorage.user);
    user = user.profile.fields.role;
    const { classes, offline } = this.props;
    if (lockStat.isLocked) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ textAlign: 'center' }}>
          <Typography variant="h4" style={{ margin: '30vh' }}>
            This claim is being edited by {lockStat.username}. Please check back
            in some time.
          </Typography>
        </Grid>
      );
    }

    if (data === false) {
      return null;
    }

    if (dataLoadFailed === true && loading === false && !this.props.offline) {
      return (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <Typography>
            Oops, looks like something went wrong. Please try again later
          </Typography>
        </div>
      );
    }

    return (
      <div style={{ backgroundColor: '#F1F4F8' }}>
        <div id="FactCheck" style={{ paddingBottom: 20 }}>
          <Grid container>
            <Grid item xs={12} md={10}>
              <div className="left-content-side" style={{ padding: 15 }}>
                <ClaimStatus data={data} User_Details={dataff} />
                <Claim
                  data={data}
                  User_Details={dataff}
                  email={emailid}
                  news_location={news_location}
                  handleChange={(loc) => this.updateData(loc, 'loc')}
                />
                {data.complaints && user === 'supervisor' && (
                  <Complaints
                    data={data}
                    handleChange={(complaint) =>
                      this.updateData(complaint, 'complaint')
                    }
                  />
                )}
                <DetailClaimReceived
                  data={data}
                  handleChange={(complaint) =>
                    this.updateData(complaint, 'complaint')
                  }
                />
                <NotesEditor
                  data={data}
                  handleChange={(d) => this.updateData(d, 'notes')}
                  onHandleRefChange={this.handleRefChange}
                />
                <Judgement
                  data={data}
                  handleChange={(d) => this.updateData(d, 'judgement')}
                  publisherImages={publisherImages}
                />
                <SEO
                  data={data}
                  handleChange={(d) => this.updateData(d, 'seo')}
                />
              </div>

              {!offline && (
                <Button
                  style={{
                    backgroundColor: '#F62459',
                    marginLeft: '80%',
                    width: 120,
                  }}
                  className={classes.button}
                  onClick={() =>
                    this.setState({ randomNumber: Math.random() })
                  }>
                  <Typography className={classes.text}>
                    <b>Save</b>
                  </Typography>
                </Button>
              )}
            </Grid>
            <Grid item xs={12} md={2}>
              {!offline && (
                <SideBar
                  data={data}
                  updateSemaphore={this.updateSemaphore}
                  save={(d) =>
                    this.setState({ data: d, backup: JSON.stringify(d) })
                  }
                  backup={this.state.backup}
                  handleChange={(d) => this.updateData(d, 'sidebar')}
                  handleSave={this.handleSave}
                  shouldSave={this.state.randomNumber}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

class ClaimStatus extends Component {
  render() {
    const { data } = this.props;
    return (
      <div>
        <text style={{ paddingRight: 40, fontWeight: 'bold' }}>Status</text>
        <FormControlLabel
          style={{ fontWeight: 'bold' }}
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="default" />}
              checkedIcon={
                <CheckBoxIcon fontSize="default" style={{ color: '#3FC380' }} />
              }
              checked={true}
              disabled
            />
          }
          label={<Typography style={{ fontWeight: 'bold' }}>New</Typography>}
        />
        <FormControlLabel
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="default" />}
              checkedIcon={
                <CheckBoxIcon fontSize="default" style={{ color: '#3FC380' }} />
              }
              checked={data.assigned}
              disabled
            />
          }
          label={
            <Typography style={{ fontWeight: 'bold' }}>In-progress</Typography>
          }
        />

        <FormControlLabel
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="default" />}
              checkedIcon={
                <CheckBoxIcon fontSize="default" style={{ color: '#3FC380' }} />
              }
              checked={data.judgement}
              disabled
            />
          }
          label={
            <Typography style={{ fontWeight: 'bold' }}>In-review</Typography>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon ffontSize="default" />}
              checkedIcon={
                <CheckBoxIcon fontSize="default" style={{ color: '#3FC380' }} />
              }
              checked={data.verification}
              disabled
            />
          }
          label={
            <Typography style={{ fontWeight: 'bold' }}>Completed</Typography>
          }
        />
      </div>
    );
  }
}

// class GoTop extends Component {
//   state = {
//     intervalId: 0,
//     thePosition: false,
//   };

//   componentDidMount() {
//     document.addEventListener("scroll", () => {
//       if (window.scrollY > 170) {
//         this.setState({ thePosition: true });
//       } else {
//         this.setState({ thePosition: false });
//       }
//     });
//     window.scrollTo(0, 0);
//   }

//   onScrollStep = () => {
//     if (window.pageYOffset === 0) {
//       clearInterval(this.state.intervalId);
//     }
//     window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
//   };

//   scrollToTop = () => {
//     let intervalId = setInterval(this.onScrollStep, this.props.delayInMs);
//     this.setState({ intervalId: intervalId });
//   };

//   renderGoTopIcon = () => {
//     if (this.state.thePosition) {
//       return (
//         <div className="go-top" onClick={this.scrollToTop}>
//           <ArrowUpwardTwoToneIcon />
//         </div>
//       );
//     }
//   };

//   render() {
//     return <React.Fragment>{this.renderGoTopIcon()}</React.Fragment>;
//   }
// }

// function getUserByID(id, users) {
//   let ret = false;
//   for (var i = 0; i < users.length; i++) {
//     if (users[i].user_id === id) {
//       ret =
//         users[i].first_name.length > 0
//           ? users[i].first_name + " " + users[i].last_name
//           : users[i].username;
//     }
//   }
//   return ret;
// }

const mapStateToProps = (data) => {
  return {
    loading: data.loading,
    offline: data.offline,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(App));
