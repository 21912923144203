import React from "react";
import Typography from "@material-ui/core/Typography";
import "./notification.css";
import { Link } from "react-router-dom";
import { getTitle } from "../../utils/notifications"

// const conjunction = {
//   Rejected: "was",
//   Accepted: "was",
//   Deleted: "was",
//   "Requested-Approval": "was",
//   Approved: "your",
//   Finalized: "your",
//   Assigned: "a",
//   "Updated": "a"
// };

// const getTitle = (data) => {
//   if (data.action_by) {
//     let sentence = "";
//     if (!conjunction[data.action_by]) {
//       const items = data.action_by.split(" ");
//       sentence =
//         items[items.length - 1] +
//         " " +
//         data.action.replace(" claim", "") +
//         " " +
//         conjunction[items[0]] +
//         " claim - " +
//         data.tracking_id;
//     } else
//       sentence =
//         "claim - " +
//         data.tracking_id +
//         " " +
//         conjunction[data.action_by] +
//         " " +
//         data.action;
//     return sentence;
//   }
// };

const Notification = ({ data }) => {
  return (
    <Link to={"/claim/" + data.claim_id}>
      <div className="notification-item">
        <div className="notif-text-message">
          <Typography style={{ fontWeight: "bold" }}>
            {getTitle(data)}
          </Typography>
          <Typography>{data.title}</Typography>
        </div>
      </div>
    </Link>
  );
};

export default Notification;
