const conjunction = {
    Rejected: "was",
    Accepted: "was",
    Deleted: "was",
    "Requested-Approval": "was",
    Approved: "your",
    Finalized: "your",
    Assigned: "a",
    "Updated": "a"
};

export const getTitle = (data) => {
    if (data.action_by) {
        let sentence = "";
        if (!conjunction[data.action_by]) {
            const items = data.action_by.split(" ");
            sentence =
                items[items.length - 1] +
                " " +
                data.action.replace(" claim", "") +
                " " +
                conjunction[items[0]] +
                " claim - " +
                data.tracking_id;
        } else
            sentence =
                "Claim - " +
                data.tracking_id +
                " " +
                conjunction[data.action_by] +
                " " +
                data.action;
        return sentence;
    }
};
