/** @format */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import api, { getBase, getUser } from '../../../api';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FlagIcon } from 'react-flag-kit';
import { filter, handleSort } from '../NewClaims/NewClaims';
import { getLocation } from '../../../utils/common';
import Filter from '../Filter/filter';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import HtmlTooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  fontFamily: ['Roboto'].join(','),
  '&:focus': {
    borderRadius: 4,
    borderColor: '#80bdff',
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
  },
  actions: {
    display: 'flex',
  },
});

class RejectedClaims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.rejectedclaim
        ? JSON.parse(this.props.rejectedclaim)
        : [],
      loading: this.props.loading,
      expanded: false,
      offset: 0,
      total: 0,
      claim_checked: [],
      selectedValue: false,
      highlights: false,
      disabled: false,
      startDate: new Date(),
      dataPreview: [],
      getDate: new Date(),
      modalIsOpen: false,
      Deselectchecked: false,
      isFilterApply: false,
      queryValue: '',
      pageNumbers: [],
      location: '',
      showAcceptedClaims: false,
      reason: false,
      clearFilter: 0,
      dataSort: {
        country: 'asc',
        reason: 'asc',
      },
      offline: this.props.offline,
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
    sessionStorage.setItem('invisible', 'true');
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
    sessionStorage.clear();
  };
  clearedFilter() {
    const { data } = this.state;

    if (this.props.clearFilter === 0) {
      if (this.props.searchData.length === 0) {
        this.setState({ clearFilter: this.props.clearFilter }, () => {
          if (data.length === 0) {
            if (this.props.queryValue === '') {
              this.getData(this.state.selectionRange, this.props.queryValue);
            }
          }
        });
      }
    } else {
      if (this.props.queryValue === '') {
        this.getData(this.state.selectionRange, this.props.queryValue);
      }
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: "VIEW_BUTTON",
      activeButton: "URL claims",
    });
    this.props.onHandleClearFilters()
    this.getData(null);
  }
  componentWillReceiveProps(nextProps) {
    // if (nextProps.searchData.length > 0 && !nextProps.clearFilter) {
    //   this.setState({ data: nextProps.searchData }, () =>
    //     this.props.dispatch({ type: 'SET_LOADING', loading: false })
    //   );
    // }
    // if (nextProps.clearFilter) {
    //   this.getData(null);
    // }
    const { enableRedux } = this.state
    const { searchData, clearFilter, searchFilter } = nextProps;
    if (searchData !== this.props.searchData && searchFilter && !clearFilter) {
      this.setState({ data: searchData }, () =>
        this.props.dispatch({ type: "SET_LOADING", loading: false })
      );
    }
    if (clearFilter && !this.props.clearFilter) {
      this.props.dispatch({ type: "SET_LOADING", loading: true })
      this.getData();
    }
    if (nextProps.loading !== this.state.loading) {
      this.setState({ loading: nextProps.loading });
    }
    if (nextProps.activeButton !== this.state.filterTabs) {
      this.setState({ filterTabs: nextProps.activeButton });
    }
    if (nextProps.rejectedclaim && enableRedux && clearFilter) {
      let reduxData = JSON.parse(nextProps.rejectedclaim);
      this.setState({ data: reduxData }, () =>
        this.props.dispatch({ type: 'SET_LOADING', loading: false })
      );
    }
    if (nextProps.offline !== this.state.offline) {
      this.setState({ offline: nextProps.offline });
    }
    if (!nextProps.offline && this.state.offline) {
      this.getData(null);
    }
  }

  clearedFilterOld() {
    if (this.props.clearFilter === 0) {
      if (this.props.searchData.length === 0) {
        this.setState({ clearFilter: this.props.clearFilter }, () => {
          if (this.state.data.length === 0) {
            if (this.props.queryValue === '') {
              this.getData(null);
            }
          }
        });
      }
    } else {
      if (this.props.queryValue === '') {
        this.getData(null);
      }
    }
  }

  handleClicks(offset) {
    this.setState({ offset }, () => {
      this.getData(this.props.queryValue);
    });
  }
  handleChangedate = (date) => {
    let Dates = JSON.stringify(date);
    Dates = Dates.toString()
      .replace('T', ' ')
      .split(' ')[0];
    Dates = Dates.toString().replace('"', '');
    this.setState({
      getDate: Dates,
    });
  };
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  getDataPreview = () => {
    let selectedValue = this.state.selectedValue;
    let CheckedClaim = this.state.claim_checked;
    if (selectedValue) {
      let _id = selectedValue;
      let _ids = CheckedClaim;
      let datas = {
        _id: _id,
        _ids: _ids,
        location: 'IN',
      };
      axios
        .post(getBase(api.SHOWPREVIEW), datas, {
          headers: { Authorization: 'Token ' + localStorage.token },
        })
        .then((res) => {
          this.setState({
            dataPreview: res.data.data,
            modalIsOpen: true,
          });
          this.props.dispatch({ type: 'SET_LOADING', loading: false });
          sessionStorage.setItem('invisible', 'true');
        })
        .catch((err) => {
          toast.error('Error, could not Saved Changes', {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      this.setState({
        modalIsOpen: true,
      });
      this.props.dispatch({ type: 'SET_LOADING', loading: false });
    }
  };
  multiFilteredData = (val) => {
    const { searchData } = this.props;
    if (searchData.length > 0) {
      this.props.dispatch({ type: 'NAVCLAIMS', navClaims: val });
    } else {
      this.getData(null);
    }
  };
  getData = async (queryValue) => {
    let { reason, isFilterApply, offset, data, loading } = this.state;
    this.props.dispatch({
      type: 'SET_LOADING_SPIN',
      loading_spin: true,
    });
    try {
      let url = '';
      let header = '';
      if (isFilterApply) {
        url = getUser(
          api.SEARCH_FACTCHECKS +
          '?tab=completed&location=IN&limit=100&offset=' +
          offset +
          '&' +
          queryValue
        );
        header = { headers: {} };
      } else {
        if (reason === 'Reason 1') {
          reason = 'We cannot find a place where this claim was made';
          url = getBase(
            api.MODERATOR +
            `rejected_claims?reject_reason=${reason}&limit=100&offset=${offset}`
          );
        } else if (reason === 'Reason 2') {
          reason = 'We don’t understand this claim';
          url = getBase(
            api.MODERATOR +
            `rejected_claims?reject_reason=${reason}&limit=100&offset=${offset}`
          );
        } else if (reason === 'Reason 3') {
          reason = 'This claim is beyond our scope to check';
          url = getBase(
            api.MODERATOR +
            `rejected_claims?reject_reason=${reason}&limit=100&offset=${offset}`
          );
        } else if (reason === 'Reason 4') {
          reason = 'We can’t check this claim in a responsible way';
          url = getBase(
            api.MODERATOR +
            `rejected_claims?reject_reason=${reason}&limit=100&offset=${offset}`
          );
        } else if (reason === 'Reason 5') {
          reason = 'none';
          url = getBase(
            api.MODERATOR +
            `rejected_claims?reject_reason=${reason}&limit=100&offset=${offset}`
          );
        } else {
          url = getBase(
            api.MODERATOR +
            `rejected_claims?reject_reason=&limit=100&offset=${offset}`
          );
        }
        header = { headers: { Authorization: 'Token ' + localStorage.token } };
      }
      const res = await axios.get(url, header);
      if (this.props.rejectedclaim !== JSON.stringify(res.data.data)) {
        if (JSON.stringify(data) !== JSON.stringify(res.data.data)) {
          this.props.setBadge(res.data.total_count);
          this.props.dispatch({
            type: 'REJECTED_CLAIMS',
            rejected: JSON.stringify(res.data.data),
          });
          this.props.dispatch({
            type: 'SET_LOADING_SPIN',
            loading_spin: false,
          });
          for (let i = 1; i <= Math.ceil(res.data.total_count / 100); i++) {
            this.state.pageNumbers.push(i);
          }
          let allData = [];
          allData = data;
          allData = [];
          for (var i = 0; i < res.data.data.length; i++) {
            let done = false;
            for (var b = 0; b < allData.length; b++) {
              if (allData[b]._id === res.data.data[i]._id) {
                done = true;
              }
            }
            if (!done) {
              allData.push(res.data.data[i]);
            }
          }
          this.setState({
            data: allData,
            total: res.data.total_count,
          });
          let claim_checked = this.state.claim_checked;
          let selectedValue = this.state.selectedValue;
          for (let i in res.data.data) {
            if (
              res.data.data[i].highly_rated === true &&
              moment(res.data.data[i].last_updated).format('YYYY-MM-DD')
            ) {
              claim_checked.push(res.data.data[i]._id);
            }

            if (
              res.data.data[i].highest_rated === true &&
              moment(res.data.data[i].last_updated).format('YYYY-MM-DD')
            ) {
              selectedValue = res.data.data[i]._id;
            }
          }
          this.setState(
            {
              claim_checked: claim_checked,
              selectedValue: selectedValue,
            },
            () => {
              this.props.dispatch({ type: 'SET_LOADING', loading: false });
              this.props.dispatch({
                type: 'SET_LOADING_SPIN',
                loading_spin: false,
              });
            }
          );
        } else if (loading) {
          this.props.dispatch({ type: 'SET_LOADING', loading: false });
          this.props.dispatch({
            type: 'SET_LOADING_SPIN',
            loading_spin: false,
          });
        }
      } else {
        let reduxData = JSON.parse(this.props.rejectedclaim);
        this.setState({ data: reduxData, loading: false }, () => {
          this.props.dispatch({ type: 'SET_LOADING', loading: false });
          this.props.dispatch({
            type: 'SET_LOADING_SPIN',
            loading_spin: false,
          });
        });
      }
    } catch (err) {
      this.props.dispatch({ type: 'SET_LOADING', loading: false });
      this.props.dispatch({
        type: 'SET_LOADING_SPIN',
        loading_spin: false,
      });
    }
  };
  handleScroll = () => {
    let marginBottom =
      document.body.scrollHeight - window.scrollY - window.innerHeight;
    if (marginBottom < 500 && this.state.loading === false) {
      this.getData();
      if (this.state.total !== this.state.data.length && this.props.current) {
        this.getData();
      }
    }
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };
  ifError() {
    const { data } = this.state;

    const { classes } = this.props;
    if (data.length === 0) {
      return (
        <div className={classes.root}>
          <Typography>No records found!</Typography>
        </div>
      );
    }
    if (data === false) {
      return (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <Typography>
            Oops, looks like something went wrong. Please try again later
          </Typography>
        </div>
      );
    }
    return null;
  }
  handleShowAcceptedClaims(val) {
    console.log('show accepted claims', val);
    this.setState({ showAcceptedClaims: val }, () => {
      this.getData(null);
    });
  }
  handleLoader() {
    return null;
  }
  handleSort = (type, sort, data) => {
    console.log('click', data);
    let sortedData;
    switch (type) {
      case 'country':
        sortedData = data.sort(
          sort === 'asc'
            ? (a, b) => (a.location > b.location ? 1 : -1)
            : (a, b) => (a.location > b.location ? -1 : 1)
        );
        break;
      case 'reason':
        sortedData = data.sort(
          sort === 'asc'
            ? (a, b) => (a.claim_reject_message > b.reject_message ? 1 : -1)
            : (a, b) => (a.reject_message > b.reject_message ? -1 : 1)
        );
        break;

      default:
        return data;
    }
    this.setState({ data: sortedData });
  };

  render() {
    const { data, dataSort } = this.state;
    const {
      classes,
      filters,
      searchVal,
      filterOptions,
      datapoint,
      loading,
    } = this.props;
    const { dropDown, dropDownOptions } = filterOptions;
    let allData = filter(data, filters, searchVal);
    let faster = {};
    let Complaints = {};
    return (
      <>
        <Filter
          datapoint={datapoint}
          length={data.length}
          filterbutton={(val) => {
            this.setState({ filterTabs: val }, () =>
              this.multiFilteredData(val)
            );
          }}
          countrySelected={(reason) => {
            if (reason !== 'All') {
              this.setState({ reason: reason }, () => {
                this.getData(null);
              });
            } else {
              this.setState({ reason: false }, () => {
                this.getData(null);
              });
            }
          }}
          dropDown={dropDown}
          dropDownOptions={dropDownOptions}
          checkbox={false}
          showingClaims={true}
          checkboxValue={(val) => this.handleShowAcceptedClaims(val)}
        />
        {this.ifError()}
        {this.handleLoader()}
        {!loading && allData.length > 0 && (
          <Grid container>
            <Paper
              className={classes.paper1}
              elevation={0}
              style={{
                marginBottom: 16,
                width: '100%',
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                backgroundColor: faster.backgroundColor,
                border: Complaints.border,
                borderRadius: 10,
              }}>
              <Grid
                container
                spacing={16}
                alignItems="center"
                style={{ padding: 8, marginBottom: '10px', marginTop: '10px' }}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  style={{
                    backgroundColor: '#f1f4f8',
                    borderRadius: '10px',
                    paddingTop: 15,
                    paddingBottom: 15,
                  }}>
                  <Grid container alignItems="center">
                    <Grid item sm={2} md={2}>
                      <Typography>Claim ID</Typography>
                    </Grid>
                    <Grid item sm={7} md={7}>
                      <Typography>Claim</Typography>
                    </Grid>
                    <Grid item sm={1} md={1} style={{ textAlign: 'center' }}>
                      <Typography
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          this.setState({
                            dataSort: {
                              ...dataSort,
                              country:
                                dataSort.country === 'desc' ? 'asc' : 'desc',
                              data: handleSort(
                                'country',
                                dataSort.country,
                                data
                              ),
                            },
                          });
                        }}>
                        Country{' '}
                        {dataSort.country === 'asc' ? (
                          <ArrowDropUpIcon
                            style={{ position: 'relative', top: 5 }}
                            size="small"
                            fontSize="small"
                          />
                        ) : (
                          <ArrowDropDownIcon
                            style={{ position: 'relative', top: 5 }}
                            size="small"
                            fontSize="small"
                          />
                        )}
                      </Typography>
                    </Grid>

                    <Grid item sm={2} md={2} style={{ textAlign: 'center' }}>
                      <Typography
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          console.log('***Reason', data);
                          this.setState({
                            dataSort: {
                              ...dataSort,
                              reason:
                                dataSort.reason === 'desc' ? 'asc' : 'desc',
                              data: handleSort('reason', dataSort.reason, data),
                            },
                          });
                        }}>
                        Reason{' '}
                        {dataSort.reason === 'asc' ? (
                          <ArrowDropUpIcon
                            style={{ position: 'relative', top: 5 }}
                            size="small"
                            fontSize="small"
                          />
                        ) : (
                          <ArrowDropDownIcon
                            style={{ position: 'relative', top: 5 }}
                            size="small"
                            fontSize="small"
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {allData.map((claim, index) => {
                claim.faster_check
                  ? (faster = { backgroundColor: '#fff' })
                  : (faster = {});
                claim.has_complaint
                  ? (Complaints = { border: '1px solid red' })
                  : (Complaints = {});
                return (
                  <Grid container spacing={16} key={index}>
                    <Grid item lg={12} md={12}>
                      <Grid container>
                        <Paper
                          className={classes.paper1}
                          elevation={1}
                          key={index}
                          style={{
                            marginBottom: 16,
                            width: '100%',
                            padding: 8,
                            border: Complaints.border,
                          }}>
                          {/* <Link to={"/claim/" + claim._id}> */}
                          <Link
                            to={{
                              pathname: '/claim/' + claim._id,
                              state: {
                                dataFromCard: claim,
                              },
                            }}>
                            <Grid container alignItems="center">
                              <Grid item sm={2} md={2}>
                                <Typography style={{ marginTop: 5 }}>
                                  {claim.tracking_id}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                sm={7}
                                md={7}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  // paddingLeft: 20,
                                }}>
                                <HtmlTooltip
                                  title={
                                    <Typography
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'white',
                                      }}>
                                      {claim.modified_claim}
                                    </Typography>
                                  }>
                                  <Typography
                                    component="p"
                                    className={
                                      classes.paragraphText + ' maxlines2'
                                    }
                                    style={{ fontWeight: 'bold' }}>
                                    {claim.modified_claim}
                                  </Typography>
                                </HtmlTooltip>
                              </Grid>
                              <Grid
                                item
                                sm={1}
                                md={1}
                                style={{ textAlign: 'center' }}>
                                <Typography
                                  component="p"
                                  className={classes.codeText}>
                                  <FlagIcon
                                    code={getLocation(claim.location)}
                                    size={44}
                                  />
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sm={2}
                                md={2}
                                style={{ textAlign: 'center' }}>
                                <small>{claim.reject_message}</small>
                              </Grid>
                            </Grid>
                          </Link>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Paper>
          </Grid>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    navClaims: state.navClaims,
    loading: state.loading,
    clearFilter: state.clearFilter,
    rejectedclaim: state.rejected,
    offline: state.offline,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(RejectedClaims));
