import React, { Component } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ContextDialog from './contextDialog';
import bg from './imgs/bg.jpg';
import './evidence.css';
import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import MoodBadOutlinedIcon from '@material-ui/icons/MoodBadOutlined';
import { formatDate } from "../EvidenceRetieval/formatData";
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import OutlinedFlagIcon from '@material-ui/icons/OutlinedFlag';
import { Button } from '@material-ui/core';

const styles = {
  button: {
    textTransform: 'capitalize',
    color: 'white',
    borderRadius: '2px',
  },
  evidenceBanner: {
    backgroundImage: `url(${bg})`,
    backgroundSize: `100%`,
    padding: '10px',
    borderRadius: '3px',
  },
  feedback: {
    color: "#B0B4C5",
    paddingRight: "8px"
  },
  feedbackSelected: {
    color: "#006FF9",
    paddingRight: "8px"
  },
  addUrl: {
    color: "#006FF9",
    fontWeight: "bold",
    paddingLeft: "10px"
  },
  helpIcon: {
    color: "#006FF9",
    paddingLeft: "8px",
    height: '18px',
  },
  dialogTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    fontstyle: "bold",
    paddingBottom: "20px"
  },
  dialogTitle2: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  dialogTitle3: {
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: "20px",
  },
  dialogUrlText: {
    fontSize: "17px",
    fontWeight: "bold",
    fontstyle: "bold",
    paddingTop: "20px"
  },
  addUrltext: {
    fontSize: "18px",
    fontWeight: "bold",
    fontstyle: "bold",
    paddingBottom: "0px",
    color: "#006FF9",
    cursor: "pointer"
  },
  dialogButtonCancel: {
    minWidth: "fit-content",
    marginRight: "3%",
    height: 45,
    textTransform: "none",
    color: "#006FF9",
    border: "1px solid #006FF9 "
  },
  dialogButtonSave: {
    minWidth: "fit-content",
    marginRight: "3%",
    height: 45,
    textTransform: "none",
    backgroundColor: "#006FF9",
    color: "white"
  },
  input: {
    height: 45
  },
  highQualitySelected: {
    color: "#006FF9",
    fontSize: 40,
    paddingTop: "10px",
    paddingLeft: "5px",
    cursor: "pointer"
  },
  highQualityNotSelected: {
    color: "grey",
    fontSize: 40,
    paddingTop: "10px",
    paddingLeft: "5px",
    cursor: "pointer"
  },
  headFeed: {
    padding: "8px 8px 8px",
  },
  dividerfeed: {
    color: "#E5E6EB",
    transform: 'rotate(90deg)',
    paddingBottom: "20px"
  },
  flagIcon: {
    color: "#B0B4C5",
    paddingLeft: "4px",
    marginRight: "-8px",
    height: "30px"
  },
  flagIconSelected: {
    color: "#006FF9",
    paddingLeft: "4px",
    marginRight: "-8px",
    height: "30px"
  },
  wrapicon: {
    display: "flex",
    paddingRight: '5px',
    cursor: "pointer"
  }
};
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    width: 120,
    fontSize: 15,
    padding: 15
  },
}))(Tooltip);


class Evidences extends Component {
  state = {
    data: [],
    isDataRetrieved: false,
    currentEvidence: null,
    evidenceIndex: -1,
    progress: 0,
    evidenceLoading: false,
    evidences: [],
    openContextDialog: false,
    selectedEvidences: [],
    timer: null,
    openFeedbackUrls: false,
    cancelDialog: false,
    feedbackUrls: [{ url: "", high_quality: false, ai_component: "evidence_retrieval" }],
    backupFeedbackUrls: [],
    otherUsersUrls: [],
    selectedEvidence: "",
  };

  componentDidMount() {
    this.getClaimDetails();
  }

  componentDidUpdate(prevProps) {
    const { deletedRef } = this.props;
    if (deletedRef !== prevProps.deletedRef) {
      this.externalDelete(deletedRef);
    }
    const { data } = this.state
    if (prevProps.feedback !== this.props.feedback) {
      let dataWithFeedback = data.map(item => {
        const feedback = this.getEvidenceFeedback(item)
        item.feedbacks = feedback.evidence
        item.sourceCredibility = feedback.credibility
        return item
      })
      this.setState({
        data: dataWithFeedback,
      });
    }
  }

  getClaimDetails = async () => {
    const { claimObj } = this.props;
    try {
      const { data } = await axios.post(
        'https://thelogicallydata.co.uk/api/v1/claim/evidences_v3',
        { claim: claimObj.claim || claimObj.body }
      );
      let dataWithFeedback = data.data.map(item => {
        const feedback = this.getEvidenceFeedback(item)
        item.feedbacks = feedback.evidence
        item.sourceCredibility = feedback.credibility
        return item
      })
      this.getEvidenceFeedbackUrls()
      this.setState({
        data: dataWithFeedback,
        isDataRetrieved: true,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isDataRetrieved: true,
      });
    }
  };

  getEvidenceFeedbackUrls = () => {
    const feedback = JSON.parse(JSON.stringify(this.props.feedback))
    let filteredFeedbacks = feedback.feedback_urls && feedback.feedback_urls.filter(fb => {
      return fb.ai_component === "evidence_retrieval"
    }
    )
    if (filteredFeedbacks) {
      this.setState({
        feedbackUrls: filteredFeedbacks.length > 0 ?
          filteredFeedbacks
          :
          [{ url: "", high_quality: false, ai_component: "evidence_retrieval" }]
      })
    }
  }

  getEvidenceFeedback = (ev) => {
    const { feedback } = this.props
    const { id: userId } = JSON.parse(localStorage.user);
    let evidence = ""
    let credibility = ""
    const evidenceId = ev.passage.doc_id
    const credibilityDomain = ev.passage.doc_domain.replace(".", "_")
    if (feedback.feedback_collected) {
      evidence = feedback.feedback_collected.find(item => item._id === `${userId}_evidence_retrieval_${evidenceId}`)
      credibility = feedback.feedback_collected.find(item => item._id === `${userId}_source_credibility_${credibilityDomain}`)
    }
    return { evidence, credibility }
  }

  isValidURL(string) {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };

  errorUrls = (urls, type) => {
    //No validation for first URL as it will always be blank
    if (urls.length === 1 && urls[0].url === "" && type !== "addUrl") {
      return
    }
    let urlList = []
    urls.map(url => {
      urlList.push(url.url) //getting all URLS to find duplicates
    })
    urls.map(url => {
      const repetetion = urlList.reduce((n, x) => n + (x === url.url), 0) //finds the no. of times an element is repeated in array
      if ((!url.user_details) && repetetion > 1) { //no user_details implies its a new entry
        return urls[urlList.lastIndexOf(url.url)].error = "This URL is already submitted"
      }
      if (this.isValidURL(url.url)) {
        return delete url.error
      } else {
        url.error = "Please enter a valid URL"
      }
    })
    const errorUrls = urls.filter(url => url.error)
    if (errorUrls.length > 0) return urls
  }

  handlePostFeedback = (e, type, value, evi, rank) => {
    e.stopPropagation()
    const { feedbackUrls: stateUrls, selectedEvidence } = this.state
    const { postFeedbacks, feedback } = this.props;
    const evidence = evi ? evi : selectedEvidence
    const user = JSON.parse(localStorage.user);
    const userDetails = { "id": user.id, "username": user.username }
    let newFeedback, existingFb
    if (type === "urls") {
      if (this.errorUrls(stateUrls)) return this.setState({ feedbackUrls: this.errorUrls(stateUrls) })
    }
    if (type === "credibility") {
      existingFb = feedback.feedback_collected && feedback.feedback_collected.find(item => item._id === `${user.id}_source_credibility_${evidence.passage.doc_domain.replace(".", "_")}`)
    }
    else if (type === "feedback") {
      existingFb = feedback.feedback_collected && feedback.feedback_collected.find(item => item._id === `${user.id}_evidence_retrieval_${evidence.passage.doc_id}`)
    }
    if (existingFb) {
      newFeedback = {
        ...existingFb,
      }
      if (type === "feedback" || type === "credibility") {
        newFeedback.feedback_given = {
          feedback_label: value
        }
      }

    }
    else {
      const { feedbackUrls, feedbacks, ...cleanedEvidence } = evidence
      if (type !== "urls")
        newFeedback = {
          _id: `${user.id}_${type === "credibility" ? `source_credibility_${evidence.passage.doc_domain.replace(".", "_")}` : `evidence_retrieval_${evidence.passage.doc_id}`}`,
          user_details: userDetails,
          ai_component: `${type === "credibility" ? "source_credibility" : "evidence_retrieval"}`,
          rank,
          submitted_date: moment().format("YYYY-MM-DD"),
          feedback_for: type === "credibility" ? { "_id": `${cleanedEvidence.passage.doc_domain}`, "credibility_label": `${cleanedEvidence.passage.doc_cred}` } : cleanedEvidence,
          feedback_given: { feedback_label: value }
        }
    }
    stateUrls.map(url => {
      if (url.user_details) return
      url.user_details = userDetails
    })
    postFeedbacks(newFeedback, stateUrls)
    this.setState({ openFeedbackUrls: false })
  }

  postEvidences = async (evidencesArr, id) => {
    const evidencesData = { _id: id, evidences: evidencesArr };
    try {
      await axios.post(
        'https://checker.logically.co.uk/api/update/evidences',
        evidencesData
      );
    } catch (error) {
      console.log(error);
    }
  };

  postDebounce = (evidencesData, id) => {
    const { timer } = this.state;
    clearInterval(timer);
    const newTimer = setTimeout(this.postEvidences, 300, evidencesData, id);
    this.setState({ timer: newTimer });
  };

  removeUnderscores = (str) => str.replace(/_/g, ' ');

  fixDate = (date) => {
    const splitDate = date.split('-');
    if (isNaN(splitDate[0])) return 'N/A';
    else return splitDate.reverse().join('-');
  };

  handleContextClick = (currentEvidence, evidenceIndex) => {
    this.setState({ currentEvidence, evidenceIndex, openContextDialog: true });
  };

  handleClose = () => {
    this.setState({ openContextDialog: false });
  };

  getButtonStyle = (evidence) => {
    const { isRelevant } = evidence;
    if (isRelevant === 'possibly')
      return {
        text: 'High Credibility',
        fontSize: '1%',
        color: '#FFFFFF',
        bg: '#3FC380',
      };
    if (isRelevant === 'yes') return { text: 'Source Added', bg: '#11aa00' };
    if (isRelevant === 'no') return { text: 'No Match', bg: 'red' };
  };

  renderProgressInfo = (evidences, evidenceLoading) => {
    if (evidenceLoading)
      return 'Please wait. We are finding news from credible source... This will take a minute';
    if (!evidenceLoading) {
      if (evidences.length > 0) {
        return `We found ${evidences.length} evidences`;
      } else return 'No matching evidences found';
    }
  };

  getEvidences = async (claimObj) => {
    const { data } = await axios.post(
      'https://thelogicallydata.co.uk/api/v1/claim/evidences',
      {
        _id: claimObj._id,
        claim: claimObj.claim,
      }
    );
    if (data.resultCount === 0) {
      this.setState({
        evidences: [],
        progress: 100,
        evidenceLoading: false,
        showEvidence: true,
        showFacts: false,
        rightScreen: 'evidenceSuccess',
      });
    } else {
      this.setState({
        evidences: data.data.evidences,
        progress: 100,
        evidenceLoading: false,
        showEvidence: true,
        showFacts: false,
        rightScreen: 'evidenceSuccess',
      });
    }
  };

  externalDelete = (deletedRef) => {
    const { data } = this.state;
    if (data.evidence) {
      const index = data.evidences.findIndex(
        (evidence) => evidence.topk_url === deletedRef
      );
      if (index !== -1) {
        data.evidences[index].isRelevant = 'possibly';
        this.setState({ data });
        this.postEvidences(data.evidences, data._id);
      }
    }
  };

  showSummary = (summary, id) => {
    const { currentExpandedNews } = this.state;
    if (id === currentExpandedNews) return summary;
    return summary.substring(0, 130);
  };

  cleanUrl = (url) =>
    'https://www.google.com/s2/favicons?domain=' +
    url
      .replace('http://', '')
      .replace('https://', '')
      .split(/[/?#]/)[0];

  handleExpandNews = (id) => {
    const { currentExpandedNews } = this.state;
    if (id === currentExpandedNews)
      this.setState({
        currentExpandedNews: '',
      });
    else
      this.setState({
        currentExpandedNews: id,
      });
  };

  handleSelect = () => {
    const { data, evidenceIndex, currentEvidence } = this.state;
    data.evidences[evidenceIndex].isRelevant = 'yes';
    this.setState({ data });
    this.postDebounce(data.evidences, data._id);
    this.props.appendReference(currentEvidence);
  };

  nameofsource = (source_name) => {
    let source_url;
    source_url = source_name
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
      .split('/')[0]
      .split('.')[0];
    return source_url;
  };

  handleIgnore = () => {
    const { data, evidenceIndex } = this.state;
    data.evidences[evidenceIndex].isRelevant = 'no';
    this.setState({ data });
    this.postDebounce(data.evidences, data._id);
  };

  handleEvidenceChange = (currentEvidence, evidenceIndex) => {
    this.setState({ currentEvidence, evidenceIndex });
  };

  handleUrlDelete = (index, userDetails) => {
    const { id: userId } = JSON.parse(localStorage.user);
    if (!userDetails || userDetails.id.toString() === userId.toString()) {
      let fbUrls = [...this.state.feedbackUrls]
      fbUrls.splice(index, 1)
      if (fbUrls.length === 0) {
        fbUrls.push({ url: "", high_quality: false, ai_component: "fact_finder" })
      }
      return this.setState({ feedbackUrls: fbUrls })
    } else return
  }

  handleRunEvidence = () => {
    const { currentClaim } = this.state;
    this.getEvidences(currentClaim);
    this.setState({ evidenceRetInit: false, evidenceLoading: true });
  };

  handleSelUndo = () => {
    const { data, evidenceIndex, currentEvidence } = this.state;
    data.evidences[evidenceIndex].isRelevant = 'possibly';
    this.props.onBotRefDelete(currentEvidence);
    this.setState({ data });
    this.postDebounce(data.evidences, data._id);
  };

  handleIgnoreUndo = () => {
    const { data, evidenceIndex } = this.state;
    data.evidences[evidenceIndex].isRelevant = 'possibly';
    this.setState({ data });
    this.postDebounce(data.evidences, data._id);
  };

  creadibilityCap = (string) => {
    const cred = string.charAt(0).toUpperCase() + string.slice(1);
    return cred;
  };

  getCredBgColor = (cred) => {
    if (cred.toLowerCase().indexOf('high') !== -1) {
      return '#3FC380';
    } else if (cred.toLowerCase().indexOf('low') !== -1) {
      return 'red';
    } else {
      return 'orange';
    }
  };

  cleanUrl = (url) =>
    'https://www.google.com/s2/favicons?domain=' +
    url
      .replace('http://', '')
      .replace('https://', '')
      .split(/[/?#]/)[0];

  renderStanceStyle = (label) => {
    if (label === 'agree')
      return { text: 'Reliable content', color: '#FFFFFF', bg: '#19B5FE' };
    if (label === 'disagree') return { text: 'Disagree', bg: 'red' };
    if (label === 'discuss') return { text: 'Discuss', bg: '#FF6F00' };
    return false;
  };

  handleOpenFeebackUrl = (e) => {
    e.preventDefault();
    let fbUrls = this.state.feedbackUrls
    fbUrls = fbUrls.map(url => {
      delete url.error
      return url
    })
    this.setState({ openFeedbackUrls: true, backupFeedbackUrls: JSON.parse(JSON.stringify(fbUrls)), feedbackUrls: fbUrls });
  };

  handleCloseFeebackUrl = () => {
    const { backupFeedbackUrls, feedbackUrls } = this.state
    const differenceUrls = feedbackUrls.filter(({ url: url1, high_quality: quality1 }) => !backupFeedbackUrls.some(({ url: url2, high_quality: quality2 }) => (url2 === url1 && quality1 === quality2)));
    if (differenceUrls.length > 0) {
      this.setState({ openFeedbackUrls: false, cancelDialog: true })
    } else {
      this.setState({ openFeedbackUrls: false });
    }
  };

  handleCancelDialog = (cancelDialog, openFeedbackUrls) => {
    this.setState({ cancelDialog, openFeedbackUrls });
  };

  handleDiscardUrls = () => {
    this.setState({ openFeedbackUrls: false, cancelDialog: false, feedbackUrls: this.state.backupFeedbackUrls })
  }

  //To change the icon color to blue when selected
  getFeedbackStyles = (feedback, evidence) => {
    const { classes } = this.props
    if (evidence.feedbacks && evidence.feedbacks.feedback_given && evidence.feedbacks.feedback_given.feedback_label) {
      if (evidence.feedbacks.feedback_given.feedback_label === feedback) {
        return classes.feedbackSelected
      } else {
        return classes.feedback
      }
    } else {
      return classes.feedback
    }
  }
  getFlagStyles = (credibility) => {
    const { classes } = this.props
    if (credibility === "Low") {
      return classes.flagIconSelected
    } else {
      return classes.flagIcon
    }
  }
  //Updates feedbackUrls state when url is typed
  handleAddUrl = (val, index) => {
    let allUrls = JSON.parse(JSON.stringify(this.state.feedbackUrls))
    let feedbackUrls1 = JSON.parse(JSON.stringify(allUrls[index]))
    feedbackUrls1.url = val
    if (feedbackUrls1.error) delete feedbackUrls1.error
    allUrls[index] = feedbackUrls1
    this.setState({ feedbackUrls: allUrls })
  }

  addAnotherUrl = () => {
    let urls = JSON.parse(JSON.stringify(this.state.feedbackUrls))
    if (this.errorUrls(urls, "addUrl")) return this.setState({ feedbackUrls: this.errorUrls(urls, "addUrl") })
    urls.push({ url: "", high_quality: false, ai_component: "evidence_retrieval" })
    this.setState({ feedbackUrls: urls })
  }

  handleHighQuality = (item, index) => {
    const { id: userId } = JSON.parse(localStorage.user);
    if (item.url === "") return
    if (!item.user_details || item.user_details.id.toString() === userId.toString()) {
      let allUrls = JSON.parse(JSON.stringify(this.state.feedbackUrls))
      let feedbackUrls1 = JSON.parse(JSON.stringify(allUrls[index]))
      feedbackUrls1.high_quality = !feedbackUrls1.high_quality
      allUrls[index] = feedbackUrls1
      this.setState({ feedbackUrls: allUrls })
    }
    else return
  }

  getFeedbackUrlsText = (evidence) => {
    const { feedbackUrls } = this.state
    let filteredUrls = JSON.parse(JSON.stringify(feedbackUrls))
    filteredUrls = filteredUrls.filter((item) => item.url !== "")
    if (filteredUrls.length === 1) {
      return `${filteredUrls.length} URL added`
    }
    else if (filteredUrls.length > 0) {
      return `${filteredUrls.length} URLs added`
    } else {
      return "Add URLs"
    }
  }
  getSourceCredibility = (evidence) => {
    if (evidence.sourceCredibility) {
      if (evidence.sourceCredibility.feedback_given && evidence.sourceCredibility.feedback_given.feedback_label === "Low") {
        return "Low"
      }
    }
    else {
      return ""
    }
  }

  isUrlEditable = (userDetails) => {
    const { id: userId } = JSON.parse(localStorage.user);
    if (!userDetails) {
      return false
    }
    if (userDetails.id.toString() === userId.toString()) {
      return false
    }
    else return true
  }

  render() {
    const {
      data,
      isDataRetrieved,
      currentExpandedNews,
      openContextDialog,
      currentEvidence,
      evidenceIndex,
      feedbackUrls
    } = this.state;
    const { classes } = this.props;
    const longText = `Add URLs of any articles that were missed by our Evidence finder but helped with your research.`
    return (
      <div className="facts-container">
        <><Typography style={{ fontWeight: "bold", marginTop: "24px" }}>{`Evidence finder ${data.length > 0 ? `(${data.length})` : ""}`}</Typography>
          {isDataRetrieved ? (
            <>
              {data.length > 0 ? (
                <div>
                  <div className="facts-container">
                    {data.map((evidence, index) => (
                      <div
                        className="fact-item"
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div><Typography
                            className="evidence-cred"
                            style={{
                              backgroundColor: this.getCredBgColor(
                                evidence.passage.doc_cred
                              ),
                            }}>
                            {this.creadibilityCap(evidence.passage.doc_cred)}
                            {'    '}
                            Credibility
                          </Typography>
                          </div>
                          {'  '}
                          <OutlinedFlagIcon size="medium" className={this.getFlagStyles(this.getSourceCredibility(evidence))}
                            onClick={(e) => this.handlePostFeedback(e, "credibility", this.getSourceCredibility(evidence) === "Low" ? "" : "Low", evidence, index)} />
                          <LightTooltip title={`Mark credibility label as low.`}>
                            <HelpRoundedIcon
                              className={classes.helpIcon} />
                          </LightTooltip>
                        </div>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={evidence.passage.doc_url}
                          key={index}>
                          <div className="evidence-title">
                            <Typography>
                              <b>{evidence.passage.doc_title}</b>
                            </Typography>
                          </div>
                          <div className="fact-item-info">

                            <Typography>
                              <img
                                style={{
                                  width: '15px',
                                  paddingtop: '10px',
                                  marginTop: '10px',
                                }}
                                src={this.cleanUrl(evidence.passage.doc_url)}
                                alt="logo"
                              />
                              <span style={{ color: 'red' }}>
                                {'     '}
                                {evidence.passage.doc_domain
                                  ? this.nameofsource(
                                    evidence.passage.doc_domain
                                  )
                                  : 'N/A'}{' '}
                              </span>
                              <span className="date-item">
                                |{' '}
                                {evidence.passage.doc_date
                                  ? formatDate(evidence.passage.doc_date)
                                  : 'unknown'}
                              </span>{' '}
                            </Typography>
                          </div>
                          <div className="evidence-container">
                            <Typography className="evidence-source">
                              {this.showSummary(
                                evidence.passage.text,
                                evidence.passage.doc_id
                              )}
                              {evidence.passage.text.length > 130 ? (
                                <span
                                  className="expand-news"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleExpandNews(evidence.passage.doc_id);
                                  }}>
                                  {currentExpandedNews === evidence.passage.doc_id
                                    ? ' Read less'
                                    : '...Read more'}
                                </span>
                              ) : (
                                ''
                              )}
                            </Typography>
                          </div>
                        </a>
                        <Divider />
                        <Grid container>
                          <Typography className={classes.headFeed}> How related is this evidence?
                          </Typography>
                          <Grid item xs={12}>
                            <Grid>
                              <div
                                className="hamlet-main-div">
                                <div
                                  className="hamlet-sub-div">
                                  <div className={classes.wrapicon} onClick={(e) => this.handlePostFeedback(e, "feedback", "Highly", evidence, index)}>
                                    <ThumbUpOutlinedIcon
                                      className={this.getFeedbackStyles("Highly", evidence)}
                                    />
                                    <Typography>Highly</Typography>
                                  </div>

                                  <div className={classes.wrapicon} onClick={(e) => this.handlePostFeedback(e, "feedback", "Somewhat", evidence, index)}>
                                    <MoodBadOutlinedIcon
                                      className={this.getFeedbackStyles("Somewhat", evidence)}
                                      onClick={(e) => this.handlePostFeedback(e, "feedback", "Somewhat", evidence, index)}
                                    />
                                    <Typography>Somewhat</Typography>
                                  </div>

                                  <div className={classes.wrapicon} onClick={(e) => this.handlePostFeedback(e, "feedback", "Not related", evidence, index)}>
                                    <ThumbDownOutlinedIcon
                                      className={this.getFeedbackStyles("Not related", evidence)}
                                      onClick={(e) => this.handlePostFeedback(e, "feedback", "Not related", evidence, index)}
                                    />
                                    <Typography>Not related</Typography>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </div>

                  {openContextDialog ? (
                    <ContextDialog
                      open={openContextDialog}
                      onHandleClose={this.handleClose}
                      currentEvidence={currentEvidence}
                      evidenceIndex={evidenceIndex}
                      evidences={data.evidences}
                      onHandleIgnore={this.handleIgnore}
                      onHandleSelect={this.handleSelect}
                      onHandleEvidenceChange={this.handleEvidenceChange}
                      onHandleSelUndo={this.handleSelUndo}
                      onHandleIgnoreUndo={this.handleIgnoreUndo}
                      claim={data.claim}
                    />
                  ) : null}
                </div>
              ) : (
                <Grid container style={styles.evidenceBanner} alignItems="center">
                  <Typography style={{ color: 'white', fontWeight: 'bold' }}>
                    Sorry, we didn't find any relevant evidence in our database.
                  </Typography>
                </Grid>
              )}
            </>
          ) : (
            <Grid container style={styles.evidenceBanner} alignItems="center">
              <Typography style={{ color: 'white', fontWeight: 'bold', paddingLeft: "5px" }}>
                Finding evidences
              </Typography>
            </Grid>
          )}
          <Dialog
            open={this.state.openFeedbackUrls}
            onClose={this.handleCloseFeebackUrl}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle>
              <Typography
                className={classes.dialogTitle}>
                {longText}
              </Typography>
              <Divider />
            </DialogTitle>
            <DialogContent>
              <Typography
                className={classes.dialogTitle2}> Press on the star icon if you want to mark this URL as high quality.</Typography>
              {feedbackUrls.map((item, index) => {
                return (<>
                  <Typography
                    className={classes.dialogUrlText}>
                    {`URL ${index + 1}`}
                  </Typography>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <TextField
                      id="outlined-bare"
                      margin="dense"
                      variant="outlined"
                      size="small"
                      value={item.url}
                      onChange={(e) => this.handleAddUrl(e.target.value, index)}
                      InputProps={{
                        className: classes.input
                      }}
                      style={{ flexGrow: 1, width: "98%" }}
                      disabled={this.isUrlEditable(item.user_details)}
                      error={item.error}
                      helperText={item.error}
                    />
                    <StarBorderIcon
                      onClick={() => this.handleHighQuality(item, index)}
                      className={item.high_quality === true ? classes.highQualitySelected : classes.highQualityNotSelected}
                      fontSize="large"
                    />
                    <ClearIcon onClick={() => this.handleUrlDelete(index, item.user_details)}
                      className={classes.highQualityNotSelected}
                      fontSize="large"
                    />
                  </div>
                </>)
              })}
              <Typography onClick={this.addAnotherUrl}
                className={classes.addUrltext}
              > + Add another URL </Typography>
              <div style={{ marginTop: "20px" }}>
                <Grid item style={{ display: "flex", marginRight: "5%" }}>
                  <Button
                    fullWidth={false}
                    size="medium"
                    variant={"outlined"}
                    className={classes.dialogButtonCancel}
                    color="#006FF9"
                    onClick={this.handleCloseFeebackUrl}
                  >
                    Cancel
                  </Button>
                  <Button
                    fullWidth={false}
                    size="medium"
                    variant={"contanied"}
                    className={classes.dialogButtonSave}
                    onClick={(e) => this.handlePostFeedback(e, "urls")}
                  >
                    Save
                  </Button>
                </Grid>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            open={this.state.cancelDialog}
            onClose={() => this.handleCancelDialog(false, true)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle>
              <Typography
                className={classes.dialogTitle}>
                Are you sure you want to cancel without saving?
              </Typography>
              <Divider />
            </DialogTitle>
            <DialogContent>
              <Typography
                className={classes.dialogTitle3}> If you cancel, all of the changes you've already did, wont be saved.</Typography>
              <Divider />
              <div style={{ marginTop: "20px" }}>
                <Grid item style={{ display: "flex", marginRight: "5%" }}>
                  <Button
                    fullWidth={false}
                    size="medium"
                    variant={"outlined"}
                    className={classes.dialogButtonCancel}
                    color="#006FF9"
                    onClick={() => this.handleCancelDialog(false, true)}
                  >
                    Back to editing
                  </Button>
                  <Button
                    fullWidth={false}
                    size="medium"
                    variant={"contanied"}
                    className={classes.dialogButtonSave}
                    onClick={this.handleDiscardUrls}
                  >
                    Yes, cancel
                  </Button>
                </Grid>
              </div>
            </DialogContent>
          </Dialog>
          <div className="hamletUrls">
            <Typography
              className={classes.addUrl} onClick={(e) => this.handleOpenFeebackUrl(e)}
              style={{ textDecoration: this.getFeedbackUrlsText() === "Add URLs" ? "inherit" : "underline", cursor: "pointer" }}>
              {this.getFeedbackUrlsText()}
            </Typography>
            <LightTooltip title={longText}>
              <HelpRoundedIcon
                className={classes.helpIcon} />
            </LightTooltip>
          </div>
        </>
      </div>

    );
  }
}
export default (withStyles(styles)(Evidences));
