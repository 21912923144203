import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

const GREEN = '#11aa00';
const RED = '#ff1616';

const styles = {
  evidenceItem: {
    cursor: 'pointer',
    padding: '20px',
    paddingLeft: 0,
  },
  articleButton: {
    display: 'inline-block',
    border: '1px solid #0d66d0',
    padding: '9.5px',
    color: '#0d66d0',
    borderRadius: '5px',
    outline: 'none',
    marginRight: '10px',
    backgroundColor: 'white',
    fontSize: '15px',
  },
  buttonText: {
    borderRadius: '2px',
    color: 'white',
    textTransform: 'capitalize',
  },
  undoButton: {
    border: `1px solid ${RED}`,
    backgroundColor: RED,
    color: 'white',
    marginLeft: '10px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  toolBar: {
    backgroundColor: '#e0e4ff',
    padding: '20px',
    position: 'fixed',
    width: '69.3%',
  },
  dialogContent: {
    borderRadius: 0,
    padding: 0,
  },
  contextContainer: {
    padding: '20px',
    marginTop: '10%',
    height: '50vh',
    overflow: 'auto',
  },
  evidenceList: {
    backgroundColor: '#f1f3ff',
    overflow: 'auto',
  },

  hightLight: {
    width: '3%',
    marginRight: '2%',
  },
  boldFont: {
    fontWeight: 'bold',
  },

  iconImg: {
    width: '12%',
    marginRight: '10px',
  },
  exitButton: {
    backgroundColor: '#2964DA',
    textTransform: 'capitalize',
    color: 'white',
    marginRight: '15px',
  },
  exitButtonContainer: {
    padding: '15px 0',
    borderTop: '1px solid #E0E4FF',
    marginTop: '10px',
  },
};

const processContext = (relatedSentences, context) => {
  let modContext = context;
  let allSentences = [];
  relatedSentences.forEach((sentence) => {
    const cleanedSentence = sentence.split('|')[1].trim();
    allSentences.push(cleanedSentence);
  });

  let allPossibleSentences = [];

  allSentences.forEach((sentence) => {
    const possibleSentences = sentence.split(/\s\s+/);
    allPossibleSentences.push(...possibleSentences);
  });
  allPossibleSentences.forEach((sentence) => {
    const index = modContext.indexOf(sentence);
    if (index !== -1) {
      const end = index + sentence.length;
      modContext = insertSpan(modContext, index, end);
    }
  });
  return modContext;
};

const insertSpan = (modContext, start, end) =>
  modContext.slice(0, start) +
  `<span style="background-color:yellow;">${modContext.slice(
    start,
    end
  )}</span>` +
  modContext.slice(end);

const createMarkup = (relatedSentences, context) => {
  const modContext = processContext(relatedSentences, context);
  const beautifulContext = beautifyModContext(modContext);
  return { __html: beautifulContext };
};

const beautifyModContext = (modContext) => {
  let periodCount = 0;
  let beautifulContext = '<p>' + modContext;
  for (let i = 0; i < beautifulContext.length; i++) {
    if (
      beautifulContext.charAt(i) === '.' &&
      isNaN(parseInt(beautifulContext.charAt(i + 1)))
    ) {
      if (periodCount === 3) {
        beautifulContext = insertParagraph(beautifulContext, i + 1);
        periodCount = 0;
      } else periodCount += 1;
    }
  }
  return beautifulContext + '</p>';
};

const insertParagraph = (modContext, start) =>
  modContext.slice(0, start) + '</p><p>' + modContext.slice(start);

const fixDate = (date) =>
  date
    .split('-')
    .reverse()
    .join('-');

const renderButtons = (
  evidence,
  onHandleSelect,
  onHandleIgnore,
  onHandleSelUndo,
  onHandleIgnoreUndo
) => {
  const { isRelevant, topk_url } = evidence;
  if (isRelevant === 'possibly') {
    return (
      <>
        {topk_url ? (
          <a
            style={styles.articleButton}
            rel="noopener noreferrer"
            target="_blank"
            href={topk_url}>
            View Article
          </a>
        ) : null}
        <Button
          style={{
            backgroundColor: GREEN,
            color: 'white',
            marginRight: '10px',
            ...styles.buttonText,
          }}
          onClick={onHandleSelect}>
          Select
        </Button>
        <Button
          style={{
            backgroundColor: 'red',
            color: 'white',
            ...styles.buttonText,
          }}
          onClick={onHandleIgnore}>
          Ignore
        </Button>
      </>
    );
  }

  if (isRelevant === 'no') {
    return (
      <>
        <Button
          style={{
            border: `1px solid ${RED}`,
            color: RED,
            ...styles.buttonText,
          }}>
          Evidence Ignored
        </Button>
        <Button onClick={onHandleIgnoreUndo} style={styles.undoButton}>
          Undo
        </Button>
      </>
    );
  }

  if (isRelevant === 'yes') {
    return (
      <>
        <Button
          style={{
            color: GREEN,
            border: `1px solid ${GREEN}`,
            ...styles.buttonText,
          }}>
          Evidence added as a source
        </Button>
        <Button onClick={onHandleSelUndo} style={styles.undoButton}>
          Undo
        </Button>
      </>
    );
  }
};

const getHighlightColor = (isRelevant, isSelected) => {
  if (isRelevant === 'possibly') {
    if (isSelected) return '#e0e4ff';
    else return '#f1f3ff';
  }
  if (isRelevant === 'no') return 'red';
  if (isRelevant === 'yes') return GREEN;
};

const cleanUrl = (url) =>
  'https://cors-anywhere-dot-corenlp-processing.appspot.com/https://www.google.com/s2/favicons?domain=' +
  url
    .replace('http://', '')
    .replace('https://', '')
    .split(/[/?#]/)[0];

const renderStanceStyle = (label) => {
  if (label === 'agree') return { text: 'Agree', bg: 'green' };
  if (label === 'disagree') return { text: 'Disagree', bg: 'red' };
  if (label === 'discuss') return { text: 'Discuss', bg: '#FF6F00' };
  return false;
};

const ContextDialog = ({
  classes,
  open,
  onHandleClose,
  currentEvidence,
  evidenceIndex,
  evidences,
  onHandleIgnore,
  onHandleSelect,
  onHandleEvidenceChange,
  onHandleSelUndo,
  onHandleIgnoreUndo,
  claim,
}) => {
  return (
    <div className="dialog">
      <Dialog
        open={open}
        onClose={onHandleClose}
        fullWidth={true}
        maxWidth={'xl'}>
        <DialogContent style={styles.dialogContent}>
          <Grid container>
            <Grid
              item
              className="evidence-list"
              xs={12}
              md={3}
              style={styles.evidenceList}>
              <Grid container direction="column">
                <div
                  style={{
                    padding: '20px',
                    paddingLeft: '0',
                    marginLeft: '5%',
                  }}>
                  <Typography
                    style={{
                      ...styles.boldFont,
                      fontSize: '16px',
                    }}>
                    {claim}
                  </Typography>
                  <Typography style={{ color: '#0d66d0' }}>
                    {evidences.length} Evidence(s) found
                  </Typography>
                </div>
                {evidences.map((evidence, index) => (
                  <Grid
                    key={index}
                    container
                    style={{
                      backgroundColor:
                        evidenceIndex === index ? '#e0e4ff' : '#f1f3ff',
                    }}>
                    <div
                      style={{
                        ...styles.hightLight,
                        backgroundColor: getHighlightColor(
                          evidence.isRelevant,
                          evidenceIndex === index
                        ),
                      }}></div>
                    <Grid
                      item
                      onClick={() => onHandleEvidenceChange(evidence, index)}
                      key={index}
                      className={classes.evidenceItem}
                      style={{
                        width: '95%',
                      }}>
                      <Typography
                        style={{ ...styles.boldFont, marginBottom: '10px' }}>
                        {evidence.topk_title}
                      </Typography>
                      <Grid container alignItems="center">
                        <img
                          src={cleanUrl(evidence.topk_url)}
                          alt="logo"
                          style={{ marginRight: '10px', width: '16px' }}
                        />
                        <Typography
                          style={{
                            fontSize: '12px',
                            textTransform: 'capitalize',
                          }}>
                          {evidence.publisher ? evidence.publisher : 'N/A'} |{' '}
                          {evidence.published_date
                            ? fixDate(evidence.published_date)
                            : 'N/A'}{' '}
                          |
                        </Typography>
                        <Typography
                          style={{
                            color: renderStanceStyle(evidence.stance_label).bg,
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                          }}>
                          &nbsp;{renderStanceStyle(evidence.stance_label).text}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                className="toolbar"
                style={styles.toolBar}>
                <Grid item xs={12} md={5}>
                  <Grid container alignItems="center">
                    <img
                      src={cleanUrl(currentEvidence.topk_url)}
                      alt="logo"
                      style={styles.iconImg}
                    />
                    <div>
                      <Typography style={{ color: '#0d66d0' }}>
                        Author
                      </Typography>
                      <Typography>
                        {currentEvidence.authors.length === 0
                          ? 'N/A'
                          : currentEvidence.authors[0]}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid item className="action-buttons">
                  {renderButtons(
                    currentEvidence,
                    onHandleSelect,

                    onHandleIgnore,
                    onHandleSelUndo,
                    onHandleIgnoreUndo
                  )}
                </Grid>
              </Grid>
              <Typography
                style={styles.contextContainer}
                dangerouslySetInnerHTML={createMarkup(
                  currentEvidence.related_sentences,
                  currentEvidence.context
                )}
              />
              <Grid
                container
                justify="flex-end"
                style={styles.exitButtonContainer}>
                <Button style={styles.exitButton} onClick={onHandleClose}>
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(ContextDialog);
