import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { toast } from 'react-toastify';

class ProfDetails extends Component {
  state = {
    fullName: "",
    email: "",
    officeLoc: "Mysore",
  };

  // nextPath(path) {
  //   // this.setState({ textClaim: false });
  //   this.props.history.push(path);
  // }

  componentDidMount() {
    const { fullName, email, officeLoc } = this.state;
    const { user } = this.props;
    this.setState({
      fullName: fullName || user.first_name,
      email: user.email || email,
      officeLoc: user.profile.fields.location,
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

  };

  getProfilePic = () => {
    const { user } = this.props;
    if (user.username) {
      return user.username.split("")[0];
    }
  };

  handleSave = () => {
    const { fullName, email, officeLoc } = this.state;
    const { onHandleUpdate } = this.props;
    console.log('officeLoc', officeLoc)
    if (email) {
      if (typeof email !== 'string') return false;
      if (
        !(
          email.match(
            /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
          ) != null
        )
      ) {
        return toast.error('Please enter valid email', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    if (this.validate()) {
      onHandleUpdate(fullName, email, officeLoc);
      // this.props.onHandleUpdate = (fullName, email, officeLoc)
      return true;
    } else alert("One or more fields are empty");
    console.log('onHandleUpdate', onHandleUpdate);

  };

  validate = () => {
    const { fullName, email } = this.state;
    return fullName.trim().length > 0 && email.trim().length > 0;
  };

  handleNext = () => {
    const { onNext } = this.props;
    if (this.handleSave()) onNext("skills");
    console.log('onNext', onNext)

  };


  render() {
    const { isInit } = this.props;
    const { fullName, email, officeLoc } = this.state;
    return (
      <div className="prof-details-container">
        <div className="prof-details-left">
          <div className="prof-input-container">
            <Typography>Full name</Typography>
            <input
              name="fullName"
              type="text"
              value={fullName}
              onChange={this.handleChange}
            />
          </div>
          <div className="prof-input-container">
            <Typography>Email</Typography>
            <input
              type="text"
              name="email"
              value={email}
              onChange={this.handleChange}
            />
          </div>
          <div className="prof-input-container">
            <Typography>Office location</Typography>
            <select
              name="officeLoc"
              value={officeLoc}
              onChange={this.handleChange}
            >
              <option value="Mysore">Mysore</option>
              <option value="Banglore">Banglore</option>
              <option value="London">London</option>
              <option value="Brighouse">Brighouse HQ</option>
            </select>
          </div>
          {isInit ? (
            <button className="save-button" onClick={this.handleNext}>
              Next
            </button>
          ) : (
            <button className="save-button" onClick={this.handleSave}>
              Save
            </button>
          )}
        </div>
        <div className="prof-pic-container">
          <Typography>Add a profile picture</Typography>
          <div className="user-avatar-container">
            <div className="user-avatar">
              <h1>{this.getProfilePic()}</h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfDetails;
