import moment from "moment";
import "moment-timezone";

moment.tz.setDefault("Asia/Kolkata");

export default moment;

export function getTimeDifference(date, newDate, type) {
  let published = "";
  let updated = "";

  if (typeof date === "number") {
    published = moment(new Date(date * 1000));
  } else {
    published = moment(new Date(date));
  }
  if (typeof newDate === "number") {
    updated = moment(new Date(newDate * 1000));
  } else {
    updated = moment(new Date(newDate));
  }

  let d = getDurationRounded(published, updated);

  if (type === 2) {
    let diff = updated.diff(published);
    const daysCount = updated.diff(published, "days");
    let days = daysCount === 1 ? "Day" : "Days";
    if (d.days > 1) {
      return `${daysCount} ${days} ${moment.utc(diff).format("HH:mm:ss")}`;
    } else {
      return moment.utc(diff).format("HH:mm:ss");
    }
  }

  if (d.seconds < 0) {
    published = published.subtract(1, "days");
    d = getDurationRounded(published, updated);
  }

  let output = moment.duration(-d.duration).humanize(type === 3);

  return output === "Invalid date" ? "Not available" : output;
}

export function getTimeFrom(date, type) {
  let published = "";

  if (typeof date === "number") {
    published = moment(new Date(date * 1000));
  } else {
    published = moment(new Date(date));
  }

  let d = getDurationRounded(published, new Date());

  if (d.seconds < 0) {
    published = published.subtract(1, "days");
    d = getDurationRounded(published, new Date());
  }

  let output = moment.duration(d.duration).humanize() + " ago";

  if (d.hours > 24) {
    output = published.format("Do MMM YY hh:mm A");
    if (type === "nohours") {
      output = published.format("Do MMM YY A");
    }
  }

  return output;
}

export function getDurationRounded(date, newDate) {
  date = moment(date);
  let now = moment(new Date(newDate));
  let duration = moment.duration(now.diff(date));

  let formatted = moment.utc(duration.as("milliseconds")).format("HH:mm:ss");

  let seconds = Math.round(duration.asSeconds());
  let minutes = Math.round(duration.asMinutes());
  let hours = Math.round(duration.asHours());
  let days = Math.round(duration.asDays());
  let weeks = Math.round(duration.asWeeks());
  let months = Math.round(duration.asMonths());

  return { seconds, minutes, hours, days, weeks, months, formatted, duration };
}