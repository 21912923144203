import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SkillsIcon from "../../assets/SkillsIcon";
import PasswordIcon from "../../assets/PasswordIcon";
import UserIcon from "../../assets/UserIcon";
import ProfDetails from "./ProfDetails";
import Skills from "./skills";
import ChangePass from "./changePass";
import ProfileComplete from "./ProfileComplete";
import axios from "axios";
import { toast } from "react-toastify";
import api, { getBase } from "../../api";

class InitProfile extends Component {
  state = {
    user: this.props.user,
    activeRoute: "profDetails",
    screen: "new",
    skills: [],
    skill: "",
    interests: [
      { interest: "Education", isInterested: false },
      { interest: "Politics", isInterested: false },
      { interest: "Current affairs", isInterested: false },
      { interest: "Entertainment", isInterested: false },
      { interest: "Sports", isInterested: false },
      { interest: "Health", isInterested: false },
      { interest: "Business & Finance", isInterested: false },
      { interest: "Environment", isInterested: false },
      { interest: "Lifestyle", isInterested: false },
      { interest: "Science & Tech", isInterested: false },
    ],
  };

  handleRouteChange = (activeRoute) => {
    this.setState({ activeRoute });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleAddSkill = (skill) => {
    const { skills } = this.state;
    if (skill !== "") {
      const newSkills = [...skills, skill];
      this.setState({ skills: newSkills, skill: "" });
    }
  };

  handleDeleteSkill = (skill) => {
    const { skills } = this.state;
    const newSkills = skills.filter((sk) => skill !== sk);
    this.setState({ skills: newSkills });
  };

  handleAddInterest = (index) => {
    const { interests } = this.state;
    interests[index].isInterested = !interests[index].isInterested;
    this.setState({ interests });
  };

  validate = () => {
    const { skills, interests } = this.state;

    const interestStrings = interests.filter(
      (interest) => interest.isInterested
    );
    if (skills.length > 0 && interestStrings.length > 0) {
      this.handleRouteChange("passwordChange");
      return true;
    } else alert("One or more fields are empty");
  };

  handleSkillSave = () => {
    const { skills, interests } = this.state;
    const { user } = this.props;
    // console.log('skills', skills)
    if (this.validate()) {
      let interestString = [];
      for (let index = 0; index < interests.length; index++) {
        const element = interests[index];
        if (element.isInterested) {
          interestString.push(element.interest);
        }
      }
      let intrestsfromurl = user.profile.fields.interests.split(",");
      for (let index = 0; index < this.state.interests.length; index++) {
        const element = this.state.interests[index];
        if (intrestsfromurl.includes(element.interest)) {
          let interests = this.state.interests;
          interests[index].isInterested = true;
          this.setState({ interests });
        }
      }
      let userdata = {};
      console.log("skills", skills);
      user.profile.fields.skills = `${skills}`;
      user.profile.fields.interests = `${interestString}`;
      userdata.skills = `${skills}`;
      userdata.interests = `${interestString}`;

      // console.log('user', userdata);
      this.setState({ skills: userdata.skills });
      // this.setState({interests: userdata.interests})
      this.setState({ user }, () => {
        this.getProfile(userdata);
        console.log("user", this.state.user);
      });
      return true;
    } else alert("One or more fields are empty");
  };

  renderCompleteScreen = () => {
    this.setState({ screen: "complete" });
  };

  renderActiveRoute = (activeRoute) => {
    const { user } = this.props;
    console.log("user", user);
    const { skills, interests, skill } = this.state;
    if (activeRoute === "profDetails")
      return (
        <ProfDetails
          user={user}
          isInit={true}
          onNext={this.handleRouteChange}
          onHandleUpdate={this.updateProfileDetails}
        />
      );
    if (activeRoute === "skills")
      return (
        <Skills
          user={user}
          isInit={true}
          onNext={this.handleSkillSave}
          onHandleUpdate={this.updateInterestsAndSkills}
          skills={skills}
          skill={skill}
          interests={interests}
          onHandleAddSkill={this.handleAddSkill}
          onHandleDeleteSkill={this.handleDeleteSkill}
          onHandleAddInterest={this.handleAddInterest}
          onHandleChange={this.handleChange}
          onHandleSkillSave={this.handleSkillSave}
        />
      );
    if (activeRoute === "passwordChange")
      return (
        <ChangePass
          user={user}
          isInit={true}
          onComplete={this.renderCompleteScreen}
        />
      );
  };
  getUser(user) {
    axios
      .put(getBase(api.USER), user, {
        headers: { Authorization: "Token " + localStorage.token },
      })
      .then((res) => {
        console.log("Updated profile");
      })
      .catch((err) => {
        toast.error(
          "Error, could not update profile. API probably not working yet",
          { position: toast.POSITION.TOP_RIGHT }
        );
      });
  }
  getProfile(fields) {
    axios
      .put(getBase(api.PROFILE), fields, {
        headers: { Authorization: "Token " + localStorage.token },
      })
      .then((res) => {
        console.log("Updated profile");
      })
      .catch((err) => {
        toast.error(
          "Error, could not update profile. API probably not working yet",
          { position: toast.POSITION.TOP_RIGHT }
        );
      });
  }
  updateInterestsAndSkills = (skills, interests) => {
    const { user } = this.props;
    user.skills = skills;
    user.interests = interests;
    console.log(user);
    this.setState({ user });
  };

  updateProfileDetails = (fullName, email, officeLoc) => {
    const { user } = this.props;
    // user.fullName = fullName;
    // user.email = email;
    // user.officeLoc = officeLoc;
    // console.log(user);

    // let user = {}
    let fields = {};
    if (fullName && email) {
      user.first_name = fullName;
      user.email = email;
      this.setState({ user }, () => {
        this.getUser(user);
      });
    }

    if (officeLoc) {
      fields.location = officeLoc;
      // console.log('user',user)
      this.getProfile(fields);
    }
  };

  render() {
    const { user, onHandleProfileComplete } = this.props;
    const { activeRoute, screen } = this.state;

    if (!user) return <div></div>;
    else {
      if (screen === "new")
        return (
          <div className="init-profile">
            <div className="init-prof-container">
              <div className="init-prof-route">
                <h1 className="init-prof-user-greet">
                  Hello <span>{user.username},</span>
                </h1>
                <div className="init-prof-route-container">
                  <h3>Complete your profile</h3>
                  <div className="init-prof-buttons-container">
                    <button
                      onClick={() => this.handleRouteChange("profDetails")}
                    >
                      <Grid container alignItems="center">
                        <UserIcon
                          fill={
                            activeRoute === "profDetails"
                              ? "#F62459"
                              : "#050e2b"
                          }
                          styles={{ width: "16px", marginRight: "10px" }}
                        />
                        <Typography
                          style={{
                            color:
                              activeRoute === "profDetails"
                                ? "#F62459"
                                : "#050e2b",
                          }}
                        >
                          Profile Details
                        </Typography>
                      </Grid>
                    </button>
                    <button onClick={() => this.handleRouteChange("skills")}>
                      <Grid container alignItems="center">
                        <SkillsIcon
                          fill={
                            activeRoute === "skills" ? "#F62459" : "#050e2b"
                          }
                          styles={{ width: "16px", marginRight: "10px" }}
                        />
                        <Typography
                          style={{
                            color:
                              activeRoute === "skills" ? "#F62459" : "#050e2b",
                          }}
                        >
                          Skills and interest
                        </Typography>
                      </Grid>
                    </button>
                    <button
                      onClick={() => this.handleRouteChange("passwordChange")}
                    >
                      <Grid container alignItems="center">
                        <PasswordIcon
                          fill={
                            activeRoute === "passwordChange"
                              ? "#F62459"
                              : "#050e2b"
                          }
                          styles={{ width: "16px", marginRight: "10px" }}
                        />
                        <Typography
                          style={{
                            color:
                              activeRoute === "passwordChange"
                                ? "#F62459"
                                : "#050e2b",
                          }}
                        >
                          Change password
                        </Typography>
                      </Grid>
                    </button>
                  </div>
                </div>
              </div>
              <div className="init-prof-component-container">
                <div className="init-prof-component">
                  {this.renderActiveRoute(activeRoute)}
                </div>
              </div>
            </div>
          </div>
        );
      else {
        return (
          <ProfileComplete
            user={user}
            onHandleProfileComplete={onHandleProfileComplete}
          />
        );
      }
    }
  }
}

export default InitProfile;
