import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import moment from 'moment';
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import api, { getBase } from "../../../api";
import axios from 'axios'
import { MenuItem, Select } from "@material-ui/core";
import { List, AutoSizer } from "react-virtualized";

const commonStyles = {
  button: {
    fontSize: "12px",
    textTransform: "capitalize",
    borderColor: "green"
  },

  badge: {
    color: "#fff",
    padding: "3px 8px",
    borderRadius: "2px",
    fontSize: "10px",
    marginRight: "10px"
  }
};

const emailTemplates = {
  'The user has pointed out a spelling or grammar mistake': 'We are glad to hear from you. We have now updated and corrected our check and believe that it now reads more clearly. We always aim to produce work of the highest quality but sometimes we do make mistakes. Thank you for pointing out the error(s).',
  'The user has sent us abuse': 'We really appreciate feedback, as it allows us to issue corrections or updates where necessary. Unfortunately, we did not find merit in the complaint that you raised. We believe our fact check explains the issue in question. We are not going to issue an update or correction on this occasion.',
  'The user has sent us an unreliable source to contradict our fact check': 'We really appreciate feedback, as it allows us to issue corrections or updates where necessary. Unfortunately, when we looked into the details you provided, we found that your information might be from an unreliable source. As such, we cannot make an update or correction based on your feedback.',
  'The user has pointed out a valid flaw in our fact check': 'We are glad to hear from you. We will look at the details you provided and we will either issue a correction or an update. We aim to do this as quickly as possible, usually within days, but at least within three weeks. We really appreciate feedback, as it allows us to issue corrections or updates where necessary.',
  'The user has sent us nonsense/ irrelevant': 'We really appreciate feedback, as it allows us to issue corrections or updates where necessary. Unfortunately, we did not find merit in the complaint that you raised. We believe our fact check explains the issue in question. We are not going to issue an update or correction on this occasion.',
  'The user claims the post is a joke': 'We really appreciate feedback, as it allows us to issue corrections or updates where necessary. On this occasion, we cannot make an update or correction based on your feedback. This is because many people have been sharing this post not as satire but as misinformation.',
  'The user is apologizing for spreading misinformation on Facebook': 'We understand that you didn’t intentionally spread false information and we appreciate you telling us this.'
}

const styles = () => ({
  root: {
    flexGrow: 1,
    boxShadow: "none"
  },
  paper: {
    backgroundColor: "#f8f8f8",
    padding: 15,
    boxShadow: "none"
  },
  textField: {
    width: "100%",
    color: "#ffffff",
    fontSize: "12px"
  },
  main: {
    marginTop: 15,
    boxShadow: "none"
  },
  resolvedBadge: {
    ...commonStyles.badge,
    backgroundColor: "green"
  },
  unresolvedBadge: {
    ...commonStyles.badge,
  },
  buttonUnresolved: {
    ...commonStyles.button,
    color: "green"
  },
  buttonResolved: {
    ...commonStyles.button,
    color: "#fff",
    backgroundColor: "green"
  },
  resolvedDate: {
    color: "grey",
    fontSize: "12px",
    margin: "5px 0"
  },
  formControl: {
    maxWidth: "100%",
    marginBottom: "8px"
  },
  spaceTopAndBottom: {
    margin: "2px 0"
  }
});

class Complaints extends Component {
  state = {
    TextFieldValues: [],
    ComplaintsData: [],
    data: this.props.data,
    ComplaintsOpen: false,
    flags: false,
    count: 0,
    resolutionReason: 'The user has pointed out a spelling or grammar mistake',
    otherEmailTemplate: ''
  };

  handleResolve = async (data2, index) => {
    let { ComplaintsData, data } = this.state;
    ComplaintsData[index].complaint_resolved = !data2.complaint_resolved;
    ComplaintsData[index].date_resolved = moment().format('DD/MM/YYYY');
    if (ComplaintsData[index].resolutionReason !== 'Other') {
      ComplaintsData[index].emailTemplate = emailTemplates[ComplaintsData[index].resolutionReason]
    }
    data.complaints = ComplaintsData;
    data.complaints_updated = true
    this.setState({ ComplaintsData, data },
    );
    let openComplaintsData = ComplaintsData.filter(complaint => complaint.complaint_resolved === false)
    if (openComplaintsData.length > 0) {
      data.complaint_resolved = false
    } else {
      data.complaint_resolved = true
    }

    try {
      await axios
        .put(getBase(api.CLAIM + data._id), data, {
          headers: {
            Authorization: "Token " + localStorage.token,
            "content-type": "application/json",
          },
        }).then((response) => {
          let updatedComplaints = response.data.data.complaints
          this.props.handleChange(response.data.data)
          this.setState({ data: response.data.data, ComplaintsData: updatedComplaints })
          let count = 0;
          for (let i = 0; i < updatedComplaints.length; i++) {
            if (updatedComplaints[i].complaint_resolved === false) {
              count++;
              this.setState({ flags: true, count: count })
            }
            else {
              this.setState({ flags: false, })
            }
          }
        })
    } catch (error) {
      console.log(error)
      ComplaintsData[index].complaint_resolved = false
      data.complaints = ComplaintsData
      this.setState({ ComplaintsData, data })
    }
  };

  handleUserMessageChange = e => {
  };

  handleReason = (e, index) => {
    const { ComplaintsData } = this.state
    let updatedComplaints = [...ComplaintsData]
    updatedComplaints[index].resolutionReason = e.target.value
    this.setState({ ComplaintsData: updatedComplaints })
  }

  handleEmailTemplate = (e, index) => {
    let { ComplaintsData } = this.state
    let userEmail = e.target.value
    let updatedComplaints = [...ComplaintsData]
    updatedComplaints[index].emailTemplate = userEmail
    this.setState({ ComplaintsData: updatedComplaints })
  }

  componentDidMount(newData) {
    let data = newData ? newData : this.props.data;
    let complaints = [...data.complaints]
    complaints.forEach(complaint => {
      complaint.resolutionReason = complaint.resolutionReason ? complaint.resolutionReason : "The user has pointed out a spelling or grammar mistake"
      complaint.emailTemplate = complaint.emailTemplate ? complaint.emailTemplate : ""
    })
    this.setState({ ComplaintsData: complaints }, () => {
      let count = 0;
      for (let i = 0; i < data.complaints.length; i++) {
        if (data.complaints[i].complaint_resolved === false) {
          count++;
          this.setState({ flags: true, count: count })
        }
        else {
          this.setState({ flags: false, })
        }
      }
    })

  }
  handleClick = title => {
    this.setState(state => ({ [title]: !state[title] }));
  };
  render() {
    const { classes } = this.props;
    const { ComplaintsData, flags, count, otherEmailTemplate } = this.state;
    return (
      <Grid container className={classes.main}>
        <Grid item md={12}>
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <Grid container spacing={16} className={classes.demo}>
                <Grid item md={12}>
                  <Paper className={classes.papers}>
                    <ListItem
                      button
                      onClick={this.handleClick.bind(this, "ComplaintsOpen")}
                    >
                      <ListItemText primary={flags === true ? <div>New Complaints <text className={classes.unresolvedBadge} style={{ backgroundColor: 'red', fontSize: 12 }} >{count}</text> </div> : ComplaintsData.length > 0 ? "Complaints" : "No Complaints"} />
                      {this.state.ComplaintsOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.ComplaintsOpen} timeout="auto">
                      <LazyComplaints ComplaintsData={ComplaintsData} handleUserMessageChange={this.handleUserMessageChange}
                        handleEmailTemplate={(e, index) => this.handleEmailTemplate(e, index)}
                        handleReason={(e, index) => this.handleReason(e, index)}
                        handleResolve={(e, index) => this.handleResolve(e, index)}
                        classes={classes}
                        otherEmailTemplate={otherEmailTemplate}
                      />
                    </Collapse>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Complaints);


const LazyComplaints = ({ width, ComplaintsData = [], handleEmailTemplate, handleReason, handleResolve, classes, handleUserMessageChange, otherEmailTemplate }) => {

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          width={width}
          height={700}
          rowCount={ComplaintsData.length}
          rowHeight={250}
          rowRenderer={({ index, key, style }) => {
            const s = { ...style }
            s.right = 0;
            delete s.width
            const item = ComplaintsData[index];
            return <Paper className={classes.paper} key={key} style={s}>
              <Grid container justify="space-between" xs={12}>
                <Grid item xs={3} className="details">
                  <Grid
                    container
                    direction="column"
                    justify="space-between"
                  >
                    <Grid item>
                      <Grid container alignItems="center">
                        <div className={classes.unresolvedBadge} style={{ backgroundColor: item.complaint_resolved === false ? 'red' : 'green', fontSize: 12 }}>{index + 1}</div>
                        <Typography className={classes.spaceTopAndBottom}>
                          {item.date_created}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography className={classes.spaceTopAndBottom}>
                      Type: <strong>{item.complaint_type}</strong>
                    </Typography>
                    <Typography className={classes.spaceTopAndBottom}>
                      User ID: {item.user_id}
                    </Typography>
                    {item.complaint_email &&
                      <Typography className={classes.spaceTopAndBottom}>
                        Email : {item.complaint_email}
                      </Typography>
                    }
                  </Grid>
                </Grid>
                <Grid container xs={6}>
                  <TextField
                    label="User Message"
                    variant="outlined"
                    multiline
                    rows="3"
                    value={item.complaint}
                    className={classes.textField}
                    onChange={handleUserMessageChange}
                  />
                  {item.resolutionReason === "Other" && <TextField
                    label="Email to user"
                    variant="outlined"
                    multiline
                    rows="3"
                    value={item.emailTemplate || otherEmailTemplate}
                    className={classes.textField}
                    onChange={(e) => handleEmailTemplate(e, index)}
                    style={{ marginTop: 20 }}
                  />}
                </Grid>
                <Grid item xs={2}>
                  <Select name="resolutionReason" onChange={(e) => { handleReason(e, index) }} value={item.resolutionReason} className={classes.formControl}>
                    <MenuItem value="The user has pointed out a spelling or grammar mistake">The user has pointed out a spelling or grammar mistake</MenuItem>
                    <MenuItem value="The user has sent us abuse">The user has sent us abuse</MenuItem>
                    <MenuItem value="The user has sent us an unreliable source to contradict our fact check">The user has sent us an unreliable source to contradict our fact check</MenuItem>
                    <MenuItem value="The user has pointed out a valid flaw in our fact check">The user has pointed out a valid flaw in our fact check</MenuItem>
                    <MenuItem value="The user has sent us nonsense/ irrelevant">The user has sent us nonsense/ irrelevant</MenuItem>
                    <MenuItem value="The user claims the post is a joke">The user claims the post is a joke</MenuItem>
                    <MenuItem value="The user is apologizing for spreading misinformation on Facebook">The user is apologizing for spreading misinformation on Facebook</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  <Grid
                    container
                    direction="column"
                    justify="space-between"
                  >
                    {item.complaint_resolved === false ? <Button
                      variant="outlined"
                      className={classes.buttonResolved}
                      onClick={(e) => handleResolve(item, index)}
                    >
                      Mark as resolved
                    </Button> :
                      <Button
                        variant="outlined"
                        className={classes.buttonResolved}
                        onClick={(e) => handleResolve(item, index)}
                      >
                        resolved
                      </Button>

                    }
                    {item.date_resolved && <div> <Typography className={classes.resolvedDate}>
                      Resolved Date
                    </Typography>
                      <Typography>{item.date_resolved}</Typography></div>}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          }}
        />
      )}
    </AutoSizer>
  )
}

