import { store } from '../index'
const languages = [
    { name: 'English', code: 'en', checked: false },
    { name: 'Danish', code: 'da', checked: false },
    { name: 'Norwegian', code: 'no', checked: false },
    { name: 'Swedish', code: 'sv', checked: false },
    { name: 'Finnish', code: 'fi', checked: false },
    { name: 'Mandarin', code: 'zh', checked: false },
    { name: 'Turkish', code: 'tr', checked: false },
    { name: 'Ukrainian', code: 'uk', checked: false },
    { name: 'Russian', code: 'ru', checked: false },
    { name: 'French', code: 'fr', checked: false },
    { name: 'Spanish', code: 'es', checked: false },
    { name: 'German', code: 'de', checked: false },
    { name: 'Sinhalese', code: 'si', checked: false },
    { name: 'Arabic', code: 'ar', checked: false },
    { name: 'Kannada', code: 'kn', checked: false },
    { name: 'Assamese', code: 'as', checked: false },
    { name: 'Telugu', code: 'te', checked: false },
    { name: 'Malayalam', code: 'ml', checked: false },
    { name: 'Bangla', code: 'bn', checked: false },
    { name: 'Tamil', code: 'ta', checked: false },
    { name: 'Nepali', code: 'ne', checked: false },
    { name: 'Urdu', code: 'ur', checked: false },
    { name: 'Hindi', code: 'hi', checked: false },
    { name: 'Marathi', code: 'mr', checked: false }
]


export function getVerdictByCode(languageCode, verdictCode) {
    switch (languageCode) {
        case "en":
            switch (verdictCode) {
                case 0:
                    return "None";
                case 1:
                    return "True";
                case 2:
                    return "False";
                case 3:
                    return "Misleading";
                case 4:
                    return "Unverifiable";
                case 5:
                    return "Partly_True";
                default:
                    return "Invalid verdict code";
            }
        case "sv":
            switch (verdictCode) {
                case 0:
                    return "None";
                case 1:
                    return "SANT";
                case 2:
                    return "FALSKT";
                case 3:
                    return "MISSVISANDE";
                case 4:
                    return "OVERIFIERBAR";
                case 5:
                    return "DELVIS SANT";
                default:
                    return "Invalid verdict code";
            }
        case "kn":
            switch (verdictCode) {
                case 0:
                    return "None";
                case 1:
                    return "ನಿಜ";
                case 2:
                    return "ತಪ್ಪು";
                case 3:
                    return "ಸಂದರ್ಭದಿಂದ ಹೊರಗಿಡಲಾಗಿದೆ";
                case 4:
                    return "ಪರಿಶೀಲಿಸಲಾಗುವುದಿಲ್ಲ";
                case 5:
                    return "ಭಾಗಶಃ ನಿಜ";
                default:
                    return "Invalid verdict code";
            }
        case "as":
            switch (verdictCode) {
                case 0:
                    return "None";
                case 1:
                    return "সঁচা";
                case 2:
                    return "মিছা";
                case 3:
                    return "বিভ্ৰান্তিকৰ";
                case 4:
                    return "অপৰীক্ষাযোগ্য";
                case 5:
                    return "আংশিকভাৱে সত্য";
                default:
                    return "Invalid verdict code";
            }
        case "te":
            switch (verdictCode) {
                case 0:
                    return "None";
                case 1:
                    return "నిజం";
                case 2:
                    return "అబద్ధం";
                case 3:
                    return "తప్పుదారి పట్టించేది";
                case 4:
                    return "నిర్ధారించలేనిది";
                case 5:
                    return "పాక్షికంగా నిజం";
                default:
                    return "Invalid verdict code";
            }
        case "mr":
            switch (verdictCode) {
                case 0:
                    return "None";
                case 1:
                    return "खरे";
                case 2:
                    return "खोटे";
                case 3:
                    return "दिशाभूल";
                case 4:
                    return "असत्यापित";
                case 5:
                    return "अंशतः सत्य";
                default:
                    return "Invalid verdict code";
            }
        case "hi":
            switch (verdictCode) {
                case 0:
                    return "None";
                case 1:
                    return "सत्य";
                case 2:
                    return "असत्य";
                case 3:
                    return "भ्रामक";
                case 4:
                    return "असत्यापित";
                case 5:
                    return "आंशिक रूप से सच है";
                default:
                    return "Invalid verdict code";
            }
        default:
            return getVerdictByCode('en', verdictCode);
    }
}


export const getVerdictAndPosition = (lang_code, verdict) => {
    const verdictByCode = store.getState().verdictByCode
    let position = 0;
    let matchFound = false
    for (let j = 0; j < Object.keys(verdictByCode).length; j++) {
        const keys = Object.keys(verdictByCode)[j];
        const code = verdictByCode[keys]
        for (let index = 0; index < code.length; index++) {
            const element = code[index];
            if (element === verdict) {
                position = index
                matchFound = true
            }
        }

    }
    if (!matchFound) {
        lang_code = 'en'
    }
    let changedVerdict = verdictByCode[lang_code] ? verdictByCode[lang_code][position] : verdictByCode['en'][position]
    return { position, changedVerdict }
}

export const languagesByCode = [
    { en: ["None", "True", "False", "Misleading", "Unverifiable", "Partly_True"] },
    { da: ["Ingen", "Sandt", "Falsk", "Misvisende", "Uverificerbar", "Delvis_sandt"] },
    { no: ["Ingen", "Sant", "Usant", "Villedende", "Uverifiserbar", "Delvis_sant"] },
    { sv: ["Ingen", "Sant", "Falskt", "Villedande", "Ej_verifierbar", "Delvis_sant"] },
    { fi: ["Ei_mitään", "Tosi", "Epätosi", "Harhaanjohtava", "Vaikea_todentaa", "Osittain_tosi"] },
    { zh: ["无", "真", "假", "误导", "无法验证", "部分真实"] },
    { tr: ["Hiçbiri", "Doğru", "Yanlış", "Yanıltıcı", "Doğrulanamaz", "Kısmen_Doğru"] },
    { uk: ["Немає", "Правда", "Неправда", "Маніпулятивне", "Неможливо_перевірити", "Частково_правда"] },
    { ru: ["Нет", "Правда", "Ложь", "Вводящая_в_заблуждение", "Неверифицируемая", "Частично_правда"] },
    { fr: ["Aucun", "Vrai", "Faux", "Trompeur", "Non_vérifiable", "Partiellement_vrai"] },
    { es: ["Ninguno", "Verdadero", "Falso", "Engañoso", "No_verificable", "Parcialmente_verdadero"] },
    { de: ["Keine", "Wahr", "Falsch", "Irreführend", "Nicht_verifizierbar", "Teilweise_wahr"] },
    { si: ["කිසිදු", "සාමාන්ය", "බිහිවූ", "වැරදි", "සහාය_නොවීය", "ප්‍රචාරය_මාර්ගයේ"] },
    { ar: ["لاشيء", "صحيح", "غير_صحيح", "مضلل", "لايمكن_التحقق", "صحيح_جزئيا"] },
    { kn: ["ಯಾವುದೂ_ಇಲ್ಲ", "ನಿಜ", "ತಪ್ಪು", "ಭ್ರಮಾತ್ಮಕ", "ಸತ್ಯಾಪನಾ_ಅಸಾಧ್ಯ", "ಅಂಶ_ಸತ್ಯ"] },
    { as: ["কোন", "সত্", "মিথ্যা", "ভ্রান্তিকর", "যাচাই_কৰিব_নোৱা", "অংশসত্"] },
    { te: ["ఏమీ_లేదు", "నిజం", "అసత్యం", "మోసంచేయు", "ధృవీకరించలేని", "భాగస్వామ్యం_నిజం"] },
    { ml: ["ഒന്നും_ഇല്ല", "ശരിയാണ്", "തെറ്റാണ്", "മാറ്റമുണ്ട്", "പരിശോധിച്ചിട്ടില്ല", "ഭാഗം_ശരി"] },
    { bn: ["কোনটিই_নেই", "সত্য", "মিথ্যা", "ভ্রান্তিকর", "যাচাই_করা_যায়_না", "আংশিকভাবে_সত্য"] },
    { ta: ["எதுவும்_இல்லை", "உண்மை", "பொய்", "மோசமான", "சரிபார்க்கமுடியாதது", "முழுமையான_உண்மை"] },
    { ne: ["कुनैपनि", "सत्य", "असत्य", "भ्रान्तिकर", "सत्यापन_गर्न_असमर्थ", "अंशबाट_सत्य"] },
    { ur: ["کچھ_نہیں", "سچ", "جھوٹ", "غلط_ہدایت", "تصدیق_نہیں_کی_جا_سکتی", "جزوی_سچ"] }
];

export function getAllLanguage() {
    return languages
}

export function getLanguageByCode(code) {
    if (languages.filter(item => item.code === code.toLowerCase())[0]) {
        return languages.filter(item => item.code === code.toLowerCase())[0]
    }
    return languages[0]
}