import React, { Component } from "react";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import api, { getBase } from "./../api";

class Referrals extends Component {
  constructor(props) {
    super(props);
    var date_from = new Date();
    date_from.setDate(date_from.getDate() - 7);

    this.state = {
      data: false,
      loading: true,
      selectionRange: {
        startDate: date_from,
        endDate: new Date(),
        key: "selection",
      },
      openDate: false,
      total: false,
      limit: 10,
      offset: 0,
    };
  }
  componentDidMount() {
    axios
      .get(getBase(api.PROFILE), {
        headers: { Authorization: "Token " + localStorage.token },
      })
      .then((res) => {
        if (
          res.data.data.fields.role === "supervisor" ||
          res.data.data.fields.role === "admin"
        ) {
          this.getData();
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getData = () => {
    axios
      .get(
        "https://thelogicallydata.co.uk/appref/admin/aggr_referrals?date_from=" +
          moment(this.state.selectionRange.startDate).format("YYYY/MM/DD") +
          "&date_to=" +
          moment(this.state.selectionRange.endDate).format("YYYY/MM/DD") +
          "&limit=" +
          this.state.limit +
          "&offset=" +
          this.state.offset +
          "&order_by=total_success",
        {
          headers: {
            Authorization: "Token ed925f3b159c09a7a3ea2080d696e1a37b32306d",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        let data = this.state.data || [];
        for (var i = 0; i < res.data.data.length; i++) {
          let dupe = false;
          for (var b = 0; b < data.length; b++) {
            if (res.data.data[i].referred_by._id === data[b].referred_by._id) {
              dupe = true;
            }
          }
          if (!dupe) {
            data.push(res.data.data[i]);
          }
        }
        this.setState({ data, loading: false, total: res.data.total_count });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };
  handleSelect = (ranges) => {
    this.setState({ selectionRange: ranges.selection }, this.getData);
  };
  loadMore = () => {
    this.setState({ offset: this.state.offset + 10, loading: true }, () => {
      this.getData();
    });
  };
  render() {
    const { loading, selectionRange, openDate, total } = this.state;
    let data = this.state.data;
    if (!data && !loading) {
      return (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography>
            Oops, looks like something went wrong. Please try again later.
          </Typography>
        </div>
      );
    }

    if (data) {
      for (var i = 0; i < data.length; i++) {
        data[i].ids = [];
        let idsDone = [];
        for (var b = 0; b < data[i].refferedto_ids.length; b++) {
          if (idsDone.indexOf(data[i].refferedto_ids[b]) === -1) {
            idsDone.push(data[i].refferedto_ids[b]);
            data[i].ids.push({ val: data[i].refferedto_ids[b], count: 1 });
          } else {
            data[i].ids[idsDone.indexOf(data[i].refferedto_ids[b])].count++;
          }
        }
      }
    }

    return (
      <div>
        {loading && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {data && (
          <div style={{ maxWidth: 1200, margin: "0 auto", padding: 20 }}>
            <Dialog
              onClose={() => this.setState({ openDate: false })}
              open={openDate}
            >
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={this.handleSelect}
              />
              <Button onClick={() => this.setState({ openDate: false })}>
                Cancel
              </Button>
            </Dialog>
            <Grid container spacing={40}>
              <Grid item>
                <Button onClick={() => this.setState({ openDate: true })}>
                  {moment(selectionRange.startDate).format("YYYY/MM/DD")} -{" "}
                  {moment(selectionRange.endDate).format("YYYY/MM/DD")}
                </Button>
              </Grid>
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>No. Qualified Referrals</TableCell>
                  <TableCell>Referred Users</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((person, index) => {
                  return (
                    <TableRow key={index + person.referred_by.username}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {person.referred_by.first_name}{" "}
                        {person.referred_by.last_name}
                      </TableCell>
                      <TableCell>{person.referred_by.email}</TableCell>
                      <TableCell>{person.total_success}</TableCell>
                      <TableCell>
                        {person.ids.map((id, ind2) => {
                          return (
                            <span key={id.val + "" + ind2}>
                              <a
                                href={
                                  "https://thelogicallydata.co.uk/admin/auth/user/" +
                                  id.val
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {id.val}
                                {id.count > 1 && "(" + id.count + ")"}
                              </a>
                              {person.ids.length - 1 > ind2 && ", "}
                            </span>
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {total && (
              <div>
                {total > data.length && (
                  <Button fullWidth onClick={this.loadMore}>
                    Show More
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Referrals;
