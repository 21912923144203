import React, { Component } from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "@material-ui/core/Button";
import moment from 'moment';
import axios from "axios";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import DonutChart from './DonutChart';
import DateFilter from '../../Dashboard/components/SearchFilter/DateFilter';

import ReactSelect, { components } from "react-select";

//MUI styles
const styles = {
    formControl: {
        margin: 8,
        marginTop: -7,
        minWidth: 300,
        maxWidth: 700,
        maxHeight: 100,
    },
};

export class PerformanceMetrics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSecondaryUsers: [],
            dateDialogOpen: false,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
                key: "dateRange",
            },
            startDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            userData: "",
            loading: false,
            currentUser: {},
            usersSelected: [],
            optionUsers: []
        }
    }

    componentDidMount() {
        const { users } = this.props
        this.getCurrentUser()
        let optionUsers = users.map(user => {
            const optUser = {
                value: user.user_id,
                label: user.first_name.length > 0
                    ? user.first_name + ' ' + user.last_name
                    : user.username
            }
            return optUser
        })
        this.setState({ optionUsers })
    }

    getCurrentUser = async () => {
        const user = await JSON.parse(localStorage.getItem("user"))
        const userRole = user.profile.fields.role;
        const userName = user.username;
        this.setState({
            currentUser: { userName, userRole }, usersSelected: [{
                value: user.id, label: user.first_name.length > 0
                    ? user.first_name + ' ' + user.last_name
                    : user.username
            }]
        }, () => this.getCount())
    }

    secondaryUsersSelect = (e) => {
        this.setState({ selectedSecondaryUsers: e.target.value });
    }

    renderSecondayUsernames = (userIds) => {
        const { allUsers } = this.props;
        let names = []
        userIds.forEach(userId => {
            const secondaryUser = allUsers.find(user => user.user_id === userId)
            names.push(secondaryUser.username)
        })
        return names.join(", ")
    }

    handleDateSelect = (ranges) => {
        const dateFromfilter = ranges.dateRange;
        const startDate = moment(dateFromfilter.startDate).format("YYYY-MM-DD")
        const endDate = moment(dateFromfilter.endDate).format("YYYY-MM-DD")
        this.setState({ dateRange: ranges.dateRange, startDate, endDate });
    };

    //Close date dialog
    handleDialogClose = () => {
        this.setState({ dateDialogOpen: false })
    };

    // API
    getCount = async () => {
        const { usersSelected, startDate, endDate, currentUser } = this.state;
        const { allUsers } = this.props

        let users = []
        usersSelected.forEach(user => {
            const userId = user.value;
            const secondaryUser = allUsers.find(user => user.user_id === userId)
            users.push({ role: secondaryUser.role, name: secondaryUser.username, primary: 0 })
        })
        if (users[0]) {
            users[0].primary = 1
        } else {
            // this.setState({ selectedSecondaryUsers: [currentUser] })
            users.push({ primary: 1, role: currentUser.userRole, name: currentUser.userName })
        }

        try {
            this.setState({ loading: true })
            const { data } = await axios.post(
                "https://checker.logically.co.uk/api/performance_metricsView/reports",
                {
                    users,
                    date: [startDate, endDate],
                }
            );
            this.setState({ userData: data, loading: false })
        } catch (error) {
            console.log(error);
            this.setState({ loading: false })
        }
    };

    handleUserSelect = (selected) => {
        this.setState({
            usersSelected: selected
        });
    };

    render() {
        const { users, classes } = this.props;
        const { dateRange, dateDialogOpen, userData, startDate, endDate, loading, currentUser, usersSelected, optionUsers } = this.state

        let isSupervisor =
            ["admin", "supervisor", "moderator"].indexOf(currentUser.userRole) !== -1;

        return (
            <div className="perf_matrix_container">

                {userData && <div style={{ display: "none" }}>
                    <table id="table-to-xls">
                        <thead>
                            <tr>
                                <th>{" "}</th>
                                <th>Assigned</th>
                                <th>Has complaint</th>
                                <th>In progress</th>
                                <th>Pending Authorisation</th>
                                <th>Rejected</th>
                                <th>Completed</th>
                            </tr>
                        </thead>
                        <tbody key={userData.primary_user.name}>
                            <tr>
                                <td>{userData.primary_user.name}</td>
                                <td>{userData.primary_user.assigned}</td>
                                <td>{userData.primary_user.has_complaint}</td>
                                <td>{userData.primary_user.in_progress}</td>
                                <td>{userData.primary_user.pending_authorisation}</td>
                                <td>{userData.primary_user.rejected}</td>
                                <td>{userData.primary_user.resolved}</td>
                            </tr>
                            {userData.other_users.map((user) => {
                                return (
                                    <tr>
                                        <td>{user.name}</td>
                                        <td>{user.assigned}</td>
                                        <td>{user.has_complaint}</td>
                                        <td>{user.in_progress}</td>
                                        <td>{user.pending_authorisation}</td>
                                        <td>{user.rejected}</td>
                                        <td>{user.resolved}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>}

                <div className="perf_matrix_controls">
                    <div style={{}}>

                        {/* Users dropdown */}
                        {isSupervisor &&
                            <FormControl className={classes.formControl}>
                                <ReactSelect
                                    placeholder="Select users..."
                                    options={optionUsers}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    // components={{
                                    //     Option
                                    // }}
                                    onChange={this.handleUserSelect}
                                    allowSelectAll={true}
                                    value={usersSelected}

                                />

                                {/* <InputLabel id="secondary-user">Users</InputLabel>
                                 <Select
                                     disabled={loading}
                                     labelId="secondary-user"
                                     id="secondary-user-select"
                                     multiple
                                     value={selectedSecondaryUsers}
                                     onChange={(e) => this.secondaryUsersSelect(e)}
                                     input={<Input />}
                                     renderValue={(selected) => this.renderSecondayUsernames(selected)}
                                     MenuProps={MenuProps}
                                 >
                                     {sortedUsers.map((user, index) => (
                                         <MenuItem key={index} value={user.user_id}>
                                             <Checkbox checked={selectedSecondaryUsers.indexOf(user.user_id) > -1} />
                                             <ListItemText primary={user.first_name.length > 0
                                                 ? user.first_name + ' ' + user.last_name
                                                 : user.username} />
                                         </MenuItem>
                                     ))}
                                 </Select> */}
                            </FormControl>

                        }

                    </div>
                    <div className="perf-matrix-buttons-container">
                        {/* Date range button */}
                        <Typography style={{ padding: "8px" }}>{`${moment(startDate).format("DD-MMM-YYYY")} to ${moment(endDate).format("DD-MMM-YYYY")}`}</Typography>
                        <Button size="medium"
                            variant="contained"
                            color="secondary"
                            onClick={() => this.setState({ dateDialogOpen: true })}
                            disabled={loading}
                        >
                            Date range
                        </Button>
                        {/* Generate report button  */}
                        {

                            <Button size="medium"
                                variant="contained"
                                color="secondary"
                                style={{ marginLeft: "10px", marginRight: "10px", minWidth: '105px' }}
                                onClick={this.getCount}
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={18} /> : 'Generate'}
                            </Button>
                        }
                        <Button
                            // disabled={!(startDate && endDate && selectedSecondaryUsers)}
                            disabled={loading}
                            variant="contained"
                            color={!loading ? "secondary" : "disabled"}
                            style={{ marginRight: "10px" }}>
                            <ReactHTMLTableToExcel
                                id="table-xls-button"
                                className="button-report"
                                table="table-to-xls"
                                filename={`FC-Perf-${moment().format(
                                    "MMM-DD-YYYY"
                                )}.xlsx`}
                                sheet={`${moment(startDate).format("DD-MMM-YYYY")} to ${moment(endDate).format("DD-MMM-YYYY")}`}
                                buttonText="DOWNLOAD REPORT"
                            />
                        </Button>

                    </div>
                    {/* Date filter modal */}
                    {dateDialogOpen && (
                        <DateFilter
                            dialogOpen={dateDialogOpen}
                            onHandleDialogClose={this.handleDialogClose}
                            dateRange={dateRange}
                            onHandleSelect={this.handleDateSelect}
                        />
                    )}
                </div>
                {
                    userData &&
                    <>
                        <Grid container className="secondary_donut_container">
                            <Grid item xs={4}>
                                <DonutChart
                                    username={userData.primary_user.name}
                                    label1a='Assigned' label1b={userData.primary_user.assigned}
                                    label2a='Has complaint' label2b={userData.primary_user.has_complaint}
                                    label3a='In progress' label3b={userData.primary_user.in_progress}
                                    label4a='Pending authorisation' label4b={userData.primary_user.pending_authorisation}
                                    label5a='Rejected' label5b={userData.primary_user.rejected}
                                    label6a='Completed' label6b={userData.primary_user.resolved}
                                />
                            </Grid>
                            {userData.other_users.map(data => {
                                return <Grid item xs={4} style={{ paddingBottom: "20px" }}>
                                    <DonutChart
                                        username={data.name}
                                        label1a='Assigned' label1b={data.assigned}
                                        label2a='Has complaint' label2b={data.has_complaint}
                                        label3a='In progress' label3b={data.in_progress}
                                        label4a='Pending authorisation' label4b={data.pending_authorisation}
                                        label5a='Rejected' label5b={data.rejected}
                                        label6a='Completed' label6b={data.resolved}
                                    />
                                </Grid>
                            })}
                        </Grid>
                    </>
                }

            </div>
        )
    }
}

export default withStyles(styles)(PerformanceMetrics)

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};