import { Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Input } from '@material-ui/core'
import React, { Component } from 'react'
import api, { getBase } from '../api'
import axios from 'axios'
import ExpandableTable from './ExpandableTable'
import { toast } from 'react-toastify'

export class Topics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            topicsData: [],
            topicsDataEnglish: [],
            isModalOpen: false,
            newTopicInput: '',
            extendedData: {}
        }
    }

    componentDidMount() {
        this.getTopics()
    }

    async getTopics() {
        const { data: { data: { topics } } } = await axios.get(getBase(api.TOPICS_WITH_ASSOCIATED_FACTCHEKCS), {
            headers: { Authorization: "Token " + localStorage.token },
        })
        const topicsDataEnglish = topics.filter(item => item.language === 'en')
        this.setState({ topicsData: topics, topicsDataEnglish: topicsDataEnglish })
    }

    handleAddNewTopic() {
        const { newTopicInput } = this.state
        let dataToSend = {
            topic: newTopicInput,
            language: 'en',
            parent_id: 0
        }
        axios.post(getBase(api.TOPICS_WITH_ASSOCIATED_FACTCHEKCS), dataToSend, {
            headers: { Authorization: "Token " + localStorage.token }
        }).then(res => {
            toast.success('New Topic has added successfully', {
                position: toast.POSITION.TOP_RIGHT,
            });
            this.setState({ isModalOpen: false, newTopicInput: '' }, () => {
                this.getTopics()
            })
        }).catch(e => {
            toast.error('There was an error please try again', {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.log(e)
        })
    }

    handleAddData(data) {
        this.setState({ extendedData: this.state.topicsData.filter(item => item.parent_id === data) })
    }

    render() {
        const { topicsData, topicsDataEnglish, isModalOpen, newTopicInput, extendedData } = this.state;
        return (
            <div className='main'>
                {topicsData.length > 0 ? <Paper>
                    <br />
                    <div className='flex space-evenly'>
                        <Typography align='center' variant={'h5'}>Topic</Typography>
                        <button onClick={() => this.setState({ isModalOpen: true })} className='save-button w-max'>Add Topic</button>

                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Topic</TableCell>
                                <TableCell></TableCell>
                                <TableCell>Language</TableCell>
                                <TableCell></TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {topicsDataEnglish.map(item => {
                                return <ExpandableTable singleTopic={item} key={item._id} extendedData={extendedData} handleSelectedTopic={(data) => this.handleAddData(data)} refetchList={() => this.getTopics()} />
                            })}
                        </TableBody>
                    </Table>
                </Paper>
                    :
                    <Paper>
                        <br />
                        <Typography align='center' style={{ fontWeight: 'bold' }} variant='h5'>Loading...</Typography>
                        <br />
                    </Paper>
                }
                <Modal open={isModalOpen}
                    onClose={() => { this.setState({ isModalOpen: false }) }}>
                    <div className='modal-center'>
                        <Paper style={{ padding: 16 }}>
                            <Typography style={{ fontWeight: 'bold' }}>Add New Topic</Typography>
                            <Typography><b>Note:</b> Only english topic will be created</Typography>
                            <div className='flex gap'>
                                <Input value={newTopicInput} onChange={(e) => this.setState({ newTopicInput: e.target.value })} placeholder='Write topic name' fullWidth />
                                <button className='save-button w-max' onClick={() => this.handleAddNewTopic()}>Submit</button>
                            </div>
                        </Paper>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Topics;