import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button, MenuItem, Menu, Paper,
  Grid,
  Typography
} from "@material-ui/core";
import moment, { getTimeDifference } from "./../../../utils/time";
import { getLocation } from "./../../../utils/common";
import { FlagIcon } from "react-flag-kit";
import { getAllLanguage, getLanguageByCode, getVerdictAndPosition } from "../../../utils/language";

const styles = (theme) => ({
  root: {
    fontSize: 14,
    flexGrow: 1,
  },

  paper: {
    padding: " 4px 12px 12px 4px",
    color: theme.palette.text.secondary,
    backgroundColor: "#f8f8f8",
    width: "auto",
    boxShadow: " none",
  },
  icon: {
    position: "relative",
    top: 10,
    right: -7,
  },
  title: {
    marginTop: 5,
    marginLeft: 25,
    textTransform: "none",
    fontSize: "14px",
  },
});

class Claim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      language: getLanguageByCode(this.props.data.lang_code) || '',
      defaultLanguage: this.props.data.lang_code,
      verdictPosition: 0
    };
  }
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (item) => {
    let data = { ...this.props.data }
    data.lang_code = item.code
    this.setState({ verdictPosition: getVerdictAndPosition(data.lang_code, data.verdict).position }, () => {
      this.setState({ anchorEl: null, language: getLanguageByCode(item.code) }, () => this.props.handleChange({ ...data, verdictPosition: this.state.verdictPosition }));
    })
  };
  render() {
    const { classes, data } = this.props;
    const { anchorEl, language, defaultLanguage } = this.state
    return (
      <div className={classes.root}>
        <Grid container className={classes.main}>
          <Grid item lg={12} md={12}>
            <Paper className={classes.paper}>
              <Grid
                container
                className={classes.main}
                style={{ marginTop: 10, display: "flex", flexDirection: "row" }}
              >
                <Grid item md={2} lg={2} style={{ paddingRight: 5, flex: 1 }}>
                  <Typography variant="button" className={classes.title}>
                    Claim ID
                  </Typography>
                  <Typography className={classes.title}>
                    {!data.recheck_image ? (
                      <span style={{ fontWeight: "bold" }}>
                        {data.tracking_id}
                      </span>
                    ) : (
                      <span>{data._id}</span>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={3} lg={3} style={{ paddingRight: 0, flex: 1 }}>
                  <Typography
                    variant="button"
                    className={classes.title}
                    style={{ color: "red" }}
                  >
                    Total time
                  </Typography>
                  <Typography
                    variant="button"
                    color="secondary"
                    className={classes.title}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      <Counter
                        created={data.check_created_at}
                        completed={data.supervisor_completed_at}
                      />
                    </span>
                  </Typography>
                </Grid>
                <Grid item md={3} lg={3} style={{ paddingRight: 5, flex: 1 }}>
                  <Typography variant="button" className={classes.title}>
                    Received time
                  </Typography>
                  <Typography variant="button" className={classes.title}>
                    <span style={{ fontWeight: "bold" }}>
                      {moment(new Date(data.check_created_at)).format(
                        "ll |  h:mm a"
                      )}
                    </span>
                  </Typography>
                </Grid>
                <Grid item md={2} lg={2} style={{ flex: 1, marginRight: 10 }}>

                  <Typography variant="button" className={classes.title}>
                    Language
                  </Typography>
                  {!data.recheck_image && (
                    <div style={{ textAlign: 'left', marginLeft: 20 }}>
                      <Button
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        style={{ fontWeight: 600 }}
                      >
                        {language.name}
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                      >
                        {getAllLanguage().map(item => {
                          return <MenuItem onClick={() => this.handleClose(item)} key={item.name} style={{ color: defaultLanguage === item.code ? 'red' : 'black' }}>
                            {item.name}
                          </MenuItem>
                        })}

                      </Menu>
                    </div>
                  )}
                </Grid>
                {data.claim_source && (
                  <Grid item md={2} lg={2} style={{ flex: 1 }}>
                    <Typography variant="button" className={classes.title}>
                      Source
                    </Typography>
                    <Typography variant="button" className={classes.title}>
                      <span style={{ fontWeight: "bold" }}>
                        {data.claim_source}
                      </span>
                    </Typography>
                  </Grid>
                )}
                <Grid item md={2} lg={2} style={{ paddingRight: 10, flex: 1 }}>
                  <Typography variant="button" className={classes.title}>
                    <FlagIcon code={getLocation(data.location)} size={44} />
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid >
      </div >
    );
  }
}

class Counter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: "",
    };
    this.timer = null;
  }
  componentDidMount() {
    this.geTime();
  }
  geTime = () => {
    this.setState(
      {
        time: getTimeDifference(
          new Date(this.props.created),
          this.props.completed ? new Date(this.props.completed) : new Date(),
          2
        ),
      },
      () => {
        this.timer = setTimeout(() => {
          if (this.props.completed) {
          } else {
            this.geTime();
          }
        }, 1000);
      }
    );
  };
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  render() {
    return <span>{this.state.time}</span>;
  }
}

export default withStyles(styles)(Claim);
