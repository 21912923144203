import React, { Component } from "react";

import Typography from "@material-ui/core/Typography";
import { Link, withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { FlagIcon } from "react-flag-kit";
import Pagination from "material-ui-flat-pagination";
import { colours, getLocation } from "../../../utils/common";
import "./SearchResult.css";
import { formatDate } from "../../../utils/fomratDate";
import SearchFilter from "../SearchFilter/SearchFilter";
import SearchImages from "../SearchResults/SearchImages";
import { connect } from "react-redux";
import { getLanguageByCode } from "../../../utils/language";

const tabs = ["URL claims", "Multimedia claims"];
class SearchResult extends Component {
  componentDidMount() {
    const { searchVal, history } = this.props;
    if (searchVal === "") {
      history.push("/");
    }
  }

  getFCActivityDetails = (claim) => {
    const { allUsers } = this.props;
    let fc, mo, sv;
    allUsers.forEach((user) => {
      if (user.user_id === parseInt(claim.factchecker_id)) fc = user.username;
      if (user.user_id === parseInt(claim.moderator_id)) mo = user.username;
      if (user.user_id === parseInt(claim.supervisor_id)) sv = user.username;
    });
    return [
      { type: "FC", name: fc ? fc : "N/A" },
      { type: "MO", name: mo ? mo : "N/A" },
      { type: "SV", name: sv ? sv : "N/A" },
    ];
  };

  getPriorityStyles = (priority) => {
    if (priority === "high")
      return { backgroundColor: "#FFEAEF", color: "#F64747" };
    if (priority === "medium")
      return { backgroundColor: "#ffca9a", color: "#FF8C00" };
    if (priority === "low")
      return { backgroundColor: "#E1FFD8", color: "#3FC380" };
    else return { backgroundColor: "", color: "" };
  };

  getLanguage = (code) => {
    return getLanguageByCode(code) || "English"
  };

  getTabStyles = (index) => {
    const { currentTab } = this.props;
    if (index === currentTab)
      return { color: "#fff", backgroundColor: "#F62459" };
    else return { color: "#F62459", backgroundColor: "transparent" };
  };

  render() {
    const {
      isSearchLoading,
      searchData,
      searchVal,
      isAllUserLoading,
      allUsers,
      supFilter,
      fcerFilter,
      modFilter,
      userStatusFilter,
      verdictFilter,
      languages,
      sources,
      countries,
      onHandleUserChange,
      onHandleCheckBox,
      onHandleClearFilters,
      currentTab,
      switchTabs,
      imgSearchData,
      totalCount,
      onHandlePageChange,
      searchPageOffset,
      onHandleDeleteFilterItem,
      onHandleRangeChange,
      searchFilter,
    } = this.props;

    if (isSearchLoading)
      return <div className="loading-screen">Loading...</div>;

    return (
      <div className="search-results-container">
        <Typography>
          Showing {searchData.length} claims of <strong>{searchVal}</strong>
        </Typography>
        <div className="search-filter-reports">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => switchTabs(index)}
              className="search-tab-button"
              style={this.getTabStyles(index)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="search-results-body">
          {currentTab === 0 ? (
            <div className="search-results-list">
              {searchData.map((claim, index) => (
                <Link to={"/claim/" + claim._id} key={index}>
                  <div className="search-results-item">
                    <div className="search-claim-details">
                      <Typography>
                        <strong>{claim.tracking_id}</strong> |{" "}
                        {formatDate(claim.check_created_at)}
                      </Typography>
                      <div className="claim-code-details">
                        {claim.priority.length > 0 && (
                          <div
                            className="priority-container"
                            style={this.getPriorityStyles(claim.priority)}
                          >
                            {claim.priority}
                          </div>
                        )}
                        <div className="code-container">
                          {this.getLanguage(claim.lang_code).name}
                        </div>
                        {claim.claim_source && (
                          <div className="code-container">
                            {claim.claim_source}
                          </div>
                        )}
                        {claim.location.length > 0 && (
                          <FlagIcon
                            size={30}
                            code={getLocation(claim.location)}
                          />
                        )}
                      </div>
                    </div>
                    <div className="search-claim-text">
                      <Typography>
                        {claim.modified_claim.length > 85
                          ? claim.modified_claim.substr(0, 85) + "..."
                          : claim.modified_claim}
                      </Typography>
                    </div>
                    {!isAllUserLoading && (
                      <div className="claim-history">
                        {this.getFCActivityDetails(claim).map(
                          (history, index) => (
                            <Typography
                              className="claim-history-item"
                              key={index}
                            >
                              {history.type}: {history.name}
                            </Typography>
                          )
                        )}
                      </div>
                    )}
                    <div className="claim-state-verdict">
                      {(!claim.fact_checked && !claim.assigned && !claim.rejected === true && (
                        <Typography className="claim-status">New</Typography>
                      )) ||
                        (claim.status === "in progress" && !claim.rejected === true && (
                          <Typography className="claim-status">
                            In-Progress
                          </Typography>
                        )) ||
                        (claim.status === "pending authorisation" &&
                          claim.judgement &&
                          !claim.finalising && !claim.rejected === true && (
                            <Typography className="claim-status">
                              In-Progress
                            </Typography>
                          )) ||
                        (claim.status === "pending authorisation" &&
                          claim.judgement &&
                          claim.finalising && !claim.rejected === true && (
                            <Typography className="claim-status">
                              In-Review
                            </Typography>
                          )) ||
                        (claim.status === "resolved" && claim.verification && !claim.rejected === true && (
                          <Typography className="claim-status">
                            Completed
                          </Typography>
                        )) ||
                        (claim.rejected === true && claim.reject_message && (
                          <Typography className="claim-status">
                            Rejected
                          </Typography>
                        ))
                        ||
                        (claim.rejected === true && !claim.reject_message && (
                          <Typography className="claim-status">
                            Deleted
                            <span>{claim.reject_message}</span>
                          </Typography>
                        ))


                      }
                      {claim.verdict.length > 0 && (
                        <div
                          className="verdict-container"
                          style={{ backgroundColor: colours[claim.verdict] }}
                        >
                          {claim.verdict.replace("_", " ")}
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <SearchImages
              imgSearchData={imgSearchData}
              allUsers={allUsers}
              isAllUserLoading={isAllUserLoading}
            />
          )}
          <SearchFilter
            allUsers={allUsers}
            isAllUserLoading={isAllUserLoading}
            supFilter={supFilter}
            fcerFilter={fcerFilter}
            modFilter={modFilter}
            userStatusFilter={userStatusFilter}
            verdictFilter={verdictFilter}
            languages={languages}
            sources={sources}
            countries={countries}
            onHandleUserChange={onHandleUserChange}
            onHandleCheckBox={onHandleCheckBox}
            onHandleClearFilters={onHandleClearFilters}
            onHandleDeleteFilterItem={onHandleDeleteFilterItem}
            onHandleRangeChange={onHandleRangeChange}
          />
        </div>
        <Pagination
          limit={20}
          total={totalCount}
          offset={searchPageOffset}
          onClick={(e, offset, page) => onHandlePageChange(page)}
        />
      </div>
    );
  }
}
export default connect()(withRouter(SearchResult));
