import React from "react";

const DashIcon = ({ fill, styles }) => {
  return (
    <svg viewBox="0 0 26.9 27" fill={fill} style={styles}>
      <path d="M11.3 11.4c-.2-.2-.5-.2-.7-.2-.3 0-.5.2-.7.4L6 16.7c-.3.4-.3 1.1.2 1.4.2.1.4.2.6.2.3 0 .6-.1.8-.4l3.3-4.3 3.6 2.9c.2.2.5.2.7.2.3 0 .5-.2.7-.4l3.8-4.9c.3-.4.3-1.1-.2-1.4-.4-.3-1.1-.3-1.4.2L15 14.3l-3.7-2.9zM23.4 7c1.9 0 3.5-1.6 3.5-3.5S25.3 0 23.4 0s-3.5 1.6-3.5 3.5S21.5 7 23.4 7zm0-5c.8 0 1.5.7 1.5 1.5S24.2 5 23.4 5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5z" />
      <path d="M24.9 9.2c-.6 0-1 .4-1 1v9.2c0 3.5-2.1 5.7-5.4 5.7H7.4C4.1 25 2 22.8 2 19.3V8.8c0-3.5 2.1-5.7 5.4-5.7h9.5c.6 0 1-.4 1-1s-.4-1-1-1H7.4C3 1.1 0 4.2 0 8.8v10.5C0 23.9 3 27 7.4 27h11.2c4.4 0 7.4-3.1 7.4-7.7v-9.2c-.1-.5-.5-.9-1.1-.9z" />
    </svg>
  );
};

export default DashIcon;
