import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import moment from "../../../utils/time";

import axios from "axios";
import api, { getBase } from "./../../../api";
import { toast } from "react-toastify";
import Paper from "@material-ui/core/Paper";
import Resizer from "react-image-file-resizer";

// import { colours } from "../../../utils/common";
import domtoimage from "dom-to-image-improved";
import qs from "qs";
import TinyEditor from "./Editor";
import { getVerdictAndPosition } from "../../../utils/language";
import { connect } from "react-redux";


const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#f7f7f7",
    fontSize: 25,
    minWidth: 150,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    font: "-webkit-small-control",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const styles = (theme) => ({
  margin: {
    position: "relative",
    right: `${0.5}em`,
    top: `${0.3}em`,
    margin: theme.spacing.unit,
  },



  button: {
    textTransform: "none",
    boxShadow: "none",
  },
  heading: {
    position: "relative",
    top: `${0.7}em`,
    fontWeight: "bold !important",
  },

  paper: {
    backgroundColor: "#fffff",
    textAalign: "center",
    padding: 15,
    borderRadius: 15,
    height: "100%",
  },
  textColor: {
    color: "#050E28",
    padding: "6px 0px",
  },
  rte: {
    height: '30vh'
  },
  main: {
    marginTop: 10,
    boxShadow: "none",
  },
  editable: {
    '& p': {
      margin: '4px 0px'
    }
  },
  tableRow: {
    '& th': {
      fontSize: 16
    }
  },
  nestedInputRoot: {
    height: 37
  }
});

class Judgement extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    let conclusionStart = "";
    let conclusionFull = "";
    if (data.conclusion) {
      conclusionStart = data.conclusion.slice(0, 150);
      conclusionFull = data.conclusion.replace(conclusionStart, "");
    }
    this.state = {
      age: "",
      claim: data.claim,
      modified_claim: data.modified_claim,
      open: false,
      imageurl: "",
      image: false,
      imageName: '',
      conclusionStart: conclusionStart,
      conclusionFull: conclusionFull,
      compressimg: false,
      datetime: "",
      errors: [],
      previewButton: "New",
      backgroundImageUpdate: "",
      backgroundImageUpdateThumbinal: false,
      image_caption: data.image_caption || "",
      imageAlt: data.image_alt || "",
      previewHtmlModal: false,
      lang_code: data.lang_code,
      verdict: data.verdict
    };
  }
  componentDidMount() {
    let urlprops = {
      image_url: qs.parse(window.location.search, { ignoreQueryPrefix: true })
        .image_url,
    };
    if (urlprops.image_url !== undefined) {
      axios
        .get(
          "https://thelogicallydata.co.uk/lgp/images?search_term=" +
          urlprops.image_url
        )
        .then((res) => {
          this.setState(
            {
              imageurl: "data:image/jpeg;base64," + res.data.image_base64,
              image: "data:image/jpeg;base64," + res.data.image_base64,
              imageName: res.data.image_name,
              backgroundImageUpdate: "data:image/jpeg;base64," + res.data.image_base64,
              backgroundImageUpdateThumbinal: true,
            },
            () => {
              let baseUrl = window.location.href.split("?")[0];
              window.history.pushState("name", "", baseUrl);
            }
          );
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      axios
        .get("/apiv2/metadata?url=" + encodeURIComponent(this.props.data.url))
        .then((res) => {
          let image = "";
          if (res.data.openGraph) {
            if (res.data.openGraph.image) {
              if (res.data.openGraph.image.url) {
                image = res.data.openGraph.image.url;
              }
            }
          }
          if (res.data.twitter) {
            if (res.data.twitter.image) {
              if (res.data.twitter.image.url) {
                image = res.data.twitter.image.url;
              }
            }
          }
          if (res.data.jsonLD) {
            if (res.data.jsonLd.image) {
              if (res.data.jsonLd.image.url) {
                image = res.data.jsonLd.image.url;
              }
            }
          }
          this.setState({
            imageName: '',
            imageurl: image,
            image: image,
            backgroundImageUpdate: image,
            backgroundImageUpdateThumbinal: true
          });
        });
    }
    if (this.props.data._id.length <= 9) {
      this.getimagdata(this.props.data._id);
    }
    this.setState({
      datetime:
        moment(new Date()).format("DD/MM/YYYY") +
        " " +
        new Date().getHours() +
        ":" +
        new Date().getMinutes() +
        " " +
        moment(new Date()).format("z"),
    });
    this.interval = setInterval(() => {
      this.setState({
        datetime:
          moment(new Date()).format("DD/MM/YYYY") +
          " " +
          new Date().getHours() +
          ":" +
          new Date().getMinutes() +
          " " +
          moment(new Date()).format("z"),
      });
    }, 2000);
    // const { data } = this.props;
    // let conclusionStart = "";
    // let conclusionFull = "";
    // if (data.conclusion) {
    // conclusionStart = data.conclusion.slice(0, 150);
    // conclusionFull = data.conclusion.replace(conclusionStart, "");
    // this.setState({
    //   conclusionStart: data.conclusion.slice(0, 150),
    //   conclusionFull: data.conclusion.replace(conclusionStart, ""),
    // })
    // this.setState({ conclusionFull: data.conclusion.replace(conclusionStart, "") })
    // this.setState({ imageCaption: data.image_caption })

    // }
  }

  getimagdata = (imgid) => {
    axios
      .put(
        getBase(api.SHAREABLE_IMAGE + imgid),
        { image: this.state.image, type: "image" },
        {
          headers: {
            Authorization: "Token " + localStorage.token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        if (this.shareable_image) {
          this.shareable_image.src = "";
          this.shareable_image.src = res.data.data.sharable_image_url;
        }
        toast.success("Successfully changed Image", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ image: false });
        let data = this.props.data;
        data.sharable_image_url = res.data.data.sharable_image_url;
        this.props.handleChange(data);
      });
    axios
      .put(
        getBase(api.BACKGROUND_IMAGE + imgid),
        { image: this.props.data.recheck_image, type: "image" },
        {
          headers: {
            Authorization: "Token " + localStorage.token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        let data = this.props.data;
        data.background_image = res.data.data.background_image;
        this.props.handleChange(data);
      });
  };

  diffRefUrls = (originalObj, currentObj) => {
    if (originalObj.length === currentObj.length) {
      for (let i = 0; i < originalObj.length; i++) {
        for (let key in originalObj[i]) {
          if (originalObj[i][key] !== currentObj[i][key]) return true;
        }
      }
    } else {
      return true;
    }
    return false;
  };
  // this compares langcode from claims component and changes the verdict
  componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data.lang_code && this.props.data && this.props.data.lang_code && nextProps.data.lang_code !== this.props.data.lang_code) {
      const data = { ...nextProps.data }
      data.verdict = getVerdictAndPosition(nextProps.data.lang_code, this.props.data.verdict).changedVerdict
      this.props.handleChange(data)
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  handleOpen = () => {
    this.setState({
      open: true,
    });
  };
  closeHandle = () => {
    this.setState({
      open: false,
    });
  };
  handleGenerateImage = (e) => {
    e.preventDefault();
    // console.log('generate', this.image, this.state.imageurl)
    if (!this.state.imageurl) {
      toast.error("Please select a background image", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (this.image) {

        domtoimage
          .toJpeg(this.image, { quality: 0.75 })
          .then((data) => {
            this.setState({ image: data });
            // this.setState({ backgroundImageUpdate: data });
            toast.success(
              "Successfully created, see image below and press save image to save it",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          })
          .catch(function (error) {
            toast.error("Could not take screenshot", {
              position: toast.POSITION.TOP_RIGHT,
            });
            console.error("oops, something went wrong!", error);
          });
        this.setState({ backgroundImageUpdateThumbinal: true }, () => {
          domtoimage
            .toJpeg(this.backgroundImage)
            .then((data) => {
              this.setState({ backgroundImageUpdate: data }, () => {
                this.setState({ backgroundImageUpdateThumbinal: false })
              });
              toast.success(
                "Successfully created, see image below and press save image to save it",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            })
            .catch(function (error) {
              toast.error("Could not take screenshot", {
                position: toast.POSITION.TOP_RIGHT,
              });
              console.error("oops, something went wrong!", error);
              this.setState({ backgroundImageUpdateThumbinal: false })
            });
        })
      }
    }
  };
  handleSaveImage = (e) => {
    e.preventDefault()
    if (this.state.image) {
      toast.info("Please wait, trying to upload a picture. Could take time", {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (this.props.data._id.length > 9) {
        axios
          .put(
            getBase(api.SHAREABLE_IMAGE + this.props.data._id),
            { image: this.state.image, image_name: this.state.imageName },
            {
              headers: {
                Authorization: "Token " + localStorage.token,
                "content-type": "application/json",
              },
            }
          )
          .then((res) => {
            if (this.shareable_image) {
              this.shareable_image.src = "";
              this.shareable_image.src = res.data.data.sharable_image_url;
            }
            toast.success("Successfully changed Image", {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.setState({ image: false });
            let data = this.props.data;
            data.sharable_image_url = res.data.data.sharable_image_url;
            this.props.handleChange(data);
          });
        // console.log('back', this.state.backgroundImageUpdate)
        axios
          .put(
            getBase(api.BACKGROUND_IMAGE + '/' + this.props.data._id),
            { image: this.state.backgroundImageUpdate.length > 20 ? this.state.backgroundImageUpdate : this.state.imageurl, image_name: this.state.imageName },
            {
              headers: {
                Authorization: "Token " + localStorage.token,
                "content-type": "application/json",
              },
            }
          )
          .then((res) => {
            // console.log(res.data);
            let data = this.props.data;
            data.background_image = res.data.data.background_image;
            this.setState({ imageName: '' }, () => {
              this.props.handleChange(data);
            })
          });
      } else if (this.props.data._id.length <= 9) {
        axios
          .put(
            getBase(api.SHAREABLE_IMAGE + this.props.data._id),
            { image: this.state.image, type: "image", image_name: this.state.imageName },
            {
              headers: {
                Authorization: "Token " + localStorage.token,
                "content-type": "application/json",
              },
            }
          )
          .then((res) => {
            if (this.shareable_image) {
              this.shareable_image.src = "";
              this.shareable_image.src = res.data.data.sharable_image_url;
            }
            toast.success("Successfully changed Image", {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.setState({ image: false });
            let data = this.props.data;
            data.sharable_image_url = res.data.data.sharable_image_url;
            this.props.handleChange(data);
          });
        axios
          .put(
            getBase(api.BACKGROUND_IMAGE + '/' + this.props.data._id),
            { image: this.state.imageurl, type: "image", image_name: this.state.imageName },
            {
              headers: {
                Authorization: "Token " + localStorage.token,
                "content-type": "application/json",
              },
            }
          )
          .then((res) => {
            // console.log(res.data);
            let data = this.props.data;
            data.background_image = res.data.data.background_image;
            this.setState({ imageName: '' }, () => {
              this.props.handleChange(data);
            })
          });
      }
    } else {
      toast.error("No image found", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  handleVerdictChange = (e) => {
    let data = this.props.data;
    data.verdict = e.target.value;
    this.setState({ verdict: e.target.value }, () => {
      this.props.handleChange(data);
    })
  };

  handleChange = (val, type, max) => {
    let data = this.props.data;
    // muted the code on purpose do not delete kept for refrence
    // if (max) {
    //   if (val.length <= max) {
    //     if (type === "conclusion_summary") {
    // data.conclusion = data.conclusion.replace(
    //   data.conclusion_summary,
    //   val
    // );
    // }
    // data[type] = val;
    // this.props.handleChange(data);
    // }
    // } else {
    //   if (type === "conclusion") {
    // data[type] = data.conclusion_summary + "" + val;
    // data[type] = (data.conclusion_summary + "" + val).replace(
    //   /\s\s\s\s\s\s\s+/g,
    //   " "
    // );
    // data[type] = (data.conclusion_summary + "" + this.state.newValue).replace(
    //   /\s\s\s\s\s\s\s+/g,
    //   " "
    // );
    // console.log('change', data[type], data.conclusion_summary)
    //   } else {
    //   }
    // }
    if (type === 'image_caption') {
      toast.success('Caption saved', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (type === 'image_alt') {
      toast.success('Image Alt saved', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    data[type] = val;
    this.props.handleChange(data);
  };
  fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      const imageName = event.target.files[0].name.replace(/\.[^/.]+$/, "")
      Resizer.imageFileResizer(
        event.target.files[0],
        800,
        600,
        "JPEG",
        100,
        0,
        (uri) => {
          // console.log("uriuri", uri);
          this.setState({
            imageName,
            imageurl: uri,
            image: uri,
            backgroundImageUpdate: uri,
            backgroundImageUpdateThumbinal: true
          });
        },
        "base64"
      );
    } else {
      toast.error(
        "Image could not be uploaded, check to see if it is the correct format",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  }

  redirectForUpload(data) {

    let path = window.location.origin + window.location.pathname;
    let urldata = "?path=" + path;

    window.location.href = "https://images.logically.ai/" + urldata;

  }

  handleErr = (index) => {

    let errors = this.state.errors;
    errors.push(index);
    setTimeout(() => {
      this.setState({ errors });
    }, 1000);
  };
  createMarkup = (e) => {
    return e.target.innerHTML = this.state.value;
  }
  toggleHyperLink = () => {
    this.setState({ hyperlinkToogle: !this.state.hyperlinkToogle }, () => this.state.hyperlinkToogle && this.setState({ hyperlink: '' }))
  }
  toggleBold = () => {
    this.setState({ bold: !this.state.bold })
  }
  toggleItalic = () => {
    this.setState({ italic: !this.state.italic })
  }

  uploadImageCallBack(file) {
    // console.log(file)
    const size = (file.size / 1024).toFixed(2);
    const localStorageSize = new Blob(Object.values(window.localStorage)).size * 2 / 1024
    // less than 4.5mb
    if (localStorageSize > 4500) {
      return alert("Please save your data and empty your local storage")
    }
    if (size < 1) {
      return alert("Max Image size is 1mb")
    }
    return new Promise(
      (resolve, reject) => {
        const reader = new FileReader(); // eslint-disable-line no-undef
        reader.onload = e => resolve({ data: { link: e.target.result } });
        reader.onerror = e => reject(e);
        reader.readAsDataURL(file);
      });
  }

  getVerdictData = (lang_code) => {
    const { verdictByCode } = this.props;
    if (verdictByCode[lang_code]) {
      return verdictByCode[lang_code]
    }
    return verdictByCode['en']
  }
  render() {
    const { classes, data } = this.props;
    const { imageurl, image_caption, imageAlt } = this.state;
    console.log('props', image_caption)
    let user = JSON.parse(localStorage.user);
    let isFactchecker = true;
    if (user) {
      if (user.profile) {
        if (user.profile.fields) {
          isFactchecker = user.profile.fields.role === "factchecker";
        }
      }
    }
    // let previewimg = data.recheck_image;
    return (
      <div>
        <form>
          {/* <form onSubmit={this.handleGenerateImage}> */}
          <Grid container className={classes.main}>
            <Grid item lg={12} md={12} style={{ marginBottom: 40 }}>
              {!data.recheck_image ? (
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 15,
                    fontStyle: "Muli",
                  }}
                >
                  Claim and Conclusion
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 15,
                    fontStyle: "Muli",
                  }}
                >
                  Conclusion
                </Typography>
              )}
              <Paper className={classes.paper} elevation={0}>
                {!data.recheck_image && (
                  <TextField
                    style={{ fontWeight: 600 }}
                    placeholder="Claim"
                    className={classes.textField}
                    fullWidth
                    multiline
                    rows="3"
                    label="Claim"
                    required
                    value={data.modified_claim}
                    onChange={(e) =>
                      this.handleChange(e.target.value, "modified_claim")
                    }
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                <p
                  style={{
                    textAlign: "right",
                    color: data.conclusion_summary
                      ? data.conclusion_summary.length
                      : 0 > 150
                        ? "red"
                        : "#000",
                  }}
                >
                  {"(" +
                    (data.conclusion_summary
                      ? data.conclusion_summary.length
                      : 0) +
                    "/150)"}
                </p>

                <TextField
                  style={{ marginTop: -10, fontWeight: 600 }}
                  id="outlined-full-width"
                  label={"Conclusion - Max 150 Characters(0/150)"}
                  className={classes.textField}
                  fullWidth
                  multiline
                  required
                  rows="2"
                  inputProps={{ maxLength: 150 }}
                  value={data.conclusion_summary || ""}
                  onChange={(e) =>
                    this.handleChange(e.target.value, "conclusion_summary", 150)
                  }
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <div
                >
                  <TinyEditor
                    editorState={data.conclusion}
                    onEditorStateChange={(data) => this.handleChange(data, 'conclusion')}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Grid container className={classes.main} spacing={16}>
            <Grid item lg={12} md={12}>
              <Typography
                variant="h6"
                style={{ fontWeight: 600, fontSize: 15, fontStyle: "Muli" }}
                className={classes.heading}
              >
                Judgement
              </Typography>
              <Paper
                className={classes.paper}
                elevation={0}
                style={{ marginTop: 15, flex: 1 }}
              >
                <Grid container spacing={16}>
                  <Grid item lg={12} md={12}>
                    <FormControl
                      className={classes.margin}
                      style={{ margin: 8, width: "100%", marginBottom: 20 }}
                    >
                      {!data.recheck_image && (
                        <NativeSelect
                          value={
                            getVerdictAndPosition(data.lang_code, data.verdict).changedVerdict
                          }
                          id="Judgement"
                          onChange={this.handleVerdictChange}
                          required
                          input={
                            <BootstrapInput
                              name="verdict"
                              id="verdict-customized-native-simple"
                            />
                          }
                        >
                          {this.getVerdictData(data.lang_code).map((item, index) => {
                            return <option
                              value={item}
                              id={`Judgement${index}`}
                            >
                              {item}
                            </option>
                          })}
                        </NativeSelect>
                      )}
                    </FormControl>
                    {!isFactchecker && (
                      <div>
                        <FormControl
                          className={classes.margin}
                          style={{ margin: 8, width: "100%", borderRadius: 5 }}
                        >


                          <label
                            variant="contained"
                            size="large"
                            className={classes.button}
                            style={{
                              backgroundColor: "#E4EBF3",
                              width: "100%",
                              cursor: "pointer",
                              paddingTop: 10,
                              paddingBottom: 10,
                              textAlign: "center",
                            }}
                          >
                            Upload image
                            <input
                              type="file"
                              accept="image/*"
                              style={{ display: "none" }}
                              name={this.state.imageName}
                              onChange={this.fileChangedHandler.bind(this)}
                            />
                          </label>

                        </FormControl>
                        <Button
                          variant="contained"
                          size="large"
                          className={classes.button}
                          style={{
                            backgroundColor: "#E4EBF3",
                            width: "100%",
                            marginTop: 8,
                          }}
                          onClick={() => this.redirectForUpload(data)}
                        >
                          Choose Image from Dashboard
                        </Button>

                        {this.state.image && !isFactchecker && (
                          <FormControl
                            className={classes.margin}
                            style={{ margin: 8, width: "100%" }}
                          >
                            <Button
                              onClick={this.handleSaveImage}
                              variant="contained"
                              size="large"
                              type="button"
                              className={classes.button}
                              style={{
                                backgroundColor: "#E4EBF3",
                                width: "100%",
                              }}
                            >
                              Save Image
                            </Button>
                          </FormControl>

                        )}
                        {data.sharable_image_url && <FormControl fullWidth>
                          <Typography
                            variant="h6"
                            style={{ fontSize: 15, margin: 8 }}
                            className={classes.heading}
                          >
                            Add Image Caption:
                          </Typography>
                          <Grid
                            container
                            style={{
                              flex: 1,
                              paddingLeft: 10,
                              border: "1px solid #ced4da",
                              borderRadius: "5px",
                              marginTop: "8px",
                            }}
                          >
                            <input
                              placeholder="type here"
                              defaultValue={image_caption}
                              onChange={(e) => this.setState({ image_caption: e.target.value })}
                              style={{ flex: 1, paddingLeft: 10, border: 'none', font: 'inherit' }}
                              type="text"
                            />
                            <button className="save-button w-max"
                              type="button"
                              onClick={() => this.handleChange(image_caption, 'image_caption')}>
                              Save caption
                            </button>
                          </Grid>

                          <Typography
                            variant="h6"
                            style={{ fontSize: 15, margin: 8 }}
                            className={classes.heading}
                          >
                            Add Image Alt:
                          </Typography>
                          <Grid
                            container
                            style={{
                              flex: 1,
                              paddingLeft: 10,
                              border: "1px solid #ced4da",
                              borderRadius: "5px",
                              marginTop: "8px",
                            }}
                          >
                            <input
                              placeholder="type here"
                              value={imageAlt}
                              onChange={(e) => this.setState({ imageAlt: e.target.value })}
                              style={{ flex: 1, paddingLeft: 10, border: 'none', font: 'inherit' }}
                            // classes={{
                            //   root: classes.nestedInputRoot,
                            // }}
                            />
                            <button className="save-button w-max"
                              type="button"
                              onClick={() => this.handleChange(imageAlt, 'image_alt')}>
                              Save Image Alt
                            </button>
                          </Grid>
                        </FormControl>}
                      </div>
                    )}
                  </Grid>

                </Grid>
              </Paper>
            </Grid>
            <Grid item lg={12} md={12} style={{ marginTop: 55 }}>
              <Typography
                variant="h6"
                style={{ fontWeight: 600, fontSize: 15, fontStyle: "Muli" }}
                className={classes.heading}
              >
                Image
              </Typography>
              <Paper
                className={classes.paper}
                elevation={0}
                style={{ marginTop: 15, height: 'max-content' }}

              >
                <Grid container spacing={16}>
                  <Grid item lg={12} md={12}>
                    <img
                      src={imageurl || data.background_image}
                      ref={(ref) => {
                        this.backgroundImage = ref;
                        this.image = ref
                      }}
                      alt='some'
                      style={{
                        width: "100%",
                        objectFit: 'cover',
                        borderRadius: 16,
                      }}
                    />
                  </Grid>

                </Grid>
              </Paper>
            </Grid>
            {data.verdict && data.modified_claim && <Grid item lg={12} md={12}>
              <Typography
                variant="h6"
                style={{ fontWeight: 600, fontSize: 15, fontStyle: "Muli" }}
                className={classes.heading}
              >
                Hubspot Link
              </Typography>
              <Paper
                className={classes.paper}
                elevation={0}
                style={{ marginTop: 15, height: 'max-content' }}>
                <Typography
                  variant="h6"
                  style={{ fontSize: 15, fontStyle: "Muli", paddingBottom: '10px' }}
                >
                  <b style={{ color: 'red' }}>Note:</b>{" "} This is a possible link that will be generated after the claim has been completed and sent to the user.<b>(English claim only)</b>
                </Typography>
                <a href={`https://www.logicallyfacts.com/en/fact-check/${data.verdict.toLowerCase()}-${data.modified_claim.replaceAll(" ", "-").replaceAll(/[!@#$%^&*,]/g, "").toLowerCase()}`} target="_blank" rel="noopener noreferrer">{`https://www.logicallyfacts.com/en/fact-check/${data.verdict.toLowerCase()}-${data.modified_claim.replaceAll(" ", "-").replaceAll(/[!@#$%^&*,]/g, "").toLowerCase()}`}</a>
              </Paper>
            </Grid>}
          </Grid>


        </form>
      </div >
    );
  }
};

const mapStateToProps = (state) => {
  return { verdictByCode: state.verdictByCode }
}
export default connect(mapStateToProps)(withStyles(styles)(Judgement));
