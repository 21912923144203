import React from "react";

const PasswordIcon = ({ fill, styles }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.5 27"
      style={styles}
    >
      <path
        d="M21 11.3h-1.5v-4c0-4-3.3-7.3-7.3-7.3C8.3 0 5 3.3 5 7.3v4H3.5c-1.9 0-3.5 1.6-3.5 3.5v8.8C0 25.4 1.6 27 3.5 27H21c1.9 0 3.5-1.6 3.5-3.5v-8.8c0-1.9-1.6-3.4-3.5-3.4zm-14-4C7 4.4 9.4 2 12.3 2s5.3 2.4 5.3 5.3v4H7v-4zm15.5 16.2c0 .8-.7 1.5-1.5 1.5H3.5c-.8 0-1.5-.7-1.5-1.5v-8.8c0-.8.7-1.5 1.5-1.5H21c.8 0 1.5.7 1.5 1.5v8.8z"
        fill={fill}
      />
    </svg>
  );
};

export default PasswordIcon;
