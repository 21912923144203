import React, { Component } from 'react'
import axios from 'axios';
import api, { getBase } from '../api';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography, Input } from '@material-ui/core';
import UserExpandableTable from './UserExpandableTable';
import SortData from './SortData';

export class AdminUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            userSearch: '',
            order: '',
            orderBy: ''
        }
    }
    componentDidMount() {
        this.getAllUsers();
    }
    getAllUsers = () => {
        axios
            .get(getBase(api.USERS), {
                headers: { Authorization: "Token " + localStorage.token },
            })
            .then((res) => {
                this.setState({ users: res.data.data });
            });
    }
    handleSearch = (user) => {
        const { userSearch } = this.state
        if (userSearch) {
            if (user.first_name.toLowerCase().includes(userSearch)) {
                return user
            }
            if (user.last_name.toLowerCase().includes(userSearch)) {
                return user
            }
            if (user.username.toLowerCase().includes(userSearch)) {
                return user
            }
            if (user.email.toLowerCase().includes(userSearch)) {
                return user
            }
            return null
        }
        return user
    }
    handleSort = (a, b) => {
        const { order, orderBy } = this.state
        console.log(this.state.orderBy, this.state.order)
        if (order === 'asc') {
            return a[orderBy].localeCompare(b[orderBy])
        } else if (order === 'dsc') {
            return b[orderBy].localeCompare(a[orderBy])
        }
        return

    }
    render() {
        const { users, userSearch } = this.state;
        return (
            <div className='main' style={{ width: '100%' }}>
                <Paper style={{ padding: 16 }}>
                    <Typography style={{ fontWeight: 'bold' }}>Enter user details</Typography>
                    <Input value={userSearch} fullWidth style={{ maxWidth: '80%' }} placeholder='first name, last name, username or email' onChange={(e) => this.setState({ userSearch: e.target.value.toLocaleLowerCase() })} />
                    <br />
                    <Table padding="dense">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    ID
                                </TableCell>
                                <TableCell>
                                    <SortData name='First Name' orderBy="first_name" getSortedData={(data) => this.setState({ order: data.order, orderBy: data.orderBy })} />
                                </TableCell>
                                <TableCell>
                                    <SortData name='Last Name' orderBy="last_name" getSortedData={(data) => this.setState({ order: data.order, orderBy: data.orderBy })} />

                                </TableCell>
                                <TableCell>
                                    <SortData name='Username' orderBy="username" getSortedData={(data) => this.setState({ order: data.order, orderBy: data.orderBy })} />

                                </TableCell>
                                <TableCell>
                                    <SortData name='Email' orderBy="email" getSortedData={(data) => this.setState({ order: data.order, orderBy: data.orderBy })} />
                                </TableCell>
                                <TableCell>
                                    Role
                                </TableCell>
                                <TableCell>
                                    Status
                                </TableCell>
                                <TableCell>
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.filter(this.handleSearch).sort(this.handleSort).map(user => {
                                return (
                                    <UserExpandableTable key={user.user_id} user={user} refetchUsers={this.getAllUsers} />
                                );
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }
}

export default AdminUsers;