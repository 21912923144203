import React, { Component, createRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { InstagramEmbed, TikTokEmbed, FacebookEmbed, YouTubeEmbed, TwitterEmbed } from 'react-social-media-embed';
import Dropdown from '../../../Admin/Dropdown';
import { Input } from '@material-ui/core';
import { toast } from 'react-toastify';

export class TinyEditor extends Component {
    constructor(props) {
        super(props);
        const { editorState } = this.props
        this.state = {
            mediaSelector: '',
            mediaSelectorUrl: '',
            editorState: editorState,
        }
        this.editorRef = createRef(null);
    };
    getIframe = (e) => {
        e.preventDefault()
        const { mediaSelector } = this.state;
        const iframe = document.getElementsByTagName('iframe')[0];
        if (mediaSelector === 'rsme-twitter-embed') {
            navigator.clipboard.writeText(iframe.outerHTML.toString().replace('static', 'block'));
        } else {
            navigator.clipboard.writeText(iframe.outerHTML.toString());
        }
        toast.success('Successfully copied to clipboard')
    }
    optionSelected = () => {
        const { mediaSelector, mediaSelectorUrl } = this.state;

        if (mediaSelector === 'rsme-instagram-embed') {
            return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <InstagramEmbed url={mediaSelectorUrl} width={328} />
            </div>
        } else if (mediaSelector === 'rsme-tiktok-embed') {
            return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <TikTokEmbed url={mediaSelectorUrl} width={328} />
            </div>
        }
        else if (mediaSelector === 'rsme-facebook-embed') {
            return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <FacebookEmbed url={mediaSelectorUrl} width={328} />
            </div>
        } else if (mediaSelector === 'rsme-koo-embed') {
            return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <KooEmbed url={mediaSelectorUrl} width={328} />
            </div>
        }
        else if (mediaSelector === 'rsme-youtube-embed') {
            return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <YouTubeEmbed url={mediaSelectorUrl} width={328} />
            </div>
        }
        else if (mediaSelector === 'rsme-twitter-embed') {
            return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <TwitterEmbed url={mediaSelectorUrl} width={328} />

            </div>
        } else {
            return null
        }
    }
    render() {
        const { mediaSelectorUrl, editorState } = this.state;
        return (
            <div style={{ marginTop: 8 }}>
                {mediaSelectorUrl && this.optionSelected()}
                <div className='card-flex gap'>
                    <Input value={mediaSelectorUrl} onChange={(e) => this.setState({ mediaSelectorUrl: e.target.value })} fullWidth placeholder='Enter url for embed..' />
                    <Dropdown onChange={(data) => this.setState({ mediaSelector: data })} page='editor' />
                    <button className='save-button' style={{ width: 130 }} type='button' onClick={this.getIframe}>Copy embed</button>
                </div>
                <br />
                <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => this.editorRef.current = editor
                    }
                    initialValue={editorState}
                    onChange={(e) => this.props.onEditorStateChange(this.editorRef.current.getContent())}
                    init={{
                        height: '600',
                        menubar: true,
                        toolbar_sticky: true,
                        plugins: [
                            'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export', 'code',
                            'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                            'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'autoresize'
                        ],
                        image_advtab: true,
                        image_caption: true,
                        toolbar1: "bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect fontselect fontsizeselect table | hr removeformat | subscript superscript | charmap | print fullscreen | spellchecker | visualchars visualblocks nonbreaking template pagebreak restoredraft",
                        toolbar2: "cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | code | inserttime preview | forecolor backcolor",
                        toolbar_items_size: 'small',
                        images_reuse_filename: true,
                        extended_valid_elements: 'script[language|type|src]',
                        valid_elements: '+*[*]'
                    }}
                />
            </div>
        )
    }
};
const KooEmbed = ({ url, width }) => {
    const kooId = url.split('/').pop()
    const iframeUrl = `https://embed.kooapp.com/embedKoo?kooId=${kooId}`;
    return (
        <div className='rsme-koo-embed'>
            <iframe
                src={iframeUrl}
                title='koo'
                width={width}
                height="350"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    );
};

export default TinyEditor