import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CheckIcon from "@material-ui/icons/Check";
import api, { getBase } from "../../api";
import axios from "axios";
import { toast } from "react-toastify";

class ChangePass extends Component {
  state = {
    oldPass: "",
    newPass: "",
    confirmPass: "",
    isOldPassVisible: false,
    isNewPassVisible: false,
    isConfirmPassVisible: false,
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  toggleVisibility = (type) => {
    this.setState((prevState) => ({ [type]: !prevState[type] }));
  };

  validate = (newPass, confirmPass) => {
    if (newPass.length !== 0) {
      if (newPass === confirmPass) return true;
    }
    return false;
  };

  handleChangePassword = () => {
    const { oldPass, newPass } = this.state;
    if (oldPass && newPass) {
      axios
        .put(
          getBase(api.CHANGE_PASSWORD),
          { old_password: oldPass, new_password: newPass },
          { headers: { Authorization: "Token " + localStorage.token } }
        )
        .then((res) => {
          toast.success("Successfully changed password", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ oldPass: "", newPass: "", confirmPass: "" });
        })
        .catch((err) => {
          console.log("ERROR", err)
          toast.error("Error, could not change password", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      toast.error("Please fill all fields", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  handleSave = () => {
    const { newPass } = this.state;
    const { isInit, onComplete } = this.props;
    if (newPass) {
      if (isInit) onComplete();
      console.log(isInit, newPass);
      this.handleChangePassword();
    }
    else {
      toast.error("One or more fields are empty")

    }
  };

  render() {
    const {
      oldPass,
      newPass,
      confirmPass,
      isOldPassVisible,
      isNewPassVisible,
      isConfirmPassVisible,
    } = this.state;

    const { isInit, onComplete } = this.props;
    return (
      <div className="change-pass-container">
        <div className="change-pass-input-container">
          <Typography>Old password</Typography>
          <div className="pass-input-container">
            <input
              type={isOldPassVisible ? "text" : "password"}
              onChange={this.handleChange}
              name="oldPass"
              value={oldPass}
              autoComplete="new-password"
            />
            <button
              className="visibility-button"
              onClick={() => this.toggleVisibility("isOldPassVisible")}
            >
              {isOldPassVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </button>
          </div>
        </div>
        <div className="change-pass-input-container">
          <Typography>New Password</Typography>
          <div className="pass-input-container">
            <input
              type={isNewPassVisible ? "text" : "password"}
              onChange={this.handleChange}
              name="newPass"
              value={newPass}
              autoComplete="new-password"
            />
            <button
              className="visibility-button"
              onClick={() => this.toggleVisibility("isNewPassVisible")}
            >
              {isNewPassVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </button>
          </div>
        </div>
        <div className="change-pass-input-container">
          <Typography>Confirm password</Typography>
          <div className="pass-input-container">
            <input
              type={isConfirmPassVisible ? "text" : "password"}
              onChange={this.handleChange}
              name="confirmPass"
              value={confirmPass}
              autoComplete="new-password"
            />
            <div>
              {this.validate(newPass, confirmPass) && (
                <CheckIcon className="check-icon" />
              )}
              <button
                className="visibility-button"
                onClick={() => this.toggleVisibility("isConfirmPassVisible")}
              >
                {isConfirmPassVisible ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </button>
            </div>
          </div>
        </div>
        <button
          className="save-button"
          // disabled={
          //   (this.validate(newPass, confirmPass) && oldPass.length > 0)
          // }
          onClick={this.handleSave}
        >
          Save
        </button>
        {isInit && (
          <button
            className="blue-button"
            style={{ marginLeft: "10px" }}
            onClick={onComplete}
          >
            Skip
          </button>
        )}
      </div>
    );
  }
}

export default ChangePass;
