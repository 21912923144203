export function getUserByID(id, users) {
    let ret = false;
    if (id !== null) {
        for (var i = 0; i < users.length; i++) {
            if (users[i].role) {
                if (users[i].user_id === id) {
                    ret = users[i].first_name !== "" ? users[i].first_name + ' ' + users[i].last_name.charAt(0) : users[i].username;
                    return ret = ret.charAt(0).toUpperCase() + ret.slice(1);
                }

            }
        }

    }
    return ret;
}

export let colours = {
    None: '#ebebeb',
    True: '#3FC380',
    "SANDT": '#3FC380',
    "SANT": '#3FC380',
    "ನಿಜ": '#3FC380',
    "সঁচা": '#3FC380',
    "నిజం": '#3FC380',
    False: '#F64747',
    "FALSK": '#F64747',
    "FALSKT": '#F64747',
    "ತಪ್ಪು": '#F64747',
    "মিছা": '#F64747',
    "అబద్ధం": '#F64747',
    FALSE: '#F64747',
    Misleading: '#D252B2',
    "VILDLEDENDE": '#D252B2',
    "MISSVISANDE": '#D252B2',
    "ಸಂದರ್ಭದಿಂದ ಹೊರಗಿಡಲಾಗಿದೆ": '#D252B2',
    "বিভ্ৰান্তিকৰ": '#D252B2',
    "తప్పుదారి పట్టించేది": '#D252B2',
    Unverifiable: '#FF8C00',
    "UBEKRÆFTET": '#FF8C00',
    "OVERIFIERBAR": '#FF8C00',
    "ಪರಿಶೀಲಿಸಲಾಗುವುದಿಲ್ಲ": '#FF8C00',
    "অপৰীক্ষাযোগ্য": '#FF8C00',
    "నిర్ధారించలేనిది": '#FF8C00',
    Partly_True: '#19B5FE',
    "DELVIST SANDT": '#19B5FE',
    "DELVIS SANT": '#19B5FE',
    "ಭಾಗಶಃ ನಿಜ": '#19B5FE',
    "আংশিকভাৱে সত্য": '#19B5FE',
    "పాక్షికంగా నిజం": '#19B5FE',


    'काहीही नाही': '#ebebeb',
    "खरे": '#3FC380',
    "खोटे": '#F64747',
    "दिशाभूल": '#D252B2',
    "असत्यापित": '#FF8C00',
    "अंशतः सत्य": '#19B5FE',
    'कुछ भी नहीं': '#ebebeb',
    "सत्य": '#3FC380',
    "असत्य": '#F64747',
    'भ्रामक': '#D252B2',
    // "असत्यापित": '#FF8C00',
    "आंशिक रूप से सच है": '#19B5FE'
}

export function getBadgeData(data) {
    let ret = {
        newClaims: 0,
        pendingAuth: 0,
        inProgress: 0,
        resolved: 0,
        search: 0,
        newImages: 0
    }

    for (var i = 0; i < data.length; i++) {
        if (data[i].research === false) {
            ret.newClaims++;
        } else if (data[i].judgement === false) {
            ret.inProgress++;
        } else if (data[i].approved === false) {
            ret.pendingAuth++;
        } else if (data[i].approved === true) {
            ret.resolved++;
        } else if (data[i].approved === true) {
            ret.newImages++;
        }
    }

    return ret;
}

export function getLocation(data) {
    if (data !== undefined) {
        if (data === 'INDIA' || data === 'india' || data === 'India') {
            data = 'IN'
        }
        else if (data === 'UK' || data === 'uk' || data === 'Uk') {
            data = 'GB'
        }
        else if (data === 'USA' || data === 'us' || data === 'Us') {
            data = 'US'
        } else if (data === 'All') {
            data = 'All'
        } else if (data === "") {
            data = 'WW'
        }
        return data
    } else {
        return data = 'WW'
    }

}

export const priorityTextColors = {
    high: '#F62459',
    low: '#3FC380',
    medium: '#FF8C00'
}

export const priorityColors = {
    high: '#ffeaef',
    medium: '#ffe9d4',
    low: '#e1ffd8'
}
