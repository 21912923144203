import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import ButtonSection from "../buttonSection/buttonSection";
import SideBar from "../dashboardSideBar/dashboardSideBar";
import CardSection from "../factCheckSupervisorCardSection/factCheckSupervisorCardSection";
import SimpleCardSection from "../simpleCardSection/simpleCardSection";
import NewClaims from "../NewClaims/NewClaims";
import ResolvedClaims from "../ResolvedClaims/ResolvedClaims";
import FinalizeClaims from "../finalizeClaims/FinalizeClaims";
import { withStyles } from "@material-ui/core/styles";
import setParam, { getParam } from "./../../../utils/params";
import Complaint from "../ResolvedClaims/Complaint/Complaint";
import axios from "axios";
import api, { getUser, getBase } from "./../../../api";
import { toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { connect } from "react-redux";
import RejectedClaims from "../RejectedClaims/RejectedClaims";
import FactCheckerSimpleCard from "../factChecker/factCheckerSimpleCard/factCheckerSimpleCard";
import FactCheckerUrl from "../factChecker/factCheckerUrl/factCheckerUrl";
import SearchFilter from "../SearchFilter/SearchFilter";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  searchIcon: {
    width: theme.spacing.unit * 6,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

let tabs = [
  "",
  "received",
  "researching",
  "judgement",
  "finalize",
  "completed",
  "complaints",
  "rejected",
];

class FactCheckerSupervisor extends Component {
  constructor(props) {
    super(props);
    let user = JSON.parse(localStorage.user);

    let point = this.props.pointVal;
    let isFactChecker = false;
    if (user) {
      if (user.profile) {
        if (user.profile.fields) {
          isFactChecker = user.profile.fields.role === "factchecker";
          if (isFactChecker) {
            tabs = [
              "",
              "received",
              "rejected",
              "judgement",
              "completed",
              "Top_4_FC",
            ];
          }
        }
      }
    }

    let param = getParam(window.location.href, "tab");
    if (param) {
      point = tabs.indexOf(param);
    }
    let sort =
      getParam(window.location.href, "sort") ||
      '{"sort_key":"date","sort":"descending"}';
    sort = JSON.parse(sort);
    this.state = {
      point: this.props.claimsActiveTab,
      newClaims: 0,
      newImages: 0,
      pendingAuth: 0,
      inProgress: 0,
      resolved: 0,
      resolved_IND: 0,
      resolved_UK: 0,
      resolved_US: 0,
      finalize: 0,
      finalize_IND: 0,
      ImagesFinalize: 0,
      finalize_UK: 0,
      Resolved_Image: 0,
      finalize_US: 0,
      search_result: 0,
      filters: this.getFilters(),
      searchVal: getParam(window.location.href, "search"),
      sort: sort,
      searchData: [],
      searchData2: [],
      badgeSearch: 0,
      offSet: 0,
      searchTotal: 0,
      Complaintsopen: 0,
      complaintclosed: 0,
      searchImage: 0,
      badgeSearch2: 0,
      slection: "",
      complaint: 0,
      Imagecomplaint: 0,
      searchTotal_two: 0,
      judgement_image: 0,
      isfiltervisible: false,
      isSidebar: true,
      loads: false,
      queryValue: "",
      isFilterApply: false,
      complaintOpen: 0,
      resolved_Hero: 0,
      heroCountry: "IN",
      heroResolved_IN: 0,
      heroResolved_UK: 0,
      heroResolved_US: 0,
      newClaimsFilter: {
        buttons: "URL claims,Multimedia claims",
        checkbox: "Show accepted claims",
        dropDown: false,
        assigned: "Accepted by",
      },
      conductingResearchFilter: {
        buttons: "URL claims,Multimedia claims",
        checkbox: false,
        dropDown: false,
        assigned: "Assigned to",
      },
      makingJudgementFilter: {
        buttons: "URL claims,Multimedia claims",
        checkbox: "Show accepted claims",
        dropDown: "Country",
        dropDownOptions: ["India", "UK", "USA"],
        assigned: false,
      },
      finalizeFilter: {
        buttons: "URL claims,Multimedia claims",
        checkbox: "Show accepted claims",
        dropDown: "Country",
        dropDownOptions: ["India", "UK", "USA"],
        assigned: "Assigned to",
      },
      completedFilter: {
        buttons: "URL claims,Multimedia claims,Hero fact checks",
        checkbox: false,
        dropDown: "Country",
        dropDownOptions: ["India", "UK", "USA"],
        assigned: false,
      },
      complaintsFilter: {
        buttons: "Open complaints,Closed complaints",
        checkbox: false,
        dropDown: "Type",
        dropDownOptions: ["Reasoning", "Judgement", "other"],
        assigned: false,
      },
      RejectedFilter: {
        buttons: false,
        checkbox: false,
        dropDown: "Reason",
        dropDownOptions: [
          "Reason 1",
          "Reason 2",
          "Reason 3",
          "Reason 4",
          "Reason 5",
        ],
        assigned: false,
      },
      FactCheckerNewClaimsFilter: {
        buttons: "URL claims,Multimedia claims",
        checkbox: false,
        dropDown: false,
        dropDownOptions: false,
        assigned: "Assigned by",
        acceptButton: true,
      },
      FactCheckerRejectedClaimsFilter: {
        buttons: false,
        checkbox: false,
        dropDown: false,
        dropDownOptions: false,
        assigned: false,
        reason: "Reason",
      },
      FactCheckerApprovalPendingFilter: {
        buttons: "URL claims,Multimedia claims",
        checkbox: false,
        dropDown: false,
        dropDownOptions: false,
        assigned: "FC by ",
      },
      FactCheckerCompletedFilter: {
        buttons: "URL claims,Multimedia claims",
        checkbox: false,
        dropDown: "Filter by date",
        dropDownOptions: [
          "Last week",
          "Last month",
          "Last 3 months",
          "Last 6 months",
          "Last 1 year",
          "Last 2 years",
        ],
        assigned: false,
      },
      FactCheckerTop4FCFilter: {
        buttons: "India,UK,USA",
        checkbox: false,
        dropDown: false,
        dropDownOptions: false,
        assigned: false,
      },
      searchFilter: false,
    };
    this.timer = null;
  }
  getFilters = () => {
    let filters = {};
    let duration = getParam(window.location.href, "duration");
    let judgement = getParam(window.location.href, "judgement");
    let tags = getParam(window.location.href, "tags");
    let users = getParam(window.location.href, "users");
    let date = getParam(window.location.href, "date");
    let language = getParam(window.location.href, "language");
    let status = getParam(window.location.href, "status");
    let corrections = getParam(window.location.href, "corrections") === "true";
    let updates = getParam(window.location.href, "updates") === "true";

    if (duration) {
      filters.duration = JSON.parse(duration);
    }
    if (judgement) {
      filters.judgement = JSON.parse(judgement);
    }
    if (language) {
      filters.language = JSON.parse(language);
    }
    if (status) {
      filters.status = JSON.parse(status);
    }
    if (tags) {
      filters.tags = JSON.parse(tags);
    }
    if (users) {
      filters.users = JSON.parse(users);
    }
    filters.corrections = corrections;
    filters.updates = updates;
    if (date) {
      filters.date = JSON.parse(date);
      if (filters.date.length > 0) {
        if (filters.date[0].startdate) {
          filters.date[0].startDate = new Date(filters.date[0].startdate);
        }
        if (filters.date[0].enddate) {
          filters.date[0].endDate = new Date(filters.date[0].enddate);
        }
      }
    }

    return filters;
  };

  changeCountry = (country) => {
    this.setState({
      heroCountry: country,
    });
  };
  checkHandle = (point, country) => {
    let url = window.location.href;
    let currentTab = tabs[point];
    let newURL = setParam(window.location.origin, "tab", currentTab);
    window.history.pushState({ path: newURL }, "", newURL);
    this.setState(
      {
        heroCountry: country,
        isfiltervisible: false,
      },
      () => {
        this.props.dispatch({ type: "SET_LOADING", loading: true });
        this.props.dispatch({ type: "NAVPOINTS", navpoints: point });
      }
    );
    if (
      url.indexOf("/claim") > -1 ||
      url.indexOf("/users") > -1 ||
      url.indexOf("/me") > -1
    ) {
      window.location.reload();
    }
  };
  offsetData = (off) => {
    this.setState({
      offSet: off,
    });
    if (off >= 10) {
      setTimeout(() => {
        this.handlePagination(this.state.searchVal);
      }, 300);
    }
  };

  handleChange = (e) => {
    clearTimeout(this.timer);
    let val = e.target.value;
    let newURL = setParam(window.location.href, "search", val);
    window.history.pushState({ path: newURL }, "", newURL);
    this.timer = setTimeout(() => {
      this.setState({ searchVal: val });
    }, 300);
    if (val) {
      this.getSearchData(val);
    }
  };

  getSearchData(val) {
    if (val.length !== 0) {
      axios
        .get(getUser(api.SEARCH_FACTCHECKS + "?query=" + val + "&type=image"), {
          headers: {},
        })
        .then((res) => {
          if (JSON.stringify(res.data.data)) {
            this.timer = setTimeout(() => {
              this.setState({
                searchVal: val,
                searchData2: res.data.data,
                badgeSearch2: res.data.total_count,
              });
              this.props.dispatch({ type: "SWITCH_CLAIMS_TABS", tab: 12 });
            }, 200);
          } else {
            toast.error("Data Not Found", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          this.props.dispatch({ type: "SET_LOADING", loading: false });
        });
      axios
        .get(
          getUser(
            api.SEARCH_FACTCHECKS +
            "?query=" +
            val +
            this.state.queryValue +
            "&limit=25&offset=" +
            this.state.offSet
          ),
          { headers: {} }
        )
        .then((res) => {
          if (JSON.stringify(res.data.data)) {
            this.timer = setTimeout(() => {
              this.setState({
                searchVal: val,
                searchData: res.data.data,
                badgeSearch: res.data.total_count,
              });
            }, 200);
            this.props.dispatch({ type: "SWITCH_CLAIMS_TABS", tab: 12 });
          } else {
            toast.error("Data Not Found", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          this.props.dispatch({ type: "SET_LOADING", loading: false });
        });
    }
  }

  handlePagination = (val) => {
    clearTimeout(this.timer);
    let newURL = setParam(window.location.href, "search", val);
    window.history.pushState({ path: newURL }, "", newURL);
    this.timer = setTimeout(() => {
      this.setState({ searchVal: val });
    }, 300);
    if (val.length >= 3) {
      axios
        .get(
          getUser(
            api.SEARCH_FACTCHECKS +
            "?query=" +
            val +
            this.state.queryValue +
            "&limit=25&offset=" +
            this.state.offSet
          ),
          { headers: {} }
        )
        .then((res) => {
          if (JSON.stringify(res.data.data)) {
            this.timer = setTimeout(() => {
              this.setState({ searchVal: val, searchData: res.data.data });
            }, 300);
          }
        })
        .catch((err) => {
          this.props.dispatch({ type: "SET_LOADING", loading: false });
        });
    }
  };

  setFilters = (fil) => {
    this.setState({ filters: fil });
    let str = "";
    let keys = Object.keys(fil);
    for (var i = 0; i < keys.length; i++) {
      if (typeof fil[keys[i]] === "object") {
        let newarr = fil[keys[i]].filter((item, index) => {
          return item !== "";
        });
        str += keys[i] + "=" + newarr.join(",") + "&";
      } else {
        if (fil[keys[i]]) {
          str += keys[i] + "=" + fil[keys[i]] + "&";
        }
      }
    }
    this.setState({ queryValue: str, isFilterApply: true });
  };

  getData2(ranges) {
    this.setState({ slection: ranges });
  }
  setParam = (key, val) => {
    let newURL = setParam(window.location.href, key, val);

    window.history.pushState({ path: newURL }, "", newURL);
  };
  handleLoading = (process) => {
    this.setState({ loads: process });
  };
  handleCount = () => {
    axios
      .get(getBase(api.CLAIM_COUNT), {
        headers: { Authorization: "Token " + localStorage.token },
      })
      .then((res) => {
        let count = res.data.data;
        this.setState({
          newClaims: count.new_claims_count,
          newImages: count.image_claims_count,
          inProgress: count.conducting_research_count,
          pendingAuth: count.making_judgement_count,
          finalize: count.finalising_count,
          resolved: count.resolved_count,
          complaint: count.complaint_count,
          Complaintsopen: count.open_complaint_count,
        });
      });
  };
  componentDidMount() {
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    this.handleCount();
    if (this.state.point !== this.props.claimsActiveTab) {
      this.props.dispatch({
        type: "SWITCH_CLAIMS_TABS",
        tab: this.props.claimsActiveTab,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.searchFilter !== nextProps.searchFilter) {
      this.setState({ searchFilter: nextProps.searchFilter });
    }
    if (this.state.point !== nextProps.claimsActiveTab) {
      this.setState({ point: nextProps.claimsActiveTab });
    }
  }

  render() {
    const {
      classes,
      users,
      searchFilter,
      allUsers,
      countries,
      fcerFilter,
      languages,
      modFilter,
      sources,
      supFilter,
      userStatusFilter,
      verdictFilter,
      searchPageOffset,
      searchData,
    } = this.props;
    const {
      filters,
      searchVal,
      sort,
      slection,
      isfiltervisible,
      isSidebar,
      loads,
      queryValue,
      isFilterApply,
      newClaimsFilter,
      conductingResearchFilter,
      makingJudgementFilter,
      finalizeFilter,
      completedFilter,
      complaintsFilter,
      RejectedFilter,
      FactCheckerNewClaimsFilter,
      FactCheckerRejectedClaimsFilter,
      FactCheckerApprovalPendingFilter,
      FactCheckerCompletedFilter,
      FactCheckerTop4FCFilter,
    } = this.state;
    let data = {
      newClaims: this.state.newClaims,
      newImages: this.state.newImages,
      pendingAuth: this.state.pendingAuth,
      judgement_image: this.state.judgement_image,
      inProgress: this.state.inProgress,
      resolved: this.state.resolved,
      resolved_IND: this.state.resolved_IND,
      resolved_UK: this.state.resolved_UK,
      resolved_US: this.state.resolved_US,
      finalize: this.state.finalize,
      finalize_IND: this.state.finalize_IND,
      finalize_UK: this.state.finalize_UK,
      finalize_US: this.state.finalize_US,
      search_result: this.state.badgeSearch,
      ImagesFinalize: this.state.ImagesFinalize,
      Resolved_Image: this.state.Resolved_Image,
      searchTotal: this.state.badgeSearch + this.state.badgeSearch2,
      Complaintsopen: this.state.complaintOpen,
      complaintclosed: this.state.complaintclosed,
      searchTotal_two: this.state.pendingAuth + this.state.judgement_image,
      searchImage: this.state.badgeSearch2,
      complaint: this.state.complaint,
      Imagecomplaint: this.state.Imagecomplaint,
      finalize_total: this.state.finalize + this.state.ImagesFinalize,
      resolved_total: this.state.resolved + this.state.Resolved_Image,
    };

    let url = window.location.href;
    let user = JSON.parse(localStorage.user);

    let isModerated = true;
    if (user) {
      if (user.profile) {
        if (user.profile.fields) {
          isModerated = user.profile.fields.role === "moderator";
        }
      }
    }
    let isSupervisor = true;
    if (user) {
      if (user.profile) {
        if (user.profile.fields) {
          isSupervisor = user.profile.fields.role === "supervisor";
        }
      }
    }
    let isFactChecker = true;
    if (user) {
      if (user.profile) {
        if (user.profile.fields) {
          isFactChecker = user.profile.fields.role === "factchecker";
        }
      }
    }
    return (
      <div>
        <ButtonSection
          point={this.state.point}
          users={users}
          checkHandle={this.checkHandle.bind(this)}
          data={data}
          Complaintsopen={this.state.Complaintsopen}
          isSidebar={isSidebar}
          is
          loads={loads}
          handleLoader={this.handleLoading}
        />

        <Grid container justify="space-around" style={{ width: "97%" }}>
          {(isSupervisor || isModerated) && (
            <>
              <Grid item md={12} style={{ marginLeft: "3%" }}>
                <div>
                  {this.state.point === 1 && (
                    <div>
                      <NewClaims
                        isFilterApply={isFilterApply}
                        queryValue={queryValue}
                        users={users}
                        isSidebar={isSidebar}
                        setBadge={(num) => this.setState({ newClaims: num })}
                        searchVal={searchVal}
                        filters={filters}
                        current={this.state.point === 1}
                        filterOptions={newClaimsFilter}
                        searchFilter={searchFilter}
                        searchData={searchData}
                        onHandleClearFilters={this.props.onHandleClearFilters}
                      />
                    </div>
                  )}
                  {this.state.point === 2 && (
                    <div>
                      <SimpleCardSection
                        isFilterApply={isFilterApply}
                        queryValue={queryValue}
                        users={users}
                        isSidebar={isSidebar}
                        setBadge={(num) => this.setState({ pendingAuth: num })}
                        searchVal={searchVal}
                        filters={filters}
                        current={this.state.point === 2}
                        filterOptions={conductingResearchFilter}
                        searchFilter={searchFilter}
                        searchData={searchData}
                        onHandleClearFilters={this.props.onHandleClearFilters}
                      />
                    </div>
                  )}
                  {this.state.point === 3 && (
                    <div>
                      <CardSection
                        isFilterApply={isFilterApply}
                        queryValue={queryValue}
                        users={users}
                        isSidebar={isSidebar}
                        setBadge={(num) => this.setState({ inProgress: num })}
                        searchVal={searchVal}
                        filters={filters}
                        current={this.state.point === 3}
                        filterOptions={makingJudgementFilter}
                        searchFilter={searchFilter}
                        searchData={searchData}
                        onHandleClearFilters={this.props.onHandleClearFilters}
                      />
                    </div>
                  )}
                  {this.state.point === 4 && (
                    <div>
                      <FinalizeClaims
                        isFilterApply={isFilterApply}
                        queryValue={queryValue}
                        isSidebar={isSidebar}
                        users={users}
                        setBadge={(num) => this.setState({ finalize: num })}
                        searchVal={searchVal}
                        filters={filters}
                        current={this.state.point === 4}
                        filterOptions={finalizeFilter}
                        searchFilter={searchFilter}
                        searchData={searchData}
                        onHandleClearFilters={this.props.onHandleClearFilters}
                      />
                    </div>
                  )}
                  {this.state.point === 5 && (
                    <div>
                      <ResolvedClaims
                        isFilterApply={isFilterApply}
                        queryValue={queryValue}
                        users={users}
                        setBadge={(num) => this.setState({ resolved: num })}
                        searchVal={searchVal}
                        filters={filters}
                        current={this.state.point === 5}
                        sort={sort}
                        selection={slection}
                        filterOptions={completedFilter}
                        searchFilter={searchFilter}
                        searchData={searchData}
                        onHandleClearFilters={this.props.onHandleClearFilters}
                      />
                    </div>
                  )}

                  {this.state.point === 6 && (
                    <div>
                      <Complaint
                        isFilterApply={isFilterApply}
                        queryValue={queryValue}
                        users={users}
                        setBadge={(num) =>
                          this.setState({ complaintOpen: num })
                        }
                        searchVal={searchVal}
                        filters={filters}
                        current={this.state.point === 6}
                        sort={sort}
                        selection={slection}
                        filterOptions={complaintsFilter}
                        searchFilter={searchFilter}
                        searchData={searchData}
                        onHandleClearFilters={this.props.onHandleClearFilters}
                      />
                    </div>
                  )}

                  {this.state.point === 7 && (
                    <div>
                      <RejectedClaims
                        isFilterApply={isFilterApply}
                        queryValue={queryValue}
                        users={users}
                        setBadge={(num) => this.setState({ resolved_IND: num })}
                        searchVal={searchVal}
                        filters={filters}
                        current={this.state.point === 6}
                        sort={sort}
                        filterOptions={RejectedFilter}
                        searchFilter={searchFilter}
                        searchData={searchData}
                        onHandleClearFilters={this.props.onHandleClearFilters}
                      />
                    </div>
                  )}
                </div>
              </Grid>
              {searchFilter && (
                <SearchFilter
                  from="claims"
                  supFilter={supFilter}
                  fcerFilter={fcerFilter}
                  modFilter={modFilter}
                  userStatusFilter={userStatusFilter}
                  verdictFilter={verdictFilter}
                  languages={languages}
                  sources={sources}
                  countries={countries}
                  allUsers={allUsers}
                  searchPageOffset={searchPageOffset}
                  onHandleUserChange={this.props.onHandleUserChange}
                  onHandleCheckBox={this.props.onHandleCheckBox}
                  onHandleClearFilters={this.props.onHandleClearFilters}
                  onHandleDeleteFilterItem={this.props.onHandleDeleteFilterItem}
                  onHandleRangeChange={this.props.onHandleRangeChange}
                />
              )}
            </>
          )}
          {isFactChecker && (
            <>
              <Grid item md={12} style={{ paddingLeft: 16, paddingTop: 0 }}>
                <div>
                  {this.state.point === 1 && (
                    <div>
                      <FactCheckerSimpleCard
                        isFilterApply={isFilterApply}
                        queryValue={queryValue}
                        users={users}
                        isSidebar={isSidebar}
                        setBadge={(num) => this.setState({ pendingAuth: num })}
                        searchVal={searchVal}
                        filters={filters}
                        current={this.state.point === 1}
                        filterOptions={FactCheckerNewClaimsFilter}
                        searchFilter={searchFilter}
                        searchData={searchData}
                        clearFilter={this.props.clearFilter}
                      />
                    </div>
                  )}
                  {this.state.point === 2 && (
                    <div>
                      <FactCheckerSimpleCard
                        isFilterApply={isFilterApply}
                        queryValue={queryValue}
                        users={users}
                        isSidebar={isSidebar}
                        setBadge={(num) => this.setState({ pendingAuth: num })}
                        searchVal={searchVal}
                        filters={filters}
                        current={this.state.point === 2}
                        filterOptions={FactCheckerRejectedClaimsFilter}
                        searchFilter={searchFilter}
                        searchData={searchData}
                        clearFilter={this.props.clearFilter}
                      />
                    </div>
                  )}
                  {this.state.point === 3 && (
                    <div>
                      <FactCheckerSimpleCard
                        isFilterApply={isFilterApply}
                        queryValue={queryValue}
                        users={users}
                        isSidebar={isSidebar}
                        setBadge={(num) => this.setState({ pendingAuth: num })}
                        searchVal={searchVal}
                        filters={filters}
                        current={this.state.point === 3}
                        filterOptions={FactCheckerApprovalPendingFilter}
                        searchFilter={searchFilter}
                        searchData={searchData}
                        clearFilter={this.props.clearFilter}
                      />
                    </div>
                  )}
                  {this.state.point === 4 && (
                    <div>
                      <FactCheckerUrl
                        isFilterApply={isFilterApply}
                        queryValue={queryValue}
                        users={users}
                        isSidebar={isSidebar}
                        setBadge={(num) => this.setState({ pendingAuth: num })}
                        searchVal={searchVal}
                        filters={filters}
                        datapoint={this.state.point}
                        current={this.state.point === 4}
                        searchFilter={searchFilter}
                        searchData={searchData}
                        clearFilter={this.props.clearFilter}
                        filterOptions={FactCheckerCompletedFilter}
                      />
                    </div>
                  )}
                  {this.state.point === 5 && (
                    <div>
                      <FactCheckerSimpleCard
                        isFilterApply={isFilterApply}
                        queryValue={queryValue}
                        users={users}
                        isSidebar={isSidebar}
                        setBadge={(num) => this.setState({ pendingAuth: num })}
                        searchVal={searchVal}
                        filters={filters}
                        current={this.state.point === 5}
                        filterOptions={FactCheckerTop4FCFilter}
                        searchFilter={searchFilter}
                        searchData={searchData}
                        clearFilter={this.props.clearFilter}
                      />
                    </div>
                  )}
                </div>
              </Grid>
              {searchFilter && (
                <SearchFilter
                  from="claims"
                  supFilter={supFilter}
                  fcerFilter={fcerFilter}
                  modFilter={modFilter}
                  userStatusFilter={userStatusFilter}
                  verdictFilter={verdictFilter}
                  languages={languages}
                  sources={sources}
                  countries={countries}
                  allUsers={allUsers}
                  searchPageOffset={searchPageOffset}
                  onHandleUserChange={this.props.onHandleUserChange}
                  onHandleCheckBox={this.props.onHandleCheckBox}
                  onHandleClearFilters={this.props.onHandleClearFilters}
                  onHandleDeleteFilterItem={this.props.onHandleDeleteFilterItem}
                  onHandleRangeChange={this.props.onHandleRangeChange}
                />
              )}
            </>
          )}

          <Grid
            className={classes.gridItem}
            item
            md={3}
            style={{ padding: 16 }}
          >
            <div className={isfiltervisible ? "filterui active" : "filterui"}>
              <SideBar
                users={users}
                setFilters={this.setFilters}
                handleFilter={(d) => {
                  this.setState({ isfiltervisible: d });
                }}
                isfiltervisible={isfiltervisible}
                filters={filters}
                current={this.state.point}
                setSort={(nsort) => {
                  this.setState({ sort: nsort });
                  this.setParam("sort", JSON.stringify(nsort));
                }}
                sort={sort}
                getData2={(ranges) => this.getData2(ranges)}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    navpoints: state.navpoints,
    searchFilter: state.searchFilter,
    claimsActiveTab: state.claimsActiveTab,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(FactCheckerSupervisor)
);

export class Searchbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isSidebar, searchVal } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <Grid container>
          <Grid item md={isSidebar ? 10 : 11}>
            <InputBase
              onChange={this.props.handleChange}
              defaultValue={searchVal}
              placeholder="Tracking Code or Claim"
              startAdornment={
                <IconButton>
                  <SearchIcon />
                </IconButton>
              }
              style={{
                fontSize: 14,
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "10px",
                paddingLeft: 5,
                height: "40px",
              }}
              type="search"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
