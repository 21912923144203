import React, { Component } from "react";

// import "react-circular-progressbar/dist/styles.css";

// import "react-circular-progressbar/dist/styles.css";
import api, { getBase } from "../../api";
import axios from "axios";
import RadialProgress from "./RadialProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

class Summary extends Component {
  state = {
    loading: true,
    summaries: [],
  };

  componentDidMount() {
    this.getPercentages();
  }

  getPercentages = async () => {
    const { data } = await axios.get(getBase(api.OVERVIEW_PERCENTAGES), {
      headers: { Authorization: "Token " + localStorage.token },
    });
    const { completed, sla_crossed, in_progress, in_queue } = data.data;
    let summaries = [
      {
        value: Math.round(in_queue * 10) / 10,
        pathColour: "#D252B2",
        bg: "#FFDCF6",
        type: "In queue",
      },
      {
        value: Math.round(sla_crossed * 10) / 10,
        pathColour: "#F64747",
        bg: "#FFDDDD",
        type: "Crossed SLA",
      },
      {
        value: Math.round(in_progress * 10) / 10,
        pathColour: "#3360F3",
        bg: "#F1F4F8",
        type: "In progress",
      },
      {
        value: Math.round(completed * 10) / 10,
        pathColour: "#3FC380",
        bg: "#D3FFE9",
        type: "Completed",
      },
    ];
    this.setState({ summaries, loading: false });
  };

  render() {
    const { summaries, loading } = this.state;
    if (loading)
      return (
        <div className="loading-screen">
          <CircularProgress />
        </div>
      );
    return (
      <div className="summary-container">
        {summaries.map((summary, index) => (
          <RadialProgress
            key={index}
            value={summary.value}
            pathColour={summary.pathColour}
            bg={summary.bg}
            type={summary.type}
          />
        ))}
      </div>
    );
  }
}

export default Summary;
