import { MenuItem, Select } from '@material-ui/core';
import React, { Component } from 'react'


const role = ['admin', 'supervisor', 'factchecker'];
const default1 = [true, false];
const media = [
    { name: 'Instagram', value: 'rsme-instagram-embed' },
    { name: 'TikTok', value: 'rsme-tiktok-embed' },
    { name: 'Facebook', value: 'rsme-facebook-embed' },
    { name: 'Koo', value: 'rsme-koo-embed' },
    { name: 'YouTube', value: 'rsme-youtube-embed' },
    { name: 'Twitter', value: 'rsme-twitter-embed' }
];
export class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value || false,
        }
    }
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.props.onChange(this.state.value)
        });
    };
    render() {
        const { value } = this.state;
        const { page } = this.props
        return (
            <Select
                value={value}
                onChange={this.handleChange}
                inputProps={{
                    name: 'value'
                }}
            >
                {page === 'users' ? role.map(item => {
                    return <MenuItem key={item} value={item}>{item}</MenuItem>
                })
                    : page === 'editor' ?
                        media.map(item => {
                            return <MenuItem key={item.name} value={item.value}>{item.name}</MenuItem>
                        })
                        : default1.map(item => {
                            return <MenuItem key={item.toString()} value={item}>{item.toString()}</MenuItem>
                        })
                }
            </Select>
        )
    }
}

export default Dropdown