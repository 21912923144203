import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getRole } from "../../App";
import { getTimeDifference } from "../../utils/time";

class Activity extends Component {
  render() {
    const { users, currentUser, userActivity } = this.props;
    return (
      <div className="Activity-container">
        {getRole(currentUser) !== "factchecker" && (
          <h4 style={{ marginLeft: "2.3%" }}>My Squad</h4>
        )}
        <div className="activity-details">
          <div className="activity-header">
            <div className="activity-names">
              <Typography>Names</Typography>
            </div>
            <div className="activity-stat">
              <Typography>Status</Typography>
            </div>
            <div className="activity-stat">
              <Typography>Time in state</Typography>
            </div>
            <div className="activity-stat">
              <Typography>Pending claims</Typography>
            </div>
          </div>
          <div className="users-list-container">
            {users.length > 0 && userActivity ? (
              users.map((user) => (
                <div className="user-details-container" key={user.user_id}>
                  <div className="activity-names">
                    <Typography>{user.username}</Typography>
                  </div>
                  <div className="activity-stat">
                    <Typography>{userActivity[user.user_id].status}</Typography>
                  </div>
                  <div className="activity-stat">
                    <Typography>
                      {getTimeDifference(
                        userActivity[user.user_id].time_in_status,
                        new Date()
                      )}
                    </Typography>
                  </div>
                  <div
                    className="activity-stat"
                    style={{ textAlign: "center" }}
                  >
                    <Typography>{user.pending}</Typography>
                  </div>
                </div>
              ))
            ) : (
              <div className="loading-screen">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Activity;
