import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ProcessImage from 'react-imgpro';
import { AddCircle, DeleteForever, ExpandLess, ExpandMore } from "@material-ui/icons";

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormControl, IconButton, Input, InputAdornment } from '@material-ui/core';
import moment from 'moment';
import axios from 'axios';

const styles = theme => ({
    root: {
        flexGrow: 1,
        boxShadow: 'none'
    },


    paper: {
        backgroundColor: '#f8f8f8',
        textAalign: 'center',
        padding: 15,
        boxShadow: 'none',
        borderRadius: 15
    },

    textField: {
        position: 'relative',
        width: '100%',
        color: '#ffffff'
    },
    textField2: {
        position: 'relative',
        width: '100%',
        color: '#ffffff',
        marginTop: -10,
    },

    button: {
        marginLeft: 35,
        textTransform: 'none',
        width: `${70}%`,
        margin: theme.spacing.unit,
        marginTop: 40
    },
    button2: {
        position: 'relative',
        marginLeft: '90%',
        marginTop: -90,
        textTransform: 'none',
    },
    main:
    {
        marginTop: 15,
        boxShadow: 'none'
    },
    title: {
        fontWeight: "bold"
    }
});

class DetailClaimReceived extends Component {
    constructor(props) {
        super(props);
        this.state = {
            src: '',
            err: null,
            checkval: this.props.data.complaint_resolved,
            copied: false
        }
    }

    isUrlMultiline = (url) => {
        if (url) {
            return url.length > 100 ? true : false
        }
        else return false;
    }

    render() {
        const { classes, data } = this.props;
        let msg
        if (!data.recheck_image) {
            msg = data.message
        }
        else {
            msg = data.recheck_message

        }
        return (
            <Grid container className={classes.main}>
                <Grid item md={12}>
                    <Grid container className={classes.root}>
                        <Grid item xs={12}>
                            <Grid container spacing={16} className={classes.demo}>
                                <Grid item md={12}>
                                    <Grid container justify="space-between" alignItems="center">
                                        <Grid item>
                                            {data.recheck_image ? <Typography variant="h6" gutterBottom style={{ marginTop: 10, fontWeight: 600, fontSize: 15, fontStyle: "Muli" }}>Details about Image received</Typography> : <Typography variant="h6" gutterBottom style={{ marginTop: 10, fontWeight: 600, fontSize: 15, fontStyle: "Muli" }}>Details about claim received </Typography>}
                                        </Grid>

                                    </Grid>
                                    <Paper className={classes.paper} >
                                        <div>
                                            {data.recheck_image ?
                                                <Grid container spacing={24}>
                                                    <Grid item lg={3}>
                                                        <Typography variant="h6" gutterBottom style={{ marginTop: 10, textalign: 'center', fontWeight: "bold" }}>Original Image</Typography>
                                                        <ProcessImage
                                                            image={data.original_image}
                                                            resize={{ width: '100%' }}
                                                            colors={{
                                                                mix: {
                                                                    color: 'mistyrose',
                                                                    amount: 20
                                                                }
                                                            }}
                                                            processedImage={(src, err) => this.setState({ src, err })}
                                                        />

                                                    </Grid>
                                                    {data.analysed_image && <Grid item lg={3}>
                                                        <Typography variant="h6" gutterBottom style={{ marginTop: 10, textalign: 'center' }}>Automatically Analysed</Typography>
                                                        <ProcessImage
                                                            image={data.analysed_image}
                                                            resize={{ width: '100%' }}
                                                            colors={{
                                                                mix: {
                                                                    color: 'mistyrose',
                                                                    amount: 20
                                                                }
                                                            }}
                                                            processedImage={(src, err) => this.setState({ src, err })}
                                                        />
                                                    </Grid>}
                                                    <Grid item lg={3}>
                                                        <Typography variant="h6" gutterBottom style={{ marginTop: 10, textalign: 'center' }}>Image To Be Checked</Typography>
                                                        <ProcessImage
                                                            image={data.recheck_image}
                                                            resize={{ width: '100%' }}
                                                            colors={{
                                                                mix: {
                                                                    color: 'mistyrose',
                                                                    amount: 20
                                                                }
                                                            }}
                                                            processedImage={(src, err) => this.setState({ src, err })}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid container>
                                                    <Grid item xs={12} md={data.has_complaint ? 7 : 12} style={{ paddingRight: 30 }}>
                                                        <Typography variant="h6" className={classes.title}>
                                                            {data.claim}
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            }

                                        </div>

                                        <div>
                                            <Grid container className={classes.root}>
                                                <Grid item xs={12} md={12} style={{ paddingRight: data.has_complaint ? 30 : 0 }}>
                                                    <TextField
                                                        label="Message"
                                                        multiline
                                                        rows="5"
                                                        defaultValue={msg}
                                                        className={classes.textField}
                                                        margin="normal"
                                                        variant="outlined"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                    <TextField
                                                        disabled
                                                        id="outlined-disabled"
                                                        defaultValue={data.claim_source_url || data.url}
                                                        className={classes.textField2}
                                                        multiline={this.isUrlMultiline(data.claim_source_url || data.url)}
                                                        margin="normal"
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: (data.claim_source_url || data.url) && <CopyToClipboard text={data.claim_source_url || data.url}
                                                                onCopy={() => this.setState({ copied: true })}>
                                                                <Button style={{ backgroundColor: '#3360F3', margin: '5px' }}>
                                                                    <Typography style={{ color: '#fff' }}><b>COPY</b></Typography>
                                                                </Button>
                                                            </CopyToClipboard>
                                                        }}
                                                    />
                                                    <Grid container >
                                                        {data.source && <Grid item>
                                                            <Typography><span style={{ fontFamily: 'Mulish', fontWeight: "600" }}>{data.source}</span> </Typography>
                                                        </Grid>}
                                                        {data.source_published_date && data.source && <Grid item>
                                                            <Typography><span style={{ fontWeight: 'bold', fontSize: 16 }}> &nbsp;&nbsp;|&nbsp;&nbsp;</span></Typography>
                                                        </Grid>}
                                                        {data.source_published_date && <Grid item>
                                                            <Typography><span style={{ fontFamily: 'Mulish', fontWeight: "bold", color: 'gray' }}>Published on </span>{data.source_published_date}</Typography>
                                                        </Grid>}
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


DetailClaimReceived.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailClaimReceived);
