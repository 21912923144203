import { Input, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import Dropdown from './Dropdown';
import UserSearch from './UserSearch';

export class ClaimProcess extends Component {
    constructor(props) {
        super(props);
        const { assigned,
            assigned_by,
            rejected,
            finalising,
            moderated,
            moderator_id,
            moderator_completed_at,
            has_complaint,
            recheck,
            accepted,
            accepted_by,
            approved,
            rating,
            supervisor_id,
            supervisor_completed_at,
            fact_checked,
            factcheck_completed_at,
            status,
            completed_at,
            judgement,
            verification
        } = this.props.data;
        this.state = {
            assigned: assigned,
            assigned_by: assigned_by,
            rejected: rejected,
            finalising: finalising,
            moderated: moderated,
            moderator_id: moderator_id,
            moderator_completed_at: moderator_completed_at,
            has_complaint: has_complaint,
            recheck: recheck,
            accepted: accepted,
            accepted_by: accepted_by,
            approved: approved,
            rating: rating,
            supervisor_id: supervisor_id,
            supervisor_completed_at: supervisor_completed_at,
            fact_checked: fact_checked,
            status: status,
            completed_at: completed_at,
            factcheck_completed_at: factcheck_completed_at,
            judgement: judgement,
            verification
        }
    }
    onChangeInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleSubmit = () => {
        if (this.props.claimProcessData) {
            this.props.claimProcessData({ ...this.state })
        }
    }
    render() {
        const { assigned,
            assigned_by,
            rejected,
            finalising,
            moderated,
            moderator_id,
            moderator_completed_at,
            has_complaint,
            recheck,
            accepted,
            accepted_by,
            approved,
            supervisor_id,
            supervisor_completed_at,
            fact_checked,
            factcheck_completed_at,
            status,
            completed_at,
            judgement,
            verification
        } = this.state
        return (
            <div>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Step</TableCell>
                            <TableCell>Data Field</TableCell>
                            <TableCell >Value</TableCell>
                            <TableCell >Change</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Conducting Research</TableCell>
                            <TableCell>Assigned</TableCell>
                            <TableCell>{this.props.data.assigned ? this.props.data.assigned.toString() : ''}</TableCell>
                            <TableCell>
                                <Dropdown value={assigned ? true : false} onChange={(data) => this.setState({ assigned: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Conducting Research</TableCell>
                            <TableCell>Assigned by</TableCell>
                            <TableCell>{this.props.data.assigned_by}</TableCell>
                            <TableCell>
                                <Input fullWidth value={assigned_by} name='assigned_by'
                                    onChange={this.onChangeInput} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Conducting Research</TableCell>
                            <TableCell>Supervisor Id</TableCell>
                            <TableCell>{this.props.data.supervisor_id}</TableCell>
                            <TableCell>
                                <Input fullWidth value={supervisor_id} name='supervisor_id'
                                    onChange={this.onChangeInput} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>MJ (Approval)</TableCell>
                            <TableCell>Fact checked</TableCell>
                            <TableCell>{this.props.data.fact_checked ? this.props.data.fact_checked.toString() : ''}</TableCell>
                            <TableCell>
                                <Dropdown value={fact_checked ? true : false} onChange={(data) => this.setState({ fact_checked: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>MJ (Approval)</TableCell>
                            <TableCell>Fact checked completed at</TableCell>
                            <TableCell>{this.props.data.factcheck_completed_at ? this.props.data.factcheck_completed_at.toString() : ''}</TableCell>
                            <TableCell>
                                <Dropdown value={factcheck_completed_at ? true : false} onChange={(data) => this.setState({ factcheck_completed_at: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>MJ (Approval)</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>{this.props.data.status === 'resolved' ? 'Check for completed status' : this.props.data.status}</TableCell>
                            <TableCell>
                                <Input fullWidth value={status} name='status'
                                    onChange={this.onChangeInput} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>MJ (Approval)</TableCell>
                            <TableCell>Judgement</TableCell>
                            <TableCell>{this.props.data.judgement ? this.props.data.judgement.toString() : ''}</TableCell>
                            <TableCell>
                                <Dropdown value={judgement ? true : false} onChange={(data) => this.setState({ judgement: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>MJ (Accept button)</TableCell>
                            <TableCell>Accepted</TableCell>
                            <TableCell>{this.props.data.accepted ? this.props.data.accepted.toString() : ''}</TableCell>
                            <TableCell>
                                <Dropdown value={accepted ? true : false} onChange={(data) => this.setState({ accepted: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>MJ (Accept button)</TableCell>
                            <TableCell>Accepted_by</TableCell>
                            <TableCell>{this.props.data.accepted_by}</TableCell>
                            <TableCell>
                                <Input fullWidth value={accepted_by} name='accepted_by'
                                    onChange={this.onChangeInput} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Send to finalise button</TableCell>
                            <TableCell>Moderator Completed At</TableCell>
                            <TableCell>{this.props.data.moderator_completed_at}</TableCell>
                            <TableCell>
                                <Input fullWidth value={moderator_completed_at} name='moderator_completed_at'
                                    onChange={this.onChangeInput} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Send to finalise button</TableCell>
                            <TableCell>Finalising</TableCell>
                            <TableCell>{this.props.data.finalising ? this.props.data.finalising.toString() : ''}</TableCell>
                            <TableCell>
                                <Dropdown value={finalising ? true : false} onChange={(data) => this.setState({ finalising: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Finalise (Accept button)</TableCell>
                            <TableCell>Accepted</TableCell>
                            <TableCell>{this.props.data.accepted ? this.props.data.accepted.toString() : ''}</TableCell>
                            <TableCell>
                                <Dropdown value={accepted ? true : false} onChange={(data) => this.setState({ accepted: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Finalise (Accept button)</TableCell>
                            <TableCell>Accepted_by</TableCell>
                            <TableCell>{this.props.data.accepted_by}</TableCell>
                            <TableCell>
                                <Input fullWidth value={accepted_by} name='accepted_by'
                                    onChange={this.onChangeInput} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Completed Tab</TableCell>
                            <TableCell>Moderated</TableCell>
                            <TableCell>{this.props.data.moderated ? this.props.data.moderated.toString() : ''}</TableCell>
                            <TableCell>
                                <Dropdown value={moderated ? true : false} onChange={(data) => this.setState({ moderated: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Completed Tab</TableCell>
                            <TableCell>Approved</TableCell>
                            <TableCell>{this.props.data.approved ? this.props.data.approved.toString() : ''}</TableCell>
                            <TableCell>
                                <Dropdown value={approved ? true : false} onChange={(data) => this.setState({ approved: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Completed Tab</TableCell>
                            <TableCell>Verification</TableCell>
                            <TableCell>{this.props.data.verification ? this.props.data.verification.toString() : ''}</TableCell>
                            <TableCell>
                                <Dropdown value={verification ? true : false} onChange={(data) => this.setState({ verification: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Completed Tab</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>{this.props.data.status === 'pending authorisation' ? 'Check for MJ status' : this.props.data.status}</TableCell>
                            <TableCell>
                                <Input fullWidth value={status} name='status'
                                    onChange={this.onChangeInput} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Deleted</TableCell>
                            <TableCell>Rejected</TableCell>
                            <TableCell>{this.props.data.rejected ? this.props.data.rejected.toString() : ''}</TableCell>
                            <TableCell>
                                <Dropdown value={rejected ? true : false} onChange={(data) => this.setState({ rejected: data })} />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Other</TableCell>
                            <TableCell>Moderator Id</TableCell>
                            <TableCell>{this.props.data.moderator_id}</TableCell>
                            <TableCell>
                                <Input fullWidth value={moderator_id} name='moderator_id'
                                    onChange={this.onChangeInput} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Other</TableCell>
                            <TableCell>Has Complaint</TableCell>
                            <TableCell>{this.props.data.has_complaint ? this.props.data.has_complaint.toString() : ''}</TableCell>
                            <TableCell>
                                <Dropdown value={has_complaint ? true : false} onChange={(data) => this.setState({ has_complaint: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Other</TableCell>
                            <TableCell>Recheck</TableCell>
                            <TableCell>{this.props.data.recheck ? this.props.data.recheck.toString() : ''}</TableCell>
                            <TableCell>
                                <Dropdown value={recheck ? true : false} onChange={(data) => this.setState({ recheck: data })} />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Others</TableCell>
                            <TableCell>Supervisor completed at</TableCell>
                            <TableCell>{this.props.data.supervisor_completed_at}</TableCell>
                            <TableCell>
                                <Input fullWidth value={supervisor_completed_at} name='supervisor_completed_at'
                                    onChange={this.onChangeInput} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Others</TableCell>
                            <TableCell>Completed at</TableCell>
                            <TableCell>{this.props.data.completed_at}</TableCell>
                            <TableCell>
                                <Input fullWidth value={completed_at} name='completed_at'
                                    onChange={this.onChangeInput} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Others</TableCell>
                            <TableCell>Last updated</TableCell>
                            <TableCell>{this.props.data.last_updated}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography style={{ fontWeight: 'bold' }}>Override Changes</Typography>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <button onClick={this.handleSubmit} className='save-button'>Save</button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography style={{ fontWeight: 'bold' }}>Check all users</Typography>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <UserSearch />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        )
    }
}

export default ClaimProcess