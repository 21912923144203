import React, { Component } from 'react'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { Typography } from '@material-ui/core';

export class SortData extends Component {
    constructor(props) {
        super(props);
        const { order } = this.props
        this.state = {
            order: order || 'asc',
        }
    }
    handleSort = (name) => {
        this.setState({ order: name === 'asc' ? 'dsc' : 'asc' }, () => {
            this.props.getSortedData({ order: this.state.order, orderBy: this.props.orderBy })
        })
    }
    render() {
        const { order } = this.state
        return (<div className='flex gap align-center' onClick={() => this.handleSort(order === 'asc' ? 'asc' : 'dsc')} >
            <Typography>
                {this.props.name}
            </Typography>
            {order === 'asc' && <ExpandMore />}
            {order === 'dsc' && <ExpandLess />}
        </div>
        )

    }
}

export default SortData