import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import api, { getBase } from "./../api";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { InputAdornment } from "@material-ui/core";
import thumbsup from "../assets/thumbsuppassword.png";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import color from "@material-ui/core/colors/amber";

const styles = (theme) => ({
  main: {
    // width: 'auto',
    display: "convertHexToRGB", // Fix IE 11 issue.
    marginLeft: -30,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 450,
      marginRight: "auto",
      // backgroundImage: `url(${'../Images/backgroundimage.png'})`,
      // backgroundrepeat: 'norepeat',
      // backgroundsize: 'cover',
    },
    input: {
      '&:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 100px #000 inset',
        '-webkit-text-fill-color': '#000'
      }
    }
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  avatar: {
    margin: "10",
    backgroundColor: "primary",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "100px",
    marginBottom: "100px",
    paddingBottom: "100px",
  },
  submit: {
    boxShadow: "none",
  },
  split: {
    display: "inline",
    height: "100%",
    width: "100%",
    position: "absolute",
    //  backgroundImage: `url(${'../Images/backgroundimage.png'})`,
    // backgroundrepeat: 'norepeat',
    // backgroundsize: 'cover',
    // border :'solid'
  },
  all: {
    marginTop: "-30px",
  },

  remember: {
    display: "flex",
    alignItems: "start",
    marginLeft: "30px",
    marginTop: "-5px",
    marginRight: "20px",
    marginBottom: "30px",
    paddingRight: "10px",
    justifyContent: "space-between",
    font: "normal normal bold Mulish",
  },
  loginErrorMessage: {
    fontSize: "12px",
    fontWeight: "normal",
    color: "red",
    marginTop: "0px",
  },
  passwordicon: {
    position: " absolute",
    top: "200px",
    right: "500px",
    cursor: "pointer",
  },
  eye: {
    cursor: "pointer",
    color: "#707070",
  },
  imagebc: {
    backgroundColor: "red",
    border: "solid",
  },
});

class SignIn extends Component {
  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
    console.log(this.state);
  };
  togglePasswordVisiblity2 = (type) => {
    this.setState((prevState) => ({ [type]: !prevState[type] }));
  };
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      forgotPassword: false,
      emailCheck: false,
      otpReceived: false,
      isPasswordShown: false,
      otp: "",
      counter: 10,
      passwordResetSuccess: false,
      loginErrorMessage: "",
    };
  }
  validate = (newPass, confirmPass) => {
    if (newPass.length !== 0) {
      if (newPass === confirmPass) return true;
    }
    return false;
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { username, password, forgotPassword, counter } = this.state;
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    if (!forgotPassword) {
      this.props.loading(true);
      axios
        .post(getBase(api.LOGIN), { username, password })
        .then((res) => {
          console.log("login", res);
          axios
            .get(getBase(api.USER), {
              headers: { Authorization: "Token " + res.data.token },
            })
            .then((res2) => {
              res2.data.data.token = res.data.token;
              axios.put(
                getBase(api.USER + "/status"),
                { status: "available" },
                { headers: { Authorization: "Token " + res.data.token } }
              );
              axios
                .get(getBase(api.PROFILE), {
                  headers: { Authorization: "Token " + res.data.token },
                })
                .then((res3) => {
                  res2.data.data.profile = res3.data.data;
                  this.props.done(res2.data);
                })
                .catch((err) => {
                  console.log(err);
                  this.props.done(res2.data);
                });
            });
        })
        .catch((error) => {
          this.setState({ loginErrorMessage: "Please check the username and password" })
          this.props.loading(false, true);
        }).onChange = (event) => {
          const { name, value } = this.state;
          this.setState({ [name]: value });
          window.location.reload();
        };
    } else {
      axios
        .post(getBase(api.PASSWORD_RESET), { email: `${username}` })
        .then((res) => {
          this.setState({ emailCheck: true }, () => {
            let count = counter;
            const myInterval = setInterval(() => {
              if (count === 0) {
                clearInterval(myInterval);
                this.setState({ otpReceived: true });
              } else {
                count = count - 1;
                this.setState({ counter: count });
              }
            }, 1000);
          });
        })
        .catch((error) => {
          let { data } = error.response;
          toast.error(`${data.detail}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  handlePasswordReset = () => {
    let { newPass, confirmPass, otp, username } = this.state;
    this.setState({ counter: 5 });
    let data = {
      email: `${username}`,
      otp: `${otp}`,
      new_password1: `${newPass}`,
      new_password2: `${confirmPass}`,
    };
    axios
      .put(getBase(api.PASSWORD_RESET_FINAL), data)
      .then((response) => {
        this.setState({ passwordResetSuccess: true });
        let count = 6;
        const myInterval = setInterval(() => {
          if (count === 0) {
            clearInterval(myInterval);
            window.location.reload();
          } else {
            count = count - 1;
            this.setState({ counter: count });
          }
        }, 1000);
        console.log("response", data);
      })
      .catch((error) => {
        let { data } = error.response;
        toast.error(`${data.detail}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  render() {
    const { classes, error } = this.props;
    const {
      username,
      password,
      forgotPassword,
      emailCheck,
      otpReceived,
      newPass,
      confirmPass,
      isNewPassVisible,
      isConfirmPassVisible,
      passwordResetSuccess,
      isPasswordShown,
      // ispasswordnotshow,
      otp,
      counter,
      loginErrorMessage,
    } = this.state;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <div>
          <div className={classes.split}>
            <Paper style={styles.paperContainer}></Paper>
            <div style={{ textAlign: "left" }}>
              <Typography
                variant="title"
                paragraph
                style={{
                  color: "rgba(255,255,255)",
                  fontWeight: "600",
                  fontSize: "26px",
                  marginBottom: 0,
                  marginTop: 0,
                  font: "normal normal bold Mulish",
                  marginRight: 10,
                }}
              >
                {!forgotPassword ? (
                  "Hey, Welcome to Logically"
                ) : (
                  <>{!otpReceived ? "Forgot password?" : "Reset Password"}</>
                )}
              </Typography>
              <Typography
                variant="body1"
                paragraph
                style={{
                  color: "rgba(255,255,255)",
                  fontWeight: "600",
                  fontSize: "26px",
                  marginBottom: 0,
                  font: "normal normal bold Mulish",
                  marginRight: 10,
                }}
              >
                {!forgotPassword && "Fact Check Platform"}
              </Typography>
              <div style={{ textAlign: "left" }}>
                <Typography
                  variant="title"
                  paragraph
                  style={{
                    color: "rgba(246, 36, 89, 1)",
                    fontWeight: "600",
                    fontSize: "18px",
                    paddingTop: "30px",
                    marginTop: "40",
                    font: "normal normal bold Mulish",
                  }}
                >
                  {!forgotPassword ? (
                    `Let's fight against fake news today!`
                  ) : (
                    <>
                      {!otpReceived
                        ? `That's ok...Just enter the email address you've used to register with us and we'll send you an OTP`
                        : "Enter the OTP you received in the email"}
                    </>
                  )}
                </Typography>
              </div>
              {!forgotPassword && (
                <div style={{ paddingTop: "90px" }}>
                  <img src="../Images/log_icon.png" alt="logo" width="90" />
                </div>
              )}
            </div>
          </div>
          <div className={classes.split}>
            <form
              className={classes.form}
              onSubmit={this.handleSubmit}
              style={{
                alignItems: "flexStart",
                backgroundColor: "rgba(255, 255, 255, 1)",
                alignContent: "center",
                marginLeft: 550,
                borderRadius: "20px",
                marginTop: 0,
                marginBottom: 40,
                paddingBottom: 40,
              }}
            >
              <div>
                {!emailCheck && (
                  <FormControl
                    style={{
                      marginBottom: 0,
                      Color: "rgba(255,255,255)",
                      paddingLeft: 30,
                      paddingRight: 30,
                      paddingTop: 30,
                    }}
                    required
                    fullWidth
                  >
                    <TextField
                      id="outlined-full-width"
                      placeholder={!forgotPassword ? "username" : "Email"}
                      style={{
                        marginLeft: 0,
                        backgroundColor: "rgba(241, 244, 248, 1)",
                      }}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        style: {
                          height: 5,
                        },
                      }}
                      required
                      value={username}
                      onChange={(e) =>
                        this.setState({ username: e.target.value })
                      }
                    />
                  </FormControl>
                )}
                {!forgotPassword && (
                  <FormControl
                    style={{
                      marginBottom: "0",
                      marginLeft: 0,
                      Color: "rgba(255,255,255)",
                      paddingLeft: 30,
                      paddingRight: 30,
                    }}
                    required
                    fullWidth
                  >
                    <TextField
                      id="outlined-width"
                      placeholder="password"
                      type={isPasswordShown ? "text" : "password"}
                      style={{
                        marginLeft: 0,
                        backgroundColor: "rgba(241, 244, 248, 1)",
                        maxWidth: "100%",
                      }}
                      // fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...this.props}
                      InputProps={{
                        style: {
                          height: 40,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            {this.state.isPasswordShown ? (
                              <VisibilityIcon
                                className={classes.eye}
                                onClick={this.togglePasswordVisiblity}
                              />
                            ) : (
                              <VisibilityOffIcon
                                className={classes.eye}
                                onClick={this.togglePasswordVisiblity}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      value={password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                    <p className={classes.loginErrorMessage}>{loginErrorMessage}</p>
                  </FormControl>
                )}
                {emailCheck && (
                  <>
                    {!otpReceived ? (
                      <div style={{ marginTop: -30 }}>
                        <img
                          style={{
                            display: "flex",
                            marginLeft: "auto",
                            marginRight: "auto",
                            paddingTop: 5,
                            width: 180,
                          }}
                          src={thumbsup}
                          alt="thumsup"
                        ></img>
                        {/* <div style={{
                        width: 359,
                        marginLeft: 30,
                        marginRight: 30,
                        marginBottom: 0,
                        marginTop: 50,
                        textTransform: "none",
                      }}> */}
                        <p style={{ textAlign: "center" }}>
                          Please check your email for OTP
                          <br />
                          Getting to password reset screen in {counter}
                        </p>
                        {/* </div> */}
                      </div>
                    ) : (
                      <div style={{ marginTop: -30 }}>
                        {!passwordResetSuccess ? (
                          <div>
                            <FormControl
                              style={{
                                marginBottom: "0",
                                marginLeft: 0,
                                Color: "rgba(255,255,255)",
                                paddingLeft: 30,
                                paddingRight: 30,
                              }}
                              required
                              fullWidth
                            >
                              <TextField
                                id="outlined-width"
                                placeholder="email"
                                type="text"
                                disabled
                                style={{
                                  marginLeft: 0,
                                  backgroundColor: "rgba(241, 244, 248, 1)",
                                }}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...this.props}
                                InputProps={{
                                  style: {
                                    height: 40,
                                  },
                                }}
                                value={username}
                              // onChange={(e) => this.setState({ emailAddress: e.target.value })}
                              />
                            </FormControl>
                            <FormControl
                              style={{
                                marginBottom: "0",
                                marginLeft: 0,
                                Color: "rgba(255,255,255)",
                                paddingLeft: 30,
                                paddingRight: 30,
                              }}
                              required
                              fullWidth
                            >
                              <TextField
                                id="outlined-width"
                                placeholder="ENTER OTP"
                                type="text"
                                style={{
                                  marginLeft: 0,
                                  backgroundColor: "rgba(241, 244, 248, 1)",
                                }}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...this.props}
                                InputProps={{
                                  style: {
                                    height: 40,
                                  },
                                }}
                                value={otp}
                                onChange={(e) =>
                                  this.setState({ otp: e.target.value })
                                }
                              />
                            </FormControl>
                            <FormControl
                              style={{
                                marginBottom: "0",
                                marginLeft: 0,
                                Color: "rgba(255,255,255)",
                                paddingLeft: 30,
                                paddingRight: 30,
                              }}
                              required
                              fullWidth
                            >
                              <TextField
                                id="outlined-width"
                                placeholder="Enter New Password"
                                type={isNewPassVisible ? "text" : "password"}
                                style={{
                                  marginLeft: 0,
                                  backgroundColor: "rgba(241, 244, 248, 1)",
                                  maxWidth: "100%",
                                }}
                                // fullWidth
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...this.props}
                                InputProps={{
                                  style: {
                                    height: 40,
                                  },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {isNewPassVisible ? (
                                        <VisibilityIcon
                                          className={classes.eye}
                                          onClick={() =>
                                            this.togglePasswordVisiblity2(
                                              "isNewPassVisible"
                                            )
                                          }
                                        />
                                      ) : (
                                        <VisibilityOffIcon
                                          className={classes.eye}
                                          onClick={() =>
                                            this.togglePasswordVisiblity2(
                                              "isNewPassVisible"
                                            )
                                          }
                                        />
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                                value={newPass}
                                onChange={(e) =>
                                  this.setState({ newPass: e.target.value })
                                }
                              />
                            </FormControl>
                            <FormControl
                              style={{
                                marginBottom: "0",
                                marginLeft: 0,
                                Color: "rgba(255,255,255)",
                                paddingLeft: 30,
                                paddingRight: 30,
                              }}
                              required
                              fullWidth
                            >
                              <TextField
                                id="outlined-width"
                                placeholder={
                                  passwordResetSuccess ? "" : "Confirm Password"
                                }
                                type={
                                  isConfirmPassVisible ? "text" : "password"
                                }
                                disabled={!passwordResetSuccess ? false : true}
                                style={{
                                  marginLeft: 0,
                                  backgroundColor: "rgba(241, 244, 248, 1)",
                                  maxWidth: "100%",
                                }}
                                // fullWidth
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...this.props}
                                InputProps={{
                                  style: {
                                    height: 40,
                                  },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {isConfirmPassVisible ? (
                                        <VisibilityIcon
                                          className={classes.eye}
                                          onClick={() =>
                                            this.togglePasswordVisiblity2(
                                              "isConfirmPassVisible"
                                            )
                                          }
                                        />
                                      ) : (
                                        <VisibilityOffIcon
                                          className={classes.eye}
                                          onClick={() =>
                                            this.togglePasswordVisiblity2(
                                              "isConfirmPassVisible"
                                            )
                                          }
                                        />
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                                value={confirmPass}
                                onChange={(e) =>
                                  this.setState({ confirmPass: e.target.value })
                                }
                              />
                              {newPass !== confirmPass && (
                                <div>
                                  Password does not Match
                                  <br />
                                </div>
                              )}

                              <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                                style={{
                                  backgroundColor: "rgba(246, 36, 89, 1)",
                                  color: "rgba(255,255,255)",
                                  height: 40,
                                  width: 359,
                                  textTransform: "none",
                                }}
                                onClick={this.handlePasswordReset}
                              >
                                Send
                              </Button>
                            </FormControl>
                          </div>
                        ) : (
                          <div>
                            <Grid style={{ marginLeft: 134, marginBottom: 35 }}>
                              <img
                                src={thumbsup}
                                width="130"
                                alt=""
                                style={{ marginTop: "10%" }}
                              ></img>
                            </Grid>
                            <p style={{ textAlign: "center" }}>
                              Back to Login Screen in..{counter}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
                {!forgotPassword && (
                  <div className={classes.remember}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="remember"
                          color="primary"
                          variant="body2"
                          paddingTop="100"
                        />
                      }
                      label="Remember me"
                    />
                    <p
                      href="/forgetpassword"
                      onClick={() => this.setState({ forgotPassword: true })}
                      style={{
                        marginTop: 12,
                        cursor: "pointer",
                        fontSize: "0.875rem",
                        fontFamily: "Mulish",
                        fontWeight: 400,
                      }}
                      align="right"
                    >
                      {" Forgot password?"}
                    </p>
                  </div>
                )}
                {!emailCheck && (
                  <div className={classes.all}>
                    {forgotPassword && <div style={{ marginTop: 30 }}></div>}

                    {!emailCheck && (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        style={{
                          backgroundColor: "rgba(246, 36, 89, 1)",
                          color: "rgba(255,255,255)",
                          height: 40,
                          width: 359,
                          marginLeft: 30,
                          marginRight: 30,
                          textTransform: "none",
                        }}
                      >
                        {!forgotPassword ? "Login" : "Send"}
                      </Button>
                    )}
                    {error && (
                      <Typography
                        style={{
                          color: "red",
                          boxShadow: "none",
                          textAlign: "left",
                          paddingLeft: 30,
                          paddingTop: 10,
                          fontSize: "15px",
                        }}
                      >
                        Error
                      </Typography>
                    )}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </main>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(SignIn));
