/** @format */

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import amber from "@material-ui/core/colors/amber";
import { getTimeDifference } from "../../../../utils/time";
import axios from "axios";
import api, { getBase, getUser } from "../../../../api";
import { Link } from "react-router-dom";
import { filter, handleSort } from "../../NewClaims/NewClaims";
import green from "@material-ui/core/colors/green";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FlagIcon } from "react-flag-kit";
import HtmlTooltip from "@material-ui/core/Tooltip";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { colours, getLocation } from "./../../../../utils/common";
import Filter from "../../Filter/filter";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { connect } from "react-redux";
const theme = createMuiTheme();
const styles = (theme) => ({
  root_Radio: {
    color: green[600],
    "&$checked": {
      color: green[500],
    },
  },
  checked: {},
  root: {
    flexGrow: 1,
    position: "relative",
    boxshadow: 2,
  },
  cssRoot: {
    backgroundColor: amber["A700"],
    "&:hover": {
      backgroundColor: amber[800],
    },
    boxshadow: 2,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: 150,
    width: 200,
  },
  bootstrapRoot: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#007bff",
    borderColor: "#007bff",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#0069d9",
      borderColor: "#0062cc",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
  paper: {
    padding: theme.spacing.unit * 2,
    backgroundColor: "#f8f8f8",
    color: theme.palette.text.secondary,
  },
  paper1: {
    padding: theme.spacing.unit * 0,
    color: theme.palette.text.secondary,
  },
  fontFamily: ["Roboto"].join(","),
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
  title: {
    color: "black",
    fontWeight: 600,
  },
  card: {
    maxWidth: 300,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },

  button: {
    textTransform: "none",
    width: "12%",
    fontSize: 12,
    marginLeft: "88%",
  },
  codeText: {
    position: "relative",
  },
  paragraphText: {
    position: "relative",
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "15px",
    maxHeight: 3029,
    paddingRight: 30,
  },
  fab: {
    minWidth: `${80}%`,
    margin: theme.spacing.unit,
  },
  booleanButton: {
    borderRadius: 100,
    minWidth: `${75}%`,
    position: "relative",
    margin: "0 auto",
    display: "block",
  },
  chip: {
    position: "relative",
    fontWeight: 600,
    minWidth: `${40}%`,
    margin: 4,
  },
  butto: {
    margin: theme.spacing.unit,
    width: "100%",
  },
  text: {
    color: "#FFFFFF",
    textTransform: "capitalize",
  },
  textHistory: {
    fontSize: 13,
  },
  page_numbers: {
    marginRight: 10,
    color: "blue",
    userselect: "none",
    cursor: "pointer",
  },
  react_paginate: {
    display: "inline-block",
    paddingLeft: 15,
    paddingRight: 15,
  },

  react_paginate_li: {
    display: "inline-block",
  },
  tr: {
    background: "white",
    "&:hover": {
      background: "#F1F4F8",
    },
  },
});

class Complaint extends Component {
  constructor(props) {
    super(props);
    let diff;
    var date_from = new Date();
    let year = moment(date_from).format("YYYY");
    let days = moment(date_from).format("DD");
    var a = moment([2017, 0, 1]);
    var b = moment([year, 0, days]);
    diff = a.diff(b, "days");
    date_from.setDate(date_from.getDate() + diff);
    this.state = {
      data_op: this.props.opencomplaint
        ? JSON.parse(this.props.opencomplaint)
        : [],
      data_cp: this.props.closecomplaint
        ? JSON.parse(this.props.closecomplaint)
        : [],
      loading: this.props.loading,
      expanded: false,
      offset: 0,
      total: 0,
      claim_checked: [],
      selectedValue: false,
      highlights: false,
      disabled: false,
      startDate: new Date(),
      dataPreview: [],
      getDate: new Date(),
      modalIsOpen: false,
      Deselectchecked: false,
      users: [],
      pageNumbers: [],
      currentPage: 1,
      todosPerPage: 100,
      pageCount: 0,
      allData: [],
      searchVal: "",
      selectionRange: {
        startDate: date_from,
        endDate: new Date(),
        key: "selection",
      },
      slection: this.props.selection,
      openDate: false,
      queryValue: "",
      isFilterApply: false,
      filterTabs: this.props.activeButton,
      showAcceptedClaims: false,
      Type: false,
      enableRedux: true,
      dataSort: {
        type: "asc",
        country: "asc",
        verdict: "asc",
      },
      offline: this.props.offline,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  openModal = () => {
    this.setState({ modalIsOpen: true });
    sessionStorage.setItem("invisible", "true");
  };
  afterOpenModal = () => {
    this.subtitle.style.color = "#f00";
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
    sessionStorage.clear();
  };
  clearedFilter() {
    const { filterTabs, data_op, data_cp } = this.state;
    const data = filterTabs === "Closed complaints" ? data_cp : data_op;

    if (this.props.clearFilter === 0) {
      if (this.props.searchData.length === 0) {
        this.setState({ clearFilter: this.props.clearFilter }, () => {
          if (data.length === 0) {
            if (this.props.queryValue === "") {
              this.getData(this.state.selectionRange, this.props.queryValue);
            }
          }
        });
      }
    } else {
      if (this.props.queryValue === "") {
        this.getData(this.state.selectionRange, this.props.queryValue);
      }
    }
  }
  componentDidMount() {
    this.props.onHandleClearFilters()
    this.getData();
  }
  componentWillReceiveProps(nextProps) {
    const { enableRedux, filterTabs } = this.state;
    const { searchData, clearFilter, searchFilter } = nextProps;
    const isClosedComplaints = filterTabs === "Closed complaints";
    if (searchData !== this.props.searchData && searchFilter && !clearFilter) {
      if (isClosedComplaints) {
        this.setState({ data_cp: searchData }, () =>
          this.props.dispatch({ type: "SET_LOADING", loading: false })
        );
      } else {
        this.setState({ data_op: searchData }, () =>
          this.props.dispatch({ type: "SET_LOADING", loading: false })
        );
      }
    }
    if (clearFilter && !this.props.clearFilter) {
      this.props.dispatch({ type: "SET_LOADING", loading: true })
      this.getData(null);
    }
    if (nextProps.loading !== this.state.loading) {
      this.setState({ loading: nextProps.loading });
    }
    if (nextProps.activeButton !== this.state.filterTabs) {
      this.setState({ filterTabs: nextProps.activeButton });
    }
    if (nextProps.opencomplaint && enableRedux && clearFilter) {
      let reduxData = JSON.parse(nextProps.opencomplaint);
      this.setState({ data_op: reduxData }, () => {
        nextProps.activeButton !== "Closed complaints" &&
          this.props.dispatch({ type: "SET_LOADING", loading: false });
      });
    }
    if (nextProps.closecomplaint && enableRedux && clearFilter) {
      let reduxData = JSON.parse(nextProps.closecomplaint);
      this.setState({ data_cp: reduxData }, () => {
        nextProps.activeButton === "Closed complaints" &&
          this.props.dispatch({ type: "SET_LOADING", loading: false });
      });
    }
    if (nextProps.offline !== this.state.offline) {
      this.setState({ offline: nextProps.offline });
    }
    if (!nextProps.offline && this.state.offline) {
      this.getData();
    }
  }
  multiFilteredData = (selection, val) => {
    const { searchData } = this.props;

    if (searchData.length > 0) {
      this.props.dispatch({ type: "NAVCLAIMS", navClaims: val });
    } else {
      this.getData(selection);
    }
  };
  getData = async (selection, queryValue) => {
    let {
      Type,
      isFilterApply,
      filterTabs,
      offset,
      data,
      loading,
      data_cp,
      data_op,
    } = this.state;
    this.props.dispatch({
      type: "SET_LOADING_SPIN",
      loading_spin: true,
    });
    let start, end;
    if (selection) {
      start = moment(selection.startDate).format("YYYY/MM/DD");
      end = moment(selection.endDate).format("YYYY/MM/DD");
    } else {
      start = "";
      end = "";
    }
    try {
      let url = "";
      let header = "";
      if (isFilterApply) {
        url = getUser(
          api.SEARCH_FACTCHECKS +
          "?tab=complaints&complaint=true&complaint_resolved=false&" +
          queryValue
        );
        header = { headers: {} };
      } else {
        if (filterTabs === "Closed complaints") {
          if (Type) {
            url = getBase(
              api.RESOLVED_CLAIMS +
              `?complaint=true&complaint_resolved=true&complaint_type=${Type}&limit=100&offset= ` +
              offset +
              "&date_from=" +
              start +
              "&date_to=" +
              end
            );
          } else {
            url = getBase(
              api.RESOLVED_CLAIMS +
              "?complaint=true&complaint_resolved=true&limit=100&offset=" +
              offset +
              "&date_from=" +
              start +
              "&date_to=" +
              end
            );
          }
        } else {
          if (Type) {
            url = getBase(
              api.RESOLVED_CLAIMS +
              `?complaint=true&complaint_resolved=false&complaint_type=${Type}&limit=100&offset=` +
              offset +
              "&date_from=" +
              start +
              "&date_to=" +
              end
            );
          } else {
            url = getBase(
              api.RESOLVED_CLAIMS +
              "?complaint=true&complaint_resolved=false&limit=100&offset=" +
              offset +
              "&date_from=" +
              start +
              "&date_to=" +
              end
            );
          }
        }
        header = { headers: { Authorization: "Token " + localStorage.token } };
      }
      const res = await axios.get(url, header);
      const isopencomplaintTab = filterTabs !== "Closed complaints";
      if (
        JSON.stringify(
          isopencomplaintTab
            ? this.props.opencomplaint
            : this.props.closecomplaint
        ) !== JSON.stringify(res.data.data)
      ) {
        if (
          (isopencomplaintTab ? data_op : data_cp) !==
          JSON.stringify(res.data.data)
        ) {
          this.props.setBadge(res.data.total_count);
          if (isopencomplaintTab) {
            this.setState({ data_op: res.data.data }, () => {
              this.props.dispatch({ type: "SET_LOADING", loading: false });
              this.props.dispatch({
                type: "SET_LOADING_SPIN",
                loading_spin: false,
              });
              this.props.dispatch({
                type: "COMPLAINT_OPEN",
                complaint: JSON.stringify(res.data.data),
              });
            });
          } else {
            this.setState({ data_cp: res.data.data }, () => {
              this.props.dispatch({ type: "SET_LOADING", loading: false });
              this.props.dispatch({
                type: "SET_LOADING_SPIN",
                loading_spin: false,
              });
              this.props.dispatch({
                type: "COMPLAINT_CLOSE",
                complaint: JSON.stringify(res.data.data),
              });
            });
          }
          for (let i = 1; i <= Math.ceil(res.data.total_count / 100); i++) {
            this.state.pageNumbers.push(i);
          }

          let allData = [];
          allData = data;
          allData = [];
          for (var i = 0; i < res.data.data.length; i++) {
            let done = false;
            for (var b = 0; b < allData.length; b++) {
              if (allData[b]._id === res.data.data[i]._id) {
                done = true;
              }
            }
            if (!done) {
              allData.push(res.data.data[i]);
            }
          }
          this.setState({
            data: allData,
            total: res.data.total_count,
          });
          this.props.dispatch({ type: "SET_LOADING", loading: false });
          this.props.dispatch({
            type: "SET_LOADING_SPIN",
            loading_spin: false,
          });
          let claim_checked = this.state.claim_checked;
          let selectedValue = this.state.selectedValue;
          for (let i in res.data.data) {
            if (
              res.data.data[i].highly_rated === true &&
              moment(res.data.data[i].last_updated).format("YYYY-MM-DD")
            ) {
              claim_checked.push(res.data.data[i]._id);
            }

            if (
              res.data.data[i].highest_rated === true &&
              moment(res.data.data[i].last_updated).format("YYYY-MM-DD")
            ) {
              selectedValue = res.data.data[i]._id;
            }
          }
          this.setState({
            claim_checked: claim_checked,
            selectedValue: selectedValue,
          });
        } else if (loading) {
          this.props.dispatch({ type: "SET_LOADING", loading: false });
          this.props.dispatch({
            type: "SET_LOADING_SPIN",
            loading_spin: false,
          });
        } else {
          let reduxData = JSON.parse(
            isopencomplaintTab
              ? this.props.opencomplaint
              : this.props.closecomplaint
          );
          if (isopencomplaintTab) {
            this.setState(
              {
                data_op: reduxData,
                loading: false,
              },
              () => {
                this.props.dispatch({
                  type: "SET_LOADING",
                  loading: false,
                });
                this.props.dispatch({
                  type: "SET_LOADING_SPIN",
                  loading_spin: false,
                });
              }
            );
          } else {
            this.setState(
              {
                data_cp: reduxData,
                loading: false,
              },
              () => {
                this.props.dispatch({
                  type: "SET_LOADING",
                  loading: false,
                });
                this.props.dispatch({
                  type: "SET_LOADING_SPIN",
                  loading_spin: false,
                });
              }
            );
          }
        }
      }
    } catch (err) {
      this.props.dispatch({ type: "SET_LOADING", loading: false });
      this.props.dispatch({
        type: "SET_LOADING_SPIN",
        loading_spin: false,
      });
    }
  };

  handleClicks(offset) {
    this.setState({ offset }, () => {
      this.getData(this.state.selection);
    });
  }
  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id),
    });
  }
  ifError() {
    const { data_cp, data_op, filterTabs, loading } = this.state;
    const data = filterTabs === "Closed complaints" ? data_cp : data_op;

    // const { loading, data } = this.state;
    const { classes } = this.props;
    if (data.length === 0 && !loading) {
      return (
        <div className={classes.root}>
          <Typography>No records found!</Typography>
        </div>
      );
    }
    if (data === false) {
      return (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}>
          <Typography>
            Oops, looks like something went wrong. Please try again later
          </Typography>
        </div>
      );
    }
    return null;
  }
  handleShowAcceptedClaims(val) {
    this.setState({ showAcceptedClaims: val });
  }

  handleSort = (type, sort, data) => {
    let sortedData;
    switch (type) {
      case "country":
        sortedData = data.sort(
          sort === "asc"
            ? (a, b) => a.location.localeCompare(b.location)
            : (a, b) => b.location.localeCompare(a.location)
        );
        break;
      case "type":
        sortedData = data.sort(
          sort === "asc"
            ? (a, b) => a.complaint_type.localeCompare(b.complaint_type)
            : (a, b) => b.complaint_type.localeCompare(a.complaint_type)
        );
        break;
      case "verdict":
        sortedData = data.sort(
          sort === "asc"
            ? (a, b) => (a.created > b.created ? 1 : -1)
            : (a, b) => (a.created > b.created ? -1 : 1)
        );
        break;
      default:
        return data;
    }
    this.setState({ data: sortedData });
  };

  render() {
    const { selection, dataSort } = this.state;
    const { data_op, data_cp, filterTabs } = this.state;
    const data = filterTabs === "Closed complaints" ? data_cp : data_op;
    // const { data, selection } = this.state;
    const {
      classes,
      filters,
      searchVal,
      sort,
      filterOptions,
      userHashTable,
      loading,
    } = this.props;
    const { buttons, checkbox, dropDown, dropDownOptions } = filterOptions;
    let allData = filter(data, filters, searchVal);

    let faster = {};
    let Complaints = {};

    return (
      <>
        <Filter
          length={data.length}
          buttons={buttons.split(",")}
          activeIndex={this.state.filterTabs}
          filterbutton={(val) => {
            this.setState({ filterTabs: val }, () => {
              this.multiFilteredData(selection, val);
            });
          }}
          countrySelected={(Type) => {
            if (Type !== "All") {
              this.setState({ Type: Type }, () => {
                this.getData(selection);
              });
            } else {
              this.setState({ Type: false }, () => {
                this.getData(selection);
              });
            }
          }}
          dropDown={dropDown}
          dropDownOptions={dropDownOptions}
          checkbox={checkbox}
          showingClaims={true}
          checkboxValue={(val) => this.handleShowAcceptedClaims(val)}
        />
        {this.ifError()}
        {(this.state.filterTabs === "Open complaints" ||
          this.state.filterTabs === "URL claims" ||
          this.state.filterTabs === "Multimedia claims") &&
          !loading &&
          allData.length > 0 && (
            <div className={classes.root}>
              <Grid container>
                <Paper
                  className={classes.paper1}
                  elevation={0}
                  style={{
                    marginBottom: 16,
                    width: "100%",
                    padding: 8,
                    paddingLeft: 16,
                    paddingRight: 16,
                    backgroundColor: faster.backgroundColor,
                    border: Complaints.border,
                    borderRadius: 10,
                  }}>
                  <Grid
                    container
                    spacing={16}
                    alignItems='center'
                    style={{
                      padding: 8,
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      style={{
                        backgroundColor: "#f1f4f8",
                        borderRadius: "10px",
                        paddingTop: 15,
                        paddingBottom: 15,
                      }}>
                      <Grid container alignItems='center'>
                        <Grid item sm={1} md={1}>
                          <Typography>Claim ID</Typography>
                        </Grid>
                        <Grid item sm={7} md={7}>
                          <Typography>Claim</Typography>
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          md={1}
                          style={{ textAlign: "center" }}>
                          <Typography
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                dataSort: {
                                  ...dataSort,
                                  type:
                                    dataSort.type === "desc" ? "asc" : "desc",
                                  data: handleSort("type", dataSort.type, data),
                                },
                              });
                            }}>
                            Type
                            {dataSort.type === "asc" ? (
                              <ArrowDropUpIcon
                                style={{ position: "relative", top: 5 }}
                                size='small'
                                fontSize='small'
                              />
                            ) : (
                              <ArrowDropDownIcon
                                style={{ position: "relative", top: 5 }}
                                size='small'
                                fontSize='small'
                              />
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          md={1}
                          style={{ textAlign: "center" }}>
                          <Typography
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                dataSort: {
                                  ...dataSort,
                                  country:
                                    dataSort.country === "desc"
                                      ? "asc"
                                      : "desc",
                                  data: handleSort(
                                    "country",
                                    dataSort.country,
                                    data
                                  ),
                                },
                              });
                            }}>
                            Country
                            {dataSort.country === "asc" ? (
                              <ArrowDropUpIcon
                                style={{ position: "relative", top: 5 }}
                                size='small'
                                fontSize='small'
                              />
                            ) : (
                              <ArrowDropDownIcon
                                style={{ position: "relative", top: 5 }}
                                size='small'
                                fontSize='small'
                              />
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          md={2}
                          style={{ textAlign: "center" }}>
                          <Typography
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                dataSort: {
                                  ...dataSort,
                                  verdict:
                                    dataSort.verdict === "desc"
                                      ? "asc"
                                      : "desc",
                                  data: handleSort(
                                    "verdict",
                                    dataSort.verdict,
                                    data
                                  ),
                                },
                              });
                            }}>
                            Verdict
                            {dataSort.verdict === "asc" ? (
                              <ArrowDropUpIcon
                                style={{ position: "relative", top: 5 }}
                                size='small'
                                fontSize='small'
                              />
                            ) : (
                              <ArrowDropDownIcon
                                style={{ position: "relative", top: 5 }}
                                size='small'
                                fontSize='small'
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {allData.map((claim, index) => {
                    claim.faster_check
                      ? (faster = { backgroundColor: "#fff" })
                      : (faster = {});

                    return (
                      <Grid container spacing={16} key={index}>
                        <Grid item lg={12} md={12}>
                          <Grid container>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Typography style={{ color: "white" }}>
                                    {claim.modified_claim}
                                  </Typography>
                                  <Typography color='inherit'>
                                    FactChecker History
                                  </Typography>

                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.factchecker_id && userHashTable && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Current Factchecker</b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.factchecker_id] && userHashTable[claim.factchecker_id]
                                            .username
                                        }
                                        {claim.factcheck_completed_at &&
                                          claim.factcheck_started_at &&
                                          (claim.factcheck_started_at &&
                                            claim.factcheck_completed_at) && (
                                            <strong>
                                              {" "}
                                              (
                                              {getTimeDifference(
                                                claim.factcheck_started_at,
                                                claim.factcheck_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.old_factchecker_id && userHashTable && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Old Factchecker </b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.factchecker_id] && userHashTable[claim.factchecker_id]
                                            .username
                                        }
                                        {claim.oldfactcheck_completed_at &&
                                          (claim.oldfactcheck_started_at &&
                                            claim.oldfactcheck_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.oldfactcheck_started_at,
                                                claim.oldfactcheck_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.moderator_id && userHashTable && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Current Moderator </b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.moderator_id] && userHashTable[claim.moderator_id]
                                            .username
                                        }
                                        {claim.moderator_completed_at &&
                                          claim.moderator_started_at &&
                                          (claim.moderator_started_at &&
                                            claim.moderator_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.moderator_started_at,
                                                claim.moderator_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.old_moderator_id && userHashTable && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Old Moderator </b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.old_moderator_id] && userHashTable[claim.old_moderator_id]
                                            .username
                                        }
                                        {claim.oldmoderator_completed_at &&
                                          (claim.oldmoderator_started_at &&
                                            claim.oldmoderator_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.oldmoderator_started_at,
                                                claim.oldmoderator_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.supervisor_id && userHashTable && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Current Supervisor </b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.supervisor_id] && userHashTable[claim.supervisor_id]
                                            .username
                                        }
                                        {claim.supervisor_completed_at &&
                                          (claim.supervisor_started_at &&
                                            claim.supervisor_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.supervisor_started_at,
                                                claim.supervisor_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>

                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.old_supervisor_id && userHashTable && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Old Supervisor</b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.old_supervisor_id] && userHashTable[claim.old_supervisor_id]
                                            .username
                                        }
                                        {claim.oldsupervisor_completed_at &&
                                          (claim.oldsupervisor_started_at &&
                                            claim.oldsupervisor_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.oldsupervisor_started_at,
                                                claim.oldsupervisor_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                </React.Fragment>
                              }>
                              <Paper
                                className={(classes.paper1, classes.tr)}
                                key={index}
                                elevation={1}
                                style={{
                                  marginBottom: 16,
                                  width: "100%",
                                  padding: 8,
                                  backgroundColor: faster.backgroundColor,
                                }}
                              >
                                {/* <Link to={"/claim/" + claim._id}> */}
                                <Link
                                  to={{
                                    pathname: "/claim/" + claim._id,
                                    state: {
                                      dataFromCard: claim,
                                    },
                                  }}
                                >
                                  <Grid container alignItems="center">
                                    <Grid item sm={1} md={1}>
                                      <Typography style={{ marginTop: 2 }}>
                                        {claim.tracking_id}
                                      </Typography>
                                    </Grid>
                                    <Grid item sm={7} md={7}>
                                      <Typography
                                        component='p'
                                        className={
                                          classes.paragraphText + " maxlines2"
                                        }>
                                        {claim.modified_claim}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      sm={1}
                                      md={1}
                                      style={{ textAlign: "center" }}>
                                      <Typography
                                        component='p'
                                        className={classes.codeText}>
                                        {claim.complaint_type}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      sm={1}
                                      md={1}
                                      style={{ textAlign: "center" }}>
                                      <Typography
                                        component='p'
                                        className={classes.codeText}>
                                        <FlagIcon
                                          code={getLocation(claim.location)}
                                          size={44}
                                        />
                                      </Typography>
                                    </Grid>

                                    <Grid item sm={2} md={2}>
                                      {claim.verdict !== "" && (
                                        <div
                                          style={{
                                            backgroundColor:
                                              colours[claim.verdict],
                                            padding: "8px 14px",
                                            width: "100%",
                                            borderRadius: 4,
                                            marginLeft: "auto",
                                          }}>
                                          <Typography
                                            style={{
                                              fontWeight: "600",
                                              textTransform: "uppercase",
                                              textAlign: "center",
                                              color:
                                                colours[claim.verdict] ===
                                                  "#e9ff00"
                                                  ? "#000"
                                                  : "#fff",
                                            }}>
                                            {claim.verdict.replace("_", " ")}
                                          </Typography>
                                        </div>
                                      )}
                                    </Grid>
                                    {claim.editorColumn && (
                                      <Grid item sm={2} md={2}>
                                        {claim.editorColumn.tags.map(
                                          (tag, index) => {
                                            return (
                                              <Chip
                                                label={tag}
                                                className={classes.chip}
                                                variant='outlined'
                                                key={index}
                                              />
                                            );
                                          }
                                        )}
                                      </Grid>
                                    )}
                                  </Grid>
                                </Link>
                              </Paper>
                            </HtmlTooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Paper>
              </Grid>
              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Pagination
                  limit={100}
                  offset={this.state.offset}
                  total={this.state.total}
                  onClick={(e, offset) => this.handleClicks(offset)}
                />
              </MuiThemeProvider>
            </div>
          )}
        {this.state.filterTabs === "Closed complaints" &&
          !loading &&
          data.length > 0 && (
            <div className={classes.root}>
              <Grid container>
                <Paper
                  className={classes.paper1}
                  elevation={0}
                  style={{
                    marginBottom: 16,
                    width: "100%",
                    padding: 8,
                    paddingLeft: 16,
                    paddingRight: 16,
                    backgroundColor: faster.backgroundColor,
                    border: Complaints.border,
                    borderRadius: 10,
                  }}
                >
                  <Grid
                    container
                    spacing={16}
                    alignItems="center"
                    style={{
                      padding: 8,
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      style={{
                        backgroundColor: "#f1f4f8",
                        borderRadius: "10px",
                        paddingTop: 15,
                        paddingBottom: 15,
                      }}
                    >
                      <Grid container alignItems="center">
                        <Grid item sm={1} md={1}>
                          <Typography>Claim ID</Typography>
                        </Grid>
                        <Grid item sm={7} md={7}>
                          <Typography>Claim</Typography>
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          md={1}
                          style={{ textAlign: "center" }}
                        >
                          <Typography
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                dataSort: {
                                  ...dataSort,
                                  type:
                                    dataSort.type === "desc" ? "asc" : "desc",
                                  data: handleSort("type", dataSort.type, data),
                                },
                              });
                            }}
                          >
                            Type
                            {dataSort.type === "asc" ? (
                              <ArrowDropUpIcon
                                style={{ position: "relative", top: 5 }}
                                size="small"
                                fontSize="small"
                              />
                            ) : (
                              <ArrowDropDownIcon
                                style={{ position: "relative", top: 5 }}
                                size="small"
                                fontSize="small"
                              />
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          md={1}
                          style={{ textAlign: "center" }}
                        >
                          <Typography
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                dataSort: {
                                  ...dataSort,
                                  country:
                                    dataSort.country === "desc"
                                      ? "asc"
                                      : "desc",
                                  data: handleSort(
                                    "country",
                                    dataSort.country,
                                    data
                                  ),
                                },
                              });
                            }}
                          >
                            Country
                            {dataSort.country === "asc" ? (
                              <ArrowDropUpIcon
                                style={{ position: "relative", top: 5 }}
                                size="small"
                                fontSize="small"
                              />
                            ) : (
                              <ArrowDropDownIcon
                                style={{ position: "relative", top: 5 }}
                                size="small"
                                fontSize="small"
                              />
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          md={2}
                          style={{ textAlign: "center" }}
                        >
                          <Typography
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                dataSort: {
                                  ...dataSort,
                                  verdict:
                                    dataSort.verdict === "desc"
                                      ? "asc"
                                      : "desc",
                                  data: handleSort(
                                    "verdict",
                                    dataSort.verdict,
                                    data
                                  ),
                                },
                              });
                            }}
                          >
                            Verdict
                            {dataSort.verdict === "asc" ? (
                              <ArrowDropUpIcon
                                style={{ position: "relative", top: 5 }}
                                size="small"
                                fontSize="small"
                              />
                            ) : (
                              <ArrowDropDownIcon
                                style={{ position: "relative", top: 5 }}
                                size="small"
                                fontSize="small"
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {allData.map((claim, index) => {
                    claim.faster_check
                      ? (faster = { backgroundColor: "#fff" })
                      : (faster = {});

                    return (
                      <Grid container spacing={16} key={index}>
                        <Grid item lg={12} md={12}>
                          <Grid container>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Typography style={{ color: "white" }}>
                                    {claim.modified_claim}
                                  </Typography>
                                  <Typography color="inherit">
                                    FactChecker History
                                  </Typography>

                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.factchecker_id && userHashTable && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Current Factchecker</b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.factchecker_id]
                                            .username
                                        }
                                        {claim.factcheck_completed_at &&
                                          claim.factcheck_started_at &&
                                          (claim.factcheck_started_at &&
                                            claim.factcheck_completed_at) && (
                                            <strong>
                                              {" "}
                                              (
                                              {getTimeDifference(
                                                claim.factcheck_started_at,
                                                claim.factcheck_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.old_factchecker_id && userHashTable && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Old Factchecker </b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.factchecker_id]
                                            .username
                                        }
                                        {claim.oldfactcheck_completed_at &&
                                          (claim.oldfactcheck_started_at &&
                                            claim.oldfactcheck_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.oldfactcheck_started_at,
                                                claim.oldfactcheck_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.moderator_id && userHashTable && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Current Moderator </b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.moderator_id]
                                            .username
                                        }
                                        {claim.moderator_completed_at &&
                                          claim.moderator_started_at &&
                                          (claim.moderator_started_at &&
                                            claim.moderator_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.moderator_started_at,
                                                claim.moderator_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.old_moderator_id && userHashTable && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Old Moderator </b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.old_moderator_id]
                                            .username
                                        }
                                        {claim.oldmoderator_completed_at &&
                                          (claim.oldmoderator_started_at &&
                                            claim.oldmoderator_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.oldmoderator_started_at,
                                                claim.oldmoderator_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.supervisor_id && userHashTable && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Current Supervisor </b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.supervisor_id]
                                            .username
                                        }
                                        {claim.supervisor_completed_at &&
                                          (claim.supervisor_started_at &&
                                            claim.supervisor_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.supervisor_started_at,
                                                claim.supervisor_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>

                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.old_supervisor_id && userHashTable && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Old Supervisor</b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.old_supervisor_id]
                                            .username
                                        }
                                        {claim.oldsupervisor_completed_at &&
                                          (claim.oldsupervisor_started_at &&
                                            claim.oldsupervisor_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.oldsupervisor_started_at,
                                                claim.oldsupervisor_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                </React.Fragment>
                              }
                            >
                              <Paper
                                className={classes.paper1}
                                elevation={1}
                                key={index}
                                style={{
                                  marginBottom: 16,
                                  width: "100%",
                                  padding: 8,
                                  backgroundColor: faster.backgroundColor,
                                }}
                              >
                                <Link
                                  to={{
                                    pathname: "/claim/" + claim._id,
                                    state: {
                                      dataFromCard: claim,
                                    },
                                  }}
                                >
                                  <Grid container alignItems="center">
                                    <Grid item sm={1} md={1}>
                                      <Typography style={{ marginTop: 2 }}>
                                        {claim.tracking_id}
                                      </Typography>
                                    </Grid>

                                    <Grid item sm={7} md={7}>
                                      <Typography
                                        component="p"
                                        className={
                                          classes.paragraphText + " maxlines2"
                                        }
                                      >
                                        {claim.modified_claim}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      sm={1}
                                      md={1}
                                      style={{ textAlign: "center" }}
                                    >
                                      <Typography
                                        component="p"
                                        className={classes.codeText}
                                      >
                                        {claim.complaint_type}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      sm={1}
                                      md={1}
                                      style={{ textAlign: "center" }}
                                    >
                                      <Typography
                                        component="p"
                                        className={classes.codeText}
                                      >
                                        <FlagIcon
                                          code={getLocation(claim.location)}
                                          size={44}
                                        />
                                      </Typography>
                                    </Grid>

                                    <Grid item sm={2} md={2}>
                                      {claim.verdict !== "" && (
                                        <div
                                          style={{
                                            backgroundColor:
                                              colours[claim.verdict],
                                            padding: "8px 14px",
                                            width: "100%",
                                            borderRadius: 4,
                                            marginLeft: "auto",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              fontWeight: "600",
                                              textTransform: "uppercase",
                                              textAlign: "center",
                                              color:
                                                colours[claim.verdict] ===
                                                  "#e9ff00"
                                                  ? "#000"
                                                  : "#fff",
                                            }}
                                          >
                                            {claim.verdict.replace("_", " ")}
                                          </Typography>
                                        </div>
                                      )}
                                    </Grid>
                                    {claim.editorColumn && (
                                      <Grid item sm={2} md={2}>
                                        {claim.editorColumn.tags.map(
                                          (tag, index) => {
                                            return (
                                              <Chip
                                                label={tag}
                                                className={classes.chip}
                                                variant="outlined"
                                                key={index}
                                              />
                                            );
                                          }
                                        )}
                                      </Grid>
                                    )}
                                  </Grid>
                                </Link>
                              </Paper>
                            </HtmlTooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Paper>
              </Grid>
              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Pagination
                  limit={100}
                  offset={this.state.offset}
                  total={this.state.total}
                  onClick={(e, offset) => this.handleClicks(offset)}
                />
              </MuiThemeProvider>
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    userHashTable: data.userHashTable,
    navClaims: data.navClaims,
    loading: data.loading,
    activeButton: data.activeButton,
    clearFilter: data.clearFilter,
    opencomplaint: data.complaint.opencomplaint,
    closecomplaint: data.complaint.closecomplaint,
    offline: data.offline,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Complaint));
