export default function reducer(state = initState, action) {
  switch (action.type) {
    case "ADD_USER_HASH_TABLE":
      return { ...state, userHashTable: action.userHashTable };
    case "SEARCH":
      return {
        ...state,
        search: action.search,
      };
    case "NAVPOINTS":
      return {
        ...state,
        navpoints: action.navpoints,
      };
    case "TOGGLE_DATE_FILTER":
      return {
        ...state,
        isDateRangeActive: action.isDateRangeActive,
      };
    case "SEARCH_FILTER":
      return {
        ...state,
        searchFilter: action.searchFilter,
      };
    case "NAVCLAIMS":
      return {
        ...state,
        navClaims: action.navClaims,
      };
    case "SEARCH_DATA":
      return {
        ...state,
        searchData: action.searchData,
      };
    case "SHOW_COUNT":
      return {
        ...state,
        showCount: action.showCount,
      };
    case "VIEW_HISTORY":
      return {
        ...state,
        currentPath: action.viewHistory,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.loading,
      };
    case "VIEW_BUTTON":
      return {
        ...state,
        activeButton: action.activeButton,
        clearFilter: true,
      };
    case "CLAIMED_DATA":
      return {
        ...state,
        claim: action.claim,
      };
    case "CLEAR_FILTER":
      return {
        ...state,
        clearFilter: action.clearFilter,
      };
    case "SWITCH_CLAIMS_TABS":
      return {
        ...state,
        claimsActiveTab: action.tab,
      };
    case "CHECKED_BOXES":
      return {
        ...state,
        checkedBoxes: action.checkedBoxes,
      };
    case "OFFLINE":
      return {
        ...state,
        offline: action.offline,
      };
    //Data caching for New claims URL claims
    case "NEW_CLAIMS_URL":
      return {
        ...state,
        newclaims: {
          ...state.newclaims,
          url: action.newclaims,
        },
      };

    //Data caching for New claims Multimedia claims
    case "NEW_CLAIMS_MM":
      return {
        ...state,
        newclaims: {
          ...state.newclaims,
          multimedia: action.newclaims,
        },
      };
    //Data caching for conducting reaseasrch URL claims
    case "CONDUCTING_RESEARCH_URL":
      return {
        ...state,
        conducting_research: {
          ...state.conducting_research,
          url: action.conducting_Research,
        },
      };
    //Data caching for conducting reaseasrch Multimedia claims
    case "CONDUCTING_RESEARCH_MM":
      return {
        ...state,
        conducting_research: {
          ...state.conducting_research,
          multimedia: action.conducting_Research,
        },
      };
    case "MAKING_JUDGEMENT":
      return {
        ...state,
        making_Judgement: action.making_Judgement,
      };
    //Data caching for finalize URL claims
    case "FINALIZE_URL":
      return {
        ...state,
        finalize: {
          ...state.finalize,
          url: action.finalize,
        },
      };
    //Data caching for finalize Multimedia claims
    case "FINALIZE_MM":
      return {
        ...state,
        finalize: {
          ...state.finalize,
          multimedia: action.finalize,
        },
      };
    case "MAKING_JUDGEMENT_URL":
      return {
        ...state,
        making_Judgement: {
          ...state.making_Judgement,
          url: action.making_Judgement,
        },
      };
    case "MAKING_JUDGEMENT_MM":
      return {
        ...state,
        making_Judgement: {
          ...state.making_Judgement,
          multimedia: action.making_Judgement,
        },
      };
    case "COMPLETED_URL":
      return {
        ...state,
        completed: {
          ...state.completed,
          url: action.completed,
        },
      };
    //Data caching for finalize Multimedia claims
    case "COMPLETED_MM":
      return {
        ...state,
        completed: {
          ...state.completed,
          multimedia: action.completed,
        },
      };
    case "COMPLETED_HF":
      return {
        ...state,
        completed: {
          ...state.completed,
          hf: action.completed,
        },
      };

    case "COMPLAINT_OPEN":
      return {
        ...state,
        complaint: {
          ...state.complaint,
          opencomplaint: action.complaint,
        },
      };
    case "COMPLAINT_CLOSE":
      return {
        ...state,
        complaint: {
          ...state.complaint,
          closecomplaint: action.complaint,
        },
      };
    case "REJECTED_CLAIMS":
      return {
        ...state,
        rejected: action.rejected,
      };
    case "SET_LOADING_SPIN":
      return {
        ...state,
        loading_spin: action.loading_spin,
      };
    case "NOTIFICATIONS":
      return {
        ...state,
        notifications: action.notifications
      };
    case "APIKEY":
      return {
        ...state,
        apikey: action.apikey
      };
    case "VERDICT_BY_CODE":
      return {
        ...state,
        verdictByCode: action.verdictByCode
      };
    case "SOURCES_BY_CODE":
      return {
        ...state,
        sourcesByCode: action.sourcesByCode
      }

    default:
      return state;
  }
}

let initState = {
  userHashTable: {},
  apikey: "",
  search: "",
  navpoints: 1,
  isDateRangeActive: false,
  searchFilter: false,
  navClaims: "URL claims",
  searchData: [],
  count: [],
  currentPath: "",
  loading: false,
  activeButton: "URL claims",
  claim: {},
  clearFilter: true,
  claimsActiveTab: 1,
  checkedBoxes: false,
  offline: false,
  //Initial states for cache logic starts here
  newclaims: {
    url: "",
    multimedia: "",
  },
  conducting_research: {
    url: "",
    multimedia: "",
  },
  making_Judgement: {
    url: "",
    multimedia: "",
  },
  finalize: {
    url: "",
    multimedia: "",
  },
  completed: {
    url: "",
    multimedia: "",
    hf: "",
  },
  complaint: {
    opencomplaint: "",
    closecomplaint: "",
  },
  rejected: "",
  loading_spin: false,
  verdictByCode: {
    en: ["None", "True", "False", "Misleading", "Unverifiable", "Partly_True"]
  },
  sourcesByCode: {
    en: ['Supports', 'Neutral', 'Refutes']
  }
  //Initial state for cache logic ends here
};
