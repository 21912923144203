import { Typography } from "@material-ui/core";
import React from "react";
import smallscreen from "../assets/smallscreen.png";

function SmallScreen() {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                height: "100vh",
                flexDirection: "column",
                padding: "10px",
            }}
        >
            <img
                src={smallscreen}
                alt="Screen size"
                width="40%"
                style={{ marginBottom: "15px" }}
            />
            <Typography variant="h6">
                This site is currently not supported on smaller screens. Please switch
                to a device with larger screen for a better experience.
            </Typography>
        </div>
    );
}

export default SmallScreen;
