import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import SimpleCard from "../simpleSectionCard/simpleSectionCard";
import axios from "axios";
import api, { getBase, getUser } from "./../../../api";
import Typography from "@material-ui/core/Typography";
import { filter } from "./../NewClaims/NewClaims";
import { withStyles } from "@material-ui/core/styles";
import Filter from "../Filter/filter";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import CssBaseline from "@material-ui/core/CssBaseline";
import { connect } from "react-redux";

const theme = createMuiTheme();
const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },

  "&:hover": {
    backgroundColor: "red",
  },
});
class CardSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_url: this.props.urlClaims ? JSON.parse(this.props.urlClaims) : [],
      data_mm: this.props.mmClaims ? JSON.parse(this.props.mmClaims) : [],
      data2: [],
      isFilterApply: false,
      queryValue: "",
      filterTabs: this.props.activeTab,
      country: false,
      offset: 0,
      total: 0,
      pageCount: 0,
      enableRedux: true,
      offline: this.props.offline,
    };
  }
  clearedFilter() {
    const { filterTabs, data_url, data_mm } = this.state;
    const data = filterTabs === "URL claims" ? data_url : data_mm;
    if (this.props.clearFilter === 0) {
      if (this.props.searchData.length === 0) {
        this.setState({ clearFilter: this.props.clearFilter }, () => {
          if (data.length === 0) {
            if (this.props.queryValue === "") {
              this.getData(null);
            }
          }
        });
      }
    } else {
      if (this.props.queryValue === "") {
        this.getData(null);
      }
    }
  }

  componentDidMount() {
    this.props.onHandleClearFilters()
    this.getData(null);
  }

  componentWillReceiveProps(nextProps) {
    const { searchData, searchFilter, clearFilter } = nextProps;
    const { filterTabs, enableRedux } = this.state;
    const isUrlClaimsTab = filterTabs === "URL claims";

    if (searchData !== this.props.searchData && searchFilter && !clearFilter) {
      if (isUrlClaimsTab) {
        this.setState({ data_url: searchData }, () =>
          this.props.dispatch({ type: "SET_LOADING", loading: false })
        );
      } else {
        this.setState({ data_mm: searchData }, () =>
          this.props.dispatch({ type: "SET_LOADING", loading: false })
        );
      }
    }

    if (clearFilter && !this.props.clearFilter) {
      this.props.dispatch({ type: "SET_LOADING", loading: true })
      this.setState({ enableRedux: true })
      this.getData(null);
    }

    if (searchFilter !== this.props.searchFilter) {
      this.setState({ enableRedux: !searchFilter })
    }

    if (nextProps.urlClaims && enableRedux && clearFilter) {
      let reduxData = JSON.parse(nextProps.urlClaims);
      this.setState({ data_url: reduxData }, () => {
        nextProps.activeTab === "URL claims" &&
          this.props.dispatch({ type: "SET_LOADING", loading: false });
      });
    }
    if (nextProps.mmClaims && enableRedux && clearFilter) {
      let reduxData = JSON.parse(nextProps.mmClaims);
      this.setState({ data_mm: reduxData }, () => {
        nextProps.activeTab === "Multimedia claims" &&
          this.props.dispatch({ type: "SET_LOADING", loading: false });
      });
    }

    if (nextProps.offline !== this.state.offline) {
      this.setState({ offline: nextProps.offline });
    }
    if (!nextProps.offline && this.state.offline) {
      this.getData();
    }
  }

  getData = async (queryValue) => {
    let {
      showAcceptedClaims,
      isFilterApply,
      filterTabs,
      offset,
      data_url,
      data_mm,
      loading,
    } = this.state;
    this.props.dispatch({
      type: "SET_LOADING_SPIN",
      loading_spin: true,
    });
    try {
      let url = "";
      let header = "";
      if (isFilterApply) {
        url = getUser(
          api.SEARCH_FACTCHECKS +
          `?tab=judgement&limit=100&offset=${offset}&` +
          queryValue
        );
        header = { headers: {} };
        this.props.dispatch({ type: "SET_LOADING", loading: true });
      } else {
        if (filterTabs === "Multimedia claims") {
          this.setState({ loading: true });
          if (showAcceptedClaims) {
            url = getBase(
              api.IN_PROGRESS +
              `?type=image&accepted=ok&limit=25&offset=${offset}`
            );
          } else {
            url = getBase(
              api.IN_PROGRESS + `?type=image&limit=25&offset=${offset}`
            );
          }
        } else {
          if (showAcceptedClaims) {
            url = getBase(
              api.IN_PROGRESS + `?accepted=ok&limit=100&offset=${offset}`
            );
          } else {
            url = getBase(api.IN_PROGRESS + `?limit=100&offset=${offset}`);
          }
        }
        header = { headers: { Authorization: "Token " + localStorage.token } };
      }
      const res = await axios.get(url, header);
      const isUrlClaimsTab = filterTabs === "URL claims";
      if (
        (isUrlClaimsTab ? this.props.urlClaims : this.props.mmClaims) !==
        JSON.stringify(res.data.data)
      ) {
        if (
          JSON.stringify(isUrlClaimsTab ? data_url : data_mm) !==
          JSON.stringify(res.data.data)
        ) {
          this.props.setBadge(res.data.total_count);
          if (isUrlClaimsTab) {
            this.setState({ data_url: res.data.data }, () => {
              this.props.dispatch({ type: "SET_LOADING", loading: false });
              this.props.dispatch({
                type: "SET_LOADING_SPIN",
                loading_spin: false,
              });
              this.props.dispatch({
                type: "CONDUCTING_RESEARCH_URL",
                conducting_Research: JSON.stringify(res.data.data),
              });
            });
          } else {
            this.setState({ data_mm: res.data.data }, () => {
              this.props.dispatch({ type: "SET_LOADING", loading: false });
              this.props.dispatch({
                type: "SET_LOADING_SPIN",
                loading_spin: false,
              });
              this.props.dispatch({
                type: "CONDUCTING_RESEARCH_MM",
                conducting_Research: JSON.stringify(res.data.data),
              });
            });
          }
        } else if (loading) {
          this.props.dispatch({ type: "SET_LOADING", loading: false });
          this.props.dispatch({
            type: "SET_LOADING_SPIN",
            loading_spin: false,
          });
        }
      } else {
        let reduxData = JSON.parse(
          isUrlClaimsTab ? this.props.url : this.props.mmClaims
        );
        if (isUrlClaimsTab) {
          this.setState({ data_url: reduxData, loading: false }, () => {
            this.props.dispatch({ type: "SET_LOADING", loading: false });
            this.props.dispatch({
              type: "SET_LOADING_SPIN",
              loading_spin: false,
            });
          });
        } else {
          this.setState({ data_mm: reduxData, loading: false }, () => {
            this.props.dispatch({ type: "SET_LOADING", loading: false });
            this.props.dispatch({
              type: "SET_LOADING_SPIN",
              loading_spin: false,
            });
          });
        }
      }
      this.props.dispatch({
        type: "SET_LOADING_SPIN",
        loading_spin: false,
      });
    } catch (err) {
      this.setState({ loading: false });
      this.props.dispatch({ type: "SET_LOADING", loading: false });
      this.props.dispatch({
        type: "SET_LOADING_SPIN",
        loading_spin: false,
      });
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  multiFilteredData = (val) => {
    const { searchData } = this.props;

    if (searchData.length > 0) {
      this.props.dispatch({ type: "NAVCLAIMS", navClaims: val });
    } else {
      this.getData(null);
    }
  };

  ifError() {
    const { data_url, data_mm, filterTabs } = this.state;
    const data = filterTabs === "URL claims" ? data_url : data_mm;
    const { classes } = this.props;
    if (data.length === 0) {
      this.props.dispatch({ type: "SET_LOADING", loading: false });
      return (
        <div className={classes.root}>
          <Typography>No records found!</Typography>
        </div>
      );
    }
    if (data === false) {
      return (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography>
            Oops, looks like something went wrong. Please try again later
          </Typography>
        </div>
      );
    }
    return null;
  }

  handleShowAcceptedClaims(val) {
    this.setState({ showAcceptedClaims: val }, () => {
      this.getData(null);
    });
  }

  handleClicks(offset) {
    this.setState({ offset }, () => {
      this.getData(this.state.querValue);
    });
  }

  reloadwindow = () => {
    window.location.reload();
  };

  render() {
    const { data_url, data_mm, filterTabs } = this.state;
    const data = filterTabs === "URL claims" ? data_url : data_mm;
    const {
      filters,
      users,
      searchVal,
      isSidebar,
      filterOptions,
      userHashTable,
      datapoint,
      loading,
    } = this.props;
    const { buttons, checkbox, assigned } = filterOptions;

    if (Object.keys(userHashTable).length === 0) {
      return (
        <div>
          Something Seems Wrong Click{" "}
          <button
            style={{ border: 0, fontSize: 15, textDecoration: "underline" }}
            onClick={this.reloadwindow}
          >
            {" "}
            here{" "}
          </button>
          to Reload
        </div>
      );
    }

    return (
      <>
        <Filter
          datapoint={datapoint}
          length={data.length}
          buttons={buttons ? buttons.split(",") : false}
          activeIndex={this.state.filterTabs}
          filterbutton={(val) => {
            this.setState({ filterTabs: val }, () => {
              this.multiFilteredData(val);
            });
          }}
          countrySelected={(country) => {
            if (country !== "All") {
              this.setState({ country: country }, () => {
                this.getData(null);
              });
            } else {
              this.setState({ country: false }, () => {
                this.getData(null);
              });
            }
          }}
          checkbox={checkbox}
          showingClaims={true}
          checkboxValue={(val) => this.handleShowAcceptedClaims(val)}
        />
        {this.ifError()}
        {this.state.filterTabs === "URL claims" && !loading && (
          <Grid container spacing={24}>
            {filter(data, filters, searchVal).map((claim) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={isSidebar ? 6 : 4}
                  md={isSidebar ? 4 : 3}
                  key={claim._id}
                >
                  <SimpleCard
                    data={claim}
                    users={users}
                    assigned={assigned}
                    images={false}
                    tab="CR"
                    urlAccept={true}
                    userHashTable={userHashTable}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {this.state.filterTabs === "Multimedia claims" && !loading && (
          <Grid container spacing={24}>
            {filter(data, filters, searchVal).map((claim, index) => {
              return (
                claim.recheck_image && (
                  <Grid
                    item
                    xs={12}
                    sm={isSidebar ? 6 : 4}
                    md={isSidebar ? 4 : 3}
                    key={index}
                  >
                    <SimpleCard
                      data={claim}
                      users={users}
                      assigned={assigned}
                      images={true}
                      tab="CR"
                      urlAccept={true}
                      userHashTable={userHashTable}
                      key={claim._id}
                    />
                  </Grid>
                )
              );
            })}
          </Grid>
        )}
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Pagination
            limit={100}
            offset={this.state.offset}
            total={this.state.total}
            onClick={(e, offset) => this.handleClicks(offset)}
          />
        </MuiThemeProvider>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    navClaims: state.navClaims,
    userHashTable: state.userHashTable,
    activeTab: state.activeButton,
    clearFilter: state.clearFilter,
    loading: state.loading,
    urlClaims: state.conducting_research.url,
    mmClaims: state.conducting_research.multimedia,
    offline: state.offline,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(CardSection));
