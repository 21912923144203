import React, { Component } from 'react';
import { Button, IconButton, Input, Modal, Paper, TableCell, TableRow, Typography } from '@material-ui/core';
import { getLanguageByCode } from '../utils/language';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

export class SourcesTable extends Component {
    constructor(props) {
        super(props);
        const { data, code, index } = this.props;
        this.state = {
            isOpen: false,
            languageCode: code,
            source: data,
            index: index,
            tempSource: data,
            isModalOpen: false,

        }
    }
    handleUpdateSource = (sourceUpdate, index) => {
        let temp = [...this.state.tempSource]
        temp[index] = sourceUpdate
        this.setState({ tempSource: temp })
    }
    sendUpdatedSource = () => {
        this.props.handleUpdateSource({ sourceChanged: this.state.tempSource })
        this.setState({ isOpen: false })
    }
    sendDeleteSource = () => {
        this.props.handleDeleteSource();
    }
    handleDeleteSource = (deleteSource) => {
        this.setState({ isModalOpen: false }, () => {
            if (deleteSource) {
                this.sendDeleteSource()
            }
        })
    }
    render() {
        const { data, code, index } = this.props;
        const { isOpen, isModalOpen } = this.state
        return (
            <>
                <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{getLanguageByCode(code).name}</TableCell>
                    <TableCell>{code}</TableCell>
                    <TableCell>{data.length && data.toString()}</TableCell>
                    <TableCell>
                        <IconButton onClick={() => this.setState({ isOpen: !isOpen })}>
                            {isOpen ? <ExpandLess /> :
                                <ExpandMore />}

                        </IconButton>
                    </TableCell>
                </TableRow>
                {isOpen && <TableRow>
                    <TableCell colSpan={5} style={{ borderColor: '#f62459' }}>
                        <div className='flex gap align-center' style={{ flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                            {data.map((item, sourceIndex) => {
                                return <SourceUpdate data={item}
                                    key={item}
                                    updatedSource={(sourceUpdate) => this.handleUpdateSource(sourceUpdate, sourceIndex)}
                                    index={sourceIndex}
                                />
                            })
                            }
                            <button onClick={this.sendUpdatedSource} className='save-button'>Update</button>
                            <Button onClick={() => this.setState({ isModalOpen: true })} variant='outlined' color='secondary'>Delete</Button>
                        </div>
                    </TableCell>
                </TableRow>
                }
                <Modal open={isModalOpen}
                    onClose={() => { this.setState({ isModalOpen: false }) }}>
                    <div className='modal-center'>
                        <Paper style={{ padding: 16 }}>
                            <Typography style={{ fontWeight: 'bold' }}>Are you sure you want to delete {getLanguageByCode(code).name} source?</Typography>
                            <br />
                            <div className='flex gap'>
                                <button onClick={() => this.handleDeleteSource(true)} className='save-button'>Yes</button>
                                <Button onClick={() => this.handleDeleteSource(false)} variant='outlined' style={{ textTransform: 'inhert' }} color='secondary'>No</Button>

                            </div>
                        </Paper>
                    </div>
                </Modal>
            </>
        )
    }
}

class SourceUpdate extends Component {
    constructor(props) {
        super(props)
        const { data } = this.props
        this.state = {
            value: data
        }
    }
    handleChange = (e) => {
        this.setState({ value: e.target.value }, () => {
            this.props.updatedSource(this.state.value)
        })
    }
    render() {
        const { value } = this.state
        return (
            <div className='flex gap align-center'>
                <b style={{ color: '#f62459' }}>({this.props.index + 1})</b> <Input style={{ minWidth: 'max-content' }} value={value} onChange={this.handleChange} />
            </div>
        )
    }
}

export default SourcesTable