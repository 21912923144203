import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import Dropdown from './Dropdown';

export class ClaimStatus extends Component {
    constructor(props) {
        super(props);
        const { assigned,
            judgement,
            verification } = this.props.data;
        this.state = {
            assigned: assigned || false,
            judgement: judgement || false,
            verification: verification || false,
        }
    }
    onChangeInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleSubmit = () => {
        if (this.props.claimStatusData) {
            this.props.claimStatusData({ ...this.state })
        }
    }
    render() {
        const { assigned,
            judgement,
            verification } = this.props.data;
        return (
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data Field</TableCell>
                            <TableCell >Value</TableCell>
                            <TableCell >Change</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>In-progress</TableCell>
                            <TableCell>{assigned ? "True" : "False"}</TableCell>
                            <TableCell>
                                <Dropdown value={assigned ? true : false} onChange={(data) => this.setState({ assigned: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>In-review</TableCell>
                            <TableCell>{judgement ? "True" : "False"}</TableCell>
                            <TableCell>
                                <Dropdown value={judgement ? true : false} onChange={(data) => this.setState({ judgement: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Completed</TableCell>
                            <TableCell>{verification ? "True" : "False"}</TableCell>
                            <TableCell>
                                <Dropdown value={verification ? true : false} onChange={(data) => this.setState({ verification: data })} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography style={{ fontWeight: 'bold' }}>Override Changes</Typography>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <button onClick={this.handleSubmit} className='save-button'>Save</button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        )
    }
}

export default ClaimStatus