import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { Check, Close } from '@material-ui/icons';
import React, { Component } from 'react'

export class ClaimData extends Component {
    handleStatus = (isPresent) => {
        if (isPresent) {
            return <TableCell><Check color='primary' /></TableCell>
        }
        return <TableCell><Close color='secondary' /></TableCell>

    }
    render() {
        const { beneficiary,
            modified_claim,
            background_image,
            topics,
            claim_type,
            image_alt,
            lang_code,
            claim,
            conclusion_summary,
            conclusion,
            group_id,
            editor_reference_urls,
            tags,
            verdict,
            _id,
            rating
        } = this.props.data;
        return (
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data Field</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Present</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Beneficiary</TableCell>
                            <TableCell className='tableCell'>{beneficiary}</TableCell>
                            {this.handleStatus(beneficiary)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Modified Claim</TableCell>
                            <TableCell className='tableCell'>{modified_claim}</TableCell>
                            {this.handleStatus(modified_claim)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Background Image</TableCell>
                            <TableCell className='tableCell'>{background_image}</TableCell>
                            {this.handleStatus(background_image)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Topics</TableCell>
                            <TableCell className='tableCell'>{topics ? topics.length : 0}</TableCell>
                            {this.handleStatus(topics ? topics.length : 0)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Claim Type</TableCell>
                            <TableCell className='tableCell'>{claim_type}
                            </TableCell>
                            {this.handleStatus(claim_type)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Image Alt</TableCell>
                            <TableCell className='tableCell'>{image_alt}
                            </TableCell>
                            {this.handleStatus(image_alt)}
                        </TableRow>

                        <TableRow>
                            <TableCell>Lang code</TableCell>
                            <TableCell className='tableCell'>{lang_code}
                            </TableCell>
                            {this.handleStatus(lang_code)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Claim</TableCell>
                            <TableCell className='tableCell'>{claim}</TableCell>
                            {this.handleStatus(claim)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Conclusion Summary</TableCell>
                            <TableCell className='tableCell'>{conclusion_summary}</TableCell>
                            {this.handleStatus(conclusion_summary)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Conclusion</TableCell>
                            <TableCell className='tableCell' dangerouslySetInnerHTML={{ __html: conclusion }}>
                            </TableCell>
                            {this.handleStatus(conclusion)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Group Id</TableCell>
                            <TableCell className='tableCell'>{group_id}</TableCell>
                            {this.handleStatus(group_id)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Editor Refrence Urls</TableCell>
                            <TableCell className='tableCell'>{editor_reference_urls ? editor_reference_urls.length : 0}</TableCell>
                            {this.handleStatus(editor_reference_urls ? editor_reference_urls.length : 0)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Tags</TableCell>
                            <TableCell className='tableCell'>{tags ? tags.length : 0}</TableCell>
                            {this.handleStatus(tags ? tags.length : 0)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Verdict</TableCell>
                            <TableCell className='tableCell'>{verdict}</TableCell>
                            {this.handleStatus(verdict)}
                        </TableRow>
                        <TableRow>
                            <TableCell>Rating</TableCell>
                            <TableCell className='tableCell'>{rating}</TableCell>
                            {this.handleStatus(rating)}
                        </TableRow>
                        {_id && <TableRow>
                            <TableCell colSpan={3}>
                                <Typography>
                                    <b>Note: </b>
                                    The Data should be filled from the claim page directly -
                                    <a href={`http://factcheck.logically.ai/claim/${_id}`}
                                        target='_blank' rel='noopener noreferrer'>claim link</a>
                                </Typography>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
        )
    }
}

export default ClaimData