import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../../../utils/fomratDate";
import { getLocation } from "../../../utils/common";
import { FlagIcon } from "react-flag-kit";

class SearchImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      expanded: false,
      offset: 0,
      total: 0,
      claim_checked: [],
      selectedValue: false,
      highlights: false,
      disabled: false,
      startDate: new Date(),
      dataPreview: [],
      getDate: new Date(),
      modalIsOpen: false,
      Deselectchecked: false,
      users: [],
      pageNumbers: [],
      currentPage: 1,
      todosPerPage: 100,
      pageCount: 0,
      allData: [],
    };
  }

  componentDidMount() {
    const { imgSearchData } = this.props;
    console.log(imgSearchData);
  }

  getFCActivityDetails = (claim) => {
    const { allUsers } = this.props;
    let fc, mo, sv;
    allUsers.forEach((user) => {
      if (user.user_id === parseInt(claim.factchecker_id)) fc = user.username;
      if (user.user_id === parseInt(claim.moderator_id)) mo = user.username;
      if (user.user_id === parseInt(claim.supervisor_id)) sv = user.username;
    });
    return [
      { type: "FC", name: fc ? fc : "N/A" },
      { type: "MO", name: mo ? mo : "N/A" },
      { type: "SV", name: sv ? sv : "N/A" },
    ];
  };

  getPriorityStyles = (priority) => {
    if (priority === "high")
      return { backgroundColor: "#FFEAEF", color: "#F64747" };
    if (priority === "medium")
      return { backgroundColor: "#ffca9a", color: "#FF8C00" };
    if (priority === "low")
      return { backgroundColor: "#E1FFD8", color: "#3FC380" };
    else return { backgroundColor: "", color: "" };
  };

  getLanguage = (code) => {
    if (code === "en") return "English";
    if (code === "mr") return "Marathi";
    if (code === "hi") return "Hindi";
    else return "English";
  };

  render() {
    const { imgSearchData, isAllUserLoading } = this.props;
    console.log(imgSearchData);
    return (
      <div className="search-results-list">
        {imgSearchData.map((claim, index) => (
          <Link to={"/claim/" + claim._id} key={index}>
            <div className="search-results-item">
              <div className="search-claim-details">
                <Typography>
                  <strong>{claim._id}</strong> |{" "}
                  {formatDate(claim.check_created_at)}
                </Typography>
                <div className="claim-code-details">
                  {claim.priority.length > 0 && (
                    <div
                      className="priority-container"
                      style={this.getPriorityStyles(claim.priority)}
                    >
                      {claim.priority}
                    </div>
                  )}
                  <div className="code-container">
                    {this.getLanguage(claim.lang_code)}
                  </div>
                  {claim.claim_source && (
                    <div className="code-container">{claim.claim_source}</div>
                  )}
                  {claim.location && claim.location.length > 0 && (
                    <FlagIcon size={30} code={getLocation(claim.location)} />
                  )}
                </div>
              </div>
              <div className="search-img-container">
                <img src={claim.original_image} alt="thumbnail" />
              </div>
              {!isAllUserLoading && (
                <div className="claim-history">
                  {this.getFCActivityDetails(claim).map((history, index) => (
                    <Typography className="claim-history-item" key={index}>
                      {history.type}: {history.name}
                    </Typography>
                  ))}
                </div>
              )}
              <div className="claim-state-verdict">
                {(!claim.fact_checked && !claim.assigned && (
                  <Typography className="claim-status">New</Typography>
                )) ||
                  (claim.status === "in progress" && (
                    <Typography className="claim-status">
                      In-Progress
                    </Typography>
                  )) ||
                  (claim.status === "pending authorisation" &&
                    claim.judgement &&
                    !claim.finalising && (
                      <Typography className="claim-status">
                        In-Progress
                      </Typography>
                    )) ||
                  (claim.status === "pending authorisation" &&
                    claim.judgement &&
                    claim.finalising && (
                      <Typography className="claim-status">
                        In-Review
                      </Typography>
                    )) ||
                  (claim.status === "resolved" && claim.verification && (
                    <Typography className="claim-status">Completed</Typography>
                  ))}
              </div>
            </div>
          </Link>
        ))}
      </div>
    );
  }
}
export default SearchImages;
