import React, { Component } from "react";
import logo from "../../../assets/NewLogo.png";
import "./generalInfo.css";
import { NavLink, withRouter, Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import DashIcon from "../../../assets/dashIcon";
import Typography from "@material-ui/core/Typography";
import ClaimsIcon from "../../../assets/ClaimsIcon";
import api, { getBase } from "../../../api";
import axios from "axios";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import { connect } from "react-redux";

const styles = {
  iconStyles: {
    width: "16px",
    marginRight: "7px",
  },
};

const paths = [
  { pathname: "/", name: "Claims" },
  { pathname: "/overview", name: "Dashboard" },
];

class GeneralInfo extends Component {
  state = {
    counts: [],
  };

  componentDidMount() {
    this.getCount();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.offline) {
      this.getCount();
    }
  }

  getCount = () => {
    axios
      .get(getBase(api.CLAIM_COUNT), {
        headers: { Authorization: "Token " + localStorage.token },
      })
      .then((res) => {
        let count = res.data.data;
        const {
          new_claims_count,
          image_claims_count,
          conducting_research_count,
          finalising_count,
          resolved_count,
          open_complaint_count,
          making_judgement_count,
        } = count;
        let counts = [
          { name: "New claims", count: new_claims_count + image_claims_count },
          {
            name: "In-Progress",
            count: conducting_research_count + making_judgement_count,
          },
          { name: "In-Review", count: finalising_count },
          { name: "Completed", count: resolved_count },
          { name: "Complaints", count: open_complaint_count },
        ];
        this.setState({ counts }, () => {
          this.props.dispatch({ type: "SHOW_COUNT", showCount: counts });
        });
      });
  };

  renderActiveColor = (location, path) => {
    return location === path ? "#fff" : "#050E2B";
  };

  sendDataToStore(data) {
    if (this.props.offline) {
      return;
    }
    this.props.dispatch({ type: "VIEW_HISTORY", viewHistory: data.name });
  }

  render() {
    const { location, offline } = this.props;
    const { counts } = this.state;
    return (
      <div className="general-info-container">
        <div className="logo">
          <div className="logo-container">
            <Link to="/">
              <img src={logo} alt="Logo" />
              <p style={{ whiteSpace: "nowrap", fontSize: "12px" }}>
                Fact-check Dashboard
              </p>
              {offline && (
                <p
                  style={{
                    display: "flex",
                    direction: "row",
                    verticalAlign: "middle",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                    color: "red",
                    marginTop: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <WifiOffIcon
                    style={{ fontSize: "20px", marginRight: "8px" }}
                  />{" "}
                  <b>OFFLINE MODE</b>
                </p>
              )}
            </Link>
          </div>
        </div>
        <div className="sidebar-navs">
          {paths.map((path, index) => (
            <NavLink
              to={offline ? "" : path.pathname}
              key={index}
              onClick={() => this.sendDataToStore(path)}
            >
              <Grid
                container
                alignItems="center"
                className={
                  location.pathname === path.pathname
                    ? "sidebar-nav sidebar-nav-active"
                    : "sidebar-nav"
                }
              >
                {path.name === "Claims" ? (
                  <ClaimsIcon
                    fill={this.renderActiveColor(
                      location.pathname,
                      path.pathname
                    )}
                    styles={styles.iconStyles}
                  />
                ) : (
                  <DashIcon
                    fill={this.renderActiveColor(
                      location.pathname,
                      path.pathname
                    )}
                    styles={styles.iconStyles}
                  />
                )}
                <Typography
                  style={{
                    color: this.renderActiveColor(
                      location.pathname,
                      path.pathname
                    ),
                  }}
                >
                  {path.name}
                </Typography>
              </Grid>
            </NavLink>
          ))}
        </div>
        <div className="counts-container">
          {counts.map((item, index) => (
            <div className="count-item" key={index}>
              <Typography className="count-number">{item.count}</Typography>
              <Typography>{item.name}</Typography>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    offline: data.offline,
  };
};

export default withRouter(connect(mapStateToProps)(GeneralInfo));
