import React, { Component } from 'react'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import axios from 'axios'
import { connect } from 'react-redux'

const TableData = [
    "_id",
    "verdict",
    "rating",
    "tracking_id",
    "lang_code",
    "priority",
    "last_updated",
    "background_image",
    "claim",
]

export class ClaimPublishing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchClaimInput: '',
            apikey: this.props.apikey || "",
            claimData: []
        }
    }
    componentDidMount() {
        this.handleSearch()
    }
    handleSearch = () => {
        const { apikey } = this.props
        if (apikey) {
            this.getFactcheckData(apikey)
        } else {
            axios.get('/get-apikey').then(res => {
                console.log(res.data);
                this.setState({ apikey: res.data.key }, () => {
                    this.props.dispatch({
                        type: "APIKEY",
                        apikey: res.data.key
                    });
                    this.getFactcheckData(this.state.apikey)
                });
            })
        }

    }
    getFactcheckData = (apikey) => {
        const url = `/get-hubspot-factchecks?apikey=${apikey}`
        const dataToSend = [{
            "offset": 0,
            "pageSize": 10
        }]
        axios
            .post(url, dataToSend)
            .then((res) => {
                this.setState({ claimData: res.data.data })
            }).catch(e => {
                console.log(e)
            })

    }
    render() {
        const { claimData } = this.state
        return (
            <div>
                <div className='main'>
                    <Paper style={{ padding: 16, width: 'max-content' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Last 10 claims sent to hubspot</Typography>
                        <br />
                        <div>
                            <Table padding={claimData.length > 0 ? "dense" : 'default'}>
                                <TableHead>
                                    <TableRow >
                                        {TableData.map(item => {
                                            return <TableCell key={item} style={{ textTransform: 'capitalize' }}>{item.replaceAll("_", " ")}</TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {claimData.map(item => {
                                        return <TableRow key={item._id}>
                                            {TableData.map(key => {
                                                return <TableCell style={{ wordBreak: 'break-word', minWidth: 100, maxWidth: 300 }} key={item[key]}>{
                                                    key === '_id' ?
                                                        <a href={`https://factcheck.logically.ai/claim/${item[key]}`} target='_blank' rel="noopener noreferrer" >{item[key]}</a>
                                                        :
                                                        item[key]}</TableCell>

                                            })}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (data) => {
    return {
        apikey: data.apikey
    }
}

export default connect(mapStateToProps)(ClaimPublishing);