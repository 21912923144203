export function formatDate(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const newDate = new Date(date);
  let dateString = `${newDate.getDate()} ${
    months[newDate.getMonth()]
  } ${newDate.getFullYear()}`;
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return `${dateString} | ${strTime}`;
}
export function formatDateOnly(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const newDate = new Date(date);
  let dateString = `${newDate.getDate()} ${
    months[newDate.getMonth()]
  } ${newDate.getFullYear()}`;
  return `${dateString}`;
}
