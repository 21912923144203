import React, { Component } from "react";
import { connect } from "react-redux";
import noconnection from "../assets/noconnection.png";
import Button from "@material-ui/core/Button";

export default function (ComposedComponent) {
  class NetworkDetector extends Component {
    state = {
      isDisconnected: false,
      reduxData: false,
      tab: this.props.tab,
      typeOfClaim: this.props.typeOfClaim,
      conductingResearch: {
        url: false,
        multimedia: false,
      },
      makingJudgement: {
        url: false,
        multimedia: false,
      },
      finalize: {
        url: false,
        multimedia: false,
      },
      complaint: {
        opencomplaint: false,
        closecomplaint: false,
      },
      completed: {
        url: false,
        multimedia: false,
      },
      rejected: false,
    };

    componentDidMount() {
      this.handleConnectionChange();
      window.addEventListener("online", this.handleConnectionChange);
      window.addEventListener("offline", this.handleConnectionChange);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.state.tab !== nextProps.tab) {
        this.setState({
          tab: nextProps.tab,
        });
      }

      const {
        conductingResearch,
        makingJudgement,
        finalize,
        complaint,
        rejected,
        completed,
      } = this.state;

      //conducting research
      if (
        conductingResearch.url === false &&
        nextProps.conductingResearch.url
      ) {
        this.setState({
          conductingResearch: {
            ...conductingResearch,
            url: true,
          },
        });
        return;
      }
      if (
        conductingResearch.multimedia === false &&
        nextProps.conductingResearch.multimedia
      ) {
        this.setState({
          conductingResearch: {
            ...conductingResearch,
            multimedia: true,
          },
        });
        return;
      }

      //making judgement
      if (makingJudgement.url === false && nextProps.makingJudgement.url) {
        this.setState({
          makingJudgement: {
            ...makingJudgement,
            url: true,
          },
        });
        return;
      }
      if (
        makingJudgement.multimedia === false &&
        nextProps.makingJudgement.multimedia
      ) {
        this.setState({
          makingJudgement: {
            ...makingJudgement,
            multimedia: true,
          },
        });
        return;
      }

      //finalize
      if (finalize.url === false && nextProps.finalize.url) {
        this.setState({
          finalize: {
            ...finalize,
            url: true,
          },
        });
        return;
      }
      if (finalize.multimedia === false && nextProps.finalize.multimedia) {
        this.setState({
          finalize: {
            ...finalize,
            multimedia: true,
          },
        });
        return;
      }

      //completed
      if (completed.url === false && nextProps.completed.url) {
        this.setState({
          completed: {
            ...completed,
            url: true,
          },
        });
        return;
      }
      if (completed.multimedia === false && nextProps.completed.multimedia) {
        this.setState({
          completed: {
            ...completed,
            multimedia: true,
          },
        });
        return;
      }

      //Complaints
      if (
        complaint.opencomplaint === false &&
        nextProps.complaint.opencomplaint
      ) {
        this.setState({
          complaint: {
            ...complaint,
            opencomplaint: true,
          },
        });
        return;
      }
      if (
        complaint.closecomplaint === false &&
        nextProps.complaint.closecomplaint
      ) {
        this.setState({
          complaint: {
            ...complaint,
            closecomplaint: true,
          },
        });
        return;
      }

      // Rejected
      if (rejected === false && nextProps.rejected) {
        this.setState({
          rejected: {
            ...rejected,
            rejected: true,
          },
        });
        return;
      }
    }

    commonButtonAction = (point) => {
      this.setState({
        isDisconnected: false,
      });
      this.props.dispatch({
        type: "OFFLINE",
        offline: true,
      });
      if (this.state.tab === 1 || this.state.tab === 5) {
        this.props.dispatch({
          type: "NAVPOINTS",
          navpoints: point,
        });
        this.props.dispatch({
          type: "SWITCH_CLAIMS_TABS",
          tab: point,
        });
      }
    };

    handleOffline = () => {
      const {
        conductingResearch,
        makingJudgement,
        finalize,
        complaint,
        rejected,
        tab,
        completed,
      } = this.state;

      let user = JSON.parse(localStorage.user);

      let isFactChecker = true;
      if (user) {
        if (user.profile) {
          if (user.profile.fields) {
            isFactChecker = user.profile.fields.role === "factchecker";
          }
        }
      }

      if (tab === 1 || tab === 5) {
        if (isFactChecker) {
          if (rejected) {
            this.commonButtonAction(2);
            return;
          } else if (makingJudgement.url || makingJudgement.multimedia) {
            this.commonButtonAction(3);
            this.props.dispatch({
              type: "VIEW_BUTTON",
              activeButton: makingJudgement.url
                ? "URL claims"
                : "Multimedia claims",
            });
            return;
          } else if (completed.url || completed.multimedia) {
            this.commonButtonAction(4);
            this.props.dispatch({
              type: "VIEW_BUTTON",
              activeButton: completed.url ? "URL claims" : "Multimedia claims",
            });
            return;
          }
        }
        if (conductingResearch.url || conductingResearch.multimedia) {
          this.commonButtonAction(2);
          this.props.dispatch({
            type: "VIEW_BUTTON",
            activeButton: conductingResearch.url
              ? "URL claims"
              : "Multimedia claims",
          });
          return;
        } else if (makingJudgement.url || makingJudgement.multimedia) {
          this.commonButtonAction(3);
          this.props.dispatch({
            type: "VIEW_BUTTON",
            activeButton: makingJudgement.url
              ? "URL claims"
              : "Multimedia claims",
          });
          return;
        } else if (finalize.url || finalize.multimedia) {
          this.commonButtonAction(4);
          this.props.dispatch({
            type: "VIEW_BUTTON",
            activeButton: finalize.url ? "URL claims" : "Multimedia claims",
          });
          return;
        } else if (complaint.opencomplaint || complaint.closecomplaint) {
          this.commonButtonAction(6);
          this.props.dispatch({
            type: "VIEW_BUTTON",
            activeButton: complaint.url
              ? "Open complaints"
              : "Closed complaints",
          });
          return;
        } else if (rejected) {
          this.commonButtonAction(7);
          return;
        }
      } else {
        this.commonButtonAction();
      }
    };

    componentWillUnmount() {
      window.removeEventListener("online", this.handleConnectionChange);
      window.removeEventListener("offline", this.handleConnectionChange);
    }

    handleConnectionChange = () => {
      const { isDisconnected } = this.state;
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        const webPing = setInterval(() => {
          fetch("//google.com", {
            mode: "no-cors",
          })
            .then(() => {
              isDisconnected &&
                this.setState({
                  isDisconnected: false,
                });
              this.props.dispatch({
                type: "OFFLINE",
                offline: false,
              });
            })
            .catch(() => {
              !isDisconnected &&
                this.setState({
                  isDisconnected: true,
                });
            });
        }, 3000);
        return;
      }
      return this.setState({
        isDisconnected: true,
      });
    };

    render() {
      const { isDisconnected } = this.state;
      const { offline } = this.props;

      const {
        conductingResearch,
        makingJudgement,
        finalize,
        complaint,
        rejected,
        completed,
      } = this.state;

      const buttonVisible =
        conductingResearch.url ||
        conductingResearch.multimedia ||
        makingJudgement.url ||
        makingJudgement.multimedia ||
        finalize.url ||
        finalize.multimedia ||
        complaint.opencomplaint ||
        complaint.closecomplaint ||
        completed.url ||
        completed.multimedia ||
        rejected;

      if (isDisconnected && !offline)
        return (
          <div>
            <div>
              <img
                src={noconnection}
                alt="noconnection_img"
                style={{
                  width: "30vw",
                  height: isDisconnected ? "30vh" : "0px",
                  position: "relative",
                  zIndex: 1000,
                  textAlign: "center",
                  justifyContent: "center",
                  marginLeft: "450px",
                  marginTop: "200px",
                }}
              />{" "}
            </div>{" "}
            <div
              style={{
                textAlign: "center",
                paddingTop: "20px",
                color: "rgba(246, 36, 89, 1)",
                font: "normal normal bold Mulish",
                fontWeight: "600",
              }}
            >
              You are disconnected{" "}
            </div>{" "}
            <div
              style={{
                textAlign: "center",
                paddingTop: "20px",
                font: "normal normal bold Mulish",
                fontWeight: "400",
              }}
            >
              Please check your internet connection and try again{" "}
            </div>{" "}
            {buttonVisible && (
              <div
                style={{
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <Button
                  onClick={this.handleOffline}
                  fullWidth={false}
                  size="medium"
                  variant={"outlined"}
                  style={{
                    font: "Mulish",
                    minWidth: "fit-content",
                    marginRight: "3%",
                    height: 30,
                    textTransform: "none",
                    paddingBottom: "10px",
                  }}
                >
                  <b> View Offline </b>{" "}
                </Button>{" "}
              </div>
            )}{" "}
          </div>
        );
      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (data) => {
    return {
      tab: data.navpoints,
      typeOfClaim: data.activeButton,
      conductingResearch: data.conducting_research,
      makingJudgement: data.making_Judgement,
      finalize: data.finalize,
      complaint: data.complaint,
      rejected: data.rejected,
      completed: data.completed,
      offline: data.offline,
    };
  };

  return connect(mapStateToProps)(NetworkDetector);
}
