import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getTimeDifference } from "../../../../utils/time";
import { formatDate } from "../../../../utils/fomratDate";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import api, { getBase } from "../../../../api";
import { FlagIcon } from "react-flag-kit";
import ProcessImage from "react-imgpro";
import {
  getLocation,
  priorityTextColors,
  priorityColors,
} from "../../../../utils/common";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { db, putClaimData } from "../../../../App";
import { getLanguageByCode } from "../../../../utils/language";

const styles = {
  textDiv: {
    marginTop: 10,
    height: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
  },
};
class CommonCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      src: "",
      err: null,
      isAcceptLoading: false,
      acceptedBy: "",
    };
  }

  isFactChecker = () => {
    let user = JSON.parse(localStorage.user);
    let isFactchecker = false;
    if (user) {
      if (user.profile) {
        if (user.profile.fields) {
          isFactchecker = user.profile.fields.role === "factchecker";
        }
      }
    }

    return isFactchecker;
  };

  modaretor_time_start = (data) => {
    let started_at = moment().format("YYYY/MM/DD HH:mm [GMT+5:30]");

    let user = JSON.parse(localStorage.user);
    user = user.profile.fields.role;
    if (user) {
      if (data.moderator_started_at === "" && user === "moderator") {
        data.moderator_started_at = started_at;
        data.type = "image";
        axios
          .put(getBase(api.CLAIM + data._id), data, {
            headers: {
              Authorization: "Token " + localStorage.token,
              "content-type": "application/json",
            },
          })
          .then((res) => {
            if (JSON.stringify(res.data.data)) {
              this.setState({ loading: false });
            } else if (this.state.loading) {
              this.setState({ loading: false });
            }
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      }
      if (user === "supervisor") {
        if (data.finalising === true) {
          data.supervisor_started_at = started_at;
          data.type = "image";
        }
        if (data.finalising === false) {
          data.moderator_started_at = started_at;
          data.type = "image";
        }
        axios
          .put(getBase(api.CLAIM + data._id), data, {
            headers: {
              Authorization: "Token " + localStorage.token,
              "content-type": "application/json",
            },
          })
          .then((res) => {
            if (JSON.stringify(res.data.data)) {
              this.setState({ loading: false });
            } else if (this.state.loading) {
              this.setState({ loading: false });
            }
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      }
    }
  };

  modaretor_time_start2 = (data) => {
    let started_at = moment().format("YYYY/MM/DD HH:mm [GMT+5:30]");

    let user = JSON.parse(localStorage.user);
    user = user.profile.fields.role;
    if (user) {
      if (data.moderator_started_at === "" && user === "moderator") {
        data.moderator_started_at = started_at;
        axios
          .put(getBase(api.CLAIM + data._id), data, {
            headers: {
              Authorization: "Token " + localStorage.token,
              "content-type": "application/json",
            },
          })
          .then((res) => {
            if (JSON.stringify(res.data.data)) {
              this.setState({ loading: false });
            } else if (this.state.loading) {
              this.setState({ loading: false });
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
      if (user === "supervisor") {
        if (data.finalising === true) {
          data.supervisor_started_at = started_at;
        }
        if (data.finalising === false) {
          data.moderator_started_at = started_at;
        }
        axios
          .put(getBase(api.CLAIM + data._id), data, {
            headers: {
              Authorization: "Token " + localStorage.token,
              "content-type": "application/json",
            },
          })
          .then((res) => {
            if (JSON.stringify(res.data.data)) {
              this.setState({ loading: false });
            } else if (this.state.loading) {
              this.setState({ loading: false });
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    }
  };

  handleAcceptClaim = () => {
    const { data, filtertabs } = this.props;
    this.setState({ isAcceptLoading: true });
    const userInfo = JSON.parse(localStorage.user);
    const updates = { status: "working", time_in_status: new Date() };
    db.ref()
      .child(`${userInfo.id}`)
      .update(updates);
    if (filtertabs === "URL claims") {
      axios
        .post(
          `${getBase(api.CLAIM)}${data._id}/accept`,
          {},
          {
            headers: {
              Authorization: "Token " + localStorage.token,
              "content-type": "application/json",
            },
          }
        )
        .then((res) => {
          this.setState(
            {
              isAcceptLoading: false,
              acceptedBy: userInfo.username,
            },
            () => {
              putClaimData(data._id, userInfo.username);
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(
          `${getBase(api.CLAIM)}${data._id}/accept?type=image`,
          {},
          {
            headers: {
              Authorization: "Token " + localStorage.token,
              "content-type": "application/json",
            },
          }
        )
        .then((res) => {
          this.setState(
            {
              isAcceptLoading: false,
              acceptedBy: userInfo.username,
            },
            () => {
              putClaimData(data._id, userInfo.username);
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  getFCUserName(data) {
    if (data.factchecker_id === (undefined || "")) {
      return data.old_factchecker_id;
    }
    return data.factchecker_id;
  }
  getModUserName(data) {
    if (data.moderator_id === (undefined || "")) {
      return data.old_moderator_id;
    }
    return data.moderator_id;
  }
  getAssignedBy(data) {
    if (!data.assigned_by) {
      return false;
    } else {
      return data.assigned_by;
    }
  }

  render() {
    const {
      classes,
      data,
      assigned,
      images,
      userHashTable,
      urlAccept,
      reason,
    } = this.props;
    const { isAcceptLoading, acceptedBy } = this.state;

    return (
      <>
        <Card
          style={{
            height: images ? 300 : 270,
            boxShadow: "none",
            borderRadius: 20,
            border: `2px solid ${priorityTextColors[data.priority]}`,
          }}
        >
          <CardContent>
            <Grid container>
              <Grid item md={3}>
                {data.recheck_image ? (
                  <Typography component="p">
                    <span style={{ color: "black", fontWeight: "600" }}>
                      {data._id}
                    </span>
                  </Typography>
                ) : (
                  <Typography component="p">
                    <span style={{ color: "black", fontWeight: "600" }}>
                      {data.tracking_id}
                    </span>
                  </Typography>
                )}
              </Grid>
              <Grid item md={3}>
                {data.lang_code && (
                  <Typography
                    style={{
                      fontSize: "10px",
                      color: "black",
                      width: "100%",
                      textTransform: "uppercase",
                      textAlign: "right",
                      lineHeight: "20px",
                    }}
                    variant="body1"
                  >
                    <span
                      style={{
                        padding: "3px",
                        borderRadius: "3px",
                        backgroundColor: "#f1f4f8",
                      }}
                    >
                      {getLanguageByCode(data.lang_code).name}
                    </span>
                  </Typography>
                )}
              </Grid>
              <Grid item md={3}>
                <Typography
                  style={{
                    fontSize: "10px",
                    color: "black",
                    width: "100%",
                    textTransform: "uppercase",
                    textAlign: "center",
                    lineHeight: "20px",
                  }}
                  variant="body1"
                >
                  <span
                    style={{
                      padding: "3px",
                      borderRadius: "3px",
                      backgroundColor: "#f1f4f8",
                    }}
                  >
                    {data.claim_source}
                  </span>
                </Typography>
              </Grid>
              {data.location && (
                <Grid item md={2} style={{ textAlign: "center" }}>
                  <FlagIcon
                    code={getLocation(data.location)}
                    size={20}
                    style={{ paddingLeft: "auto", marginTop: 0 }}
                  />
                </Grid>
              )}
              <Grid item md={12}>
                <div style={{ display: "flex", marginTop: 5 }}>
                  <span
                    style={{
                      fontSize: "10px",
                      backgroundColor: priorityColors[data.priority],
                      textTransform: "uppercase",
                      lineHeight: "20px",
                      marginRight: "5px",
                      padding: "0 5px",
                      borderRadius: "3px",
                      fontWeight: "bold",
                    }}
                  >
                    {data.priority}
                  </span>
                  <span
                    style={{
                      fontSize: "10px",
                      textTransform: "uppercase",
                      display: "flex",
                      marginTop: 2,
                    }}
                  >
                    <ScheduleIcon color="disabled" style={{ height: 15 }} />
                    {formatDate(data.check_created_at)}
                  </span>
                </div>
              </Grid>
              {!data.recheck_image && (
                <div
                  style={{
                    border: "1px solid #e4ebf3",
                    width: "100%",
                    marginTop: 10,
                  }}
                />
              )}
              {images ? (
                <Grid item md={12}>
                  <Link
                    to={{
                      pathname: "/claim/" + data._id,
                      state: {
                        dataFromCard: data,
                      },
                    }}
                  >
                    <div className={classes.textDiv}>
                      {data.background_image && (
                        <ProcessImage
                          image={data.background_image}
                          resize={{ width: "100%" }}
                          colors={{
                            mix: {
                              color: "mistyrose",
                              amount: 20,
                            },
                          }}
                          processedImage={(src, err) =>
                            this.setState({ src, err })
                          }
                        />
                      )}
                    </div>
                  </Link>
                </Grid>
              ) : (
                <Grid item md={12}>
                  <Link
                    to={{
                      pathname:
                        "/claim/" + data._id + (urlAccept ? "/accept" : ""),
                      state: {
                        dataFromCard: data,
                      },
                    }}
                  >
                    <div className={classes.textDiv}>
                      {data.recheck_image ? (
                        <ProcessImage
                          image={data.recheck_image}
                          resize={{ width: "100%" }}
                          colors={{
                            mix: {
                              color: "mistyrose",
                              amount: 20,
                            },
                          }}
                          processedImage={(src, err) =>
                            this.setState({ src, err })
                          }
                        />
                      ) : (
                        <Typography component="p" style={{ fontWeight: 600 }}>
                          {data.claim}
                        </Typography>
                      )}
                    </div>
                  </Link>
                </Grid>
              )}
              {!data.recheck_image && (
                <div
                  style={{
                    border: "1px solid #e4ebf3",
                    width: "100%",
                    marginTop: 10,
                  }}
                />
              )}
              <Grid item md={12}>
                {assigned && (
                  <>
                    {userHashTable && (
                      <>
                        {this.getFCUserName(data) && (
                          <>
                            {!data.accepted || acceptedBy ? (
                              <span style={{ textTransform: "capitalize" }}>
                                {this.getAssignedBy(data) && (
                                  <span>
                                    <small>
                                      {assigned} :{" "}
                                      <b>
                                        {
                                          userHashTable[
                                            this.getAssignedBy(data)
                                          ].username
                                        }{" "}
                                      </b>
                                    </small>
                                  </span>
                                )}
                              </span>
                            ) : (
                              <span style={{ textTransform: "capitalize" }}>
                                {this.getFCUserName(data) && (
                                  <span>
                                    <small>
                                      {assigned} :{" "}
                                      <b>
                                        {
                                          userHashTable[
                                            this.getFCUserName(data)
                                          ].username
                                        }{" "}
                                      </b>
                                    </small>
                                  </span>
                                )}
                              </span>
                            )}
                            {data.factcheck_started_at &&
                              data.factcheck_completed_at && (
                                <span>
                                  <small>
                                    <b>| </b> Duration{" "}
                                    <b>
                                      {" "}
                                      {data.factcheck_started_at &&
                                        data.factcheck_completed_at && (
                                          <>
                                            {getTimeDifference(
                                              data.factcheck_started_at,
                                              data.factcheck_completed_at
                                            )}
                                          </>
                                        )}
                                    </b>
                                  </small>
                                </span>
                              )}
                          </>
                        )}
                        {data.factcheck_started_at &&
                          data.factcheck_completed_at && (
                            <span>
                              <small>
                                <b>| </b> Duration{" "}
                                <b>
                                  {" "}
                                  {data.factcheck_started_at &&
                                    data.factcheck_completed_at && (
                                      <>
                                        {getTimeDifference(
                                          data.factcheck_started_at,
                                          data.factcheck_completed_at
                                        )}
                                      </>
                                    )}
                                </b>
                              </small>
                            </span>
                          )}
                      </>
                    )}
                  </>
                )}
                <div>
                  {data.accepted_by || acceptedBy ? (
                    <small>
                      Accepted by <b>{data.accepted_by || acceptedBy}</b>
                    </small>
                  ) : (
                    <button
                      type="button"
                      style={{
                        padding: "5px",
                        backgroundColor: "#F1F4F8",
                        cursor: "pointer",
                        marginTop: 3,
                        border: "none",
                        borderRadius: "5px",
                      }}
                      onClick={() => this.handleAcceptClaim(data._id)}
                    >
                      {isAcceptLoading ? "Loading..." : "Accept"}
                    </button>
                  )}
                </div>
                {reason && (
                  <small>
                    <p style={{ marginBottom: 5 }}>
                      Rejected by
                      <b style={{ marginLeft: 3 }}>
                        {userHashTable && (
                          <span style={{ textTransform: "capitalize" }}>
                            {this.getFCUserName(data) && (
                              <span>
                                {
                                  userHashTable[this.getFCUserName(data)]
                                    .username
                                }
                              </span>
                            )}
                          </span>
                        )}
                      </b>
                    </p>
                    <p>
                      Reason: <b> {data.reject_message}</b>
                    </p>
                  </small>
                )}
                {images && (
                  <Typography component="p" style={{ fontWeight: 600 }}>
                    {data.conclusion_summary}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  }
}

export default withStyles(styles)(CommonCard);
