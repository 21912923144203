import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Chip,
  NativeSelect,
  InputBase,
  FormControl,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import moment from "../../../utils/time";
import { TagSearch } from "./../../../Dashboard/components/dashboardSideBar/dashboardSideBar";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Evidences from "../EvidenceRetieval/evidences";
import FactFinder from "../SimilarFactFinder/FactFinder";
import api, { getBase } from "../../../api";
import { getHeaders } from './api'
import { toast } from "react-toastify";
import LoadingCircular from '../../../components/Loading'
import ApiConstants from "../../../api";
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { connect } from "react-redux";


const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    border: `${1}px solid darkgrey`,
    borderRadius: 4,
    position: "relative",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const styles = (theme) => ({
  root: {
    fontSize: 14,
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    minHeight: 300,
    position: "relative",
    marginTop: 15,
    boxShadow: "none",
  },
  fontFamily: ["Mulish"].join(","),
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
  paper: {
    backgroundColor: "#fff",
    textAalign: "center",
    padding: 15,
    boxShadow: "none",
    borderRadius: 15,
    marginBottom: 15,
  },
  paperFact: {
    backgroundColor: "#fff",
    textAalign: "center",
    padding: 5,
    boxShadow: "none",
    borderRadius: 15,
    marginBottom: 15,
  },
  paperEvidence: {
    backgroundColor: "#F2F4F8",
    textAalign: "center",
    boxShadow: "none",
    borderRadius: 15,
    marginBottom: 15,
  },
  chip: {
    margin: theme.spacing.unit / 2,
    color: "#fff",
    marginTop: 10,
    backgroundColor: "#F62459",
    borderRadius: 16,
  },

  button: {
    position: "relative",
    right: `${0.4}rem`,
    bottom: `${1}rem`,
    textTransform: "none",
  },
  notes: {
    position: "relative",
  },
  margin: {
    width: "95%",
  },
  dropdowns: {
    position: "relative",
  },
  refURL: {
    position: "relative",
    left: `${0.4}rem`,
  },
  textField: {
    position: "relative",
  },

  menuList: {
    paddingBottom: 15,
    width: "100%",
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  tableRow: {
    '& th': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '0.8125rem'
    },
  },
  nestedInputRoot: {
    height: 37
  }
});
const headers = {
  Authorization: "Token " + localStorage.token,
  "content-type": "application/json",
}
class NotesEditors extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    if (!data.editor_provenance_urls) {
      data.editor_provenance_urls = []
    }
    this.state = {
      tag: "",
      topics: "",
      editor_reference_urls: data.editor_reference_urls || [],
      newrefurl: {
        url: "",
        source: "",
        source_name: "",
        published_date: "",
        access_date: moment(new Date()).format("YYYY-MM-DD"),
        offensive_content: false,
      },
      deletedRef: "",
      editor_provenance_urls: data.editor_provenance_urls || [],
      newprovenanceurl: {
        url: "",
        // source_name: "",
        published_date: "",
        access_date: moment(new Date()).format("YYYY-MM-DD"),
        user_or_author: "",
        platform_name: ""
      },
      deletedRefprovenance: "",
      feedback: {
        "_id": "",
        "claim": "",
        "feedback_collected": [
          {
            _id: `34_fact_finder_03c057d91ad75b9083cd484ed4ebfcfa`,
            user_details: { id: 34, username: "syeda" },
            ai_component: "fact_finder",
            feedback_for: {},
            feedback_given: {
              feedback_label: "Partial",
              feedback_urls: [
                "https://www.bbc.com/news/world-asia-china-59595952",
                "https://www.bbc.com/news/world-asia-59459910"
              ],
            }
          }
        ]
      },
      group_id: data.group_id,
      associated_factchecks: [],
      loading: false,
      circularLoading: false,

    };
  }

  componentDidMount() {
    this.getFeedbacks();
    this.handleGetAssociatedGroup(this.props.data.group_id)
  }

  handleDelete = (chip, type) => () => {
    let data = { ...this.props.data };
    const chipToDelete = data.tags.indexOf(chip);
    const chipToDeleteCategory = data.topics.indexOf(chip);
    if (type === 'topics') {
      data.topics.splice(chipToDeleteCategory, 1);
    } else if (type === 'group') {
      // data.tags.splice(chipToDelete, 1);
      // handle delete api
    } else {
      data.tags.splice(chipToDelete, 1);
    }
    this.props.handleChange(data);
  };
  handleAdd = (e) => {
    e.preventDefault();
    if (this.state.tag.length > 1) {
      let data = this.props.data;
      data.tags.push(this.state.tag);
      this.props.handleChange(data);
      this.setState({ tag: "" });
    }
  };
  handleChange = (val, type) => {
    const data = { ...this.props.data };
    data[type] = val;
    this.props.handleChange(data);
  };

  handleAddRefprovenanceUrl = () => {
    let { newprovenanceurl } = this.state;
    let { data, onHandleRefChange } = this.props;
    if (newprovenanceurl.url.length > 5) {
      // if (!data.editor_provenance_urls) {
      //   data.editor_provenance_urls = []
      // }
      data.editor_provenance_urls.push(newprovenanceurl);
      this.props.handleChange(data);
      onHandleRefChange();
      this.setState({
        newprovenanceurl: {
          url: "",
          // source_name: "",
          published_date: "",
          access_date: moment(new Date()).format("YYYY-MM-DD"),
          user_or_author: "",
          platform_name: ""
        },
      });
    }
  };
  handleRemoveRefprovenanceUrl = (index) => {
    let { data, onHandleRefChange } = this.props;
    this.setState({ deletedRefprovenance: data.editor_provenance_urls[index].url });
    data.editor_provenance_urls.splice(index, 1);
    this.props.handleChange(data);
    onHandleRefChange();
  };

  handleAddRef = () => {
    let { newrefurl } = this.state;
    let { data, onHandleRefChange } = this.props;
    if (newrefurl.url.length > 5) {
      data.editor_reference_urls.push(newrefurl);
      this.props.handleChange(data);
      onHandleRefChange();
      this.setState({
        newrefurl: {
          url: "",
          source: "",
          source_name: "",
          published_date: "",
          access_date: moment(new Date()).format("YYYY-MM-DD"),
          offensive_content: false,
        },
      });
    }
  };
  handleRemoveRef = (index) => {
    let { data, onHandleRefChange } = this.props;
    this.setState({ deletedRef: data.editor_reference_urls[index].url });
    data.editor_reference_urls.splice(index, 1);
    this.props.handleChange(data);
    onHandleRefChange();
  };

  appendReference = (referenceData) => {
    const newrefurl = {
      url: referenceData.topk_url,
      source: "",
      source_name: referenceData.publisher,
      published_date: referenceData.published_date,
      access_date: moment(new Date()).format("YYYY-MM-DD"),
      offensive_content: false,
    };
    this.props.onHandleRefChange();
    this.setState({ newrefurl }, () => this.handleAddRef());
  };
  appendReferenceprovenanceUrl = (referenceData) => {
    const newprovenanceurl = {
      url: referenceData.topk_url,
      // source_name: referenceData.publisher,
      published_date: referenceData.published_date,
      access_date: moment(new Date()).format("YYYY-MM-DD"),
      user_or_author: referenceData.user_or_author,
      platform_name: referenceData.platform_name
    };
    this.props.onHandleRefChange();
    this.setState({ newprovenanceurl }, () => this.handleAddRef());
  };

  handleBotRefDelete = (evidence) => {
    const { data } = this.props;
    const index = data.editor_reference_urls.findIndex(
      (ref) => ref.url === evidence.topk_url
    );
    if (index !== -1) this.handleRemoveRef(index);
  };

  getFeedbacks = async () => {
    const { _id: claimId } = this.props.data;
    const headers = await getHeaders()
    try {
      const url = `https://checker.logically.co.uk/api/hamlet/feedback?_id=${claimId}`
      const { data } = await axios.get(url, {
        headers: { Authorization: "Token " + localStorage.token },
      })
      this.setState({ feedback: data.data })
    } catch (error) {
      console.log(error);
    }
  }

  postFeedbacks = async (fb, fbUrls) => {
    let payload = { ...this.state.feedback }
    const { data } = this.props
    let otherUrls = []
    if (!payload.claim) {
      payload.claim = data.modified_claim || data.claim
    }
    if (!payload._id) {
      payload._id = data._id
    }
    if (fbUrls && !fb) {
      if (payload.feedback_urls) {
        otherUrls = payload.feedback_urls.filter(url => url.ai_component === (fbUrls[0].ai_component === "fact_finder" ? "evidence_retrieval" : "fact_finder"))
      }
      payload.feedback_urls = [...otherUrls, ...fbUrls]
    }
    payload.feedback_date = moment().format("YYYY-MM-DD")
    let existingFb = fb && payload.feedback_collected && payload.feedback_collected.findIndex(item => {
      return item._id === fb._id
    })
    if (existingFb >= 0) {
      //When user has selected same feedback again, delete the feedback
      if (payload.feedback_collected[existingFb].feedback_given.feedback_label === fb.feedback_given.feedback_label) {
        payload.feedback_collected.splice(existingFb, 1)
      } else {
        payload.feedback_collected[existingFb] = fb
      }
    } else {
      if (!payload.feedback_collected) {
        payload.feedback_collected = []
      }
      fb && payload.feedback_collected.push(fb)
    }
    try {
      const url = "https://checker.logically.co.uk/api/hamlet/feedback"
      let response = await axios.post(url, payload, {
        headers: { Authorization: "Token " + localStorage.token },
      })

      if (response) {
        this.setState({ feedback: payload })
      }
    } catch (error) {
      console.log(error);
    }
  }
  handleGetAssociatedGroup = (group_id) => {
    const { data } = this.props
    const claimToSubmit = { group_id: group_id, _id: data._id, lang_code: data.lang_code, }
    if (group_id) {
      axios.get(getBase(ApiConstants.GROUP), { params: claimToSubmit, headers: headers }).then(res => {
        if (res.data.data.associated_factchecks && res.data.data.associated_factchecks.length === 0) {
          this.setState({ group_id: '', associated_factchecks: res.data.data.associated_factchecks })
        } else {
          this.setState({ group_id: group_id, associated_factchecks: res.data.data.associated_factchecks })
        }
        // we will get associated claims in response

      }).catch(err => console.log(err))
    }
  }
  handleCreateGroup = () => {
    const { data } = this.props
    const dataToSend = {
      title: data.claim,
      lang_code: data.lang_code,
      _id: data._id
    }
    axios.post(getBase(ApiConstants.GROUP), dataToSend, { headers: headers }).then(res => {

      // add group_id to main props
      this.setState({ group_id: res.data.data.group_id, associated_factchecks: res.data.data.associated_factchecks })
      this.handleChange(res.data.data.group_id, "group_id")
      // we will get associated claims in response
      toast.success('Group Created', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }).catch(err => {
      toast.error('Group Creation failed', {
        position: toast.POSITION.TOP_RIGHT,
      })
      console.log(err)
    })
  }
  handleRemoveClaim = (id) => {
    const { data } = this.props
    const { group_id } = this.state
    const data1 = { _id: id, group_id: group_id }
    axios.put(getBase(ApiConstants.GROUP), data1, { headers: headers }).then(res => {
      if (data._id === id) {
        this.setState({ group_id: '', associated_factchecks: [] }, () => {
          this.handleChange('', "group_id")
        })
      } else {
        this.handleGetAssociatedGroup(group_id)
      }
      toast.success('Claim removed from Group', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }).catch(e => {
      toast.error('Something went wrong while removing the claim', {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
  }
  handleAddToGroup = () => {
    const { group_id } = this.state
    const { data } = this.props
    const claimToSubmit = { group_id: group_id, _id: data._id, lang_code: data.lang_code, }
    axios.get(getBase(ApiConstants.GROUP), { headers: headers, params: claimToSubmit }).then(res => {
      toast.success('Added to Group', {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.handleChange(group_id, "group_id")
      this.setState({ loading: false, associated_factchecks: res.data.data.associated_factchecks })
      // this.handleGetAssociatedGroup(group_id)
    }).catch(e => {
      console.log('handleAddToGroup', e)
      toast.error('Soemthing went wrong', {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({ loading: false })
    });
  };
  handleCopyGroupId = () => {
    navigator.clipboard.writeText(this.props.data.group_id).then(() => {
      toast.success('Copied to clipboard', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }).catch(e => {
      toast.error('Copied to clipboard failed', {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
  }
  moveUpReferenceUrl = (index) => {
    const { data: { editor_reference_urls } } = this.props
    const now = editor_reference_urls[index];
    const prev = editor_reference_urls[index - 1];
    editor_reference_urls[index] = prev;
    editor_reference_urls[index - 1] = now
    this.setState({ editor_reference_urls: editor_reference_urls })

  }
  moveDownReferenceUrl = (index) => {
    const { data: { editor_reference_urls } } = this.props
    const now = editor_reference_urls[index];
    const next = editor_reference_urls[index + 1];
    editor_reference_urls[index] = next;
    editor_reference_urls[index + 1] = now
    this.setState({ editor_reference_urls: editor_reference_urls })
  }
  moveUpprovenanceUrl = (index) => {
    const { data: { editor_provenance_urls } } = this.props
    const now = editor_provenance_urls[index];
    const prev = editor_provenance_urls[index - 1];
    editor_provenance_urls[index] = prev;
    editor_provenance_urls[index - 1] = now
    this.setState({ editor_provenance_urls: editor_provenance_urls })

  }
  moveDownprovenanceUrl = (index) => {
    const { data: { editor_provenance_urls } } = this.props
    const now = editor_provenance_urls[index];
    const next = editor_provenance_urls[index + 1];
    editor_provenance_urls[index] = next;
    editor_provenance_urls[index + 1] = now
    this.setState({ editor_provenance_urls: editor_provenance_urls })
  }
  render() {
    const { newrefurl, deletedRef, feedback, group_id, associated_factchecks, loading, editor_provenance_urls, newprovenanceurl } = this.state;
    const { classes, data, sourcesByCode, location } = this.props;
    return (
      <Grid container className={classes.root}>
        {data.type !== "image" && (
          <>
            <Grid item xs={12}>
              {/* <Paper className={classes.paperFact}> */}
              <FactFinder trackingId={data.tracking_id} data={data} classes={classes} feedback={feedback} postFeedbacks={this.postFeedbacks} />
              {/* </Paper> */}
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paperEvidence}>
                <Grid>
                  <Evidences
                    trackingId={data.tracking_id}
                    _id={data._id}
                    claimObj={data}
                    appendReference={this.appendReference}
                    onBotRefDelete={this.handleBotRefDelete}
                    deletedRef={deletedRef}
                    feedback={feedback}
                    postFeedbacks={this.postFeedbacks}
                  />
                </Grid>
              </Paper>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: 10,
              fontWeight: 600,
              fontSize: 15,
              fontStyle: "Muli",
            }}
          >
            Topics{" "}
          </Typography>
          <Paper className={classes.paper}>
            <Grid container className={classes.main} spacing={16}>
              <Grid item xs={12} md={12}>
                <Typography
                  style={{ fontWeight: 600 }}
                  variant="body2"
                  gutterBottom
                >
                  Add topics
                </Typography>
                <TagSearch
                  classes={classes}
                  setFilters={(selected) => this.handleChange(selected, "topics")}
                  selected={data.topics || []}
                  type={2}
                  name='category'
                />
                {data.topics && data.topics.length > 0 && <>
                  <Typography
                    style={{ fontWeight: 600, paddingTop: 16 }}
                    variant="body2"
                    gutterBottom
                  >
                    Selected Topics ({data.topics.length})
                  </Typography>
                  {data.topics.map((chip, index) => {
                    return (
                      <Chip
                        key={index}
                        label={chip}
                        onDelete={this.handleDelete(chip, 'topics')}
                        className={classes.chip}
                        deletableColorPrimary="primary"
                      />
                    );
                  })
                  }
                </>
                }
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: 10,
              fontWeight: 600,
              fontSize: 15,
              fontStyle: "Muli",
            }}
          >
            Groups{" "}
          </Typography>
          <Paper className={classes.paper}>

            <Grid container className={classes.main} spacing={16}>
              {group_id && associated_factchecks.length ? <Grid item xs={12} md={12}>
                <Typography
                  style={{ fontWeight: 600 }}
                  variant="body2"
                  gutterBottom
                >
                  Group ID
                </Typography>

                <Grid
                  container
                  style={{
                    flex: 1,
                    paddingLeft: 10,
                    border: "1px solid #ced4da",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <InputBase
                    placeholder={`eg LAN-${data.tracking_id}`} value={data.group_id}
                    onChange={(e) => this.setState({ group_id: e.target.value })}
                    style={{ flex: 1, paddingLeft: 10 }}
                    classes={{
                      root: classes.nestedInputRoot,
                    }}
                  />
                  <Button color="secondary" type="button" onClick={this.handleCopyGroupId}>Copy ID</Button>
                </Grid>
              </Grid> : <> <Grid item xs={12} md={12} >
                <Typography
                  style={{ fontWeight: 600 }}
                  variant="body2"
                  gutterBottom
                >
                  Create group
                </Typography>
              </Grid>
                <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                  <button type="button" className="save-button w-max" onClick={this.handleCreateGroup}>Create group</button>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: 600 }}
                    variant="body2"
                    gutterBottom
                  >
                    Add to group
                  </Typography>
                  <div>
                    <Grid
                      container
                      style={{
                        flex: 1,
                        paddingLeft: 10,
                        border: "1px solid #ced4da",
                        borderRadius: "5px",
                        marginBottom: "20px",
                      }}
                    >
                      <InputBase
                        placeholder={`eg LAN-${data.tracking_id}`} value={group_id}
                        onChange={(e) => this.setState({ group_id: e.target.value })}
                        style={{ flex: 1, paddingLeft: 10 }}
                        classes={{
                          root: classes.nestedInputRoot,
                        }}
                      />
                      <button className="save-button w-max" onClick={this.handleAddToGroup}>
                        Add to group
                      </button>
                    </Grid>

                  </div>
                </Grid> </>}

              {loading && <LinearProgress color="secondary" style={{ width: 'inherit' }} />}

              {group_id && associated_factchecks.length ? <Grid item xs={12}>
                <Typography
                  style={{ fontWeight: 600 }}
                  variant="body2"
                  gutterBottom>Fact checks added to the group:</Typography>
                <Table style={{ border: '1px solid rgb(206, 212, 218)', borderRadius: 5, maxHeight: 100, overflow: 'auto' }}>
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      <TableCell colSpan={8}>Claim Id</TableCell>
                      <TableCell colSpan={2}>Language</TableCell>
                      <TableCell colSpan={2}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {associated_factchecks.map(item => {
                      return <TableRow key={item.claim_id}>
                        <TableCell colSpan={8}>
                          <a href={`/claim/${item.claim_id}`}
                            target="_blank"
                            style={{ color: this.props.location ? this.props.location.pathname.split('/')[2] === item.claim_id ? "red" : 'black' : 'black' }}
                            rel="noopener noreferrer">{item.claim_id}</a></TableCell>
                        <TableCell colSpan={2}>{item.language}</TableCell>
                        <TableCell colSpan={2}>
                          <LoadingCircular id={item.claim_id} handleRemoveClaim={(id) => this.handleRemoveClaim(id)} />
                        </TableCell>
                      </TableRow>
                    })}
                  </TableBody>
                </Table>
              </Grid>
                : <></>
              }
            </Grid>
          </Paper>

        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: 10,
              fontWeight: 600,
              fontSize: 15,
              fontStyle: "Muli",
            }}
          >
            Tags and Annotations{" "}
          </Typography>
          <Paper className={classes.paper}>
            <Grid container className={classes.main} spacing={16}>
              <Grid item xs={12} md={12}>
                <Typography
                  style={{ fontWeight: 600 }}
                  variant="body2"
                  gutterBottom
                >
                  Tags
                </Typography>
                <TagSearch
                  classes={classes}
                  setFilters={(selected) => this.handleChange(selected, "tags")}
                  selected={data.tags}
                  type={2}
                  name='tags'
                />

                {data.tags.map((chip, index) => {
                  return (
                    <Chip
                      key={index}
                      label={chip}
                      onDelete={this.handleDelete(chip, 'tag')}
                      className={classes.chip}
                      deletableColorPrimary="primary"
                    />
                  );
                })}
              </Grid>
            </Grid>

            <Grid item xs={1} md={1}></Grid>
            <Grid container className={classes.main} spacing={16}>
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  className={classes.dropdowns}
                  style={{ display: "flex" }}
                >
                  <Grid item md={6} style={{ flex: 1 }}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ flex: 1, marginTop: 10, fontWeight: 600 }}
                    >
                      Types of claim
                    </Typography>
                    <FormControl className={classes.margin}>
                      <NativeSelect
                        value={data.claim_type || ""}
                        onChange={(e) =>
                          this.handleChange(e.target.value, "claim_type")
                        }
                        input={
                          <BootstrapInput
                            name="claim"
                            id="claim-customized-native-simple"
                          />
                        }
                      >
                        <option value=""></option>
                        <option value={"Non-numerical prediction"}>
                          Non-numerical prediction
                        </option>
                        <option value={"Numerical prediction"}>
                          Numerical prediction
                        </option>
                        <option value={"Quantity in the past and present"}>
                          Quantity in the past and present
                        </option>
                        <option value={"Current laws or rules of operation"}>
                          Current laws or rules of operation
                        </option>
                        <option value={"Correlation and causation"}>
                          Correlation and causation
                        </option>
                        <option value={"Occurance and non-occurance"}>
                          Occurance and non-occurance
                        </option>
                        <option value={"Other claim"}>Other claim</option>
                        <option value={"Other sentence"}>Other sentence</option>
                        <option value={"Personal experience"}>
                          Personal experience
                        </option>
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} style={{ flex: 1 }}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ flex: 1, marginTop: 10, fontWeight: 600 }}
                    >
                      Beneficiary
                    </Typography>
                    <FormControl className={classes.margin}>
                      <NativeSelect
                        value={data.beneficiary || ""}
                        onChange={(e) =>
                          this.handleChange(e.target.value, "beneficiary")
                        }
                        input={
                          <BootstrapInput
                            name="beneficiary"
                            id="beneficiary-customized-native-simple"
                          />
                        }
                      >
                        <option value=""></option>
                        <option value={"no beneficiary applicable"}>
                          no beneficiary applicable
                        </option>
                        <option value={"BHARATIYA JANATA PARTY. (BJP)"}>
                          BHARATIYA JANATA PARTY. (BJP)
                        </option>
                        <option value={"BAHUJAN SAMAJ PARTY. (BSP)"}>
                          BAHUJAN SAMAJ PARTY. (BSP)
                        </option>
                        <option value={"INDIAN NATIONAL CONGRESS. (INC)"}>
                          INDIAN NATIONAL CONGRESS. (INC)
                        </option>
                        <option value={"NATIONALIST CONGRESS PARTY. (NCP)"}>
                          NATIONALIST CONGRESS PARTY. (NCP)
                        </option>
                        <option value={"COMMUNIST PARTY OF INDIA. (CPI)"}>
                          COMMUNIST PARTY OF INDIA. (CPI)
                        </option>
                        <option
                          value={"COMMUNIST PARTY OF INDIA - MARXIST.(CPI-M)"}
                        >
                          COMMUNIST PARTY OF INDIA - MARXIST.(CPI-M)
                        </option>
                        <option value={"AAM AADMI PARTY. (AAP)"}>
                          AAM AADMI PARTY. (AAP)
                        </option>
                        <option value={"RASHTRIYA JANATA DAL. (RJD)"}>
                          RASHTRIYA JANATA DAL. (RJD)
                        </option>
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                </Grid>
                <p></p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  style={{ fontWeight: 600 }}
                  className={classes.notes}
                  variant="body2"
                  gutterBottom
                >
                  Notes
                </Typography>
                <TextField
                  id="outlined-full-width"
                  placeholder="Text editor column with options to style"
                  className={classes.textField}
                  fullWidth
                  multiline
                  value={data.notes || ""}
                  onChange={(e) => this.handleChange(e.target.value, "notes")}
                  rows="7"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: 10,
              fontWeight: 600,
              fontSize: 15,
              fontStyle: "Muli",
            }}
          >
            Reference
          </Typography>
          <Paper className={classes.paper}>
            {this.state.editor_reference_urls.map((refUrl, index) => {
              return (
                <div key={index}>
                  <ReferenceURL
                    name={"References URL"}
                    keys={index + 1}
                    currentIndex={index + 1}
                    totalLength={this.state.editor_reference_urls.length}
                    classes={classes}
                    addnew={false}
                    moveUp={(data) => this.moveUpReferenceUrl(data)}
                    moveDown={(data) => this.moveDownReferenceUrl(data)}
                    data={refUrl}
                    handleChange={(da) => this.setState({ newrefurl: da })}
                    handleRemove={() => this.handleRemoveRef(index)}
                    lang_code={data.lang_code}
                    sourcesByCode={sourcesByCode}
                  />
                </div>
              );
            })}
            <ReferenceURL
              name={"References URL"}
              classes={classes}
              addnew={true}
              data={newrefurl}
              handleChange={(da) => this.setState({ newrefurl: da })}
              handleAdd={this.handleAddRef}
              lang_code={data.lang_code}
              sourcesByCode={sourcesByCode}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: 10,
              fontWeight: 600,
              fontSize: 15,
              fontStyle: "Muli",
            }}
          >
            Provenance URL
          </Typography>
          <Paper className={classes.paper} style={{ backgroundColor: '#eaf3fe' }}>
            {editor_provenance_urls.map((refUrl, index) => {
              return (
                <div key={index}>
                  <ReferenceURL
                    name={"Provenance URL"}
                    keys={index + 1}
                    currentIndex={index}
                    totalLength={editor_provenance_urls.length}
                    classes={classes}
                    addnew={false}
                    moveUp={(data) => this.moveUpprovenanceUrl(data)}
                    moveDown={(data) => this.moveDownprovenanceUrl(data)}
                    data={refUrl}
                    handleChange={(da) => this.setState({ newprovenanceurl: da })}
                    handleRemove={() => this.handleRemoveRefprovenanceUrl(index)}
                  />
                </div>
              );
            })}
            <ReferenceURL
              name={"Provenance URL"}
              classes={classes}
              addnew={true}
              data={newprovenanceurl}
              handleChange={(da) => this.setState({ newprovenanceurl: da })}
              handleAdd={this.handleAddRefprovenanceUrl}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
class ReferenceURL extends Component {
  state = {
    credibility: false,
    source_url: "",
  };

  componentDidMount() {
    const { data } = this.props;
    //  const data = this.props.data ? this.props.data : this.props.location.query.dataFromCard;
    if (data.url.length > 0) this.getCredibility(data.url);
  }

  handleChange = (val, type) => {
    let data = this.props.data;
    data[type] = val;
    this.props.handleChange(data);
  };

  nameofsource = () => {
    let source_url;
    let data = this.props.data;
    source_url = data.url
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
      .split("/")[0]
      .split(".")[0];
    data.source_name = source_url;
    this.props.handleChange(data);
  };

  handleCheckBox = (val, type) => {
    if (val === false) {
      val = true;
    } else if (val === true) {
      val = false;
    }
    this.handleChange(val, type);
  };

  getCredibility = async (url) => {
    try {
      const { data } = await axios.post(
        getBase(api.CREDIBILITY),
        { _id: 1234, url: encodeURI(url) },
        { headers: { Authorization: "Token " + localStorage.token } }
      );
      this.setState({ credibility: data.data.credibility_label }, () => {
        // this.nameofsource();
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleLinkChange = (val) => {
    let data = this.props.data;
    data.url = val;
    if (val.length > 0) this.getCredibility(val);
    this.setState({ credibility: false });
    this.props.handleChange(data);
    if (val.length > 5) {
      axios
        .get("/apiv2/metadata?url=" + encodeURIComponent(val))
        .then((res) => {
          if (res.data.jsonLd) {
            if (res.data.jsonLd.datePublished) {
              data.published_date = moment(
                new Date(res.data.jsonLd.datePublished)
              ).format("YYYY-MM-DD");
            }
          }
          if (res.data.openGraph) {
            if (res.data.openGraph.site_name) {
              data.source_name = res.data.openGraph.site_name;
            }
            if (res.data.openGraph.published_time) {
              data.published_date = moment(
                new Date(res.data.openGraph.published_time)
              ).format("YYYY-MM-DD");
            }
          }
          this.props.handleChange(data);
        });
    }
    this.nameofsource();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ credibility: false });
    this.props.handleAdd();
  };

  sourcesByCodeFnc = (lang_code) => {
    const { sourcesByCode } = this.props
    if (sourcesByCode[lang_code]) {
      return sourcesByCode[lang_code]
    }
    return sourcesByCode['en']
  }

  render() {
    const { classes, addnew, keys, data, currentIndex, totalLength, name, lang_code } = this.props;
    const { credibility } = this.state;
    // const data = this.props.data ? this.props.data : this.props.location.query.dataFromCard;
    return (
      <form onSubmit={this.handleSubmit}>
        <Grid
          container
          spacing={24}
          className={classes.ref}
          alignItems="center"
          style={{
            // backgroundColor: addnew ? "#FFFFF" : "#fff",
            marginTop: 8,
            marginBottom: 15,
          }}
        >
          <Grid item xs={10} md={10} style={{ marginTop: -16 }}>
            <Typography style={{ fontWeight: 600, alignItems: 'center', display: 'flex' }} className={classes.refURL}>
              {name} {keys && <span style={{ paddingLeft: "5px" }}>{keys}</span>}{" "} {currentIndex > 0 && <IconButton onClick={() => this.props.moveUp(currentIndex)}><ExpandLess /></IconButton>}
              {totalLength && totalLength - 1 !== currentIndex && <IconButton onClick={() => this.props.moveDown(currentIndex)}>
                <ExpandMore />
              </IconButton>}
            </Typography>
            <TextField
              id="outlined-full-width"
              className="nounderline"
              style={{
                width: "100%",
                fontFamily: "Mulish",
                border: "1px solid darkgrey",
                borderRadius: "5px",
                display: "flex",
                position: "relative",
                padding: "3px 5px",
                overflow: "hidden",
              }}
              fullWidth
              multiline
              value={data.url}
              onChange={(e) => this.handleLinkChange(e.target.value, "url")}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {!addnew && name === 'Provenance URL' && (
            <Grid item xs={1} md={1} style={{ textAlign: "center" }}>
              <Button onClick={this.props.handleRemove}>
                <DeleteForeverIcon />
              </Button>
            </Grid>
          )}
          <Grid item xs={1} md={1}>
            {addnew && name === 'Provenance URL' && (
              <Button type="submit">
                <AddCircleIcon />
              </Button>
            )}
          </Grid>
          {credibility && (
            <div style={{ marginTop: -16 }}>
              <Typography style={{ marginLeft: "10px" }}>
                Credibility
              </Typography>
              <div
                style={{
                  backgroundColor: "#3FC380",
                  color: "#fff",
                  padding: "8px",
                  marginLeft: "10px",
                  borderRadius: "5px",
                  textTransform: "capitalize",
                  textAlign: "center",
                }}
              >
                {credibility}
              </div>
            </div>
          )}
        </Grid>
        <Grid
          container
          spacing={24}
          className={classes.ref}
          alignItems="center"
          style={{
            // backgroundColor: addnew ? "#FFFFF" : "#fff",
            marginTop: 8,
            marginBottom: 15,
          }}
        >
          {name !== 'Provenance URL' && <Grid
            item
            xs={3}
            md={3}
            style={{ marginTop: 0, display: "flex", flexDirection: "column" }}
          >
            <Typography style={{ fontWeight: 600 }}>
              Name of the source
            </Typography>
            <TextField
              id="outlined-full-width"
              className="nounderline"
              style={{
                width: "100%",
                border: "1px solid darkgrey",
                height: 33,
                borderRadius: "5px",
                display: "flex",
                position: "relative",
                padding: "3px 5px",
              }}
              fullWidth
              multiline
              rows="1"
              value={data.source_name}
              // value={this.nameofsource() || data.source_name}
              onChange={(e) => this.handleChange(e.target.value, "source_name")}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>}
          {name === 'Provenance URL' && <Grid
            item
            xs={3}
            md={3}
            style={{ marginTop: 0, display: "flex", flexDirection: "column" }}
          >
            <Typography style={{ fontWeight: 600 }}>
              Name of the platform
            </Typography>
            <TextField
              id="outlined-full-width"
              className="nounderline"
              style={{
                width: "100%",
                border: "1px solid darkgrey",
                height: 33,
                borderRadius: "5px",
                display: "flex",
                position: "relative",
                padding: "3px 5px",
              }}
              fullWidth
              multiline
              rows="1"
              value={data.platform_name}
              // value={this.nameofsource() || data.platform_name}
              onChange={(e) => this.handleChange(e.target.value, "platform_name")}
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>}
          {name === 'Provenance URL' && <Grid
            item
            xs={3}
            md={3}
            style={{ marginTop: 0, display: "flex", flexDirection: "column" }}
          >
            <Typography style={{ fontWeight: 600 }}>
              User/Author
            </Typography>
            <TextField
              id="outlined-full-width"
              className="nounderline"
              style={{
                width: "100%",
                border: "1px solid darkgrey",
                height: 33,
                borderRadius: "5px",
                display: "flex",
                position: "relative",
                padding: "3px 5px",
              }}
              fullWidth
              multiline
              rows="1"
              value={data.user_or_author}
              // value={this.nameofsource() || data.user_or_author}
              onChange={(e) => this.handleChange(e.target.value, "user_or_author")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>}
          {name !== 'Provenance URL' && <Grid item xs={3} md={3} className={classes.menuData}>
            <Typography style={{ marginTop: 15, fontWeight: 600 }}>
              Source{" "}
            </Typography>
            <FormControl className={classes.menuList}>
              <NativeSelect
                value={data.source}
                required
                onChange={(e) => this.handleChange(e.target.value, "source")}
                input={
                  <BootstrapInput name="source" id="customized-native-simple" />
                }
              >
                <option value={""}>Select</option>
                {this.sourcesByCodeFnc(lang_code).map(item => {
                  return <option value={item} key={item}>{item}</option>
                })}
              </NativeSelect>
            </FormControl>
          </Grid>}
          <Grid item xs={3} md={3}>
            <Typography style={{ fontWeight: 600 }}>Published date </Typography>
            <TextField
              id="outlined-full-width"
              className="nounderline"
              style={{
                width: "100%",
                border: "1px solid darkgrey",
                height: 33,
                borderRadius: "5px",
                display: "flex",
                position: "relative",
                padding: "3px 5px",
              }}
              fullWidth
              type="date"
              value={data.published_date}
              onChange={(e) =>
                this.handleChange(e.target.value, "published_date")
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {name !== 'Provenance URL' && <Grid
            item
            xs={1}
            md={1}
            className={classes.menuData}
            style={{ textAlign: "center", padding: 0, marginTop: "-2%" }}
          >
            <Typography style={{ fontWeight: 600, fontSize: "smaller" }}>
              Offensive Content{" "}
            </Typography>
            <Checkbox
              checked={data.offensive_content ? true : false}
              onChange={(e) =>
                this.handleCheckBox(
                  data.offensive_content ? true : false,
                  "offensive_content"
                )
              }
              value={data.offensive_content}
            />
          </Grid>}

          {!addnew && name !== 'Provenance URL' && (
            <Grid item xs={1} md={1} style={{ textAlign: "center" }}>
              <Button onClick={this.props.handleRemove}>
                <DeleteForeverIcon />
              </Button>
            </Grid>
          )}
          {!addnew && <hr style={{ width: "100%" }}></hr>}
          <Grid item xs={1} md={1}>
            {addnew && name !== 'Provenance URL' && (
              <Button type="submit">
                <AddCircleIcon />
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return { sourcesByCode: state.sourcesByCode }
}

export default connect(mapStateToProps)(withStyles(styles)(NotesEditors));
