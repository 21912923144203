import { Typography } from '@material-ui/core'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const plugins = [
    {
        afterDraw: function (chart) {
            if (chart.data.datasets[0].data.every(item => item === 0)) {
                let ctx = chart.ctx;
                let width = chart.width;
                let height = chart.height;
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "25px Arial";
                ctx.fillText("No data to display", width / 2, height / 2);
                ctx.restore();
            }
        },
    },
];


export default function DonutChart({ username, label1a, label1b, label2a, label2b, label3a, label3b, label4a, label4b, label5a, label5b, label6a, label6b }) {
    const dataArray = [label1b, label2b, label3b, label4b, label5b, label6b]
    const noData = dataArray.every(e => e === 0)

    return (
        <div>
            <Typography variant="h6" style={{ textAlign: "center" }}>{username}</Typography>
            <div >
                <div style={{ textAlign: 'center' }}>
                    <Doughnut
                        data={{
                            labels: [`${label1a}: ${label1b}`, `${label2a}: ${label2b}`, `${label3a}: ${label3b}`, `${label4a}: ${label4b}`, `${label5a}: ${label5b}`, `${label6a}: ${label6b}`],
                            datasets: [
                                {
                                    data: dataArray,
                                    backgroundColor: [
                                        'rgba(67,103,248)',
                                        'rgba(217,69,208)',
                                        'rgba(0,192,199)',
                                        'rgba(255,166,0)',
                                        'rgba(255,108,76)',
                                        'rgba(71,226,111)',
                                    ],
                                    // borderColor: [
                                    //     'rgba(67,103,248, 1)',
                                    //     'rgba(239, 83, 80, 1)',
                                    //     'rgba(255, 206, 86, 1)',
                                    //     'rgba(255, 238, 88, 1)',
                                    //     'rgba(255, 167, 38, 1)',
                                    //     'rgba(102, 187, 106, 1)',
                                    // ],
                                    borderWidth: 1,
                                },
                            ],
                        }}
                        height={300}
                        width={300}
                        plugins={plugins}
                        options={
                            {
                                maintainAspectRatio: false,
                                legend: {
                                    display: false,
                                },
                                plugins: {
                                    tooltip: {
                                        callbacks: {
                                            label: function (tooltipItem) {
                                                return tooltipItem.label;
                                            },
                                        },
                                    },
                                    legend: {
                                        display: !noData
                                    }
                                },
                            }}
                    />
                </div>
            </div>
        </div>
    )
}

