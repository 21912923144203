import { Button, Menu, MenuItem, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import VerdictsTable from './VerdictsTable'
import { getAllLanguage } from '../utils/language';
import api, { getBase } from '../api';
import axios from 'axios';
import { toast } from 'react-toastify';


const noneVerdicts = { verdictChanged: ['None', 'None', 'None', 'None', 'None', 'None'] };
export class Verdicts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            verdictByCode: {},
            languageSelection: {},
            anchorEl: null
        }
    }
    componentDidMount() {
        this.getVerdicts()
    }
    getVerdicts = () => {
        axios.get(getBase(api.VERDICTS), {
            headers: { Authorization: "Token " + localStorage.token }
        }).then(res => {
            this.setState({ verdictByCode: res.data.data[0].verdictByCode, isModalOpen: false })
        }).catch(e => {
            toast.error('There was an error please try again', {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.log(e)
        })
    }
    updateVerdict = (data, role) => {
        axios.post(`/verdicts`, { verdictByCode: data }, {
            headers: { Authorization: "Token " + localStorage.token }
        }).then(res => {
            this.getVerdicts()
            toast.success(`Verdict was ${role === 'update' ? 'added' : 'deleted'} successfully`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }).catch(e => {
            toast.error('There was an error please try again', {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.log(e)
        })
    }
    handleUpdateVerdict = (data, code) => {
        const { verdictByCode } = this.state
        const { verdictChanged } = data;
        let temp = { ...verdictByCode }
        temp[code] = verdictChanged
        this.updateVerdict(temp, 'update')
    }
    handleDeleteVerdict = (verdictCode) => {
        const { verdictByCode } = this.state
        let temp = { ...verdictByCode };
        delete temp[verdictCode]
        this.updateVerdict(temp, 'delete')
    }
    handleDropdown = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleClose = (item) => {
        this.setState({ languageSelection: item }, () => {
            this.setState({ anchorEl: null });
        })
    }
    render() {
        const { isModalOpen, verdictByCode, languageSelection, anchorEl } = this.state
        return (
            <div className='main'>
                <Paper style={{ padding: 16 }}>
                    <div className='card-flex'>
                        <Typography align='center' variant={'h5'}>
                            Verdicts
                        </Typography>
                        <button onClick={() => this.setState({ isModalOpen: true })} className='save-button w-max'>
                            Add Verdict
                        </button>
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Index</TableCell>
                                <TableCell>Language</TableCell>
                                <TableCell>Language Code</TableCell>
                                <TableCell>Verdict</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(verdictByCode).map((item, index) => {
                                return <VerdictsTable key={item}
                                    data={verdictByCode[item]} code={item} index={index} handleUpdateVerdict={(data) => this.handleUpdateVerdict(data, item)}
                                    handleDeleteVerdict={() => this.handleDeleteVerdict(item)}
                                />
                            })
                            }
                        </TableBody>
                    </Table>
                    <Modal open={isModalOpen}
                        onClose={() => { this.setState({ isModalOpen: false }) }}>
                        <div className='modal-center'>
                            <Paper style={{ padding: 16 }}>
                                <Typography style={{ fontWeight: 'bold' }}>Add New Verdict</Typography>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <div className='flex gap align-center'>
                                                    <Typography style={{ fontWeight: 'bold' }}>Select Language:</Typography>

                                                    <Button
                                                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={this.handleDropdown}
                                                        style={{ fontWeight: 600 }}
                                                    >
                                                        {languageSelection.name || "None"}
                                                    </Button>
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={anchorEl}
                                                        open={Boolean(anchorEl)}
                                                    >
                                                        {getAllLanguage().map(item => {
                                                            return <MenuItem onClick={() => this.handleClose(item)} key={item.name}>
                                                                {item.name}
                                                            </MenuItem>
                                                        })}

                                                    </Menu>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className='flex gap align-items'>
                                                    <Typography style={{ fontWeight: 'bold' }}>Selected Language Code:</Typography>
                                                    <Typography>{languageSelection.code || "None"}</Typography>

                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <div className='flex gap align-items'>
                                                    <Typography style={{ fontWeight: 'bold', color: '#f62459' }}>Note:</Typography>
                                                    <Typography>
                                                        Verdict should be added from update verdict flow
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        {languageSelection.code && <TableRow>
                                            <TableCell colSpan={2}>
                                                <div className='flex' style={{ justifyContent: 'center' }}>
                                                    <button className='save-button w-max' onClick={() => this.handleUpdateVerdict(noneVerdicts, languageSelection.code)}>Add Language for Verdict</button>

                                                </div>
                                            </TableCell>
                                        </TableRow>}
                                    </TableBody>

                                </Table>
                            </Paper>
                        </div>
                    </Modal>
                </Paper>
            </div>
        )
    }
}

export default Verdicts