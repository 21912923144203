import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reducer from "./store/reducer";
import { composeWithDevTools } from "redux-devtools-extension";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#f8f8f8",
      main: "#050E2B",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      error: "#E52329",
    },
    secondary: {
      light: "#7CC78F",
      main: "#f62459",
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
      error: "#E52329",
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: "Mulish, sans-serif, Lohit-Kannada, Lohit-Assamese",
  },
});

const composeEnhancers = composeWithDevTools({
  // actionCreators,
  trace: true,
  traceLimit: 25,
});
export const store = createStore(reducer, composeEnhancers());

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
