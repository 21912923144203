const ip =
  process.env.NODE_ENV === "production" ? "10.142.0.69" : "35.196.217.202";

const ApiConstants = {
  ASSIGN_CLAIM: "api/moderator/assign_claim",
  BASE_URL: `https://checker.logically.co.uk/`,
  BASE_URL_USER: `https://thelogicallydata.co.uk/`,
  CLAIM: "api/claim/",
  CLAIM_COUNT: "api/claims/count",
  CHANGE_PASSWORD: "account/change-password",
  CREDIBILITY: "api/source_credibility",
  CREATE_CLAIM: "api/fact_checker/create_claim",
  EDITOR_COLUMN: "api/editor_column/",
  FACT_CHECKS: "api/fact_checks",
  FACT_CHECKS_ME: "api/fact_checks/me",
  FACT_CHECKS_NEW: "api/fact_checker/new_claims",
  FACT_CHECKS_ALL: "api/fact_checker/all",
  FACT_CHECKS_PENDING: "api/fact_checker/pending_auth",
  FACT_CHECKS_RESOLVED: "api/fact_checker/resolved_claims",
  FACT_CHECKS_REJECTED: "api/fact_checker/rejected_claims",
  FACT_CHECKS_DESELECT: "api/deselect_all",
  GET_TEN_FACT_CHECKS: "api/toptenfcs/",
  GET_FACT_CHECK: "api/topfcselected/",
  HERO_FACTCHECKS: "api/v1/herofcs",
  IN_PROGRESS: "api/moderator/in_progress_claims",
  IMAGE_ANALYSIS: "api/v1/image/recheck",
  LOGICALLY: `https://www.logically.co.uk/`,
  LOGIN: "account/login",
  LOGOUT: "account/logout",
  MODERATOR: "api/moderator/",
  NEW_CLAIMS: "api/moderator/new_claims",
  OVERVIEW_PERCENTAGES: "api/claims/pending/percents",
  OLD_BASE_URL: `http://${ip}/`,
  PROFILE: "account/users/profile",
  PENDING_AUTH: "api/moderator/pending_auth",
  PASSWORD_RESET: "account/forgot_password",
  PASSWORD_RESET_FINAL: "account/forgot_password_reset",
  REJECT: "api/claims/",
  RESOLVED_CLAIMS: "api/moderator/resolved_claims",
  SESSION_ID: "account/reset/get_session_id/",
  SHAREABLE_IMAGE: "api/base_sharable_image/",
  BACKGROUND_IMAGE: "api/background_image",
  SUPERVISOR: "api/supervisor/pending_claims",
  SHOWPREVIEW: "api/showpreview",
  SAVETOPFCS: "api/savetopfcs",
  SEARCH_FACTCHECKS: "api/v1/search_factchecks",
  TAGS: "api/tags",
  TOPICS: "api/get_topics",
  VERDICTS: "api/verdicts",
  SOURCES: "api/sources",
  TOPICS_WITH_ASSOCIATED_FACTCHEKCS: "api/topics",
  TOP_FACT_CHECK: "api/savetopfc",
  TOP_10_FACT_CHECK: "api/savetopfcs",
  TABLEAU: "api/tableau_auth ",
  USER: "account/user",
  USERS: "account/users",
  USER_PROFILE_APP: "api/user/details",
  GROUP: "api/group",
};

export function getBase(endpoint) {
  return ApiConstants.BASE_URL + endpoint;
}
export function getUser(endpoint) {
  return ApiConstants.BASE_URL_USER + endpoint;
}

export default ApiConstants;

