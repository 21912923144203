import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { getTimeDifference } from "./../../../utils/time";
import axios from "axios";
import api, { getBase, getUser } from "./../../../api";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FlagIcon } from "react-flag-kit";
import HtmlTooltip from "@material-ui/core/Tooltip";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { colours, getLocation } from "./../../../utils/common";
import StarRatings from "react-star-ratings";
import Filter from "../Filter/filter";
import SimpleCard from "../simpleSectionCard/simpleSectionCard";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { connect } from "react-redux";
import HeroResolvedClaims from "./HeroResolvedClaims/HeroResolvedClaims";
import { handleSort } from "./../NewClaims/NewClaims";

const theme = createMuiTheme();

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    boxshadow: 2,
  },
  paper1: {
    padding: theme.spacing.unit * 0,
    color: theme.palette.text.secondary,
  },
  fontFamily: ["Roboto"].join(","),
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
  codeText: {
    position: "relative",
  },
  paragraphText: {
    position: "relative",
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "15px",
    maxHeight: 3029,
  },
  chip: {
    position: "relative",
    fontWeight: 600,
    minWidth: `${40}%`,
    margin: 4,
  },
  textHistory: {
    fontSize: 13,
  },
  tr: {
    background: "white",
    "&:hover": {
      background: "#F1F4F8",
    },
  },
});

class ResolvedClaims extends Component {
  constructor(props) {
    super(props);
    let diff;
    var date_from = new Date();
    let year = moment(date_from).format("YYYY");
    let days = moment(date_from).format("DD");
    var a = moment([2017, 0, 1]);
    var b = moment([year, 0, days]);
    diff = a.diff(b, "days");
    date_from.setDate(date_from.getDate() + diff);
    this.state = {
      data: [],
      loading: this.props.loading,
      expanded: false,
      offset: 0,
      total: 0,
      claim_checked: [],
      selectedValue: false,
      highlights: false,
      disabled: false,
      startDate: new Date(),
      dataPreview: [],
      getDate: new Date(),
      modalIsOpen: false,
      Deselectchecked: false,
      isFilterApply: false,
      queryValue: "",
      users: [],
      pageNumbers: [],
      currentPage: 1,
      todosPerPage: 100,
      pageCount: 0,
      allData: [],
      searchVal: "",
      queryValueRange: {
        startDate: date_from,
        endDate: new Date(),
        key: "queryValue",
      },
      slection: this.props.queryValue,
      openDate: false,
      filterTabs: this.props.activeButton,
      heroFC: 1,
      checked: 1,
      country: false,
      selectedHeroFC: [],
      clearFilter: 0,
      activeButton: this.props.activeButton,
      dataSort: {
        country: "asc",
        verdict: "asc",
      },
      offline: this.props.offline,
    };
  }
  openModal = () => {
    this.setState({ modalIsOpen: true });
    sessionStorage.setItem("invisible", "true");
  };
  afterOpenModal = () => {
    this.subtitle.style.color = "#f00";
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
    sessionStorage.clear();
  };
  handleChangedate = (date) => {
    let Dates = JSON.stringify(date);
    Dates = Dates.toString()
      .replace("T", " ")
      .split(" ")[0];
    Dates = Dates.toString().replace('"', "");
    this.setState({
      getDate: Dates,
    });
  };
  clearedFilter() {
    if (this.props.clearFilter === 0) {
      if (this.props.searchData.length === 0) {
        this.setState({ clearFilter: this.props.clearFilter }, () => {
          if (this.state.data.length === 0) {
            if (this.props.queryValue === "") {
              this.getData(null);
            }
          }
        });
      }
    } else {
      if (this.props.queryValue === "") {
        this.getData(null);
      }
    }
  }
  componentDidMount() {
    this.props.onHandleClearFilters()
    this.getData(null);
  }
  componentWillReceiveProps(nextProps) {
    const { searchData, clearFilter, searchFilter } = nextProps;
    if (searchData !== this.props.searchData && searchFilter && !clearFilter) {
      this.setState({ data: searchData }, () =>
        this.props.dispatch({ type: "SET_LOADING", loading: false })
      );
    }
    if (clearFilter && !this.props.clearFilter) {
      this.props.dispatch({ type: "SET_LOADING", loading: true })
      this.getData(null);
    }
    if (nextProps.loading !== this.state.loading) {
      this.setState({ loading: nextProps.loading });
    }
    if (nextProps.activeButton !== this.state.activeButton) {
      this.setState({ activeButton: nextProps.activeButton });
    }
    if (nextProps.offline !== this.state.offline) {
      this.setState({ offline: nextProps.offline });
    }
    if (!nextProps.offline && this.state.offline) {
      this.getData(null);
    }
  }

  multiFilteredData = (val) => {
    const { searchData } = this.props;
    if (searchData.length > 0) {
      this.props.dispatch({ type: "NAVCLAIMS", navClaims: val });
    } else {
      this.getData(null);
    }
  };
  getData = async (queryValue) => {
    let {
      country,
      isFilterApply,
      filterTabs,
      offset,
      data,
      loading,
    } = this.state;

    try {
      let url = "";
      let header = "";
      if (isFilterApply) {
        url = getUser(
          api.SEARCH_FACTCHECKS +
          "?tab=completed&location=&limit=100&offset=" +
          offset +
          "&" +
          queryValue
        );
        header = { headers: {} };
      } else {
        if (filterTabs === "Multimedia claims") {
          if (country) {
            url = getBase(
              api.RESOLVED_CLAIMS +
              `?location=${country}&type=image&limit=25&offset=` +
              offset
            );
          } else {
            url = getBase(
              api.RESOLVED_CLAIMS + "?type=image&limit=25&offset=" + offset
            );
          }

          header = {
            headers: { Authorization: "Token " + localStorage.token },
          };
        } else {
          if (country) {
            url = getBase(
              api.RESOLVED_CLAIMS +
              `?location=${country}&limit=100&offset=` +
              offset
            );
          } else {
            url = getBase(
              api.RESOLVED_CLAIMS + "?location=&limit=100&offset=" + offset
            );
          }

          header = {
            headers: { Authorization: "Token " + localStorage.token },
          };
        }
      }
      const res = await axios.get(url, header);
      if (JSON.stringify(data) !== JSON.stringify(res.data.data)) {
        this.props.setBadge(res.data.total_count);
        console.log("Resolved Claims", res.data.data);
        for (let i = 1; i <= Math.ceil(res.data.total_count / 100); i++) {
          this.state.pageNumbers.push(i);
        }

        let allData = [];
        for (var i = 0; i < res.data.data.length; i++) {
          let done = false;
          for (var b = 0; b < allData.length; b++) {
            if (allData[b]._id === res.data.data[i]._id) {
              done = true;
            }
          }
          if (!done) {
            allData.push(res.data.data[i]);
          }
        }
        this.setState({
          data: allData,

          total: res.data.total_count,
        });
        this.props.dispatch({ type: "SET_LOADING", loading: false });
        let claim_checked = this.state.claim_checked;
        for (let i in res.data.data) {
          if (
            res.data.data[i].highly_rated === true &&
            moment(res.data.data[i].last_updated).format("YYYY-MM-DD")
          ) {
            claim_checked.push(res.data.data[i]._id);
          }
        }
        this.setState({
          claim_checked: claim_checked,
        });
        this.props.handleLoader(false);
      } else if (loading) {
        this.props.dispatch({ type: "SET_LOADING", loading: false });
      }
    } catch (err) {
      this.props.dispatch({ type: "SET_LOADING", loading: false });
    }
  };
  handleClicks(offset) {
    this.setState({ offset }, () => {
      this.getData(this.state.querValue);
    });
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };
  handleChangePriority = (event, id) => {
    let priorityFC = [...this.state.selectedHeroFC];
    let selectedData = [...this.state.data];
    priorityFC[event.target.value - 1] = id;
    this.setState({ selectedHeroFC: priorityFC });
    selectedData.map((claim) => {
      if (id === claim._id) {
        claim.priority = event.target.value;
      }
    });
    this.setState({ data: selectedData });
    console.log(this.state.selectedHeroFC);
  };
  handleChangeCheckBox = (index, id) => {
    let option = this.state.claim_checked;
    if (option.indexOf(id) === -1) {
      if (option.length === 10) {
        toast.error("10 Fact checks already selected", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        option.push(id);
      }
    } else {
      option.splice(option.indexOf(id), 1);
    }
    this.setState({
      claim_checked: option,
      Deselectchecked: false,
    });
    let selectedData = [...this.state.data];
    selectedData.map((claim) => {
      if (id === claim._id) {
        claim.selected = claim.selected ? false : true;
      }
    });
    this.setState({ data: selectedData });
  };
  handleChangeRadio = (indexS, id) => {
    this.setState({
      selectedValue: id,
      disabled: true,
      Deselectchecked: false,
    });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
    sessionStorage.clear();
  };
  getDataPreview = () => {
    let selectedValue = this.state.selectedValue;
    let CheckedClaim = this.state.claim_checked;
    if (selectedValue) {
      let _id = selectedValue;
      let _ids = CheckedClaim;
      let datas = {
        _id: _id,
        _ids: _ids,
        location: this.state.country,
      };
      axios
        .post(getBase(api.SHOWPREVIEW), datas, {
          headers: { Authorization: "Token " + localStorage.token },
        })
        .then((res) => {
          this.setState({
            dataPreview: res.data.data,

            modalIsOpen: true,
          });
          this.props.dispatch({ type: "SET_LOADING", loading: false });
          sessionStorage.setItem("invisible", "true");
        })
        .catch((err) => {
          toast.error("Error, could not Saved Changes", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      this.setState({
        modalIsOpen: true,
      });
      this.props.dispatch({ type: "SET_LOADING", loading: false });
    }
  };

  handleSavefromHeroFc = (selectedHeroFC, country, claim_checked) => {
    this.setState({
      selectedHeroFC,
      country,
      claim_checked,
    });
  };
  saveChanges = () => {
    console.log(this.state.selectedHeroFC);
    let CheckedClaim = this.state.selectedHeroFC;
    let datas = {
      ids: CheckedClaim,
      location: this.state.country,
    };
    if (
      CheckedClaim.length === 4 &&
      CheckedClaim[0] !== undefined &&
      CheckedClaim[1] !== undefined &&
      CheckedClaim[2] !== undefined &&
      CheckedClaim[3] !== undefined
    ) {
      // console.log('data', datas);
      axios
        .post(getUser(api.HERO_FACTCHECKS), datas, { headers: {} })
        .then((res) => {
          console.log("Save Changes" + JSON.stringify(res));
          let claim_checked = this.state.claim_checked;
          this.setState({
            claim_checked: claim_checked,
          });
          toast.success("Saved Changes successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((err) => {
          toast.error("Error, could not Save Changes", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      toast.error(
        "Warning, 4 new hero fact checks should be selected and prioritised",
        { position: toast.POSITION.TOP_RIGHT }
      );
    }
  };
  handleChangeDeselect = () => {
    if (this.state.claim_checked.length > 1) {
      for (let i = 0; i < this.state.claim_checked.length; i++) {
        if (this.state.claim_checked[i]) {
          this.setState({
            claim_checked: [],
            Deselectchecked: true,
            selectedValue: false,
          });
        }
      }
    }
  };
  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id),
    });
  }
  handleChangeSearch = (searchVal) => {
    this.setState({
      searchVal: searchVal,
    });
  };
  handleSelect = (ranges) => {
    this.setState({ selectionRange: ranges.queryValue }, this.getData);
  };
  onChangeFC = (e) => {
    this.setState({ heroFC: e.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  ifError() {
    const { loading, data } = this.state;
    const { classes } = this.props;
    if (data.length === 0 && loading === false) {
      return (
        <div className={classes.root}>
          <Typography>No records found!</Typography>
        </div>
      );
    }
    if (data === false) {
      return (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography>
            Oops, looks like something went wrong. Please try again later
          </Typography>
        </div>
      );
    }
    return null;
  }
  handleShowAcceptedClaims(val) {
    console.log("show accepted claims", val);
    this.setState({ showAcceptedClaims: val });
  }

  previewdata(claimIndex, claimID, value) {
    let selectedValue = this.state.selectedValue;
    let CheckedClaim = this.state.claim_checked;
    if (selectedValue) {
      let _id = selectedValue;
      let _ids = CheckedClaim;
      let datas = {
        _id: _id,
        _ids: _ids,
        location: this.state.country,
      };
      axios
        .post(getBase(api.SHOWPREVIEW), datas, {
          headers: { Authorization: "Token " + localStorage.token },
        })
        .then((res) => {
          this.setState({
            dataPreview: res.data.data,
            modalIsOpen: true,
          });
          this.props.dispatch({ type: "SET_LOADING", loading: false });
          sessionStorage.setItem("invisible", "true");
        })
        .catch((err) => {
          toast.error("Error, could not Saved Changes", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      this.setState({
        modalIsOpen: true,
      });
      this.props.dispatch({ type: "SET_LOADING", loading: false });
    }
  }
  reloadwindow = () => {
    window.location.reload();
  };
  render() {
    const { data, dataSort } = this.state;
    const {
      classes,
      filters,
      searchVal,
      filterOptions,
      users,
      userHashTable,
      datapoint,
      loading,
    } = this.props;
    const { buttons, dropDown, dropDownOptions } = filterOptions;
    if (Object.keys(userHashTable).length === 0) {
      return (
        <div>
          Something Seems Wrong Click{" "}
          <button
            style={{ border: 0, fontSize: 15, textDecoration: "underline" }}
            onClick={this.reloadwindow}
          >
            {" "}
            here{" "}
          </button>
          to Reload
        </div>
      );
    }

    let faster = {};
    let Complaints = {};

    return (
      <div className={classes.root}>
        <Filter
          datapoint={datapoint}
          length={data.length}
          buttons={buttons.split(",")}
          activeIndex={this.state.activeButton}
          filterbutton={(val) => {
            this.setState({ filterTabs: val }, () =>
              this.multiFilteredData(val)
            );
          }}
          countrySelected={(country) => {
            if (country !== "All") {
              this.setState({ country: country }, () => {
                this.setState({ data: [] });
                this.getData(null);
              });
            } else {
              this.setState({ country: false }, () => {
                this.getData(null);
              });
            }
          }}
          dropDown={dropDown}
          dropDownOptions={dropDownOptions}
          showingClaims={this.state.activeButton !== "Hero fact checks"}
          checkboxValue={(val) => this.handleShowAcceptedClaims(val)}
          saveChanges={this.saveChanges}
          previewChanges={this.getDataPreview}
        />
        {this.ifError()}
        {this.state.activeButton === "URL claims" &&
          !loading &&
          data.length > 0 && (
            <Grid container hover="true">
              <Paper
                className={classes.paper1}
                elevation={0}
                style={{
                  marginBottom: 16,
                  width: "100%",
                  padding: 8,
                  paddingLeft: 16,
                  paddingRight: 16,
                  backgroundColor: faster.backgroundColor,
                  border: Complaints.border,
                  borderRadius: 10,
                }}
              >
                <Grid
                  container
                  spacing={16}
                  alignItems="center"
                  style={{
                    padding: 8,
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    style={{
                      backgroundColor: "#f1f4f8",
                      borderRadius: "10px",
                      paddingTop: 15,
                      paddingBottom: 15,
                    }}
                  >
                    <Grid container alignItems="center">
                      <Grid item sm={1} md={1}>
                        <Typography>Claim ID</Typography>
                      </Grid>
                      <Grid item sm={6} md={6} style={{ paddingLeft: 20 }}>
                        <Typography>Claim</Typography>
                      </Grid>
                      <Grid item sm={1} md={1} style={{ textAlign: "center" }}>
                        <Typography
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.setState({
                              dataSort: {
                                ...dataSort,
                                country:
                                  dataSort.country === "desc" ? "asc" : "desc",
                                data: handleSort(
                                  "country",
                                  dataSort.country,
                                  data
                                ),
                              },
                            });
                          }}
                        >
                          Country{" "}
                          {dataSort.country === "asc" ? (
                            <ArrowDropUpIcon
                              style={{
                                position: "relative",
                                top: 5,
                              }}
                              size="small"
                              fontSize="small"
                            />
                          ) : (
                            <ArrowDropDownIcon
                              style={{
                                position: "relative",
                                top: 5,
                              }}
                              size="small"
                              fontSize="small"
                            />
                          )}{" "}
                        </Typography>
                      </Grid>

                      <Grid item sm={2} md={2} style={{ textAlign: "center" }}>
                        <Typography>Rating </Typography>
                      </Grid>
                      <Grid item sm={2} md={2} style={{ textAlign: "verdict" }}>
                        <Typography
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.setState({
                              dataSort: {
                                ...dataSort,
                                verdict:
                                  dataSort.verdict === "desc" ? "asc" : "desc",
                                data: handleSort(
                                  "verdict",
                                  dataSort.verdict,
                                  data
                                ),
                              },
                            });
                          }}
                        >
                          Verdict{" "}
                          {dataSort.verdict === "asc" ? (
                            <ArrowDropUpIcon
                              style={{
                                position: "relative",
                                top: 5,
                              }}
                              size="small"
                              fontSize="small"
                            />
                          ) : (
                            <ArrowDropDownIcon
                              style={{
                                position: "relative",
                                top: 5,
                              }}
                              size="small"
                              fontSize="small"
                            />
                          )}{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {data.map((claim, index) => {
                  return (
                    <Grid hover="true" container spacing={16} key={index}>
                      <Grid item lg={12} md={12}>
                        <Grid container>
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <Typography style={{ color: "white" }}>
                                  {claim.modified_claim}
                                </Typography>
                                <Typography color="inherit">
                                  FactChecker History
                                </Typography>

                                <div style={{ textTransform: "capitalize" }}>
                                  {claim.factchecker_id && userHashTable && (
                                    <em className={classes.textHistory}>
                                      <u>
                                        <b>Current Factchecker</b>
                                      </u>
                                      :
                                      {userHashTable[claim.factchecker_id]
                                        .username &&
                                        userHashTable[claim.factchecker_id]
                                          .username
                                      }
                                      {claim.factcheck_completed_at &&
                                        claim.factcheck_started_at &&
                                        (claim.factcheck_started_at &&
                                          claim.factcheck_completed_at) && (
                                          <strong>
                                            {" "}
                                            (
                                            {getTimeDifference(
                                              claim.factcheck_started_at,
                                              claim.factcheck_completed_at
                                            )}
                                            )
                                          </strong>
                                        )}
                                    </em>
                                  )}
                                </div>
                                <div style={{ textTransform: "capitalize" }}>
                                  {claim.old_factchecker_id && userHashTable && (
                                    <em className={classes.textHistory}>
                                      <u>
                                        <b>Old Factchecker </b>
                                      </u>
                                      :
                                      {
                                        userHashTable[claim.old_factchecker_id]
                                          .username
                                      }
                                      {claim.oldfactcheck_completed_at &&
                                        (claim.oldfactcheck_started_at &&
                                          claim.oldfactcheck_completed_at) && (
                                          <strong>
                                            (
                                            {getTimeDifference(
                                              claim.oldfactcheck_started_at,
                                              claim.oldfactcheck_completed_at
                                            )}
                                            )
                                          </strong>
                                        )}
                                    </em>
                                  )}
                                </div>
                                <div style={{ textTransform: "capitalize" }}>
                                  {claim.moderator_id && userHashTable && (
                                    <em className={classes.textHistory}>
                                      <u>
                                        <b>Current Moderator </b>
                                      </u>
                                      :
                                      {
                                        userHashTable[claim.moderator_id]
                                          .username
                                      }
                                      {claim.moderator_completed_at &&
                                        claim.moderator_started_at &&
                                        (claim.moderator_started_at &&
                                          claim.moderator_completed_at) && (
                                          <strong>
                                            (
                                            {getTimeDifference(
                                              claim.moderator_started_at,
                                              claim.moderator_completed_at
                                            )}
                                            )
                                          </strong>
                                        )}
                                    </em>
                                  )}
                                </div>
                                <div style={{ textTransform: "capitalize" }}>
                                  {claim.old_moderator_id && userHashTable && (
                                    <em className={classes.textHistory}>
                                      <u>
                                        <b>Old Moderator </b>
                                      </u>
                                      :
                                      {
                                        userHashTable[claim.old_moderator_id]
                                          .username
                                      }
                                      {claim.oldmoderator_completed_at &&
                                        (claim.oldmoderator_started_at &&
                                          claim.oldmoderator_completed_at) && (
                                          <strong>
                                            (
                                            {getTimeDifference(
                                              claim.oldmoderator_started_at,
                                              claim.oldmoderator_completed_at
                                            )}
                                            )
                                          </strong>
                                        )}
                                    </em>
                                  )}
                                </div>
                                <div style={{ textTransform: "capitalize" }}>
                                  {claim.supervisor_id && userHashTable && (
                                    <em className={classes.textHistory}>
                                      <u>
                                        <b>Current Supervisor </b>
                                      </u>
                                      :
                                      {
                                        userHashTable[claim.supervisor_id]
                                          .username
                                      }
                                      {claim.supervisor_completed_at &&
                                        (claim.supervisor_started_at &&
                                          claim.supervisor_completed_at) && (
                                          <strong>
                                            (
                                            {getTimeDifference(
                                              claim.supervisor_started_at,
                                              claim.supervisor_completed_at
                                            )}
                                            )
                                          </strong>
                                        )}
                                    </em>
                                  )}
                                </div>

                                <div style={{ textTransform: "capitalize" }}>
                                  {claim.old_supervisor_id && userHashTable && (
                                    <em className={classes.textHistory}>
                                      <u>
                                        <b>Old Supervisor</b>
                                      </u>
                                      :
                                      {
                                        userHashTable[claim.old_supervisor_id]
                                          .username
                                      }
                                      {claim.oldsupervisor_completed_at &&
                                        (claim.oldsupervisor_started_at &&
                                          claim.oldsupervisor_completed_at) && (
                                          <strong>
                                            (
                                            {getTimeDifference(
                                              claim.oldsupervisor_started_at,
                                              claim.oldsupervisor_completed_at
                                            )}
                                            )
                                          </strong>
                                        )}
                                    </em>
                                  )}
                                </div>
                              </React.Fragment>
                            }
                          >
                            <Paper
                              className={(classes.paper1, classes.tr)}
                              elevation={1}
                              key={index}
                              style={{
                                marginBottom: 16,
                                width: "100%",
                                padding: 8,
                                border: Complaints.border,
                              }}
                            >
                              <Link to={"/claim/" + claim._id}>
                                <Grid container alignItems="center">
                                  <Grid item sm={1} md={1}>
                                    <Typography style={{ marginTop: 5, borderBottom: claim.topics && claim.topics.length > 0 ? 'none' : '1px dotted red' }}>
                                      {claim.tracking_id}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    sm={6}
                                    md={6}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      paddingLeft: 20,
                                    }}
                                  >
                                    <Typography
                                      component="p"
                                      className={
                                        classes.paragraphText + " maxlines2"
                                      }
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {claim.modified_claim}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    sm={1}
                                    md={1}
                                    style={{ textAlign: "center" }}
                                  >
                                    <Typography
                                      component="p"
                                      className={classes.codeText}
                                    >
                                      <FlagIcon
                                        code={getLocation(claim.location)}
                                        size={44}
                                      />
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    sm={2}
                                    md={2}
                                    style={{ textAlign: "center" }}
                                  >
                                    {claim.rating > 0 && (
                                      <StarRatings
                                        rating={Number(claim.rating)}
                                        starDimension="20px"
                                        starSpacing="1px"
                                        starRatedColor="#ffd700"
                                      />
                                    )}
                                  </Grid>
                                  <Grid item sm={2} md={2}>
                                    {claim.verdict !== "" && (
                                      <div
                                        style={{
                                          backgroundColor:
                                            colours[claim.verdict],
                                          padding: "8px 14px",
                                          maxWidth: "100%",
                                          borderRadius: 4,
                                          marginLeft: "auto",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            fontWeight: "600",
                                            textTransform: "uppercase",
                                            textAlign: "center",
                                            color:
                                              colours[claim.verdict] ===
                                                "#e9ff00"
                                                ? "#000"
                                                : "#fff",
                                          }}
                                        >
                                          {claim.verdict.replace("_", " ")}
                                        </Typography>
                                      </div>
                                    )}
                                  </Grid>
                                  {claim.editorColumn && (
                                    <Grid item sm={2} md={2}>
                                      {claim.editorColumn.tags.map(
                                        (tag, index) => {
                                          return (
                                            <Chip
                                              label={tag}
                                              className={classes.chip}
                                              variant="outlined"
                                              key={index}
                                            />
                                          );
                                        }
                                      )}
                                    </Grid>
                                  )}
                                </Grid>
                              </Link>
                            </Paper>
                          </HtmlTooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Paper>

              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Pagination
                  limit={100}
                  offset={this.state.offset}
                  total={this.state.total}
                  onClick={(e, offset) => this.handleClicks(offset)}
                />
              </MuiThemeProvider>
            </Grid>
          )}

        {this.state.activeButton === "Multimedia claims" && !loading && (
          <Grid container spacing={24}>
            {data.map((claim, index) => {
              claim.priority === "high"
                ? (faster = { backgroundColor: "#fffcc8" })
                : (faster = {});
              return (
                <Grid item xs={12} md={4} key={index}>
                  <SimpleCard
                    data={claim}
                    users={users}
                    style={faster}
                    images={claim.recheck_image}
                    userHashTable={userHashTable}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {this.state.activeButton === "Hero fact checks" && (
          <HeroResolvedClaims
            isFilterApply={this.state.isFilterApply}
            queryValue={this.state.queryValue}
            users={users}
            searchVal={searchVal}
            filters={filters}
            country={this.state.country}
            onHandleSave={this.handleSavefromHeroFc}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    userHashTable: data.userHashTable,
    navClaims: data.navClaims,
    activeButton: data.activeButton,
    clearFilter: data.clearFilter,
    loading: data.loading,
    offline: data.offline,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(ResolvedClaims));
