import React, { Component } from 'react'
import { Button, TextField, Typography } from '@material-ui/core';
// import { ExpandMore } from '@material-ui/icons'
import ClaimAppearance from './ClaimAppearance';

class ReviewClaim extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: this.props.index,
            expanded: false
        }
    }

    handleOnChange = (e) => {
        console.log('e', e.target.name, e.target.value)
        let temp = { ...this.props.itemReviewed, [e.target.name]: e.target.value };
        this.props.handleOnChangeItemReviewed(temp)
    }
    handleOnChangeAppearance = (e, index) => {
        let temp = { ...this.props.itemReviewed };
        temp.appearance[index] = { ...temp[index], ...e }
        this.props.handleOnChangeItemReviewed(temp)
    }


    render() {
        const { index } = this.state
        const { itemReviewed, reviewRating, claimReviewed } = this.props
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ fontWeight: 'bold' }}>Review #{index + 1} - Claim</Typography>
                    {index > 0 && <Button color='secondary' onClick={this.props.removeClaimReview}>Remove claim review</Button>}
                </div>
                <br />
                <TextField
                    label='Claim reviewed'
                    variant='outlined'
                    fullWidth
                    value={claimReviewed}
                    onChange={(e) => this.props.handleChangeOnClaimsReview(e.target.value)}
                    name='claimReviewed'
                    helperText='What the person or entity claimed to be true.'
                />
                <div style={{ marginTop: 16 }}>
                    <TextField
                        label='Claim date'
                        type='date'
                        helperText='When the person or entity made the claim.'
                        name='datePublished'
                        value={itemReviewed.datePublished}
                        onChange={this.handleOnChange}
                        variant='outlined'
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                </div>
                <br />

                {itemReviewed.appearance.map((item, index) => {
                    return <ClaimAppearance
                        handleRemoveClaimAppearance={() => this.props.addAppearance([...itemReviewed.appearance].filter((item, index1) => index1 !== index))} claimAppearanceSingleItem={item}
                        handleOnChange={(e) => this.handleOnChangeAppearance(e, index)}
                        key={item.url}
                    />
                })
                }
                <Button color='secondary' onClick={() => {
                    this.props.addAppearance([...itemReviewed.appearance, { url: "", "@type": "CreativeWork", }])
                }}>+ Add another claim appearance</Button>
                <br />
                <br />
                <TextField
                    label='Claim author name'
                    variant='outlined'
                    fullWidth
                    value={itemReviewed.author.name}
                    name='name'
                    onChange={(e) => this.props.authorName(e.target.value)}
                    helperText='Name of the person or entity who made the claim.'
                />
                <br />
                <br />
                <TextField
                    label='Rating Text'
                    variant='outlined'
                    fullWidth
                    name='alternateName'
                    value={reviewRating.alternateName}
                    onChange={(e) => this.props.handleChangeOnReviewRating(e.target.value)}
                    helperText='Your written assessment of the claim.'
                />
                <br />
                <br />
                {/* <ExpansionPanel expanded={expanded} onChange={this.handleExpandedChange}>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                        <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>{expanded ? "Hide fields" : "Show more fields"}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ display: 'block' }}>
                        <br />
                        <TextField
                            label='Claim review URL'
                            variant='outlined'
                            fullWidth
                            helperText='URL of the fact-check. If provided, must be the same as the article URL with an optional anchor.'
                        />
                        <br />
                        <br />
                        <TextField
                            label='Claim location'
                            variant='outlined'
                            fullWidtPaperh
                            helperText='Where the claim was made.'
                        />
                        <br />
                        <br />
                        <div style={{ display: 'flex', gap: 16 }}>
                            <TextField
                                label='Claim author job title'
                                variant='outlined'
                                helperText='Position of the person or entity making the claim.'
                            />
                            <TextField
                                label='Claim author image'
                                variant='outlined'
                                fullWidth
                                helperText='Image URL of the person or entity making the claim.'
                            />
                        </div>
                        <br />
                        <br />
                        <div style={{ display: 'flex', gap: 16 }}>
                            <TextField
                                label='Numeric rating'
                                variant='outlined'
                                helperText='A number rating for the claim.'
                                value={reviewRating.ratingValue}
                            />
                            <TextField
                                label='Lowest rating'
                                variant='outlined'
                                helperText='Bottom of your rating scale.'
                                value={reviewRating.worstRating}

                            />
                            <TextField
                                label='Highest rating'
                                variant='outlined'
                                helperText='Top of your rating scale.'
                                value={reviewRating.bestRating}

                            />
                        </div>
                        <br />
                        <br />
                        <TextField
                            label='Rating explanation'
                            variant='outlined'
                            fullWidth
                            helperText='A short explanation for the given rating.'
                        />
                        <br />
                        <br />
                        <TextField
                            label='Rating image'
                            variant='outlined'
                            fullWidth
                            helperText='Image URL for the given rating.'
                        />
                        <br />
                        <br />
                    </ExpansionPanelDetails>
                </ExpansionPanel> */}
            </>
        )
    }
}

export default ReviewClaim;