import { Button, IconButton, Input, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import React, { Component } from 'react'
import Dropdown from './Dropdown'
import axios from 'axios'
import { toast } from 'react-toastify'

export class UserExpandableTable extends Component {
    constructor(props) {
        super(props)
        const { user } = this.props
        this.state = {
            user: user,
            isOpen: false,
            first_name: user.first_name,
            last_name: user.last_name,
            username: user.username,
            email: user.email,
            role: user.role,
            status: user.status,
            isLoading: false,
            isModalOpen: false,
            isModalLoading: false,
            associatedFcFound: false,
            associatedFcFoundList: [],
        }
    }
    handleToggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }
    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleProfile = ({ role, user, telephone }, prevApiData) => {
        axios.put('/update-profile',
            { role: role, user: user, telephone: telephone },
            {
                headers: {
                    Authorization: "Token " + localStorage.token,
                    "content-type": "application/json",
                },
            }
        ).then(res => {
            this.setState({
                first_name: prevApiData.first_name,
                last_name: prevApiData.last_name,
                username: prevApiData.username,
                email: prevApiData.email,
                role: res.data.data.fields.role,
                isOpen: false,
                isLoading: false
            }, () => {
                toast.success("User profile updated", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
        }).catch(e => {
            console.log(e);
            this.setState({ isLoading: false }, () => {
                toast.error("Something went wrong please try again", {
                    position: toast.POSITION.TOP_RIGHT,
                });

            })
        })
    }
    handleSave = () => {
        const { user } = this.props
        const { first_name, last_name, username, email, role } = this.state
        const dataToUpdate = {
            id: JSON.stringify(user.user_id),
            first_name: first_name,
            last_name: last_name,
            username: username,
            email: email
        }
        const dataToUpdateProfile = {
            role: role,
            user: JSON.stringify(user.user_id),
            telephone: user.telephone
        }
        this.setState({ isLoading: true }, () => {
            axios.put('/update-user', dataToUpdate, { headers: { Authorization: "Token " + localStorage.token } }).then(res => {
                this.handleProfile(dataToUpdateProfile, res.data.data)
            }).catch(e => {
                console.log(e);
                toast.error("Something went wrong please try again", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
        })
    }
    handleDelete = () => {
        this.setState({ isModalLoading: true }, () => {
            const { user } = this.props
            // console.log({ id: JSON.stringify(user.user_id) })
            const dataToSend = { user_id: JSON.stringify(user.user_id) }
            axios.delete(`/delete-user`, {
                params: dataToSend,
                headers: { Authorization: "Token " + localStorage.token }
            }).then(res => {
                console.log('handle delete', res.data)
                if (res.data.detail === 'Associated factchecks found') {
                    this.setState({ associatedFcFound: true, associatedFcFoundList: res.data.factchecks, isModalLoading: false })

                } else {
                    this.setState({ isModalLoading: false, isModalOpen: false, associatedFcFound: false }, () => {
                        toast.success("User Deleted Successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                        this.props.refetchUsers()
                    })
                }
            }).catch(e => {
                console.log(e);
                this.setState({ isModalLoading: false }, () => {
                    toast.error("Something went wrong please try again", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                })
            })

        })
    }
    render() {
        const { isOpen, first_name, last_name, username, email, role, isLoading, isModalOpen, isModalLoading, associatedFcFound, associatedFcFoundList } = this.state
        const { user } = this.props
        return (<>
            <TableRow>
                <TableCell>{user.user_id}</TableCell>
                <TableCell>{user.first_name}</TableCell>
                <TableCell>{user.last_name}</TableCell>
                <TableCell style={{ wordBreak: 'break-all' }}>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{user.status}</TableCell>
                <TableCell>
                    <IconButton onClick={this.handleToggle}>{isOpen ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </TableCell>
            </TableRow>
            {isOpen && <TableRow>
                {isLoading ? <TableCell colSpan={8} style={{ borderColor: '#f62459' }}>
                    <Typography align='center' style={{ fontWeight: 'bold' }} variant='h5'>Loading...</Typography>
                </TableCell> :
                    <>
                        <TableCell style={{ borderColor: '#f62459' }}>{user.user_id}</TableCell>
                        <TableCell style={{ borderColor: '#f62459' }}>
                            <Input value={first_name} onChange={this.handleInput} name='first_name' />
                        </TableCell>
                        <TableCell style={{ borderColor: '#f62459' }}>
                            <Input value={last_name} onChange={this.handleInput} name='last_name' />
                        </TableCell>
                        <TableCell style={{ borderColor: '#f62459' }}>
                            <Input value={username} onChange={this.handleInput} name='username' />
                        </TableCell>
                        <TableCell style={{ borderColor: '#f62459' }}>
                            <Input value={email} onChange={this.handleInput} name='email' />
                        </TableCell>
                        <TableCell style={{ borderColor: '#f62459' }}>
                            <Dropdown value={role} onChange={(data) => this.setState({ role: data })} page='users' />
                            {/* <Input value={role} onChange={this.handleInput} name='role' /> */}
                        </TableCell>
                        <TableCell colSpan={2} style={{ borderColor: '#f62459' }}>
                            <div className='card-flex'>
                                <button className='save-button' onClick={this.handleSave}>Save</button>
                                <Button color='secondary' onClick={() => this.setState({ isModalOpen: true })}>Delete</Button>

                            </div>
                        </TableCell>
                    </>
                }
            </TableRow>}
            <Modal open={isModalOpen}
                onClose={() => { this.setState({ isModalOpen: false, associatedFcFound: false, associatedFcFoundList: [] }) }}>
                <div className='modal-center' style={{ width: '61vw' }}>
                    <Paper style={{ padding: 16, textAlign: 'center' }}>
                        {associatedFcFound ?
                            <>
                                <Typography style={{ fontWeight: 'bold' }}>
                                    Associated factchecks found for username {user.username}
                                </Typography>
                                <br />
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Sr No.</TableCell>
                                            <TableCell>Tracking Id</TableCell>
                                            <TableCell>Claim Id</TableCell>
                                            <TableCell>Language</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {associatedFcFoundList.map((item, index) => {
                                            return <TableRow key={item.tracking_id}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{item.tracking_id}</TableCell>
                                                <TableCell><a href={`https://factcheck.logically.ai/claim/${item.claim_id}`} target='_blank' rel='noopener noreferrer'>{item.claim_id}</a></TableCell>
                                                <TableCell>{item.language}</TableCell>
                                            </TableRow>
                                        })
                                        }
                                    </TableBody>

                                </Table>
                            </>
                            : <><Typography style={{ fontWeight: 'bold' }}>Are you sure you want to delete the user?</Typography>
                                <Typography><b>Note:</b> This change cannot be reverted</Typography>
                                {isModalLoading ? <div>
                                    <Typography align='center' style={{ fontWeight: 'bold' }} variant='h5'>Please wait...</Typography>
                                </div> :
                                    <div className='flex gap' style={{ justifyContent: 'center', marginTop: 8 }}>
                                        <button className='save-button' onClick={this.handleDelete}>Yes</button>
                                        <Button color='secondary' onClick={() => this.setState({ isModalOpen: false, associatedFcFound: false, associatedFcFoundList: [] })}>No</Button>
                                    </div>
                                }
                            </>
                        }
                    </Paper>
                </div>
            </Modal>
        </>
        )
    }
}

export default UserExpandableTable