import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import SimpleCard from "../simpleSectionCard/simpleSectionCard";
import axios from "axios";
import api, { getBase, getUser } from "./../../../api";
import Typography from "@material-ui/core/Typography";
import Filter from "../Filter/filter";
import Pagination from "material-ui-flat-pagination";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { connect } from "react-redux";

const theme = createMuiTheme();
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  fontFamily: ["Roboto"].join(","),
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
  text: {
    color: "#FFFFFF",
    textTransform: "capitalize",
  },
  tr: {
    background: "white",
    "&:hover": {
      background: "#F1F4F8",
    },
  },
});

class FinalizeClaims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_url: this.props.urlClaims ? JSON.parse(this.props.urlClaims) : [],
      data_mm: this.props.mmClaims ? JSON.parse(this.props.mmClaims) : [],
      offset: 0,
      queryValue: "",
      isFilterApply: false,
      filterTabs: this.props.activeTab,
      showAcceptedClaims: false,
      country: false,
      clearFilter: 0,
      enableRedux: true,
      loading: this.props.loading,
      offline: this.props.offline,
    };
  }
  clearedFilter() {
    const { filterTabs, data_url, data_mm } = this.state;
    const data = filterTabs === "URL claims" ? data_url : data_mm;
    if (this.props.clearFilter === 0) {
      if (this.props.searchData.length === 0) {
        this.setState({ clearFilter: this.props.clearFilter }, () => {
          if (data.length === 0) {
            if (this.props.queryValue === "") {
              this.getData(null);
            }
          }
        });
      }
    } else {
      if (this.props.queryValue === "") {
        this.getData(null);
      }
    }
  }

  componentDidMount() {
    this.props.onHandleClearFilters()
    this.getData(null);
  }

  componentWillReceiveProps(nextProps) {
    const { searchData, searchFilter, clearFilter } = nextProps;
    const { filterTabs, enableRedux } = this.state;
    const isUrlClaimsTab = filterTabs === "URL claims";

    if (searchData !== this.props.searchData && searchFilter && !clearFilter) {
      if (isUrlClaimsTab) {
        this.setState({ data_url: searchData }, () =>
          this.props.dispatch({ type: "SET_LOADING", loading: false })
        );
      } else {
        this.setState({ data_mm: searchData }, () =>
          this.props.dispatch({ type: "SET_LOADING", loading: false })
        );
      }
    }

    if (clearFilter && !this.props.clearFilter) {
      this.props.dispatch({ type: "SET_LOADING", loading: true })
      this.setState({ enableRedux: true })
      this.getData(null);
    }

    if (searchFilter !== this.props.searchFilter) {
      this.setState({ enableRedux: !searchFilter })
    }

    if (nextProps.activeTab !== this.state.filterTabs) {
      this.setState({ filterTabs: nextProps.activeTab });
    }
    if (nextProps.urlClaims && enableRedux && clearFilter) {
      let reduxData = JSON.parse(nextProps.urlClaims);
      this.setState({ data_url: reduxData }, () => {
        nextProps.activeTab === "URL claims" &&
          this.props.dispatch({ type: "SET_LOADING", loading: false });
      });
    }
    if (nextProps.mmClaims && enableRedux && clearFilter) {
      let reduxData = JSON.parse(nextProps.mmClaims);
      this.setState({ data_mm: reduxData }, () => {
        nextProps.activeTab === "Multimedia claims" &&
          this.props.dispatch({ type: "SET_LOADING", loading: false });
      });
    }
    if (nextProps.offline !== this.state.offline) {
      this.setState({ offline: nextProps.offline });
    }
    if (!nextProps.offline && this.state.offline) {
      this.getData();
    }
  }

  multiFilteredData = (val) => {
    const { searchData } = this.props;
    if (searchData.length > 0) {
      this.props.dispatch({ type: "NAVCLAIMS", navClaims: val });
    } else {
      this.getData(null);
    }
  };

  getData = async (queryValue) => {
    let {
      country,
      showAcceptedClaims,
      isFilterApply,
      filterTabs,
      offset,
      data_url,
      data_mm,
      loading,
    } = this.state;
    this.props.dispatch({
      type: "SET_LOADING_SPIN",
      loading_spin: true,
    });
    try {
      let url = "";
      let header = "";
      if (isFilterApply) {
        url = getUser(
          api.SEARCH_FACTCHECKS + "?tab=finalize&limit=200&" + queryValue
        );
        header = { headers: {} };
        this.props.dispatch({ type: "SET_LOADING", loading: true });
      } else {
        if (filterTabs === "Multimedia claims") {
          if (showAcceptedClaims && country) {
            url = getBase(
              api.SUPERVISOR +
              `?location=${country}&type=image&accepted=ok&limit=25&offset=` +
              offset
            );
          } else {
            if (country) {
              url = getBase(
                api.SUPERVISOR +
                `?location=${country}&type=image&limit=25&offset=` +
                offset
              );
            } else if (showAcceptedClaims) {
              url = getBase(
                api.SUPERVISOR +
                "?type=image&accepted=ok&limit=25&offset=" +
                offset
              );
            } else {
              url = getBase(
                api.SUPERVISOR + "?type=image&limit=25&offset=" + offset
              );
            }
          }
        } else {
          if (showAcceptedClaims && country) {
            url = getBase(
              api.SUPERVISOR +
              `?location=${country}&accepted=ok&limit=100&offset=` +
              offset
            );
          } else {
            if (country) {
              url = getBase(
                api.SUPERVISOR +
                `?location=${country}&limit=100&offset=` +
                offset
              );
            } else if (showAcceptedClaims) {
              url = getBase(
                api.SUPERVISOR + "?limit=200&accepted=ok&offset=" + offset
              );
            } else {
              url = getBase(api.SUPERVISOR + "?limit=200&offset=" + offset);
            }
          }

          header = {
            headers: { Authorization: "Token " + localStorage.token },
          };
        }
        const res = await axios.get(url, header);
        const isUrlClaimsTab = filterTabs === "URL claims";
        if (
          (isUrlClaimsTab ? this.props.urlClaims : this.props.mmClaims) !==
          JSON.stringify(res.data.data)
        ) {
          if (
            JSON.stringify(isUrlClaimsTab ? data_url : data_mm) !==
            JSON.stringify(res.data.data)
          ) {
            this.props.setBadge(res.data.total_count);
            if (isUrlClaimsTab) {
              this.setState({ data_url: res.data.data }, () => {
                this.props.dispatch({ type: "SET_LOADING", loading: false });
                this.props.dispatch({
                  type: "SET_LOADING_SPIN",
                  loading_spin: false,
                });
                this.props.dispatch({
                  type: "FINALIZE_URL",
                  finalize: JSON.stringify(res.data.data),
                });
              });
            } else {
              this.setState({ data_mm: res.data.data }, () => {
                this.props.dispatch({ type: "SET_LOADING", loading: false });
                this.props.dispatch({
                  type: "SET_LOADING_SPIN",
                  loading_spin: false,
                });
                this.props.dispatch({
                  type: "FINALIZE_MM",
                  finalize: JSON.stringify(res.data.data),
                });
              });
            }
          } else if (loading) {
            this.props.dispatch({ type: "SET_LOADING", loading: false });
            this.props.dispatch({
              type: "SET_LOADING_SPIN",
              loading_spin: false,
            });
          }
        } else {
          let reduxData = JSON.parse(
            isUrlClaimsTab ? this.props.url : this.props.mmClaims
          );
          if (isUrlClaimsTab) {
            this.setState({ data_url: reduxData, loading: false }, () => {
              this.props.dispatch({ type: "SET_LOADING", loading: false });
              this.props.dispatch({
                type: "SET_LOADING_SPIN",
                loading_spin: false,
              });
            });
          } else {
            this.setState({ data_mm: reduxData, loading: false }, () => {
              this.props.dispatch({ type: "SET_LOADING", loading: false });
              this.props.dispatch({
                type: "SET_LOADING_SPIN",
                loading_spin: false,
              });
            });
          }
        }
      }
    } catch (err) {
      this.props.dispatch({ type: "SET_LOADING", loading: false });
      this.props.dispatch({
        type: "SET_LOADING_SPIN",
        loading_spin: false,
      });
    }
  };

  ifError() {
    const { data_url, data_mm, filterTabs } = this.state;
    const data = filterTabs === "URL claims" ? data_url : data_mm;
    const { classes } = this.props;
    if (data.length === 0) {
      return (
        <div className={classes.root}>
          <Typography>No records found!</Typography>
        </div>
      );
    }
    if (data === false) {
      return (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography>
            Oops, looks like something went wrong. Please try again later
          </Typography>
        </div>
      );
    }
    return null;
  }
  handleShowAcceptedClaims(val) {
    console.log("show accepted claims", val);
    this.setState({ showAcceptedClaims: val }, () => {
      this.getData(null);
    });
  }
  handleClicks(offset) {
    this.setState({ offset }, () => {
      this.getData(this.state.querValue);
    });
  }
  reloadwindow = () => {
    window.location.reload();
  };
  render() {
    const { data_url, data_mm, filterTabs } = this.state;
    const data = filterTabs === "URL claims" ? data_url : data_mm;
    const {
      users,
      isSidebar,
      classes,
      filterOptions,
      userHashTable,
      datapoint,
      loading,
    } = this.props;
    const { buttons, dropDown, dropDownOptions } = filterOptions;
    if (Object.keys(userHashTable).length === 0) {
      return (
        <div>
          Something Seems Wrong Click{" "}
          <button
            style={{ border: 0, fontSize: 15, textDecoration: "underline" }}
            onClick={this.reloadwindow}
          >
            {" "}
            here{" "}
          </button>
          to Reload
        </div>
      );
    }
    return (
      <>
        <Filter
          datapoint={datapoint}
          length={data.length}
          buttons={buttons.split(",")}
          activeIndex={this.state.filterTabs}
          filterbutton={(val) => {
            this.setState({ filterTabs: val }, () =>
              this.multiFilteredData(val)
            );
          }}
          countrySelected={(country) => {
            if (country !== "All") {
              this.setState({ country: country }, () => {
                this.getData(null);
              });
            } else {
              this.setState({ country: false }, () => {
                this.getData(null);
              });
            }
          }}
          dropDown={dropDown}
          dropDownOptions={dropDownOptions}
          checkbox={"show accepted claims"}
          showingClaims={true}
          checkboxValue={(val) => this.handleShowAcceptedClaims(val)}
        />
        {this.ifError()}
        {this.state.filterTabs === "URL claims" && !loading && (
          <Grid container spacing={8 * 3}>
            {data.map((claim, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={isSidebar ? 6 : 4}
                  md={isSidebar ? 4 : 3}
                  key={index}
                >
                  <SimpleCard
                    className={classes.tr}
                    data={claim}
                    claimIndex={index}
                    users={users}
                    images={false}
                    finalize={true}
                    userHashTable={userHashTable}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {this.state.filterTabs === "Multimedia claims" && !loading && (
          <Grid container spacing={8 * 3}>
            {data.map((claim) => {
              return (
                claim.recheck_image && (
                  <Grid
                    item
                    xs={12}
                    sm={isSidebar ? 6 : 4}
                    md={isSidebar ? 4 : 3}
                    key={claim._id}
                  >
                    <SimpleCard
                      data={claim}
                      users={users}
                      images={true}
                      finalize={true}
                      userHashTable={userHashTable}
                    />
                  </Grid>
                )
              );
            })}
          </Grid>
        )}
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Pagination
            limit={100}
            offset={this.state.offset}
            total={this.state.total}
            onClick={(e, offset) => this.handleClicks(offset)}
          />
        </MuiThemeProvider>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    navClaims: state.navClaims,
    userHashTable: state.userHashTable,
    activeTab: state.activeButton,
    loading: state.loading,
    clearFilter: state.clearFilter,
    urlClaims: state.finalize.url,
    mmClaims: state.finalize.multimedia,
    offline: state.offline,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(FinalizeClaims));
