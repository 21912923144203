import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter } from 'react-router-dom';
import './header.css';
import notificationIcon from '../assets/Notifications.svg';
import documents from '../assets/Documents.svg';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import UserIcon from '../assets/UserIcon';
import feedback from '../assets/Feedback.svg';
import logoutIcon from '../assets/Logout.svg';
import InputBase from '@material-ui/core/InputBase';
import Sort from '@material-ui/icons/Sort';
import Notification from './notification/notification';
import { connect } from 'react-redux';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import SearchIcon from '../assets/Icon feather-search.svg';
import { Button, Snackbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import { getTitle } from '../utils/notifications';
import { db } from '../App';

const styles = {
  snackbarStyleViaContentProps: {
    backgroundColor: 'white',
  },
  snackbarStyleViaNestedContent: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0.5px solid #c0c0c0',
  },
};

const documentsList = [
  {
    name: 'Process Guide',
    url:
      'https://drive.google.com/file/d/1zTeRtk3eFd4J-_GPyTTvAp1Bdn2-PraX/view',
  },
  {
    name: 'User Manual',
    url:
      'https://docs.google.com/document/d/1779SZ6f-s5guybZhX6LZKLoZvKZeB_r05meZ2RfjsEo/edit',
  },
  {
    name: 'Image verification',
    url: 'https://drive.google.com/open?id=1_QrmYYeyEZEf6D6yxZeoHKnME5PdVwNf',
  },
];

class Header extends Component {
  state = {
    anchorEl: null,
    docAnchor: null,
    notifsAnchor: null,
    searchFilter: false,
    notifications: [],
    openSnackbar: true,
  };

  handleMarkAsRead = (notifIndex) => {
    const userId = JSON.parse(localStorage.getItem('user')).id;
    const notifsDb = db.ref(`/${userId}/notifications`);
    notifsDb.child(notifIndex).update({ read: true });
    let notifs = [...this.state.notifications];
    if (notifs[notifIndex + 1]) {
      for (let i = notifs[notifIndex + 1]; i <= notifs.length; i++) {
        if (!notifs[i].read) {
          return (notifs[i].open = true);
        }
      }
      notifs[notifIndex].open = false;
    }
    this.setState({ notifications: notifs });
  };

  handleClick = (event) => {
    if (this.props.offline) {
      return;
    }
    this.setState({ anchorEl: event.currentTarget });
  };

  handleDocumentClick = (event) => {
    this.setState({ docAnchor: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, docAnchor: null, notifsAnchor: null });
  };

  getUserImage = () => {
    const { user } = this.props;
    if (user.username) return user.username.split('')[0];
  };

  handleLogout = () => {
    const { logout } = this.props;
    logout();
    this.handleClose();
  };

  getRole = () => {
    const { user } = this.props;
    if (user.profile) {
      if (user.profile.fields) {
        if (user.profile.fields.role) return user.profile.fields.role;
      }
    }
  };

  handleNotifsClick = (e) => {
    const { OnHandleNotificationOpen } = this.props;
    this.setState({ notifsAnchor: e.currentTarget });
    OnHandleNotificationOpen();
  };

  handleChange = (e) => {
    this.setState({ searchVal: e.target.value });
  };
  nextPath(path) {
    this.props.history.push(path, 'newclaim');
  }

  onHandleFilter = () => {
    this.setState({ searchFilter: !this.state.searchFilter }, () => {
      this.props.dispatch({
        type: 'SEARCH_FILTER',
        searchFilter: this.state.searchFilter,
      });
    });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.notifications) {
      let newNotifs = [];
      let firstOpenNotification = false;
      nextProps.notifications.map((notif) => {
        if (!firstOpenNotification) {
          if (!notif.read) {
            firstOpenNotification = true;
            notif.open = true;
          }
        } else {
          notif.open = false;
        }
        newNotifs.push(notif);
      });
      this.setState({ notifications: newNotifs });
    }
    if (nextProps.activeButton !== this.state.activeButton) {
      this.setState({ activeButton: nextProps.activeButton });
    }
    if (nextProps.claimsActiveTab !== this.state.point) {
      this.setState({
        point: nextProps.claimsActiveTab,
        activeTab: nextProps.claimsActiveTab - 1,
      });
    }
  }

  render() {
    const {
      user,
      onHandleSearchChange,
      searchVal,
      notifications,
      alertUser,
      offline,
      classes,
      isSupervisor,
    } = this.props;

    const { anchorEl, docAnchor, notifsAnchor, openSnackbar } = this.state;
    return (
      <div>
        <div className="header-container">
          <div className="searchbar-container">
            <div style={{ width: '100%' }}>
              <InputBase
                disabled={offline}
                onChange={onHandleSearchChange}
                defaultValue={searchVal}
                placeholder="Tracking Code or Claim"
                startAdornment={
                  <img
                    alt="SearchIcon"
                    src={SearchIcon}
                    style={{ paddingRight: 5, height: 20 }}
                  />
                }
                endAdornment={
                  <Sort
                    type="button"
                    onClick={this.onHandleFilter}
                    className="filter"
                    style={{
                      visibility:
                        this.props.currentPath === 'Dashboard' || offline || this.props.activeButton === 'Hero fact checks'
                          ? 'hidden'
                          : 'visible',
                    }}
                  />
                }
                style={{
                  fontSize: 14,
                  width: '100%',
                  backgroundColor: '#fff',
                  borderRadius: '10px',
                  height: '40px',
                }}
                type="search"
              />
            </div>
          </div>
          {!offline && (
            <button
              variant="contained"
              className="header-button createclaims-contanier"
              style={{ fontSize: 15 }}
              onClick={() => this.nextPath('/create/claim', 'claims')}>
              <span style={{ position: 'relative', top: 2, left: -3 }}>
                <AddBoxOutlinedIcon style={{ fontSize: 15 }} />
              </span>
              Create claims
            </button>
          )}
          <button
            className="header-button notification-button"
            aria-owns={docAnchor ? 'simple-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleNotifsClick}
            disabled={offline}>
            <img src={notificationIcon} alt="Notifications" />
            {alertUser > 0 && (
              <span className="notification-count">{alertUser}</span>
            )}
          </button>
          <Menu
            anchorEl={notifsAnchor}
            open={Boolean(notifsAnchor)}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <div className="notifications-container" onClick={this.handleClose}>
              <Grid
                container
                justify="space-between"
                className="notifications-top">
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Notifications
                </Typography>
                <button className="blue-button">Mark all as read</button>
              </Grid>
              {notifications.map((notification, index) => (
                <Notification data={notification} key={index} />
              ))}
            </div>
          </Menu>
          <button
            aria-owns={docAnchor ? 'simple-menu' : undefined}
            aria-haspopup="true"
            className="header-button"
            onClick={this.handleDocumentClick}>
            <img src={documents} alt="documents" className="menu-icon" />
          </button>
          <div className="menu-container">
            <Menu
              anchorEl={docAnchor}
              open={Boolean(docAnchor)}
              onClose={this.handleClose}>
              {documentsList.map((document, index) => (
                <MenuItem onClick={this.handleClose} key={index}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={document.url}>
                    <Grid container>
                      <img
                        src={documents}
                        alt="documents"
                        className="menu-icon"
                      />
                      {document.name}
                    </Grid>
                  </a>
                </MenuItem>
              ))}
            </Menu>
          </div>

          <div className="menu-container">
            <button
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
              className="user-button">
              <div className="user-menu-button">
                <div className="user-image">
                  <p className="user-char">{this.getUserImage()}</p>
                </div>
                <div className="user-menu-details">
                  <Typography className="user-menu-name">
                    {user.username}
                  </Typography>
                  <Typography className="user-menu-role">
                    {this.getRole()}
                  </Typography>
                </div>
              </div>
            </button>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}>
              <MenuItem onClick={this.handleClose}>
                <Link to="/me">
                  <Grid container alignItems="center">
                    <UserIcon
                      fill="#050E2B"
                      styles={{ width: '16px', marginRight: '10px' }}
                    />
                    <Typography>My Profile</Typography>
                  </Grid>
                </Link>
              </MenuItem>
              <MenuItem onClick={this.handleClose}>
                <Grid container alignItems="center">
                  <img src={feedback} alt="feedback" className="menu-icon" />
                  <Typography>Feedback</Typography>
                </Grid>
              </MenuItem>
              <MenuItem onClick={this.handleLogout}>
                <Grid container alignItems="center">
                  <img src={logoutIcon} alt="feedback" className="menu-icon" />
                  <Typography>Logout</Typography>
                </Grid>
              </MenuItem>
            </Menu>
          </div>
        </div>

        {!isSupervisor && (
          <Grid>
            {' '}
            {notifications &&
              notifications.length > 0 &&
              notifications.map((item, index) => {
                if (
                  item.tracking_id &&
                  !item.read &&
                  item.action === 'Assigned'
                )
                  return (
                    <Link
                      to={'/claim/' + item.claim_id}
                      onClick={() => {
                        this.handleMarkAsRead(index);
                        this.setState({
                          notifications: [
                            ...notifications,
                            (notifications[index].read = true),
                          ],
                        });
                      }}>
                      <div key={index}>
                        <Snackbar
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          open={item.open}>
                          <SnackbarContent
                            aria-describedby="message-id2"
                            className={classes.snackbarStyleViaNestedContent}
                            message={
                              <span id="message-id2">
                                <div>{getTitle(item)}</div>
                              </span>
                            }
                            action={
                              <React.Fragment>
                                <Button
                                  color="secondary"
                                  size="small"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.handleMarkAsRead(index);
                                  }}>
                                  Mark as read
                                </Button>
                                <IconButton
                                  size="small"
                                  aria-label="close"
                                  color="inherit"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    let notifs = [...notifications];
                                    notifs[index].open = false;
                                    if (notifs[index + 1]) {
                                      for (
                                        let i = notifs[index + 1];
                                        i <= notifs.length;
                                        i++
                                      ) {
                                        if (!notifs[i].read) {
                                          return (notifs[i].open = true);
                                        }
                                      }
                                    }
                                    this.setState({
                                      notifications: notifs,
                                    });
                                  }}>
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </React.Fragment>
                            }
                          />
                        </Snackbar>
                      </div>
                    </Link>
                  );
              })}
          </Grid>
        )}
      </div>
    );
  }
}
const mapStateToProps = (data) => {
  return {
    currentPath: data.currentPath,
    offline: data.offline,
    activeButton: data.activeButton
  };
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Header)));
