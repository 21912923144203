import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import "./index.css";
import man from "../assets/Man.png";
import { connect } from "react-redux";

class Createdclaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      claim: "",
      userId: "",
      message: "",
    };
  }

  componentDidMount() {
    setTimeout(
      () => this.props.dispatch({ type: "SET_LOADING", loading: false }),
      0
    );
  }

  nextPath(path) {
    this.props.history.push(path);
  }

  render() {
    return (
      <div>
        <Grid container spacing={25}>
          <Grid style={{ paddingRight: "30px" }}>
            <div
              style={{
                display: "flex",
                paddingTop: "100px",
                paddingLeft: "20ps",
                marginLeft: "180px",
                flexDirection: "column",
              }}
            >
              <img src={man} alt="manpng" width="180"></img>
            </div>
          </Grid>
          <Grid spacing={16} style={{ paddingleft: "50px" }}>
            <Grid direction="column" spacing={50}>
              <Grid
                style={{
                  display: "flex",
                  paddingTop: "100px",
                  flexDirection: "column",
                }}
              >
                <h2 gutterBottom style={{ font: "Mulish" }} variant="subtitle1">
                  Well, there is an investigator inside you{" "}
                  <span role="img" aria aria-label="laughing emoji">
                    🤣🤣
                  </span>
                </h2>
                <Typography
                  gutterBottom
                  style={{
                    font: "Mulish",
                    fontWeight: 600,
                    paddingBottom: "15px",
                  }}
                >
                  Your claim is submitted successfully, investigate it now.
                </Typography>
              </Grid>
              <Grid>
                <a href="/">
                  {" "}
                  <Button
                    fullWidth={false}
                    size="medium"
                    variant={"contained"}
                    style={{
                      font: "Mulish",
                      minWidth: "fit-content",
                      marginRight: "3%",
                      height: 30,
                      textTransform: "none",
                      paddingBottom: "10px",
                    }}
                    color="primary"
                  >
                    Check claims
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect()(Createdclaim);
