import React from "react";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Paper, Grid, Divider, TextField, InputAdornment } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import Sort from "@material-ui/icons/Sort";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import axios from "axios";
import api, { getBase } from "../../../api";
import { getAllLanguage } from "../../../utils/language";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  Paper: {
    paddingLeft: 10,
    paddingRight: 5,
    paddingTop: 10,
    backgroundColor: "#fff",
    borderRadius: 0,
    height: "100vh",
    boxShadow: "none",
    overflowY: "auto",
  },
  SideBarPaperDiv: {
    width: "100%",
  },
  filterText: {
    fontSize: 17,
    fontWeight: "bold",
  },
  clearFilter: {
    fontSize: 12,
    padding: 0,
    marginRight: 16,
    marginTop: -5,
  },
  nestedSearch: {
    position: "relative",
    border: "1px solid grey",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    marginRight: theme.spacing.unit * 0,
    marginLeft: 0,
    width: "100%",
  },
  nestedSearchIcon: {
    width: theme.spacing.unit * 2.3,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 20px",
  },
  nestedInputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: 12,
  },
  nestedInputRoot: {
    color: "inherit",
    width: "100%",
  },
  textNested: {
    paddingLeft: theme.spacing.unit * 2,
    paddingTop: 1,
    paddingBottom: 1,
    backgroundColor: 'red'
  },
  formRoot: {
    paddingTop: 0,
    paddingBottom: 0
  }
});

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    let start = new Date();
    start.setMonth(start.getMonth() - 1);
    let diff;
    var date_from = new Date();
    let year = moment(date_from).format("YYYY");
    let days = moment(date_from).format("DD");
    var a = moment([2017, 0, 1]);
    var b = moment([year, 0, days]);
    diff = a.diff(b, "days");
    date_from.setDate(date_from.getDate() + diff);
    this.state = {
      factOpen: false,
      modOpen: false,
      superOpen: false,
      priorityOpen: false,
      durationOpen: false,
      escalationOpen: false,
      judgementOpen: false,
      languageOpen: false,
      dateOpen: false,
      tagOpen: false,
      correctionsOpen: false,
      updatesOpen: false,
      priority: [],
      duration: this.props.filters.duration || "",
      judgement: this.props.filters.judgement || [
        "",
        "None",
        "True",
        "False",
        "Misleading",
        "Unverifiable",
        "Partly_True",
      ],
      language: this.props.filters.language || [],
      tags: this.props.filters.tags || [],
      category: this.props.category || [],
      selectedUsers: this.props.filters.users || [],
      corrections: this.props.filters.corrections,
      updates: this.props.filters.updates,
      date: this.props.filters.date || [
        { startDate: start, endDate: new Date(), key: "selection" },
      ],
      sort: this.props.sort,
      selectionRange: {
        startDate: date_from,
        endDate: new Date(),
        key: "selection",
      },
      openDate: false,
    };
  }
  handleClick = (title) => {
    this.setState((state) => ({ [title]: !state[title] }));
  };
  handleChange = (val, type, checkbox) => {
    if (checkbox) {
      let allData = this.state[type];
      if (allData.indexOf(val) !== -1) {
        allData.splice(allData.indexOf(val), 1);
      } else {
        allData.push(val);
      }
      this.setState({ [type]: allData }, this.setFilters);
      console.log(allData);
    } else {
      this.setState({ [type]: val }, this.setFilters);
      console.log(val);
    }
  };
  clearFilters = () => {
    let diff;
    var date_from = new Date();
    let year = moment(date_from).format("YYYY");
    let days = moment(date_from).format("DD");
    var a = moment([2017, 0, 1]);
    var b = moment([year, 0, days]);
    diff = a.diff(b, "days");
    date_from.setDate(date_from.getDate() + diff);
    this.setState(
      {
        duration: "",
        judgement: [
          "None",
          "True",
          "False",
          "Misleading",
          "Unverifiable",
          "Partly_True",
        ],
        language: ["en", "hi", "mr"],
        tags: [],
        priority: [],
        selectedUsers: [],
        durationOpen: false,
        escalationOpen: false,
        corrections: false,
        judgementOpen: false,
        languageOpen: false,
        selectionRange: {
          startDate: date_from,
          endDate: new Date(),
          key: "selection",
        },
      },
      () => {
        this.props.setFilters({
          duration: "",
          judgement: [
            "None",
            "True",
            "False",
            "Misleading",
            "Unverifiable",
            "Partly_True",
          ],
          lang: ["en", "hi", "mr"],
          tags: [""],
          factchecker: [""],
          correction_made: false,
          updates: false,
        });
      }
    );
    this.getPathFromUrl();
  };
  getPathFromUrl = () => {
    let url = window.location.href.split("&");
    console.log(url);
    let newurl = url[0];
    window.location.href = newurl;
    window.history.pushState({ path: newurl }, "", newurl);
  };
  setFilters = (selectionRange) => {
    const { selectionRange: stateRange } = this.state;
    selectionRange = selectionRange || stateRange;
    let pointval =
      this.props.current === 4 ||
      this.props.current === 6 ||
      this.props.current === 7 ||
      this.props.current === 8 ||
      this.props.current === 15;
    if (pointval) {
      this.props.setFilters({
        duration: this.state.duration,
        judgement: this.state.judgement,
        lang: this.state.language,
        tags: this.state.tags.length > 0 ? JSON.stringify(this.state.tags) : "",
        factchecker: this.state.selectedUsers,
        start_date: [moment(selectionRange.startDate).format("YYYY-MM-DD")],
        end_date: [moment(selectionRange.endDate).format("YYYY-MM-DD")],
        correction_made: this.state.corrections,
        updates: this.state.updates,
        priority: this.state.priority,
      });
    } else if (
      this.props.current === 1 ||
      this.props.current === 3 ||
      this.props.current === 5 ||
      this.props.current === 9 ||
      this.props.current === 10 ||
      this.props.current === 11 ||
      this.props.current === 14 ||
      this.props.current === 19 ||
      this.props.current === 13
    ) {
      this.props.setFilters({
        factchecker: this.state.selectedUsers,
        priority: this.state.priority,
        start_date: [moment(selectionRange.startDate).format("YYYY-MM-DD")],
        end_date: [moment(selectionRange.endDate).format("YYYY-MM-DD")],
        lang: this.state.language,
      });
    } else if (
      this.props.current === 2 ||
      this.props.current === 17 ||
      this.props.current === 20
    ) {
      this.props.setFilters({
        factchecker: this.state.selectedUsers,
        priority: this.state.priority,
        judgement: this.state.judgement,
        lang: this.state.language,
        start_date: [moment(selectionRange.startDate).format("YYYY-MM-DD")],
        end_date: [moment(selectionRange.endDate).format("YYYY-MM-DD")],
      });
    }
  };
  handleSelect2 = (ranges) => {
    this.props.getData2(ranges.selection);
    this.setState({ selectionRange: ranges.selection });
    this.setFilters(ranges.selection);
  };
  render() {
    const { classes, users, current, isfiltervisible } = this.props;
    const {
      judgement,
      tags,
      selectedUsers,
      sort,
      priority,
      language,
      selectionRange,
      corrections,
    } = this.state;
    let pointval =
      this.props.current === 4 ||
      this.props.current === 6 ||
      this.props.current === 7 ||
      this.props.current === 8 ||
      this.props.current === 15;
    let pointvalpriority =
      this.props.current === 2 ||
      this.props.current === 3 ||
      this.props.current === 5 ||
      this.props.current === 19 ||
      this.props.current === 9 ||
      this.props.current === 10 ||
      this.props.current === 11 ||
      this.props.current === 14;
    return (
      <div
        className={classes.root}
        id="rootSidebar"
        style={{ maxHeight: 1800, overflow: "scroll" }}
      >
        <div className={classes.SideBarPaperDiv}>
          <div style={{ background: "#00B6B1", padding: 15, color: "#fff" }}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item md={6}>
                <Typography variant="title" style={{ color: "#fff" }}>
                  Filters
                </Typography>
              </Grid>
              <Grid item md={6}>
                <CloseIcon
                  onClick={() => {
                    this.props.handleFilter(!isfiltervisible);
                  }}
                  style={{
                    fontSize: "24px",
                    float: "right",
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <Paper className={classes.Paper + " filters"}>
            <Grid container alignItems="center" justify="flex-end">
              <Grid item>
                <Button
                  className={classes.clearFilter}
                  onClick={this.clearFilters}
                >
                  Clear filter ×
                </Button>
              </Grid>
            </Grid>
            {current === 4 && (
              <div>
                <Grid
                  container
                  alignItems="center"
                  style={{ paddingBottom: 8 }}
                >
                  <Grid item>
                    <Sort style={{ display: "block" }} />
                  </Grid>
                  <Grid item style={{ paddingLeft: 4 }}>
                    <span className={classes.filterText}>Sort</span>
                  </Grid>
                  <Grid item style={{ paddingLeft: 16, flex: 1 }}>
                    <Select
                      native
                      onChange={(e) => {
                        this.setState({ sort: JSON.parse(e.target.value) });
                        this.props.setSort(JSON.parse(e.target.value));
                      }}
                      value={JSON.stringify(sort)}
                      fullWidth
                    >
                      <option value='{"sort_key":"date","sort":"descending"}'>
                        Date descending
                      </option>
                      <option value='{"sort_key":"date","sort":"ascending"}'>
                        Date ascending
                      </option>
                      <option value='{"sort_key":"rating","sort":"descending"}'>
                        Rating descending
                      </option>
                      <option value='{"sort_key":"rating","sort":"ascending"}'>
                        Rating ascending
                      </option>
                    </Select>
                  </Grid>
                </Grid>
                <Divider />
              </div>
            )}
            <div>
              <List component="nav" className={classes.root}>
                <ListItem
                  button
                  onClick={this.handleClick.bind(this, "dateOpen")}
                >
                  <ListItemText primary="Filter by date" />
                  {this.state.dateOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.dateOpen} timeout="auto">
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={this.handleSelect2}
                  />
                </Collapse>
              </List>
            </div>
            <Divider />
            {pointval && (
              <div>
                <List component="nav" className={classes.root}>
                  <ListItem
                    button
                    onClick={this.handleClick.bind(this, "correctionsOpen")}
                  >
                    <ListItemText primary="Filter by correction made" />
                    {this.state.durationOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={this.state.correctionsOpen} timeout="auto">
                    <List component="div" disablePadding>
                      <ListItem button className={classes.textNested}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={corrections}
                              onChange={(e, val) =>
                                this.handleChange(
                                  !corrections,
                                  "corrections",
                                  false
                                )
                              }
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              value="checkedI"
                            />
                          }
                          label={"Correction Made"}
                        />
                      </ListItem>
                    </List>
                  </Collapse>
                </List>
                <Divider />
              </div>
            )}

            <List component="nav" className={classes.root}>
              <ListItem
                button
                onClick={this.handleClick.bind(this, "factOpen")}
              >
                <ListItemText primary="Filter by fact checker" />
                {this.state.factOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.factOpen} timeout="auto">
                {users.length !== 0 && (
                  <UserSearch
                    role="factchecker"
                    classes={classes}
                    ref={(ref) => (this.userSearch = ref)}
                    setFilters={(selected) =>
                      this.setState(
                        { selectedUsers: selected },
                        this.setFilters
                      )
                    }
                    users={users}
                    selected={selectedUsers}
                  />
                )}
              </Collapse>
            </List>
            <Divider />
            <List component="nav" className={classes.root}>
              <ListItem button onClick={this.handleClick.bind(this, "modOpen")}>
                <ListItemText primary="Filter by moderator" />
                {this.state.modOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.modOpen} timeout="auto">
                {users.length !== 0 && (
                  <UserSearch
                    role="moderator"
                    classes={classes}
                    ref={(ref) => (this.userSearch = ref)}
                    setFilters={(selected) =>
                      this.setState(
                        { selectedUsers: selected },
                        this.setFilters
                      )
                    }
                    users={users}
                    selected={selectedUsers}
                  />
                )}
              </Collapse>
            </List>
            <Divider />
            <List component="nav" className={classes.root}>
              <ListItem
                button
                onClick={this.handleClick.bind(this, "superOpen")}
              >
                <ListItemText primary="Filter by supervisor" />
                {this.state.superOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.superOpen} timeout="auto">
                {users.length !== 0 && (
                  <UserSearch
                    role="supervisor"
                    classes={classes}
                    ref={(ref) => (this.userSearch = ref)}
                    setFilters={(selected) =>
                      this.setState(
                        { selectedUsers: selected },
                        this.setFilters
                      )
                    }
                    users={users}
                    selected={selectedUsers}
                  />
                )}
              </Collapse>
            </List>
            <Divider />
            {pointvalpriority && (
              <div>
                <List component="nav" className={classes.root}>
                  <ListItem
                    button
                    onClick={this.handleClick.bind(this, "priorityOpen")}
                  >
                    <ListItemText primary="Filter by priority" />
                    {this.state.priorityOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={this.state.priorityOpen} timeout="auto">
                    <List component="div" disablePadding>
                      {[
                        { label: "High", val: "high" },
                        { label: "Medium", val: "medium" },
                        { label: "Low", val: "low" },
                      ].map((item, index) => {
                        return (
                          <ListItem
                            button
                            className={classes.textNested}
                            key={index}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={priority.indexOf(item.val) !== -1}
                                  onChange={(e, val) =>
                                    this.handleChange(
                                      item.val,
                                      "priority",
                                      true
                                    )
                                  }
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                  value="checkedI"
                                />
                              }
                              label={item.label}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </List>
                <Divider />
              </div>
            )}
            {pointval && (
              <div>
                <List component="nav" className={classes.root}>
                  <ListItem
                    button
                    onClick={this.handleClick.bind(this, "durationOpen")}
                  >
                    <ListItemText primary="Filter by duration" />
                    {this.state.durationOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={this.state.durationOpen} timeout="auto">
                    <RadioGroup
                      value={this.state.duration}
                      onChange={(e) =>
                        this.handleChange(e.target.value, "duration")
                      }
                    >
                      <FormControlLabel
                        value={"Infinity"}
                        control={<Radio />}
                        label={"Any"}
                      />
                      {[
                        { label: "<1 hour", val: "3600" },
                        { label: "<5 hours", val: "18000" },
                        { label: "<12 hours", val: "43200" },
                        { label: "<1 Day", val: "86400" },
                        { label: "<3 Day", val: "259200" },
                        { label: "Week", val: "604800" },
                      ].map((item, index) => {
                        return (
                          <FormControlLabel
                            value={item.val}
                            control={<Radio />}
                            label={item.label}
                            key={index}
                          />
                        );
                      })}
                    </RadioGroup>
                  </Collapse>
                </List>
                <Divider />
              </div>
            )}
            {this.props.current !== 1 && this.props.current !== 13 && (
              <div>
                <List component="nav" className={classes.root}>
                  <ListItem
                    button
                    onClick={this.handleClick.bind(this, "judgementOpen")}
                  >
                    <ListItemText primary="Filter by judgement" />
                    {this.state.durationOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={this.state.judgementOpen} timeout="auto">
                    <List component="div" disablePadding>
                      {[
                        { label: "True", val: "True" },
                        { label: "False", val: "False" },
                        { label: "Misleading", val: "Misleading" },
                        { label: "Unverifiable", val: "Unverifiable" },
                        { label: "Partly_True", val: "Partly_True" },
                      ].map((item, index) => {
                        return (
                          <ListItem
                            button
                            className={classes.textNested}
                            key={index}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={judgement.indexOf(item.val) !== -1}
                                  onChange={(e, val) =>
                                    this.handleChange(
                                      item.val,
                                      "judgement",
                                      true
                                    )
                                  }
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                  value="checkedI"
                                />
                              }
                              label={item.label}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </List>
                <Divider />
              </div>
            )}
            {this.props.current !== 1 && this.props.current !== 13 && (
              <div>
                {" "}
                <List component="nav" className={classes.root}>
                  <ListItem
                    button
                    onClick={this.handleClick.bind(this, "languageOpen")}
                  >
                    <ListItemText primary="Filter by Language" />
                    {this.state.durationOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={this.state.languageOpen} timeout="auto">
                    <List component="div" disablePadding>
                      {getAllLanguage().map((item, index) => {
                        return (
                          <ListItem
                            button
                            className={classes.textNested}
                            key={index}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={language.indexOf(item.code) !== -1}
                                  onChange={(e, val) =>
                                    this.handleChange(
                                      item.code,
                                      "language",
                                      true
                                    )
                                  }
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                  value="checkedI"
                                />
                              }
                              label={item.name}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </List>
                <Divider />
              </div>
            )}
            {pointval && (
              <div>
                <List component="nav" className={classes.root}>
                  <ListItem
                    button
                    onClick={this.handleClick.bind(this, "tagOpen")}
                  >
                    <ListItemText primary="Filter by tag" />
                    {this.state.tagOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={this.state.tagOpen} timeout="auto">
                    <TagSearch
                      classes={classes}
                      ref={(ref) => (this.tags = ref)}
                      setFilters={(selected) =>
                        this.setState({ tags: selected }, this.setFilters)
                      }
                      selected={tags}
                    />
                  </Collapse>
                </List>
                <Divider />
              </div>
            )}
          </Paper>
        </div>
      </div>
    );
  }
}

export class UserSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: this.props.users,
    };
  }
  handleChange = (e) => {
    let val = e.target.value;
    if (val.length === 0) {
      this.setState({ users: this.props.users });
    } else {
      let users = [];
      let allUsers = this.props.users;
      for (var i = 0; i < allUsers.length; i++) {
        let testingText =
          allUsers[i].username +
          " " +
          allUsers[i].first_name +
          " " +
          allUsers[i].last_name +
          " " +
          allUsers[i].email;
        if (testingText.search(val) !== -1) {
          users.push(allUsers[i]);
        }
      }
      this.setState({ users });
    }
  };
  handleSelect = (item) => {
    let { selected } = this.props;

    let index = false;
    for (var i = 0; i < selected.length; i++) {
      if (selected[i] === item.user_id) {
        index = i;
      }
    }
    if (index !== false) {
      selected.splice(index, 2);
    } else {
      selected.push(item.user_id, item.username);
    }

    this.props.setFilters(selected);
  };
  render() {
    const { classes, selected, role } = this.props;
    const { users } = this.state;
    return (
      <div style={{ padding: "0 16px" }}>
        <div className={classes.nestedSearch}>
          <div className={classes.nestedSearchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search for user"
            style={{ paddingLeft: 10 }}
            classes={{
              root: classes.nestedInputRoot,
              input: classes.nestedInputInput,
            }}
            onChange={this.handleChange}
          />
        </div>
        <List
          component="div"
          disablePadding
          style={{ maxHeight: 200, overflow: "auto" }}
        >
          {users.map((item, index) => {
            if (item.role !== role) {
              return null;
            }
            return (
              <ListItem button className={classes.textNested} key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSelected2(item, selected)}
                      onChange={(e, val) => this.handleSelect(item)}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      value="checkedI"
                    />
                  }
                  label={
                    item.first_name.length > 0
                      ? item.first_name + " " + item.last_name
                      : item.username
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  }
}

function isSelected2(user, selected) {
  let ret = false;
  for (var i = 0; i < selected.length; i++) {
    if (selected[i] === user.user_id) {
      ret = true;
    }
  }
  return ret;
}

export class TagSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topicsData: [],
      topics: [],
      open: false,
      user: JSON.parse(localStorage.getItem("user")),
      newTag: "",
      categoryData: [],
      category: []
    };
    this.timer = null;
  }
  componentDidMount() {
    if (this.props.name === 'category') {
      this.getCategories()
    } else {
      this.getTags();

    }
  }
  getCategories = async () => {
    try {
      const { data } = await axios.get(getBase(api.TOPICS), {
        headers: { Authorization: "Token " + localStorage.token },
      });
      this.setState({
        categoryData: data.data.topics
      });
    } catch (error) {
      console.log(error);
    }
  }
  getTags = async () => {
    try {
      const { data } = await axios.get(getBase(api.TAGS), {
        headers: { Authorization: "Token " + localStorage.token },
      });
      const topicData = data.data.tags.sort(function (a, b) {
        var nameA = a.topic.toUpperCase();
        var nameB = b.topic.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      this.setState({
        topicsData: topicData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleAddTag = async () => {
    const { newTag, topicsData } = this.state;
    const newTopics = [
      ...topicsData,
      { topic: newTag, _id: topicsData[topicsData.length - 1]._id + 1 },
    ];
    this.setState({ topicsData: newTopics, newTag: "" });
    try {
      await axios.post(
        getBase(api.TAGS),
        { topic: newTag },
        { headers: { Authorization: "Token " + localStorage.token } }
      );
      alert(`New tag "${newTag}" added successfully`);
    } catch (error) {
      console.log(error);
    }
  };



  handleChange = (e) => {
    const { topicsData, categoryData } = this.state;
    clearTimeout(this.timer);
    let val = e.target.value;
    if (val.length === 0) {
      if (this.props.name === 'category') {
        this.setState({
          category: this.props.type !== 2 ? this.props.selected : [],
        });
      } else {
        this.setState({
          topics: this.props.type !== 2 ? this.props.selected : [],
        });
      }
    } else {
      if (this.props.name === 'category') {
        this.setState({
          category: categoryData.filter((category) => {
            return category.topic.toLowerCase().search(val.toLowerCase()) !== -1;
          }, () => {
            console.log('category', this.state.category)
          }),
        });
      } else {
        this.setState({
          topics: topicsData.filter((topic) => {
            return topic.topic.toLowerCase().search(val.toLowerCase()) !== -1;
          }),
        });

      }
    }
  };

  handleAddTagChange = (e) => {
    this.setState({ newTag: e.target.value });
  };

  handleSelect = (item) => {
    let { selected, type } = this.props;
    if (type === 2) {
      let index = selected.indexOf(item.topic);
      if (index !== -1) {
        selected.splice(index, 1);
      } else {
        selected.push(item.topic);
      }
    } else {
      let index = false;
      for (var i = 0; i < selected.length; i++) {
        if (selected[i]._id === item._id) {
          index = i;
        }
      }
      if (index !== false) {
        selected.splice(index, 1);
      } else {
        selected.push(item);
      }
    }

    this.props.setFilters(selected);
  };
  render() {
    const { classes, selected } = this.props;
    const { topics, open, user, newTag, category, categoryData, topicsData } = this.state;
    return (
      <div>
        {user.profile.fields.role === "supervisor" && this.props.name !== 'category' && (
          <Grid
            container
            style={{
              flex: 1,
              paddingLeft: 10,
              border: "1px solid #ced4da",
              borderRadius: "5px",
              marginBottom: "20px",
            }}
          >
            <InputBase
              placeholder="Create a tag"
              onChange={this.handleAddTagChange}
              name="newTag"
              value={newTag}
              style={{ flex: 1, paddingLeft: 10 }}
              classes={{
                root: classes.nestedInputRoot,
                input: classes.nestedInputInput,
              }}
            />
            <button className="save-button" onClick={this.handleAddTag}>
              Add Tag
            </button>
          </Grid>
        )}
        <div
          className="nounderline"
          style={{
            width: "100%",
            border: "1px solid #ced4da",
            borderRadius: "5px",
            display: "flex",
            position: "relative",
          }}
        >
          <div
            className={classes.nestedSearchIcon}
            style={{ padding: "0px 8px", display: 'flex', alignItems: 'center' }}
          >
            <SearchIcon />
          </div>
          <InputBase
            placeholder={this.props.name === 'category' ? 'Search for topic' : "Search for tag"}
            style={{ flex: 1, paddingLeft: 10 }}
            classes={{
              root: classes.nestedInputRoot,
              input: classes.nestedInputInput,
            }}

            onChange={this.handleChange}
          />
          <button
            className="save-button"
            onClick={() => this.setState({ open: true })}
          >
            View All
          </button>
        </div>
        <List
          component="div"
          disablePadding
          style={{ maxHeight: 200, overflow: "auto" }}
        >
          {(this.props.name === 'category' ? category : topics).map((item, index) => {
            return (
              <ListItem className={classes.textNested} key={index} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSelected(item, selected, this.props.type)}
                      onChange={(e, val) => this.handleSelect(item)}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      value="checkedI"
                    />
                  }
                  label={item.topic}
                />
              </ListItem>
            );
          })}
        </List>
        <Dialog onClose={() => this.setState({ open: false })} open={open}>
          <Grid
            container
            alignItems="stretch"
            style={{ maxHeight: "90vh", overflow: "auto" }}
          >
            <Grid item style={{ flex: 1 }}>
              <List component="div" disablePadding>
                {(this.props.name === 'category' ? categoryData : topicsData).map((item, index) => {
                  return (
                    <ListItem button className={classes.textNested} key={index} style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <FormControlLabel
                        className={classes.formRoot}
                        control={
                          <Checkbox
                            checked={isSelected(
                              item,
                              selected,
                              this.props.type,
                            )}
                            onChange={(e, val) => this.handleSelect(item)}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            value="checkedI"
                          />
                        }
                        label={item.topic}
                      // item.topic
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item>
              <CloseIcon
                style={{ position: "sticky", top: 10 }}
                className="link"
                onClick={() => this.setState({ open: false })}
              />
            </Grid>
          </Grid>
        </Dialog>
      </div>
    );
  }
}

// export const topicData = [
//     { topic: 'Politics', _id: 1 },
//     { topic: 'Election', _id: 2 },
//     { topic: 'Business', _id: 3 },
//     { topic: 'Economy', _id: 4 },
//     { topic: 'Defence', _id: 5 },
//     { topic: 'Religion', _id: 6 },
//     { topic: 'Violence', _id: 7 },
//     { topic: 'Crime', _id: 8 },
//     { topic: 'Universal basic income', _id: 9 },
//     { topic: 'Unemployment', _id: 10 },
//     { topic: 'Demonetisation', _id: 11 },
//     { topic: 'Mumbai', _id: 12 },
//     { topic: 'Delhi', _id: 13 },
//     { topic: 'Bangalore', _id: 14 },
//     { topic: 'Pakistan', _id: 15 },
//     { topic: 'BJP', _id: 16 },
//     { topic: 'Narendra Modi / Modi', _id: 17 },
//     { topic: 'Rahul Gandhi', _id: 18 },
//     { topic: 'INC / Congress / Indian National Congress', _id: 19 },
//     { topic: 'Rafale', _id: 20 },
//     { topic: 'Government', _id: 21 },
//     { topic: 'Climate', _id: 22 },
//     { topic: 'Interviews', _id: 23 },
//     { topic: 'Army', _id: 24 },
//     { topic: 'Police', _id: 25 },
//     { topic: 'Fire', _id: 26 },
//     { topic: 'Water', _id: 27 },
//     { topic: 'terrorist', _id: 28 },
//     { topic: 'India', _id: 29 },
//     { topic: 'Bank', _id: 30 },
//     { topic: 'security', _id: 31 },
//     { topic: 'Invalid', _id: 32 },
//     { topic: 'Weather', _id: 33 },
//     { topic: 'Ruling party', _id: 34 },
//     { topic: 'Opposition', _id: 35 },
//     { topic: 'Anti- Incumbency', _id: 36 },
//     { topic: 'Scams', _id: 37 },
//     { topic: 'Rumors', _id: 38 },
//     { topic: 'Jobs', _id: 40 },
//     { topic: 'Markets', _id: 41 },
//     { topic: 'Shares', _id: 42 },
//     { topic: 'Export', _id: 43 },
//     { topic: 'Import', _id: 44 },
//     { topic: 'Goverment sector', _id: 45 },
//     { topic: 'private sector', _id: 46 },
//     { topic: 'Legal proceeding', _id: 47 },
//     { topic: 'Judiciary', _id: 48 },
//     { topic: 'Constitutuion', _id: 49 },
//     { topic: 'Parliament', _id: 50 },
//     { topic: 'Acts', _id: 51 },
//     { topic: 'environment', _id: 52 },
//     { topic: 'Healthcare', _id: 53 },
//     { topic: 'Insurance', _id: 54 },
//     { topic: 'Arts', _id: 55 },
//     { topic: 'Cinema', _id: 56 },
//     { topic: 'tourism', _id: 57 },
//     { topic: 'budget', _id: 58 },
//     { topic: 'Taxation', _id: 59 },
//     { topic: 'ISRO', _id: 60 },
//     { topic: 'NASA', _id: 61 },
//     { topic: 'RSS', _id: 62 },
//     { topic: 'Airlines', _id: 63 },
//     { topic: 'Entertainment', _id: 64 },
//     { topic: 'Satellite', _id: 65 },
//     { topic: 'MP', _id: 66 },
//     { topic: 'MLA', _id: 67 },
//     { topic: 'Constituencies', _id: 68 },
//     { topic: 'Rajya Sabha', _id: 69 },
//     { topic: 'Lok Sabha', _id: 70 },
//     { topic: 'Media', _id: 71 },
//     { topic: 'CBI', _id: 72 },
//     { topic: 'Supreme Court', _id: 73 },
//     { topic: 'Awards', _id: 74 },
//     { topic: 'Donald J Trump', _id: 75 },
//     { topic: 'USA', _id: 76 },
//     { topic: 'Democrats', _id: 77 },
//     { topic: 'Republicans', _id: 78 },
//     { topic: 'Immigration', _id: 79 },
//     { topic: 'Visa', _id: 80 },
//     { topic: 'Russia', _id: 81 },
//     { topic: 'Pollution', _id: 82 },
//     { topic: 'Natural disasters', _id: 83 },
//     { topic: 'Earthquake', _id: 84 },
//     { topic: 'Hospitality', _id: 85 },
//     { topic: 'Sports', _id: 86 },
//     { topic: 'Education', _id: 87 },
//     { topic: 'Investigation', _id: 88 },
//     { topic: 'Transportation', _id: 89 },
//     { topic: 'Technology', _id: 90 },
//     { topic: 'Results', _id: 91 },
//     { topic: 'Research', _id: 92 },
//     { topic: 'Protest', _id: 93 },
//     { topic: 'Demolition', _id: 94 },
//     { topic: 'Railways', _id: 95 },
//     { topic: 'Safety', _id: 96 },
//     { topic: 'Exams', _id: 97 },
//     { topic: 'Scandal', _id: 98 },
//     { topic: 'Notification ', _id: 99 },
//     { topic: 'Finance', _id: 100 },
//     { topic: 'Accidents', _id: 101 },
//     { topic: 'Royal Family', _id: 102 },
//     { topic: 'Animal', _id: 103 },
//     { topic: 'Obama', _id: 104 },
//     { topic: 'Statistics', _id: 105 },
//     { topic: 'United Nations', _id: 106 },
//     { topic: 'Science', _id: 107 },
//     { topic: 'Wildlife', _id: 108 },
//     { topic: 'Geology', _id: 109 },
//     { topic: 'Agriculture', _id: 110 },
//     { topic: 'Irrigation', _id: 111 },
//     { topic: 'Europe', _id: 112 },
//     { topic: 'European Union', _id: 113 },
//     { topic: 'Culture', _id: 114 },
//     { topic: 'Celebrity', _id: 115 },
//     { topic: 'Health', _id: 116 },
//     { topic: 'Disease', _id: 117 },
//     { topic: 'Charity', _id: 118 },
//     { topic: 'Welfare', _id: 119 },
//     { topic: 'Schemes', _id: 120 },
//     { topic: 'Bill', _id: 121 },
//     { topic: 'Divorce', _id: 122 },
//     { topic: 'Marriage', _id: 123 },
//     { topic: 'Vehicle', _id: 124 },
//     { topic: 'Paleoanthropology', _id: 125 },
//     { topic: 'Germany', _id: 126 },
//     { topic: 'France', _id: 127 },
//     { topic: 'Italy', _id: 128 },
//     { topic: 'Floods', _id: 129 },
//     { topic: 'Singer', _id: 130 },
//     { topic: 'Brexit Talks', _id: 131 },
//     { topic: 'Emmanuel Macron', _id: 132 },
//     { topic: 'Boris Johnson', _id: 133 },
//     { topic: 'GDP', _id: 134 },
//     { topic: 'Ireland', _id: 135 },
//     { topic: 'Refugees', _id: 136 },
//     { topic: 'Novels', _id: 137 },
//     { topic: 'Book', _id: 138 },
//     { topic: 'Note Book', _id: 139 },
//     { topic: 'Story', _id: 140 },
//     { topic: 'Food', _id: 141 },
//     { topic: 'Restaurant', _id: 142 },
//     { topic: 'Painting', _id: 143 },
//     { topic: 'Retail', _id: 144 },
//     { topic: 'Statue', _id: 145 },
//     { topic: 'Angela Merkel', _id: 146 },
//     { topic: 'UK', _id: 147 },
//     { topic: 'Electronic gadgets', _id: 148 },
//     { topic: 'policy', _id: 149 },
//     { topic: 'Apology', _id: 150 },
//     { topic: 'Disclosures', _id: 151 },
//     { topic: 'Networth', _id: 152 },
//     { topic: 'profit', _id: 153 },
//     { topic: 'community', _id: 155 },
//     { topic: 'logo', _id: 156 },
//     { topic: 'Brand', _id: 157 },
//     { topic: 'Social Media', _id: 158 },
//     { topic: 'bidding', _id: 159 },
//     { topic: 'betting', _id: 160 },
//     { topic: 'football', _id: 161 },
//     { topic: 'premier league', _id: 162 },
//     { topic: 'valuation', _id: 163 },
//     { topic: 'price', _id: 164 },
//     { topic: 'doping', _id: 165 },
//     { topic: 'atrocity', _id: 166 },
//     { topic: 'Arson', _id: 167 },
//     { topic: 'mob violence', _id: 168 },
//     { topic: 'Investment', _id: 169 },
//     { topic: 'FDI', _id: 170 },
//     { topic: 'Billionaire', _id: 171 },
//     { topic: 'Commodities', _id: 172 },
//     { topic: 'Clemency', _id: 173 },
//     { topic: 'Pardon', _id: 174 },
//     { topic: 'Viral', _id: 175 },
//     { topic: 'Morphing', _id: 176 },
//     { topic: 'Acquisition', _id: 177 },
//     { topic: 'Job report', _id: 178 },
//     { topic: 'Real estate', _id: 179 },
//     { topic: 'Exhibition', _id: 180 },
//     { topic: 'Organic', _id: 181 },
//     { topic: 'Artist', _id: 182 },
//     { topic: 'Artwork', _id: 183 },
//     { topic: 'Athlete', _id: 184 },
//     { topic: 'Nutrition', _id: 185 },
//     { topic: 'Apps', _id: 186 },
//     { topic: 'WhatsApp', _id: 187 },
//     { topic: 'Navy', _id: 188 },
//     { topic: 'MOU', _id: 189 },
//     { topic: 'Report', _id: 190 },
//     { topic: 'Lynching', _id: 191 },
//     { topic: 'Citizenship', _id: 192 },
//     { topic: 'Threat', _id: 193 },
//     { topic: 'Complaint', _id: 194 },
//     { topic: 'Course', _id: 195 },
//     { topic: 'Certification', _id: 196 },
//     { topic: 'Gaming', _id: 197 },
//     { topic: 'US Congress', _id: 198 },
//     { topic: 'Power', _id: 199 },
//     { topic: 'Intelligence', _id: 200 },
//     { topic: 'Description', _id: 201 },
//     { topic: 'Account', _id: 202 },
//     { topic: 'Record', _id: 203 },
//     { topic: 'War ', _id: 204 },
//     { topic: 'Archaeology ', _id: 205 },
//     { topic: 'Earnings', _id: 154 },
//     { topic: 'Opinion', _id: 155 },
//     { topic: 'Demons', _id: 156 },
//     { topic: 'COVID', _id: 157 },
//     { topic: 'COVID-19', _id: 158 },
//     { topic: 'Coronavirus', _id: 159 },
//     { topic: 'Corona', _id: 160 },
//     { topic: 'Coronaviruses', _id: 161 },
//     { topic: 'Lockdown', _id: 162 },

// ]

function isSelected(entity, selected, type) {
  let ret = false;
  if (type === 2) {
    ret = selected.indexOf(entity.topic) !== -1;
  } else {
    for (var i = 0; i < selected.length; i++) {
      if (selected[i]._id === entity._id) {
        ret = true;
      }
    }
  }
  return ret;
}

export default withStyles(styles)(SideBar);
