import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import CommonCard from "./commonCard";
import axios from "axios";
import api, { getBase, getUser } from "../../../../api";
import Typography from "@material-ui/core/Typography";
import { filter } from "../../NewClaims/NewClaims";
import Filter from "../../Filter/filter";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

const theme = createMuiTheme();
const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
});

class FactCheckerSimpleCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data_url: [],
      data_mm: [],
      loading: this.props.loading,
      isFilterApply: false,
      queryValue: "",
      filterTabs: this.props.activeButton,
      clearFilter: 0,
      country: false,
      offset: 0,
      total: 0,
      pageCount: 0,
      offline: this.props.offline,
    };
  }
  clearedFilter() {
    if (this.props.clearFilter === 0) {
      if (this.props.searchData.length === 0) {
        this.setState({ clearFilter: this.props.clearFilter }, () => {
          if (this.state.data.length === 0) {
            if (this.props.queryValue === "") {
              this.getData(this.props.queryValue);
              if (this.props.claimsActiveTab !== 0) {
                this.interval = setInterval(() => {
                  this.getData();
                }, 60000);
              }
            }
          }
        });
      }
    } else {
      if (this.props.queryValue === "") {
        this.getData(null);
      }
    }
  }
  componentDidMount() {
    this.getData(null);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchData.length > 0 && nextProps.clearFilter === 0) {
      this.setState(
        {
          data: nextProps.searchData,
        },
        () => this.props.dispatch({ type: "SET_LOADING", loading: false })
      );
    }
    if (nextProps.loading !== this.state.loading) {
      this.setState({ loading: nextProps.loading });
    }
    if (nextProps.clearFilter) {
      this.getData();
    }
    if (nextProps.activeButton !== this.state.filterTabs) {
      this.setState({ filterTabs: nextProps.activeButton });
    }
    if (
      nextProps.datapoint === 2 &&
      nextProps.rejected &&
      nextProps.searchData.length == 0
    ) {
      let reduxData = JSON.parse(nextProps.rejected);
      this.setState({ data: reduxData }, () => {
        this.props.dispatch({ type: "SET_LOADING", loading: false });
      });
    }
    if (
      nextProps.datapoint === 3 &&
      nextProps.makingJudgement.url &&
      nextProps.searchData.length == 0
    ) {
      let reduxData = JSON.parse(nextProps.makingJudgement.url);
      this.setState({ data_url: reduxData }, () => {
        nextProps.activeButton === "URL claims" &&
          this.props.dispatch({ type: "SET_LOADING", loading: false });
      });
    }
    if (
      nextProps.makingJudgement.multimedia &&
      nextProps.searchData.length == 0
    ) {
      let reduxData = JSON.parse(nextProps.makingJudgement.multimedia);
      this.setState({ data_mm: reduxData }, () => {
        nextProps.activeButton === "Multimedia claims" &&
          this.props.dispatch({ type: "SET_LOADING", loading: false });
      });
    }
    if (nextProps.offline !== this.state.offline) {
      this.setState({ offline: nextProps.offline });
    }
    if (!nextProps.offline && this.state.offline) {
      this.getData();
    }
  }

  multiFilteredData = (val) => {
    const { searchData } = this.props;
    if (searchData.length > 0) {
      this.props.dispatch({ type: "NAVCLAIMS", navClaims: val });
    } else {
      this.getData(null);
    }
  };

  stopLoaders = () => {
    this.props.dispatch({ type: "SET_LOADING", loading: false });
    this.props.dispatch({
      type: "SET_LOADING_SPIN",
      loading_spin: false,
    });
  };

  getData = async () => {
    let { filterTabs, offset, data_url, data_mm } = this.state;
    let { datapoint, loading } = this.props;
    this.props.dispatch({
      type: "SET_LOADING_SPIN",
      loading_spin: true,
    });
    try {
      let url = "";
      let header = "";
      if (datapoint === 1) {
        if (filterTabs !== "Multimedia claims") {
          url = getBase(api.FACT_CHECKS_NEW + `?limit=100&offset=${offset}`);
        } else {
          url = getBase(
            api.FACT_CHECKS_NEW + `?type=image&limit=100&offset=${offset}`
          );
        }
        header = {
          headers: { Authorization: "Token " + localStorage.token },
        };
      }
      if (datapoint === 2) {
        url = getBase(
          api.FACT_CHECKS_REJECTED +
          `?reject_reason=&limit=100&offset=${offset}`
        );
        header = {
          headers: { Authorization: "Token " + localStorage.token },
        };
      }
      if (datapoint === 3) {
        if (filterTabs !== "Multimedia claims") {
          url = getBase(
            api.FACT_CHECKS_PENDING + `?limit=100&offset=${offset}`
          );
        } else {
          url = getBase(
            api.FACT_CHECKS_PENDING + `?type=image&limit=100&offset=${offset}`
          );
        }
        header = {
          headers: { Authorization: "Token " + localStorage.token },
        };
      }
      if (datapoint === 4) {
        if (filterTabs !== "Multimedia claims")
          url = getBase(
            api.FACT_CHECKS_RESOLVED + `?limit=100&offset=${offset}`
          );
        else
          url = getBase(
            api.FACT_CHECKS_RESOLVED + `?type=image&limit=100&offset=${offset}`
          );
        header = {
          headers: { Authorization: "Token " + localStorage.token },
        };
      }
      if (datapoint === 5) {
        if (filterTabs === "India") {
          url = getUser(api.HERO_FACTCHECKS + `?location=IN`);
        } else if (filterTabs === "UK") {
          url = getUser(api.HERO_FACTCHECKS + `?location=GB`);
        } else if (filterTabs === "USA") {
          url = getUser(api.HERO_FACTCHECKS + `?location=US`);
        } else {
          url = getUser(api.HERO_FACTCHECKS + "?location=IN&limit=100");
        }
      }
      const { data } = await axios.get(url, header);

      if (datapoint === 2) {
        if (this.props.rejected !== JSON.stringify(data.data)) {
          this.setState({ data: data.data }, () => {
            this.stopLoaders();
            this.props.dispatch({
              type: "REJECTED_CLAIMS",
              rejected: JSON.stringify(data.data),
            });
          });
        } else {
          let reduxData = JSON.parse(this.props.rejected);
          this.setState({ data: reduxData }, () => {
            this.stopLoaders();
          });
        }
      }
      if (datapoint === 3) {
        const isUrlClaimsTab = filterTabs === "URL claims";
        const urlClaims = this.props.makingJudgement.url;
        const mmClaims = this.props.makingJudgement.multimedia;
        if (
          (isUrlClaimsTab ? urlClaims : mmClaims) !== JSON.stringify(data.data)
        ) {
          if (
            JSON.stringify(isUrlClaimsTab ? data_url : data_mm) !==
            JSON.stringify(data.data)
          ) {
            if (isUrlClaimsTab) {
              this.setState({ data_url: data.data }, () => {
                this.stopLoaders();
                this.props.dispatch({
                  type: "MAKING_JUDGEMENT_URL",
                  making_Judgement: JSON.stringify(data.data),
                });
              });
            } else {
              this.setState({ data_mm: data.data }, () => {
                this.stopLoaders();
                this.props.dispatch({
                  type: "MAKING_JUDGEMENT_MM",
                  making_Judgement: JSON.stringify(data.data),
                });
              });
            }
          } else if (loading) {
            this.stopLoaders();
          }
        } else {
          const urlClaims = this.props.makingJudgement.url;
          const mmClaims = this.props.makingJudgement.multimedia;
          let reduxData = JSON.parse(isUrlClaimsTab ? urlClaims : mmClaims);
          if (isUrlClaimsTab) {
            this.setState({ data_url: reduxData }, () => {
              this.stopLoaders();
            });
          } else {
            this.setState({ data_mm: reduxData }, () => {
              this.stopLoaders();
            });
          }
        }
      } else {
        this.setState({
          data: data.data,
        });
        this.stopLoaders();
      }
    } catch (err) {
      this.stopLoaders();
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  ifError() {
    let { data_url, data_mm, filterTabs } = this.state;
    let { datapoint } = this.props;
    let data =
      datapoint === 3
        ? filterTabs === "URL claims"
          ? data_url
          : data_mm
        : (data = this.state.data);
    const { classes } = this.props;
    if (!data) {
      data = [];
    }
    if (data.length === 0) {
      return (
        <div className={classes.root}>
          <Typography>No records found!</Typography>
        </div>
      );
    }
    if (data === false) {
      return (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography>
            Oops, looks like something went wrong. Please try again later
          </Typography>
        </div>
      );
    }
    return null;
  }
  handleShowAcceptedClaims(val) {
    console.log("show accepted claims", val);
    this.setState({ showAcceptedClaims: val }, () => {
      this.getData(null);
    });
  }
  handleClicks(offset) {
    this.setState({ offset }, () => {
      this.getData(this.state.queryValue);
    });
  }
  reloadwindow = () => {
    window.location.reload();
  };
  render() {
    const { data_url, data_mm, filterTabs } = this.state;
    const {
      filters,
      users,
      searchVal,
      isSidebar,
      filterOptions,
      datapoint,
      userHashTable,
      loading,
    } = this.props;
    const { buttons, checkbox, assigned, reason, acceptButton } = filterOptions;

    let data =
      datapoint === 3
        ? filterTabs === "URL claims"
          ? data_url
          : data_mm
        : (data = this.state.data);

    if (Object.keys(userHashTable).length === 0) {
      return !loading ? (
        <div>
          Something Seems Wrong Click{" "}
          <button
            style={{ border: 0, fontSize: 15, textDecoration: "underline" }}
            onClick={this.reloadwindow}
          >
            {" "}
            here{" "}
          </button>
          to Reload
        </div>
      ) : null;
    }
    return (
      <>
        <Filter
          activeIndex={filterTabs}
          length={data && data.length}
          buttons={buttons ? buttons.split(",") : false}
          filterbutton={(val) => {
            this.setState({ filterTabs: val }, () =>
              this.multiFilteredData(val)
            );
          }}
          countrySelected={(country) => {
            if (country !== "All") {
              this.setState({ country: country }, () => {
                this.getData(null);
              });
            } else {
              this.setState({ country: false }, () => {
                this.getData(null);
              });
            }
          }}
          checkbox={checkbox}
          showingClaims={true}
          checkboxValue={(val) => this.handleShowAcceptedClaims(val)}
          previewButton={true}
        />
        {this.ifError()}

        <Grid container spacing={24}>
          {data &&
            datapoint !== 5 &&
            filter(data, filters, searchVal).map((claim, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={isSidebar ? 6 : 4}
                  md={isSidebar ? 4 : 3}
                  key={index}
                >
                  <CommonCard
                    data={claim}
                    users={users}
                    assigned={assigned}
                    images={false}
                    reason={reason}
                    acceptButton={acceptButton}
                    userHashTable={userHashTable}
                    filtertabs={filterTabs}
                  />
                </Grid>
              );
            })}
          {data &&
            !loading &&
            datapoint === 5 &&
            filter(data, filters, searchVal).map((claim, index) => {
              return (
                <>
                  {claim.selected && (
                    <Grid
                      item
                      xs={12}
                      sm={isSidebar ? 6 : 4}
                      md={isSidebar ? 4 : 3}
                      key={index}
                    >
                      <CommonCard
                        data={claim}
                        users={users}
                        assigned={assigned}
                        images={true}
                        reason={reason}
                        acceptButton={acceptButton}
                        userHashTable={userHashTable}
                      />
                    </Grid>
                  )}
                </>
              );
            })}
        </Grid>

        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Pagination
            limit={100}
            offset={this.state.offset}
            total={this.state.total}
            onClick={(e, offset) => this.handleClicks(offset)}
          />
        </MuiThemeProvider>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    navClaims: state.navClaims,
    userHashTable: state.userHashTable,
    loading: state.loading,
    activeButton: state.activeButton,
    datapoint: state.claimsActiveTab,
    rejected: state.rejected,
    makingJudgement: state.making_Judgement,
    offline: state.offline,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(FactCheckerSimpleCard)
);
