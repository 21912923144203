/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { Link } from 'react-router-dom';
import pagenotfoundimg from "../assets/404 error.png";
import Grid from '@material-ui/core/Grid';

function pagenotfound () {
  let user = JSON.parse(localStorage.user);
    return(
    <div>
      <div className="profile-container">
      <Grid container alignItems="center" className="dash-user-greet" paddingltft="20px"display= 'flex'>
                    <img src="/static/media/wave.fb87d0a5.png" alt="wave"></img>
                    <h2>Hello, {user.username}</h2>
                </Grid>
      <div style={{ display: 'flex' ,textAlign:'center',paddingTop:'40px',flexDirection:"column", alignItems:"center",justifyItems:"center"}}>
        <img src={pagenotfoundimg} alt="pagenotfoundimg" width='300' textAlign="center"></img>
      </div>
      <Grid >
        <div style={{textAlign:'center',paddingTop:'20px',color:"rgba(246, 36, 89, 1)",font: 'normal normal bold Mulish'}}>
           Page not found 
           
        <Link style={{ color: '#2D65FF' }} to="/">
                {"   Go Home"}
        </Link>
        
        </div>
        </Grid>
        </div>
        </div>
    );
}
  
export default pagenotfound;