import React from "react";

const ClaimsIcon = ({ fill, styles }) => {
  return (
    <svg viewBox="0 0 26.4 26" fill={fill} style={styles}>
      <path d="M25 10.9c-.6 0-1 .4-1 1V13c0 6.1-4.9 11-11 11-2.9 0-5.7-1.1-7.8-3.2C3.1 18.7 2 15.9 2 13c0-2.9 1.1-5.7 3.2-7.8C7.3 3.2 10.1 2 13 2c1.5 0 3.1.3 4.5 1 .5.2 1.1 0 1.3-.5.2-.5 0-1.1-.5-1.3C16.6.4 14.8 0 13 0 9.5 0 6.3 1.4 3.8 3.8 1.4 6.3 0 9.5 0 13c0 3.5 1.3 6.7 3.8 9.2C6.3 24.7 9.5 26 13 26c7.2 0 13-5.8 13-13v-1.1c0-.5-.4-1-1-1z" />
      <path d="M10.1 11.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.6 3.6c.2.2.4.3.7.3.3 0 .5-.1.7-.3l12-12c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L13 14l-2.9-2.9z" />
    </svg>
  );
};

export default ClaimsIcon;
