import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { getTimeDifference } from "../../../../utils/time";
import axios from "axios";
import api, { getBase, getUser } from "../../../../api";
import { Link } from "react-router-dom";
import { filter } from "../../NewClaims/NewClaims";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import HtmlTooltip from "@material-ui/core/Tooltip";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { colours } from "../../../../utils/common";
import StarRatings from "react-star-ratings";
import Filter from "../../Filter/filter";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { connect } from "react-redux";
import { formatDateOnly } from "../../../../utils/fomratDate";
import { handleSort } from "../../NewClaims/NewClaims";

const theme = createMuiTheme();

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    boxshadow: 2,
  },
  paper1: {
    padding: theme.spacing.unit * 0,
    color: theme.palette.text.secondary,
  },
  fontFamily: ["Roboto"].join(","),
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
  paragraphText: {
    position: "relative",
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "15px",
    maxHeight: 3029,
  },
  chip: {
    position: "relative",
    fontWeight: 600,
    minWidth: `${40}%`,
    margin: 4,
  },
  textHistory: {
    fontSize: 13,
  },
  tr: {
    background: "white",
    "&:hover": {
      background: "#F1F4F8",
    },
  },
});

class FactCheckerUrl extends Component {
  constructor(props) {
    super(props);
    let diff;
    var date_from = new Date();
    let year = moment(date_from).format("YYYY");
    let days = moment(date_from).format("DD");
    var a = moment([2017, 0, 1]);
    var b = moment([year, 0, days]);
    diff = a.diff(b, "days");
    date_from.setDate(date_from.getDate() + diff);
    this.state = {
      data_url: [],
      data_mm: [],
      loading: this.props.loading,
      expanded: false,
      offset: 0,
      total: 0,
      claim_checked: [],
      selectedValue: false,
      highlights: false,
      disabled: false,
      startDate: new Date(),
      dataPreview: [],
      getDate: new Date(),
      modalIsOpen: false,
      Deselectchecked: false,
      isFilterApply: false,
      queryValue: "",
      users: [],
      pageNumbers: [],
      currentPage: 1,
      todosPerPage: 100,
      pageCount: 0,
      allData: [],
      searchVal: "",
      dataSort: {
        verdict: "asc",
        time: "asc",
      },
      queryValueRange: {
        startDate: date_from,
        endDate: new Date(),
        key: "queryValue",
      },
      slection: this.props.queryValue,
      openDate: false,
      filterTabs: this.props.activeTab,
      heroFC: 1,
      checked: 1,
      country: false,
      selectedHeroFC: [],
      offline: this.props.offline,
    };
  }

  componentDidMount() {
    let userhash = this.props.userHashTable;
    this.getData(this.state.queryValue);
    console.log(userhash);
  }
  handleChangedate = (date) => {
    let Dates = JSON.stringify(date);
    Dates = Dates.toString()
      .replace("T", " ")
      .split(" ")[0];
    Dates = Dates.toString().replace('"', "");
    this.setState({
      getDate: Dates,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.queryValue !== nextProps.queryValue) {
      let queryValue = nextProps.queryValue;
      this.setState(
        { isFilterApply: nextProps.isFilterApply, queryValue },
        () => {
          this.getData(queryValue);
        }
      );
    }
    if (nextProps.loading !== this.state.loading) {
      this.setState({ loading: nextProps.loading });
    }
    if (nextProps.activeTab !== this.state.filterTabs) {
      this.setState({ filterTabs: nextProps.activeTab });
    }
    if (nextProps.completed.url && nextProps.searchData.length === 0) {
      let reduxData = JSON.parse(nextProps.completed.url);
      this.setState({ data_url: reduxData }, () => {
        nextProps.activeTab === "URL claims" &&
          this.props.dispatch({ type: "SET_LOADING", loading: false });
      });
    }
    if (nextProps.completed.multimedia && nextProps.searchData.length === 0) {
      let reduxData = JSON.parse(nextProps.completed.multimedia);
      this.setState({ data_mm: reduxData }, () => {
        nextProps.activeTab === "Multimedia claims" &&
          this.props.dispatch({ type: "SET_LOADING", loading: false });
      });
    }
    if (nextProps.offline !== this.state.offline) {
      this.setState({ offline: nextProps.offline });
    }
    if (!nextProps.offline && this.state.offline) {
      this.getData();
    }
  }

  stopLoaders = () => {
    this.props.dispatch({ type: "SET_LOADING", loading: false });
    this.props.dispatch({
      type: "SET_LOADING_SPIN",
      loading_spin: false,
    });
  };

  getData = async (queryValue) => {
    let {
      country,
      isFilterApply,
      filterTabs,
      offset,
      loading,
      data_url,
      data_mm,
    } = this.state;
    this.props.dispatch({
      type: "SET_LOADING_SPIN",
      loading_spin: true,
    });
    try {
      let url = "";
      let header = "";
      if (isFilterApply) {
        url = getUser(
          api.SEARCH_FACTCHECKS +
            "?tab=completed&location=&limit=100&offset=" +
            offset +
            "&" +
            queryValue
        );
        header = { headers: {} };
      } else {
        if (filterTabs !== "Multimedia claims") {
          this.getDateFilter();
          if (country) {
            url = getUser(api.SEARCH_FACTCHECKS);
          } else {
            url = getBase(
              api.FACT_CHECKS_RESOLVED + "?location=&limit=100&offset=" + offset
            );
          }
        } else {
          if (country) {
            url = getBase(
              api.FACT_CHECKS_RESOLVED +
                `?type=image&location=${country}&limit=100&offset=` +
                offset
            );
          } else {
            url = getBase(
              api.FACT_CHECKS_RESOLVED +
                "?type=image&location=IN" +
                "&limit=100&offset=" +
                offset
            );
          }
        }
        header = { headers: { Authorization: "Token " + localStorage.token } };
      }
      if (country) {
        let user = JSON.parse(localStorage.user);
        const res = await axios.post(
          url,
          this.getDateFilter(user.id),
          (header = {})
        );
        const isUrlClaimsTab = filterTabs === "URL claims";
        if (
          (isUrlClaimsTab
            ? this.props.completed.url
            : this.props.completed.multimedia) !== JSON.stringify(res.data.data)
        ) {
          if (
            JSON.stringify(isUrlClaimsTab ? data_url : data_mm) !==
            JSON.stringify(res.data.data)
          ) {
            if (isUrlClaimsTab) {
              this.setState({ data_url: res.data.data }, () => {
                this.stopLoaders();
                this.props.dispatch({
                  type: "COMPLETED_URL",
                  completed: JSON.stringify(res.data.data),
                });
              });
            } else {
              this.setState({ data_mm: res.data.data }, () => {
                this.stopLoaders();
                this.props.dispatch({
                  type: "COMPLETED_MM",
                  completed: JSON.stringify(res.data.data),
                });
              });
            }
          } else if (loading) {
            this.stopLoaders();
          }
        } else {
          let reduxData = JSON.parse(
            isUrlClaimsTab
              ? this.props.completed.url
              : this.props.completed.multimedia
          );
          if (isUrlClaimsTab) {
            this.setState({ data_url: reduxData, loading: false }, () => {
              this.stopLoaders();
            });
          } else {
            this.setState({ data_mm: reduxData, loading: false }, () => {
              this.stopLoaders();
            });
          }
        }
      } else {
        const res = await axios.get(url, header);
        const isUrlClaimsTab = filterTabs === "URL claims";
        if (
          (isUrlClaimsTab
            ? this.props.completed.url
            : this.props.completed.multimedia) !== JSON.stringify(res.data.data)
        ) {
          if (
            JSON.stringify(isUrlClaimsTab ? data_url : data_mm) !==
            JSON.stringify(res.data.data)
          ) {
            if (isUrlClaimsTab) {
              this.setState({ data_url: res.data.data }, () => {
                this.stopLoaders();
                this.props.dispatch({
                  type: "COMPLETED_URL",
                  completed: JSON.stringify(res.data.data),
                });
              });
            } else {
              this.setState({ data_mm: res.data.data }, () => {
                this.stopLoaders();
                this.props.dispatch({
                  type: "COMPLETED_MM",
                  completed: JSON.stringify(res.data.data),
                });
              });
            }
          } else if (loading) {
            this.stopLoaders();
          }
        } else {
          let reduxData = JSON.parse(
            isUrlClaimsTab
              ? this.props.completed.url
              : this.props.completed.multimedia
          );
          if (isUrlClaimsTab) {
            this.setState({ data_url: reduxData, loading: false }, () => {
              this.stopLoaders();
            });
          } else {
            this.setState({ data_mm: reduxData, loading: false }, () => {
              this.stopLoaders();
            });
          }
        }
      }
    } catch (err) {
      this.stopLoaders();
    }
  };

  getDateFilter(id) {
    const { country } = this.state;
    let now = moment().format("YYYY-MM-DD");
    let body = {
      factchecker_id: [`${id}`],
      start_date: now,
    };
    let duration = [
      "Last week",
      "Last month",
      "Last 3 months",
      "Last 6 months",
      "Last 1 year",
      "Last 2 years",
    ];
    console.log(duration, country);
    if (country === duration[0]) {
      let later = moment()
        .subtract(1, "w")
        .format("YYYY-MM-DD");
      body.end_date = later;
    }
    if (country === duration[1]) {
      let later = moment()
        .subtract(1, "M")
        .format("YYYY-MM-DD");
      body.end_date = later;
    }
    if (country === duration[2]) {
      let later = moment()
        .subtract(3, "M")
        .format("YYYY-MM-DD");
      body.end_date = later;
    }
    if (country === duration[3]) {
      let later = moment()
        .subtract(6, "M")
        .format("YYYY-MM-DD");
      body.end_date = later;
    }
    if (country === duration[4]) {
      let later = moment()
        .subtract(1, "y")
        .format("YYYY-MM-DD");
      body.end_date = later;
    }
    if (country === duration[5]) {
      let later = moment()
        .subtract(2, "y")
        .format("YYYY-MM-DD");
      body.end_date = later;
    }
    return body;
  }
  handleClicks(offset) {
    this.setState({ offset }, () => {
      this.getData(this.state.querValue);
    });
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  claimOrImage(claim) {
    let { filterTabs } = this.state;
    const { classes } = this.props;

    if (filterTabs !== "Multimedia claims") {
      return (
        <Typography
          component="p"
          className={classes.paragraphText + " maxlines2"}
          style={{ fontWeight: "bold" }}
        >
          {claim.modified_claim}
        </Typography>
      );
    } else {
      return (
        <HtmlTooltip
          title={
            <React.Fragment>
              <img
                src={claim.recheck_image}
                alt={claim.recheck_message}
                style={{ marginTop: "3px", minWidth: 300 }}
                height="210"
                width="auto"
              />
            </React.Fragment>
          }
        >
          <img
            src={claim.recheck_image}
            alt={claim.recheck_message}
            style={{
              padding: "4px",
              border: "1px solid #ddd",
              marginTop: "4px",
              background: "#fff",
            }}
            height="70"
            width="auto"
          />
        </HtmlTooltip>
      );
    }
  }
  trackingid(claim) {
    if (claim.tracking_id) {
      return claim.tracking_id;
    } else {
      return claim._id;
    }
  }

  ifError() {
    const { data_url, data_mm, filterTabs } = this.state;
    const data = filterTabs === "URL claims" ? data_url : data_mm;
    const { classes } = this.props;
    if (data.length === 0) {
      return (
        <div className={classes.root}>
          <Typography>No records found!</Typography>
        </div>
      );
    }
    if (data === false) {
      return (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography>
            Oops, looks like something went wrong. Please try again later
          </Typography>
        </div>
      );
    }
    return null;
  }

  render() {
    const { data_url, data_mm, filterTabs, dataSort } = this.state;
    const {
      classes,
      searchVal,
      sort,
      filterOptions,
      userHashTable,
      loading,
    } = this.props;
    const { buttons, dropDown, dropDownOptions } = filterOptions;

    const data = filterTabs === "URL claims" ? data_url : data_mm;

    let allData = filter(data, filter, searchVal, sort);
    if (allData.length === 0 && this.state.total > data.length) {
      this.getData();
    }

    let faster = {};
    let Complaints = {};

    return (
      <div className={classes.root}>
        <Filter
          length={data.length}
          buttons={buttons ? buttons.split(",") : false}
          activeIndex={filterTabs}
          filterbutton={(val) => {
            this.setState({ filterTabs: val }, () => this.getData(null));
          }}
          countrySelected={(country) => {
            if (country !== "All") {
              this.setState({ country: country }, () => {
                this.getData(null);
              });
            } else {
              this.setState({ country: false }, () => {
                this.getData(null);
              });
            }
          }}
          dropDown={dropDown}
          dropDownOptions={dropDownOptions}
          showingClaims={this.state.filterTabs !== "Hero fact checks"}
          checkboxValue={(val) => this.handleShowAcceptedClaims(val)}
        />
        {this.ifError()}
        {!loading && data.length > 0 && (
          <Grid container hover="true">
            <Paper
              className={classes.paper1}
              elevation={0}
              style={{
                marginBottom: 16,
                width: "100%",
                padding: 8,
                paddingLeft: 16,
                paddingRight: 16,
                backgroundColor: faster.backgroundColor,
                border: Complaints.border,
                borderRadius: 10,
              }}
            >
              <Grid
                container
                spacing={16}
                alignItems="center"
                style={{
                  padding: 8,
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  style={{
                    backgroundColor: "#f1f4f8",
                    borderRadius: "10px",
                    paddingTop: 15,
                    paddingBottom: 15,
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item sm={1} md={1}>
                      <Typography>Claim ID</Typography>
                    </Grid>
                    <Grid item sm={1} md={1}>
                      {/* <Typography style={{ position: "relative", top: "-3px" }}>
                        FC date
                        <span style={{ position: "absolute", marginTop: 3 }}>
                          <ArrowDropUpIcon size="small" fontSize="small" />
                        </span>
                        <span
                          style={{
                            position: "relative",
                            top: "8px",
                            marginTop: 5,
                          }}
                        >
                          <ArrowDropDownIcon size="small" fontSize="small" />
                        </span>
                      </Typography> */}
                      <Typography
                        style={{
                          cursor: "pointer",
                          paddingBottom: "5px",
                        }}
                        onClick={() => {
                          this.setState({
                            dataSort: {
                              ...dataSort,
                              time: dataSort.time === "desc" ? "asc" : "desc",
                              data: handleSort("FC Date", dataSort.time, data),
                            },
                          });
                        }}
                      >
                        FC date{" "}
                        {dataSort.time === "asc" ? (
                          <ArrowDropUpIcon
                            style={{
                              position: "relative",
                              top: 5,
                            }}
                            size="small"
                            fontSize="small"
                          />
                        ) : (
                          <ArrowDropDownIcon
                            style={{
                              position: "relative",
                              top: 5,
                            }}
                            size="small"
                            fontSize="small"
                          />
                        )}{" "}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} md={6} style={{ paddingLeft: 20 }}>
                      <Typography>Claim</Typography>
                    </Grid>
                    <Grid item sm={2} md={2}>
                      <Typography style={{ position: "relative", top: "2px" }}>
                        Rating{" "}
                      </Typography>
                    </Grid>
                    <Grid item sm={2} md={2} style={{ textAlign: "center" }}>
                      <Typography
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.setState({
                            dataSort: {
                              ...dataSort,
                              verdict:
                                dataSort.verdict === "desc" ? "asc" : "desc",
                              data: handleSort(
                                "verdict",
                                dataSort.verdict,
                                data
                              ),
                            },
                          });
                        }}
                      >
                        Verdict{" "}
                        {dataSort.verdict === "asc" ? (
                          <ArrowDropUpIcon
                            style={{
                              position: "relative",
                              top: 5,
                            }}
                            size="small"
                            fontSize="small"
                          />
                        ) : (
                          <ArrowDropDownIcon
                            style={{
                              position: "relative",
                              top: 5,
                            }}
                            size="small"
                            fontSize="small"
                          />
                        )}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {data.map((claim, index) => {
                return (
                  <Grid hover="true" container spacing={16} key={index}>
                    <Grid item lg={12} md={12}>
                      <Grid container>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography style={{ color: "white" }}>
                                {claim.modified_claim}
                              </Typography>
                              <Typography color="inherit">
                                FactChecker History
                              </Typography>
                              {userHashTable && (
                                <>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.factchecker_id && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Current Factchecker</b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.factchecker_id]
                                            .username
                                        }
                                        {claim.factcheck_completed_at &&
                                          claim.factcheck_started_at &&
                                          (claim.factcheck_started_at &&
                                            claim.factcheck_completed_at) && (
                                            <strong>
                                              {" "}
                                              (
                                              {getTimeDifference(
                                                claim.factcheck_started_at,
                                                claim.factcheck_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.old_factchecker_id && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Old Factchecker </b>
                                        </u>
                                        :
                                        {
                                          userHashTable[
                                            claim.old_factchecker_id
                                          ].username
                                        }
                                        {claim.oldfactcheck_completed_at &&
                                          (claim.oldfactcheck_started_at &&
                                            claim.oldfactcheck_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.oldfactcheck_started_at,
                                                claim.oldfactcheck_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.moderator_id && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Current Moderator </b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.moderator_id]
                                            .username
                                        }
                                        {claim.moderator_completed_at &&
                                          claim.moderator_started_at &&
                                          (claim.moderator_started_at &&
                                            claim.moderator_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.moderator_started_at,
                                                claim.moderator_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.old_moderator_id && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Old Moderator </b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.old_moderator_id]
                                            .username
                                        }
                                        {claim.oldmoderator_completed_at &&
                                          (claim.oldmoderator_started_at &&
                                            claim.oldmoderator_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.oldmoderator_started_at,
                                                claim.oldmoderator_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.supervisor_id && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Current Supervisor </b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.supervisor_id]
                                            .username
                                        }
                                        {claim.supervisor_completed_at &&
                                          (claim.supervisor_started_at &&
                                            claim.supervisor_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.supervisor_started_at,
                                                claim.supervisor_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>

                                  <div style={{ textTransform: "capitalize" }}>
                                    {claim.old_supervisor_id && (
                                      <em className={classes.textHistory}>
                                        <u>
                                          <b>Old Supervisor</b>
                                        </u>
                                        :
                                        {
                                          userHashTable[claim.old_supervisor_id]
                                            .username
                                        }
                                        {claim.oldsupervisor_completed_at &&
                                          (claim.oldsupervisor_started_at &&
                                            claim.oldsupervisor_completed_at) && (
                                            <strong>
                                              (
                                              {getTimeDifference(
                                                claim.oldsupervisor_started_at,
                                                claim.oldsupervisor_completed_at
                                              )}
                                              )
                                            </strong>
                                          )}
                                      </em>
                                    )}
                                  </div>
                                </>
                              )}
                            </React.Fragment>
                          }
                        >
                          <Paper
                            className={(classes.paper1, classes.tr)}
                            elevation={1}
                            key={index}
                            style={{
                              marginBottom: 16,
                              width: "100%",
                              padding: 8,
                              border: Complaints.border,
                            }}
                          >
                            <Link to={"/claim/" + claim._id}>
                              <Grid container alignItems="center">
                                <Grid item sm={1} md={1}>
                                  <Typography style={{ marginTop: 5 }}>
                                    {this.trackingid(claim)}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  sm={1}
                                  md={1}
                                  style={{ textAlign: "center" }}
                                >
                                  <Typography
                                    component="p"
                                    style={{
                                      display: "flex",
                                      fontSize: "small",
                                    }}
                                  >
                                    {formatDateOnly(
                                      claim.factcheck_completed_at
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  sm={6}
                                  md={6}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: 20,
                                  }}
                                >
                                  {this.claimOrImage(claim)}
                                </Grid>

                                <Grid
                                  item
                                  sm={2}
                                  md={2}
                                  style={{ textAlign: "center" }}
                                >
                                  {claim.rating > 0 && (
                                    <StarRatings
                                      rating={Number(claim.rating)}
                                      starDimension="20px"
                                      starSpacing="1px"
                                      starRatedColor="#ffd700"
                                    />
                                  )}
                                </Grid>
                                <Grid item sm={2} md={2}>
                                  {claim.verdict !== "" && (
                                    <div
                                      style={{
                                        backgroundColor: colours[claim.verdict],
                                        padding: "8px 14px",
                                        maxWidth: "100%",
                                        borderRadius: 4,
                                        marginLeft: "auto",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "600",
                                          textTransform: "uppercase",
                                          textAlign: "center",
                                          color:
                                            colours[claim.verdict] === "#e9ff00"
                                              ? "#000"
                                              : "#fff",
                                        }}
                                      >
                                        {claim.verdict.replace("_", " ")}
                                      </Typography>
                                    </div>
                                  )}
                                </Grid>
                                {claim.editorColumn && (
                                  <Grid item sm={2} md={2}>
                                    {claim.editorColumn.tags.map(
                                      (tag, index) => {
                                        return (
                                          <Chip
                                            label={tag}
                                            className={classes.chip}
                                            variant="outlined"
                                            key={index}
                                          />
                                        );
                                      }
                                    )}
                                  </Grid>
                                )}
                              </Grid>
                            </Link>
                          </Paper>
                        </HtmlTooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Paper>

            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <Pagination
                limit={100}
                offset={this.state.offset}
                total={this.state.total}
                onClick={(e, offset) => this.handleClicks(offset)}
              />
            </MuiThemeProvider>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    userHashTable: data.userHashTable,
    loading: data.loading,
    completed: data.completed,
    activeTab: data.activeButton,
    offline: data.offline,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(FactCheckerUrl));
