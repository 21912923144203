import { Card, Paper, Typography, CardActions, CardContent, Grid } from '@material-ui/core'
import React, { Component } from 'react'
import "./admin.css"
import { createBrowserHistory } from 'history'
import { Link } from 'react-router-dom';

const styles = (theme) => ({
    paper: {
        backgroundColor: "#fffff",
        textAalign: "center",
        padding: 15,
        borderRadius: 15,
        height: "100%",
    },
    card: {
        minWidth: 275,
        minHeight: 275,

    },
    cardContent: {
        paddingLeft: 8
    }
});
const names = ['Topics',
    'Claim Flow',
    'Users Permission',
    'Publishing Flow',
    'Verdicts',
    'Sources'
]
const history = createBrowserHistory();
export class Admin extends Component {
    constructor(props) {
        super()
    }
    routePath = (path) => {
        if (history.location.pathname === '/admin/') {
            return `${path.toLowerCase()}`
        }
        return `admin/${path.toLowerCase()}`
    }
    render() {
        return (
            <div className="main">
                <Grid container spacing={16}>
                    {names.map((item) => {
                        return <Grid item md={6} key={item} className={styles.grid}>
                            <Paper elevation={1}><Card className={styles.card} elevation={0}>
                                <CardContent className={styles.cardContent}>
                                    <Typography>{item}</Typography>
                                </CardContent>
                                <CardActions>
                                    <Link className='save-button w-max' to={this.routePath(item.split(" ")[0])}>Visit {item.split(" ")[0]}</Link>
                                </CardActions>
                            </Card>
                            </Paper>
                        </Grid>
                    })}
                </Grid>
            </div>
        )
    }
}

export default Admin