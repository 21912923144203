import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Select } from "@material-ui/core";
import { getLocation } from "../../../utils/common";
import { connect } from "react-redux";
import Badge from "@material-ui/core/Badge";
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';

const styles = () => ({
  root: {
    flexGrow: 1,
    position: "relative",
    marginLeft: 10,
  },
  margin: {
    marginRight: "5%",
  },
});

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      expanded: false,
      offset: 0,
      total: 0,
      claim_checked: [],
      selectedValue: false,
      highlights: false,
      disabled: false,
      startDate: new Date(),
      dataPreview: [],
      getDate: new Date(),
      modalIsOpen: false,
      Deselectchecked: false,
      isFilterApply: false,
      queryValue: "",
      pageNumbers: [],
      anchorEl: null,
      activeButton: this.getActiveBtn(),
      countrySelected: "All",
      checkboxVal: false,
      searchData: this.props.searchData,
      showCount: "",
      length: 0,
      activeTab: this.props.activeTab,
      autoRefresh: true,
      timer: this.props.timer
    };
  }

  getActiveBtn() {
    let user = JSON.parse(localStorage.user);
    let isSupervisor = true;
    if (user) {
      if (user.profile) {
        if (user.profile.fields) {
          isSupervisor = user.profile.fields.role === "supervisor";
        }
      }
    }
    if (!isSupervisor) {
      if (
        (this.props.activeTab === "URL claims" ||
          this.props.activeTab === "Multimedia claims") &&
        this.props.claimsActiveTab === 5
      )
        return 0;
    }
    if (
      (this.props.activeTab === "URL claims" ||
        this.props.activeTab === "Multimedia claims") &&
      this.props.claimsActiveTab === 6
    )
      return 0;

    if (this.props.activeTab === "URL claims") return 0;
    if (this.props.activeTab === "Multimedia claims") return 1;
    if (this.props.activeTab === "Open complaints") return 0;
    if (this.props.activeTab === "Closed complaints") return 1;
    if (this.props.activeTab === "India") return 0;
    if (this.props.activeTab === "UK") return 1;
    if (this.props.activeTab === "US") return 2;
    return 2;
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose(params) {
    this.setState({
      anchorEl: null,
      countrySelected: params,
    });
    this.props.countrySelected(params);
  }
  // handleSwitch = (e) => {
  //   if (e.checked) {
  //     this.props.autoRefresh(false)
  //     this.setState({ [e.name]: false })
  //   } else {
  //     this.setState({ [e.name]: true })
  //   }

  // }
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
    this.props.autoRefresh(event.target.checked);
  };
  handledropdown = (e) => {
    this.props.dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    this.setState({
      anchorEl: null,
      countrySelected: e.target.value,
    });
    let location = getLocation(e.target.value);
    this.props.countrySelected(location);
  };

  handlecheckbox(e) {
    let value = e.target.checked;
    this.setState(
      {
        checkboxVal: value,
      },
      () => {
        this.props.checkboxValue(value);
      }
    );
  }

  dropDownSpacing(name) {
    if (name === "Reason") {
      return 3;
    }
    if (name === "Type") {
      return 3;
    }
    if (name === "Filter by date") {
      return 4;
    }
    return 2;
  }
  gridSpacing(name) {
    if (name === "Filter by date") {
      // return "space-between";
    }
    if (name === "Reason") {
      return "space-between";
    }
    return "flex-start";
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.searchData !== this.state.searchData) {
      this.setState({
        searchData: nextProps.searchData,
      });
    }
    if (nextProps.showCount !== this.state.showCount) {
      this.setState({
        showCount: nextProps.showCount,
      });
    }
    if (nextProps.length !== this.props.length) {
      this.setState({
        length: nextProps.length,
      });
    }
    if (nextProps.activeTab !== this.state.activetTab) {
      this.setState({
        activeTab: nextProps.activeTab,
      });
    }
    if (nextProps.searchFilter) {
      this.setState({ autoRefresh: false });
    }
  }


  getLengthCount() {
    let pointvalue = this.props.claimsActiveTab;
    let { length, showCount } = this.state;
    let user = JSON.parse(localStorage.user);
    if (user.profile.fields.role === "factchecker") {
      return 0;
    }
    if (this.props.offline && length === 0) {
      return 0;
    }
    if (pointvalue === 1 && showCount) {
      let newClaims = showCount[0].count;
      return newClaims - length > 0 ? newClaims - length : 0;
    } else if (pointvalue === 2) {
      return 0;
    } else if (pointvalue === 3) {
      return 0;
    } else if (pointvalue === 4 && showCount) {
      let finalize = showCount[2].count;
      return finalize - length > 0 ? finalize - length : 0;
    } else if (pointvalue === 5) {
      return 0;
    } else if (pointvalue === 6) {
      return 0;
    } else if (pointvalue === 7) {
      return 0;
    } else {
      return 0;
    }
  }

  render() {
    const { activeButton, searchData } = this.state;
    const {
      classes,
      length,
      buttons,
      checkbox,
      dropDown,
      showingClaims,
      saveButtons,
      dropDownOptions,
      loading,
    } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          container
          style={{
            marginBottom: 20,
            justifyContent: this.gridSpacing(dropDown),
          }}
        >

          {/* {console.log('autoRefresh', this.props.autoRefresh)} */}
          {showingClaims && (
            <Grid item md={2}>
              <p
                style={{
                  fontWeight: 600,
                  marginTop: 10,
                  alignItems: "left",
                }}
              >
                Showing {length} Claims{" "}
              </p>{" "}
            </Grid>
          )}{" "}
          {buttons && (
            <Grid
              item
              style={{
                display: "flex",
                marginRight: "5%",
              }}
            >
              {" "}
              {buttons.map((item, index) => {
                if (activeButton !== index) {
                  return (
                    <Badge
                      color="secondary"
                      badgeContent={!this.props.searchFilter && !loading && this.getLengthCount()}
                      className={classes.margin}
                    >
                      <Button
                        fullWidth={true}
                        size="small"
                        key={`btn${index}`}
                        variant={
                          activeButton === index ? "contained" : "outlined"
                        }
                        style={{
                          minWidth: "max-content",
                          height: 35,
                        }}
                        color="secondary"
                        onClick={() => {
                          this.setState(
                            {
                              activeButton: index,
                            },
                            () => {
                              this.props.dispatch({
                                type: "VIEW_BUTTON",
                                activeButton: item,
                              });
                              this.props.dispatch({
                                type: "SET_LOADING",
                                loading: true,
                              });
                              this.props.filterbutton(item);
                            }
                          );
                        }}
                      >
                        {item}{" "}
                      </Button>{" "}
                    </Badge>
                  );
                } else {
                  return (
                    <Button
                      fullWidth={true}
                      size="small"
                      key={`btn${index}`}
                      variant={
                        activeButton === index ? "contained" : "outlined"
                      }
                      style={{
                        minWidth: "max-content",
                        height: 35,
                        marginRight: "3%",
                      }}
                      color="secondary"
                      onClick={() => {
                        this.setState(
                          {
                            activeButton: index,
                          },
                          () => {
                            this.props.filterbutton(item);
                          }
                        );
                      }}
                    >
                      {item}{" "}
                    </Button>
                  );
                }
              })}{" "}
            </Grid>
          )}{" "}
          {searchData.length === 0 && (
            <>
              {" "}
              {checkbox && (
                <Grid
                  item
                  md={3}
                  sm={3}
                  style={{
                    position: "relative",
                    top: "-5px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={checkbox}
                        onChange={(e) => {
                          this.handlecheckbox(e);
                        }}
                      />
                    }
                    style={{
                      marginRight: 0,
                    }}
                    label={checkbox}
                  />{" "}
                </Grid>
              )}{" "}
              {this.props.timer && !this.props.searchFilter && <FormControlLabel
                style={{
                  position: "relative",
                  top: "-5px",
                }}
                control={
                  <Switch
                    checked={this.state.autoRefresh}
                    onChange={this.handleChange("autoRefresh")}
                    value="autoRefresh"
                    name="autoRefresh"
                  />
                }
                label={`Auto Refresh (${this.props.timer}s)`}
              />}
              {dropDown && (
                <Grid
                  item
                  md={this.dropDownSpacing(dropDown)}
                  sm={this.dropDownSpacing(dropDown)}
                >
                  {dropDown ? (
                    <Typography
                      style={{
                        color: "#3360F3",
                        font: "Mulish",
                        fontWeight: 100,
                      }}
                    >
                      {" "}
                      {dropDown}:
                      <>
                        <Select
                          style={{
                            paddingRight: 20,
                            paddingLeft: 10,
                          }}
                          value={this.state.countrySelected}
                          onChange={this.handledropdown}
                          disableUnderline
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                        >
                          <MenuItem value={"All"}> All </MenuItem>{" "}
                          {dropDownOptions.map((value, index) => (
                            <MenuItem key={index + 1} value={value}>
                              {" "}
                              {value}{" "}
                            </MenuItem>
                          ))}{" "}
                        </Select>{" "}
                      </>{" "}
                    </Typography>
                  ) : null}{" "}
                </Grid>
              )}{" "}
              {saveButtons && (
                <Grid
                  item
                  style={{
                    display: "flex",
                  }}
                  alignItems="center"
                  justify="flex-end"
                >
                  <button
                    style={{
                      border: 0,
                      color: "#3360F3",
                      backgroundColor: "#F1F4F8",
                      font: "Mulish",
                    }}
                    onClick={this.props.saveChanges}
                  >
                    Save Changes{" "}
                  </button>{" "}
                  <button
                    style={{
                      border: 0,
                      backgroundColor: "#F1F4F8",
                      font: "Mulish",
                    }}
                    onClick={this.props.previewChanges}
                  >
                    Preview{" "}
                  </button>{" "}
                </Grid>
              )}{" "}
            </>
          )}{" "}
        </Grid>{" "}
      </div>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    searchData: data.searchData,
    showCount: data.showCount,
    activeTab: data.activeButton,
    claimsActiveTab: data.claimsActiveTab,
    loading_spin: data.loading_spin,
    loading: data.loading,
    offline: data.offline,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Filter));
