import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
class ProfileComplete extends Component {
  state = {};

  getProfilePic = () => {
    // const { user } = this.props;
    return "m";
  };

  render() {
    const { onHandleProfileComplete } = this.props;
    return (
      <div className="profile-complete">
        <div className="profile-complete-message">
          <div className="user-avatar-container">
            <div className="user-avatar">
              <h1>{this.getProfilePic()}</h1>
            </div>
          </div>
          <h1 className="profile-complete-welcome">Welcome aboard!</h1>
          <Typography>You're finally ready, have a look around!</Typography>
          <button className="save-button" onClick={onHandleProfileComplete}>
            Explore
          </button>
        </div>
      </div>
    );
  }
}

export default ProfileComplete;
