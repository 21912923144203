import { Card, CardContent, Grid, Icon, Input, Paper, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { ArrowRightAlt, ArrowDownward } from '@material-ui/icons'
import axios from 'axios'
import api, { getBase } from '../api'
import ClaimData from './ClaimData'
import ClaimStatus from './ClaimStatus'
import ClaimProcess from './ClaimProcess'
import { toast } from 'react-toastify'

const types = ["Data", "Status Check", "Process Folowed"]

export class ClaimFlow extends Component {
    constructor() {
        super()
        this.state = {
            searchClaimInput: '',
            typeSelected: "",
            claimData: {}
        }
    }
    handleSearch = () => {
        const { searchClaimInput } = this.state
        axios
            .get(getBase(api.CLAIM + searchClaimInput))
            .then((res) => {
                this.setState({ claimData: res.data.data, typeSelected: "Data" })
            }).catch(e => {
                console.log(e)
            })
    }
    handleUpdateState = (data) => {
        let dataToSend = { ...this.state.claimData, ...data }
        this.setState({ claimProcessData: dataToSend }, () => {
            axios
                .put(getBase(api.CLAIM + dataToSend._id), dataToSend, {
                    headers: {
                        Authorization: 'Token ' + localStorage.token,
                        'content-type': 'application/json',
                    },
                }).then(res => {
                    if (res.data) {
                        toast.success('Data Saved Successfully',
                            { position: toast.POSITION.TOP_RIGHT }
                        );
                        this.handleSearch()
                    }
                }).catch(e => {
                    toast.error(
                        e.response
                            ? e.response.message
                            : 'Something went wrong',
                        { position: toast.POSITION.TOP_RIGHT }
                    );
                })
        })

    }
    render() {
        const { searchClaimInput, typeSelected, claimData } = this.state
        return (
            <div className='main'>
                <Paper style={{ padding: 16 }}>
                    <Typography style={{ fontWeight: 'bold' }}>Enter Claim Id</Typography>
                    <div className='flex gap'>
                        <Input value={searchClaimInput} fullWidth placeholder='type here' onChange={(e) => this.setState({ searchClaimInput: e.target.value })} />
                        <button onClick={this.handleSearch} className='save-button'>Search</button>

                    </div>
                    <br />
                    <Grid container spacing={8}>
                        {types.map(item => {
                            return <Grid item md={4} key={item}>
                                <Card className='gradientBackground'>
                                    <CardContent>
                                        <div className='flex gap align-center cursor-pointer' onClick={() => this.setState({ typeSelected: item })}>
                                            <Typography className='white-color' variant='h6'>{item}</Typography>
                                            {item === typeSelected ? <ArrowDownward className='white-color' /> : <ArrowRightAlt className='white-color' />}
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        })}
                        {typeSelected === "Data" && <Grid md={12}>
                            <ClaimData data={claimData} />
                        </Grid>}
                        {typeSelected === "Status Check" && <Grid md={12}>
                            <ClaimStatus data={claimData} claimStatusData={(data) => this.handleUpdateState(data)} />
                        </Grid>}
                        {typeSelected === "Process Folowed" && <Grid md={12}>
                            <ClaimProcess data={claimData} claimProcessData={(data) => this.handleUpdateState(data)} />
                        </Grid>}
                    </Grid>
                </Paper>
            </div>
        )
    }
}

export default ClaimFlow