import { Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux';

export class UserSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            users: this.props.users
        }
    }
    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    render() {
        const { open, users } = this.state
        return (
            <div>
                <button onClick={this.handleOpen} className='save-button w-max'>User Search</button>
                <Modal open={open} onClose={this.handleClose}>
                    <div className='userSearch-modal'>
                        <Paper>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User ID</TableCell>
                                        <TableCell>First Name</TableCell>
                                        <TableCell>Last Name</TableCell>
                                        <TableCell>username</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(users).map((item, index) => {
                                        if (users[item].first_name) {
                                            return <TableRow key={index}>
                                                <TableCell><b>{users[item].user_id}</b></TableCell>
                                                <TableCell>{users[item].first_name}</TableCell>
                                                <TableCell>{users[item].last_name}</TableCell>
                                                <TableCell>{users[item].username}</TableCell>

                                            </TableRow>
                                        }
                                    })}
                                </TableBody>
                            </Table>

                        </Paper>
                    </div>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = (data) => {
    return { users: data.userHashTable }
}
export default connect(mapStateToProps)(UserSearch);