import React from "react";

export default function SkillsIcon({ fill, styles }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.1 26.5"
      style={styles}
    >
      <path
        d="M27.7 2.5s-.1 0 0 0C24.4-.8 19-.8 15.8 2.5l-.8.7-.7-.7C12.7.9 10.6 0 8.4 0S4 .9 2.5 2.5C.9 4 0 6.2 0 8.4s.9 4.3 2.5 5.9l11.9 11.9c.2.2.4.3.7.3s.5-.1.7-.3l11.9-11.9c1.6-1.6 2.5-3.7 2.5-5.9-.1-2.2-1-4.3-2.5-5.9zm-1.5 10.4L15 24.1 3.9 12.9C2.7 11.7 2 10.1 2 8.4s.7-3.3 1.9-4.5S6.7 2 8.4 2c1.7 0 3.3.7 4.5 1.9l1.4 1.4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l1.4-1.4c2.5-2.5 6.5-2.5 9 0C27.3 5.1 28 6.7 28 8.4c.1 1.7-.6 3.3-1.8 4.5z"
        fill={fill}
      />
    </svg>
  );
}
