import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import api, { getBase } from "./../api";
import { toast } from "react-toastify";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "./profile.css";
import ProfDetails from "./ProfileComponents/ProfDetails";
import Skills from "./ProfileComponents/skills";
import ChangePass from "./ProfileComponents/changePass";

const tabs = ["Profile details", "Skills and interests", "Change password"];
class Profile extends Component {
  state = {
    user: this.props.user,
    activeTab: 0,
    skills: [],
    skill: "",
    interests: [
      { interest: "Education", isInterested: false },
      { interest: "Politics", isInterested: false },
      { interest: "Current affairs", isInterested: false },
      { interest: "Entertainment", isInterested: false },
      { interest: "Sports", isInterested: false },
      { interest: "Health", isInterested: false },
      { interest: "Business & Finance", isInterested: false },
      { interest: "Environment", isInterested: false },
      { interest: "Lifestyle", isInterested: false },
      { interest: "Science & Tech", isInterested: false },
    ],
    interestString: ''
  };

  componentDidMount() {
    const { user } = this.props;
    const { profile } = user
    const { fields } = profile
    let intrestsfromurl = profile.fields.interests.split(',')
    let skills = fields.skills
    for (let index = 0; index < this.state.interests.length; index++) {
      const element = this.state.interests[index];
      if (intrestsfromurl.includes(element.interest)) {
        let interests = this.state.interests
        interests[index].isInterested = true
        this.setState({ interests })
      }
    }
    this.setState({ skills: [skills] })
    console.log(user);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { user } = this.state;
    this.getUser(user)
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleAddSkill = (skill) => {
    const { skills } = this.state;
    if (skill !== "") {
      const newSkills = [...skills, skill];
      this.setState({ skills: newSkills, skill: "" });
    }
  };

  handleDeleteSkill = (skill) => {
    const { skills } = this.state;
    const newSkills = skills.filter((sk) => skill !== sk);
    this.setState({ skills: newSkills });
  };

  handleAddInterest = (index) => {
    const { interests } = this.state;
    interests[index].isInterested = !interests[index].isInterested;
    this.setState({ interests },
      console.log(this.state)
    );
  };

  handleTabClick = (e, activeTab) => {
    this.setState({ activeTab });
  };

  validate = () => {
    const { skills, interests } = this.state;
    const interestStrings = interests.filter(
      (interest) => interest.isInterested
    );
    return skills.length > 0 && interestStrings.length > 0;
  };

  handleSkillSave = () => {
    const { skills, interests } = this.state;
    if (this.validate()) {
      let interestString = []
      for (let index = 0; index < interests.length; index++) {
        const element = interests[index];
        if (element.isInterested) {
          interestString.push(element.interest)
        }
      }
      let userdata = {}
      userdata.skills = `${skills}`
      userdata.interests = `${interestString}`
      this.getProfile(userdata)
      return true;
    } else alert("One or more fields are empty");
  };

  renderTab = (activeTab) => {
    const { user, skills, interests, skill } = this.state;
    if (activeTab === 0)
      return (
        <ProfDetails
          user={user}
          onHandleUpdate={this.updateProfileDetails}
          isInit={false}
        />
      );
    if (activeTab === 1)
      return (
        <Skills
          onHandleUpdate={this.updateInterestsAndSkills}
          isInit={false}
          skills={skills}
          skill={skill}
          interests={interests}
          onHandleAddSkill={this.handleAddSkill}
          onHandleDeleteSkill={this.handleDeleteSkill}
          onHandleAddInterest={this.handleAddInterest}
          onHandleChange={this.handleChange}
          onHandleSkillSave={this.handleSkillSave}
        />
      );
    if (activeTab === 2) return <ChangePass user={user} />;
  };

  updateInterestsAndSkills = (skills, interests) => {
    const { user } = this.state;
    user.skills = skills;
    user.interests = interests;
    console.log(user);
    this.setState({ user });
  };
  getUser(user) {
    axios
      .put(getBase(api.USER), user, {
        headers: { Authorization: "Token " + localStorage.token },
      })
      .then((res) => {
        toast.success("Successfully updated profile", {
          position: toast.POSITION.TOP_RIGHT,
        }); window.location.reload()
      })
      .catch((err) => {
        toast.error(
          "Error, could not update profile. API probably not working yet",
          { position: toast.POSITION.TOP_RIGHT }
        );
      });
  }
  getProfile(fields) {
    axios
      .put(getBase(api.PROFILE), fields, {
        headers: { Authorization: "Token " + localStorage.token },
      })
      .then((res) => {
        toast.success("Successfully updated profile", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        toast.error(
          "Error, could not update profile. API probably not working yet",
          { position: toast.POSITION.TOP_RIGHT }
        );
      });
  }

  updateProfileDetails = (fullName, email, officeLoc) => {
    // const { user } = this.state;
    let user = {}
    let stateuser = this.state.user
    console.log('stateuser', stateuser)
    let { fields } = stateuser.profile

    if (this.state.first_name !== fullName && stateuser.email !== email) {
      user.first_name = fullName;
      user.email = email;
      this.getUser(user)
    } else if (this.state.first_name !== fullName) {
      user.first_name = fullName;
      this.getUser(user)

    } else if (stateuser.email !== email) {
      user.email = email;
      this.getUser(user)

    }

    if (officeLoc !== fields.location) {
      fields.location = officeLoc;
      console.log('user', fields)
      this.getProfile(fields)
    }

  };

  render() {
    const { activeTab } = this.state;
    return (
      <div>
        <div className="profile-container">
          <h2>My Profile</h2>
          <Grid container>
            <Tabs
              value={activeTab}
              onChange={this.handleTabClick}
              className="profile-tab-container"
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  value={index}
                  label={tab}
                  className={
                    index === activeTab
                      ? "profile-tab"
                      : "profile-tab inactive-tab"
                  }
                />
              ))}
            </Tabs>
          </Grid>
          <div className="profile-component-container">
            {this.renderTab(activeTab)}
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;
