import React, { Component } from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import api, { getBase } from "./../api";
import { toast } from "react-toastify";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }
  componentDidMount() {
    axios
      .get(getBase(api.USERS), {
        headers: { Authorization: "Token " + localStorage.token },
      })
      .then((res) => {
        this.setState({ users: res.data.data });
      });
  }
  render() {
    const { classes } = this.props;
    const { users } = this.state;
    return (
      <div>
        <div style={{ width: "95%", margin: "20px auto" }}>
          <Grid container spacing={16}>
            <Grid item xs={12} md={12} lg={8}>
              <Paper>
                <Table padding="dense">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          color: " rgba(0, 0, 0, 0.58)",
                          fontSize: "0.75rem",
                          fontWeight: "800",
                        }}
                      >
                        ID
                      </TableCell>
                      <TableCell
                        style={{
                          color: " rgba(0, 0, 0, 0.58)",
                          fontSize: "0.75rem",
                          fontWeight: "800",
                        }}
                      >
                        First Name
                      </TableCell>
                      <TableCell
                        style={{
                          color: " rgba(0, 0, 0, 0.58)",
                          fontSize: "0.75rem",
                          fontWeight: "800",
                        }}
                      >
                        Last Name
                      </TableCell>
                      <TableCell
                        style={{
                          color: " rgba(0, 0, 0, 0.58)",
                          fontSize: "0.75rem",
                          fontWeight: "800",
                        }}
                      >
                        Username
                      </TableCell>
                      <TableCell
                        style={{
                          color: " rgba(0, 0, 0, 0.58)",
                          fontSize: "0.75rem",
                          fontWeight: "800",
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        style={{
                          color: " rgba(0, 0, 0, 0.58)",
                          fontSize: "0.75rem",
                          fontWeight: "800",
                        }}
                      >
                        Role
                      </TableCell>
                      <TableCell
                        style={{
                          color: " rgba(0, 0, 0, 0.58)",
                          fontSize: "0.75rem",
                          fontWeight: "800",
                        }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((usr, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{usr.user_id}</TableCell>
                          <TableCell>{usr.first_name}</TableCell>
                          <TableCell>{usr.last_name}</TableCell>
                          <TableCell>{usr.username}</TableCell>
                          <TableCell>{usr.email}</TableCell>
                          <TableCell>{usr.role}</TableCell>
                          <TableCell>{usr.status}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <SignUp classes={classes} />
            </Grid>
          </Grid>
        </div>
      </div >
    );
  }
}

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      first_name: '',
      last_name: '',
      error: false,
      loading: true,
      email: "",
      done: false,
      hasRegistered: false,
      telephone: "",
      role: "factchecker",
      assumedCountry: "gb",
      isvalid: false,
    };
  }
  componentDidMount() {
    axios
      .get("https://ipapi.co/json/", { timeout: 3000 })
      .then((res) => {
        this.setState({ assumedCountry: res.data.country, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      username,
      password,
      first_name,
      last_name,
      email,
      hasRegistered,
      userId,
      telephone,
    } = this.state;
    let telephoneIsValid = telephone.length > 9 || telephone.length === 0;
    if (telephoneIsValid) {
      this.setState({ loading: true });
      if (hasRegistered) {
        this.handleProfile(userId);
      } else {
        axios
          .post(
            getBase(api.USER),
            { username, password, email, first_name, last_name },
            { headers: { Authorization: "Token " + localStorage.token } }
          )
          .then((res) => {
            toast.success("Created new user", {
              position: toast.POSITION.TOP_RIGHT,
            });
            console.log(res.data);
            this.handleProfile(res.data.data.id);
            this.setState({
              loading: false,
              hasRegistered: true,
              userId: res.data.data.id,
            });
          })
          .catch((error) => {
            toast.error(
              error.response ? error.response.message : "Something went wrong",
              { position: toast.POSITION.TOP_RIGHT }
            );
            this.setState({ loading: false });
          });
      }
    } else {
      toast.error("Invalid phone number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  handleProfile = (userId) => {
    const { username, password, telephone, role } = this.state;
    axios
      .post(getBase(api.LOGIN), { username, password })
      .then((res) => {
        axios
          .post(
            getBase(api.PROFILE),
            { role: role, user: userId, telephone: telephone },
            {
              headers: {
                Authorization: "Token " + res.data.token,
                "content-type": "application/json",
              },
            }
          )
          .then((res) => {
            toast.success("Created users profile", {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.setState({
              done: true,
              username: "",
              email: "",
              password: "",
              telephone: "",
              role: "factchecker",
            });
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
            toast.error(
              err.response
                ? err.response.message
                : "Could not create users profile, please try again",
              { position: toast.POSITION.TOP_RIGHT }
            );
          });
      })
      .catch((err) => {
        this.setState({ loading: false, error: true });
      });
  };
  handleNumberChange = (val) => {
    this.setState({ telephone: val });
  };
  render() {
    const { classes } = this.props;
    const {
      username,
      password,
      error,
      email,
      done,
      telephone,
      role,
      assumedCountry,
      first_name,
      last_name
    } = this.state;
    if (done) {
      return (
        <div>
          <Button
            className="link"
            onClick={() => this.setState({ done: false, hasRegistered: false })}
          >
            Create another user
          </Button>
        </div>
      );
    }
    const ReactPhoneInput = require("react-phone-input-2").default;
    return (
      <div>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Create User
            </Typography>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="text">First Name</InputLabel>
                <Input
                  id="first_name"
                  value={first_name}
                  onChange={(e) => this.setState({ first_name: e.target.value })}
                  name="first name"
                  type="text"
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="text">Last Name</InputLabel>
                <Input
                  id="last_name"
                  value={last_name}
                  onChange={(e) => this.setState({ last_name: e.target.value })}
                  name="last name"
                  type="text"
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  name="email"
                  type="email"
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="username">Username</InputLabel>
                <Input
                  id="username"
                  value={username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                  name="username"
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  id="password"
                />
              </FormControl>
              <p style={{ fontSize: 12, color: "rgba(0, 0, 0, 0.54)" }}>
                Telephone (optional)
              </p>
              <ReactPhoneInput
                ref={(ref) => (this.phone = ref)}
                defaultCountry={assumedCountry.toLowerCase()}
                value={telephone.replace(/ /g, "")}
                onChange={this.handleNumberChange}
              />
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="role">Role</InputLabel>
                <Select
                  native
                  value={role}
                  onChange={(e) => this.setState({ role: e.target.value })}
                  inputProps={{ name: "role", id: "role" }}
                >
                  <option value={"admin"}>Admin</option>
                  <option value={"supervisor"}>Supervisor</option>
                  <option value={"moderator"}>Moderator</option>
                  <option value={"factchecker"}>Factchecker</option>
                  <option value={"trainee"}>Trainee</option>
                </Select>
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign up
              </Button>
              {error && (
                <Typography style={{ color: "red", marginTop: 10 }}>
                  Oops, looks like something went wrong, please try again later
                </Typography>
              )}
            </form>
          </Paper>
        </main>
      </div>
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Users);
