import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getTimeDifference, getTimeFrom } from "./../../../utils/time";
import { formatDate } from "../../../utils/fomratDate";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import api, { getBase } from "./../../../api";
import { FlagIcon } from "react-flag-kit";
import ProcessImage from "react-imgpro";
import {
  getUserByID,
  getLocation,
  priorityColors,
  priorityTextColors,
} from "./../../../utils/common";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { connect } from "react-redux";
import { db, putClaimData } from "../../../App";
import { getLanguageByCode } from "../../../utils/language";

const styles = {
  card: {
    borderRadius: "10px",
    display: "flex",
  },
  imgGrid: {
    flexBasis: `${20}%`,
  },
  progressColorTypo: {
    fontWeight: "bold",
    color: "orange",
    fontSize: 13,
  },
  downColorTypo: {
    fontWeight: "bold",
    color: "red",
    fontSize: 13,
  },
  rightColorTypo: {
    fontWeight: "bold",
    color: "blue",
    fontSize: 13,
  },
  textDiv: {
    marginTop: 10,
    height: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
  },
  Button: {
    textTransform: "none",
  },
  smallChip: {
    textTransform: "none",
    fontSize: 13,
    height: 25,
    width: 45,
  },
  typoBlack: {
    color: "#b79f9f",
  },
  line: {
    border: "none",
    background: "#ddd",
    height: "1px",
  },
};
class SimpleCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      loading: true,
      src: "",
      err: null,
      isAcceptLoading: false,
      acceptedBy: "",
      claimed_Data: this.props.claimed_Data,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = this.state;
    const { claimed_Data } = nextProps;
    if (
      JSON.stringify(claimed_Data) !== JSON.stringify(this.state.claimed_Data)
    ) {
      if (Object.keys(claimed_Data).includes(data._id)) {
        data.accepted = true;
        data.accepted_by = claimed_Data[data._id];
      }
      this.setState({ data: data }, () => {
        this.setState({ claimed_Data: claimed_Data });
      });
    }
  }
  modaretor_time_start = (data) => {
    let started_at = moment().format("YYYY/MM/DD HH:mm [GMT+5:30]");

    let user = JSON.parse(localStorage.user);
    user = user.profile.fields.role;
    if (user) {
      if (data.moderator_started_at === "" && user === "moderator") {
        data.moderator_started_at = started_at;
        data.type = "image";
        axios
          .put(getBase(api.CLAIM + data._id), data, {
            headers: {
              Authorization: "Token " + localStorage.token,
              "content-type": "application/json",
            },
          })
          .then((res) => {
            if (JSON.stringify(res.data.data)) {
              this.setState({ loading: false });
            } else if (this.state.loading) {
              this.setState({ loading: false });
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
      if (user === "supervisor") {
        if (data.finalising === true) {
          data.supervisor_started_at = started_at;
          data.type = "image";
        }
        if (data.finalising === false) {
          data.moderator_started_at = started_at;
          data.type = "image";
        }
        axios
          .put(getBase(api.CLAIM + data._id), data, {
            headers: {
              Authorization: "Token " + localStorage.token,
              "content-type": "application/json",
            },
          })
          .then((res) => {
            if (JSON.stringify(res.data.data)) {
              this.setState({ loading: false });
            } else if (this.state.loading) {
              this.setState({ loading: false });
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    }
  };

  modaretor_time_start2 = (data) => {
    let started_at = moment().format("YYYY/MM/DD HH:mm [GMT+5:30]");

    let user = JSON.parse(localStorage.user);
    user = user.profile.fields.role;
    if (user) {
      if (data.moderator_started_at === "" && user === "moderator") {
        data.moderator_started_at = started_at;
        axios
          .put(getBase(api.CLAIM + data._id), data, {
            headers: {
              Authorization: "Token " + localStorage.token,
              "content-type": "application/json",
            },
          })
          .then((res) => {
            if (JSON.stringify(res.data.data)) {
              this.setState({ loading: false });
            } else if (this.state.loading) {
              this.setState({ loading: false });
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
      if (user === "supervisor") {
        if (data.finalising === true) {
          data.supervisor_started_at = started_at;
        }
        if (data.finalising === false) {
          data.moderator_started_at = started_at;
        }
        axios
          .put(getBase(api.CLAIM + data._id), data, {
            headers: {
              Authorization: "Token " + localStorage.token,
              "content-type": "application/json",
            },
          })
          .then((res) => {
            if (JSON.stringify(res.data.data)) {
              this.setState({ loading: false });
            } else if (this.state.loading) {
              this.setState({ loading: false });
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    }
  };
  handleAcceptClaims(claim) {
    const { data } = this.state;
    const { claimIndex } = this.props;
    this.setState({ isAcceptLoading: true });
    const userInfo = JSON.parse(localStorage.user);
    const updates = { status: "working", time_in_status: new Date() };
    axios
      .post(
        `${getBase(api.CLAIM)}${claim._id}/accept`,
        {},
        {
          headers: {
            Authorization: "Token " + localStorage.token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        let updatedData = { ...data }
        updatedData.accepted_by = userInfo.username
        this.setState({
          isAcceptLoading: false,
          acceptedBy: userInfo.username,
          data: updatedData,
          //   acceptedBy: userInfo.username,
        });
        db.ref()
          .child(`${userInfo.id}`)
          .update(updates);
      })
      .then((res) => putClaimData(claim._id, userInfo.username))
      .catch((err) => {
        console.log(err);
      });
  }

  handleAccept(claim) {
    const { data } = this.state;
    const { claimIndex } = this.props;
    this.setState({ isAcceptLoading: true });
    const userInfo = JSON.parse(localStorage.user);
    const updates = { status: "working", time_in_status: new Date() };
    axios
      .post(
        `${getBase(api.CLAIM)}${claim._id}/accept`,
        {},
        {
          headers: {
            Authorization: "Token " + localStorage.token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        let updatedData = { ...data }
        updatedData.accepted_by = userInfo.username
        this.setState({
          isAcceptLoading: false,
          acceptedBy: userInfo.username,
          data: updatedData,
          //   acceptedBy: userInfo.username,
        });
        db.ref()
          .child(`${userInfo.id}`)
          .update(updates);
      })
      .then((res) => putClaimData(claim._id, userInfo.username))
      .catch((err) => {
        console.log(err);
      });
  }
  handleAcceptMultimediaClaim(claim) {
    this.setState({ isAcceptLoading: true });
    const userInfo = JSON.parse(localStorage.user);
    const updates = { status: "working", time_in_status: new Date() };
    axios
      .post(
        `${getBase(api.CLAIM)}${claim._id}/accept?type=image`,
        {},
        {
          headers: {
            Authorization: "Token " + localStorage.token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        this.setState({
          isAcceptLoading: false,
          acceptedBy: userInfo.username,
        });
        db.ref()
          .child(`${userInfo.id}`)
          .update(updates);
      })
      .then((res) => putClaimData(claim._id, userInfo.username))
      .catch((err) => {
        console.log(err);
      });
  }
  dataAcceptedby(data) {
    return (
      <>
        {data.accepted_by && (
          <p>
            Accepted By <b>{data.accepted_by}</b>{" "}
          </p>
        )}
      </>
    );
  }
  fcChecker(assigned, data, userHashTable) {
    if (this.getFCUserName(data)) {
      return (
        <>
          <span style={{ textTransform: "capitalize" }}>
            <small>
              {assigned} :{" "}
              {userHashTable[this.getFCUserName(data)].username !==
                undefined && (
                  <b>{userHashTable[this.getFCUserName(data)].username} </b>
                )}
            </small>
          </span>
          {this.getDurationData(data.factcheck_started_at)}
        </>
      );
    }
  }
  fcAndModChecker(data, userHashTable) {
    return (
      <>
        {this.getFCUserName(data) && userHashTable && (
          <>
            <span style={{ textTransform: "capitalize" }}>
              <small>
                FC by :{" "}
                {userHashTable[this.getFCUserName(data)].username !==
                  undefined && (
                    <b>{userHashTable[this.getFCUserName(data)].username} </b>
                  )}
              </small>
            </span>
            {this.getDurationData(
              data.factcheck_started_at,
              data.factcheck_completed_at
            )}
          </>
        )}
        <p></p>
        {this.getModUserName(data) && userHashTable && (
          <>
            <span style={{ textTransform: "capitalize" }}>
              <small>
                Moderated by{" "}
                {userHashTable[this.getModUserName(data)].username !==
                  undefined && (
                    <b> {userHashTable[this.getModUserName(data)].username} </b>
                  )}
              </small>
            </span>
            {this.getDurationData(
              data.moderator_started_at,
              data.moderator_completed_at
            )}
          </>
        )}
      </>
    );
  }
  getFCUserName(data) {
    if (data.factchecker_id === (undefined || "")) {
      return data.old_factchecker_id;
    }
    return data.factchecker_id;
  }
  getModUserName(data) {
    if (data.moderator_id === (undefined || "")) {
      return data.old_moderator_id;
    }
    return data.moderator_id;
  }
  getDurationData(start, completed) {
    if (start && completed) {
      return (
        <span>
          {start && completed && (
            <small>
              <b>| </b> Duration <b> {getTimeDifference(start, completed)}</b>
            </small>
          )}
        </span>
      );
    } else {
      if (start) {
        return (
          <span>
            <small>
              <b>| </b> Duration <b> {getTimeFrom(start)} </b>
            </small>
          </span>
        );
      }
    }
  }
  render() {
    const {
      classes,
      data,
      users,
      assigned,
      images,
      count,
      finalize,
      userHashTable,
      urlAccept,
      newClaimsMultimedia,
    } = this.props;
    const { acceptedBy, isAcceptLoading } = this.state;
    // let faster = {};
    // data.priority === "high"
    // ? (faster = { backgroundColor: "#fffcc8" })
    // : (faster = {});
    return (
      <>
        <Card
          style={{
            height: assigned ? 250 : 300,
            boxShadow: "none",
            borderRadius: 20,
            border: `2px solid ${priorityTextColors[data.priority]}`,
          }}
        >
          <CardContent>
            <Grid container>
              <Grid item md={3}>
                {data.recheck_image ? (
                  <Typography component="p">
                    <span style={{ color: "black", fontWeight: "600" }}>
                      {data._id}
                    </span>
                  </Typography>
                ) : (
                  <Typography component="p">
                    <span style={{ color: "black", fontWeight: "600" }}>
                      {data.tracking_id}
                    </span>
                  </Typography>
                )}
              </Grid>
              <Grid item md={3}>
                {data.lang_code && (
                  <Typography
                    style={{
                      fontSize: "10px",
                      color: "black",
                      width: "100%",
                      textTransform: "uppercase",
                      textAlign: "right",
                      lineHeight: "20px",
                    }}
                    variant="body1"
                  >
                    <span
                      style={{
                        padding: "3px",
                        borderRadius: "3px",
                        backgroundColor: "#f1f4f8",
                      }}
                    >
                      {getLanguageByCode(data.lang_code).name}
                    </span>
                  </Typography>
                )}
              </Grid>
              <Grid item md={3}>
                <Typography
                  style={{
                    fontSize: "10px",
                    color: "black",
                    width: "100%",
                    textTransform: "uppercase",
                    textAlign: "center",
                    lineHeight: "20px",
                  }}
                  variant="body1"
                >
                  <span
                    style={{
                      padding: "3px",
                      borderRadius: "3px",
                      backgroundColor: "#f1f4f8",
                    }}
                  >
                    {data.claim_source === "dashboard"
                      ? "FC Dash"
                      : data.claim_source}
                  </span>
                </Typography>
              </Grid>
              {data.location && (
                <Grid item md={2} style={{ textAlign: "center" }}>
                  <FlagIcon
                    code={getLocation(data.location)}
                    size={20}
                    style={{ paddingLeft: "auto", marginTop: 0 }}
                  />
                </Grid>
              )}
              <Grid item md={12}>
                <div style={{ display: "flex", marginTop: 5 }}>
                  <span
                    style={{
                      fontSize: "10px",
                      backgroundColor: priorityColors[data.priority],
                      textTransform: "uppercase",
                      lineHeight: "20px",
                      marginRight: "5px",
                      padding: "0 5px",
                      borderRadius: "3px",
                      fontWeight: "bold",
                    }}
                  >
                    {data.priority}
                  </span>
                  <span
                    style={{
                      fontSize: "10px",
                      textTransform: "uppercase",
                      display: "flex",
                      marginTop: 2,
                    }}
                  >
                    <ScheduleIcon color="disabled" style={{ height: 15 }} />
                    {formatDate(data.check_created_at)}
                  </span>
                </div>
              </Grid>
              {!images && (
                <div
                  style={{
                    border: "1px solid #e4ebf3",
                    width: "100%",
                    marginTop: 10,
                  }}
                />
              )}
              <Grid item md={12}>
                < Link to={
                  {
                    pathname: "/claim/" + data._id + (urlAccept ? "/accept" : ""),
                    state: {
                      dataFromCard: data
                    }
                  }
                }
                >
                  {/* <Link to={{pathname:"/claim/" + data._id + (urlAccept ? "/accept" : "")}}> */}
                  <div className={classes.textDiv}>
                    {images ? (
                      <ProcessImage
                        image={data.recheck_image}
                        resize={{ width: "100%" }}
                        colors={{
                          mix: {
                            color: "mistyrose",
                            amount: 20,
                          },
                        }}
                        processedImage={(src, err) =>
                          this.setState({ src, err })
                        }
                      />
                    ) : (
                      <Typography component="p" style={{ fontWeight: 600 }}>
                        {data.claim}
                      </Typography>
                    )}
                  </div>
                </Link>
              </Grid>
              {!images && (
                <div
                  style={{
                    border: "1px solid #e4ebf3",
                    width: "100%",
                    marginTop: 10,
                    marginBottom: 5,
                  }}
                />
              )}
              <Grid item md={12}>
                {assigned ? (
                  <>
                    {this.getFCUserName(data) ? (
                      this.fcChecker(assigned, data, userHashTable)
                    ) : (
                      <>
                        {count && (
                          <>
                            {data.user_id ? (
                              <small>
                                <p style={{ marginBottom: 0, marginTop: 25 }}>
                                  Accepted by
                                  <b>
                                    {getUserByID(data.user_id, users)}
                                    <Link to={"/claim/" + data._id}>
                                      <span
                                        style={{
                                          color: "#F62459",
                                          paddingLeft: 10,
                                        }}
                                      >
                                        View Claim
                                      </span>
                                    </Link>
                                  </b>
                                </p>
                              </small>
                            ) : (
                              <>
                                {data.accepted_by || acceptedBy ? (
                                  <small>
                                    Accepted by{" "}
                                    <b>{data.accepted_by || acceptedBy}</b>
                                  </small>
                                ) : (
                                  <button
                                    type="button"
                                    style={{
                                      padding: "5px",
                                      backgroundColor: "#F1F4F8",
                                      cursor: "pointer",
                                      marginTop: 3,
                                      border: 0,
                                      borderRadius: "5px",
                                    }}
                                    onClick={() => this.handleAccept(data._id)}
                                  >
                                    {isAcceptLoading ? "Loading..." : "Accept"}
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {newClaimsMultimedia ? (
                      <>
                        {data.accepted || acceptedBy ? (
                          <small>
                            Accepted by <b>{data.accepted_by || acceptedBy}</b>
                          </small>
                        ) : (
                          <button
                            style={{
                              border: 0,
                              padding: 5,
                              backgroundColor: "#F1F4F8",
                              marginTop: 25,
                              // borderRadius: 10,
                              cursor: "pointer",
                              borderRadius: "5px",
                            }}
                            onClick={() =>
                              this.handleAcceptMultimediaClaim(data)
                            }
                          >
                            {isAcceptLoading ? "Loading..." : "Accept"}
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {this.getModUserName(data) &&
                          this.fcAndModChecker(data, userHashTable)}
                        {finalize && (
                          <>
                            <br />
                            {!(data.accepted || acceptedBy) ? (
                              <button
                                style={{
                                  marginTop: 5,
                                  border: 0,
                                  padding: 5,
                                  backgroundColor: "#F1F4F8",
                                  // borderRadius: 10,
                                  cursor: "pointer",
                                  borderRadius: "5px",
                                }}
                                onClick={() => this.handleAccept(data) || this.handleAcceptMultimediaClaim(data)}
                              >
                                {isAcceptLoading ? "Loading..." : "Accept"}
                              </button>
                            ) : (
                              <small>
                                Accepted by{" "}
                                <b>{data.accepted_by || acceptedBy}</b>
                              </small>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    userHashTable: data.userHashTable,
    claimed_Data: data.claim,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SimpleCard));
