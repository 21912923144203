import React, { Component } from "react";
import axios from "axios";
import api, { getBase } from "../api";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Activity from "./components/Activity";
import "./overview.css";
import Summary from "./components/Summary";
import handWave from "../assets/wave.png";
import { db, getRole } from "../App";
import PerformanceMetrics from "./components/PerformanceMetrics";
import { connect } from "react-redux";

const tabs = ["Fact Checkers Activity", "Performance Metrics"];
class OverviewDash extends Component {
  state = {
    users: [],
    allUsers: [],
    activeTab: 0,
    userActivity: false,
  };

  componentDidMount() {
    this.props.dispatch({ type: "SET_LOADING", loading: false });
    this.getUsers();
    db.ref().on("value", (snap) => {
      this.setState({ userActivity: snap.val() });
      this.getUsers();
    });
  }

  getUsers = async () => {
    const { user } = this.props;
    const { data } = await axios.get(getBase(api.USERS), {
      headers: { Authorization: "Token " + localStorage.token },
    });
    getRole(user) === "factchecker"
      ? this.getFCerActivity(user, data.data)
      : this.setState({ users: data.data });
  };

  getFCerActivity = (currentUser, users) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].user_id === currentUser.id) {
        this.setState({ users: [users[i]] });
        break;
      }
    }
  };

  handleTabClick = (e, activeTab) => {
    this.setState({ activeTab });
  };

  renderTab = (activeTab, userActivity) => {
    const { users } = this.state;
    const { user, allUsers, isAllUserLoading, supFilter, fcerFilter,
      modFilter, userStatusFilter, sources, verdictFilter, languages, countries,
      onHandleUserChange, onHandleCheckBox, onHandleClearFilters, onHandleDeleteFilterItem, onHandleRangeChange } = this.props;
    if (activeTab === 0) {
      return (
        <Activity
          users={users}
          currentUser={user}
          userActivity={userActivity}
        />
      );
    } else if (activeTab === 1) {
      return <PerformanceMetrics
        users={users}
        allUsers={allUsers}
        isAllUserLoading={isAllUserLoading}
        supFilter={supFilter}
        fcerFilter={fcerFilter}
        modFilter={modFilter}
        userStatusFilter={userStatusFilter}
        verdictFilter={verdictFilter}
        languages={languages}
        sources={sources}
        countries={countries}
        onHandleUserChange={onHandleUserChange}
        onHandleCheckBox={onHandleCheckBox}
        onHandleClearFilters={onHandleClearFilters}
        onHandleDeleteFilterItem={onHandleDeleteFilterItem}
        onHandleRangeChange={onHandleRangeChange}
      />;
    }
  };

  render() {
    const { activeTab, userActivity } = this.state;
    const { user } = this.props;

    return (
      <div className="overview-dash">
        <Grid container alignItems="center" className="dash-user-greet">
          <img src={handWave} alt="wave" />
          <h2>Hello, {user.username}</h2>
        </Grid>
        <Summary />
        <Grid container>
          <Tabs
            value={activeTab}
            onChange={this.handleTabClick}
            className="profile-tab-container"
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                value={index}
                label={tab}
                className={
                  index === activeTab
                    ? "profile-tab"
                    : "profile-tab inactive-tab"
                }
              />
            ))}
          </Tabs>
        </Grid>
        <div className="dash-tabs-container">
          {this.renderTab(activeTab, userActivity)}
        </div>
      </div>
    );
  }
}

export default connect()(OverviewDash);
