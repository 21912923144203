import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getTimeDifference, getTimeFrom } from "./../../../utils/time";
import { Link } from "react-router-dom";
import { FlagIcon } from "react-flag-kit";
import ProcessImage from "react-imgpro";
import {
  getLocation,
  priorityColors,
  priorityTextColors,
} from "./../../../utils/common";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { formatDate } from "../../../utils/fomratDate";
import Axios from "axios";
import api, { getBase } from "./../../../api";
import { connect } from "react-redux";
import { db, putClaimData } from "../../../App";
import { getLanguageByCode } from "../../../utils/language";

const styles = {
  card: {
    borderRadius: "10px",
    position: "relative",
  },
  imgGrid: {
    flexBasis: `${20}%`,
  },
  downColorTypo: {
    fontWeight: "bold",
    color: "red",
    fontSize: 13,
  },
  progressColorTypo: {
    fontWeight: "bold",
    color: "orange",
    fontSize: 13,
  },
  textDiv: {
    marginTop: 10,
    height: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
  },
  Button: {
    textTransform: "none",
  },
  typoBlack: {
    color: "#b79f9f",
  },
  line: {
    border: "none",
    background: "#ddd",
    height: "1px",
  },
};
class SimpleCard extends React.Component {
  state = {
    data: this.props.data,
    acceptedBy: "",
    isAcceptLoading: false,
    claimed_Data: this.props.claimed_Data,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = this.state;
    const { claimed_Data } = nextProps;
    if (
      JSON.stringify(claimed_Data) !== JSON.stringify(this.state.claimed_Data)
    ) {
      if (Object.keys(claimed_Data).includes(data._id)) {
        data.accepted = true;
        data.accepted_by = claimed_Data[data._id];
      }
      this.setState({ data: data }, () => {
        this.setState({ claimed_Data: claimed_Data });
      });
    }
  }

  handleAccept(id) {
    console.log("accept");
    // const { data } = this.state;
    this.setState({ isAcceptLoading: true });
    const userInfo = JSON.parse(localStorage.user);
    const updates = { status: "working", time_in_status: new Date() };
    if (this.props.images) {
      Axios.post(
        `${getBase(api.CLAIM)}${id}/accept?type=image`,
        {},
        {
          headers: {
            Authorization: "Token " + localStorage.token,
            "content-type": "application/json",
          },
        }
      )
        .then((res) => {
          this.setState({
            isAcceptLoading: false,
            acceptedBy: userInfo.username,
          });
          putClaimData(id, userInfo.username);
          db.ref()
            .child(`${userInfo.id}`)
            .update(updates);
        })
        .catch((err) => console.log(err));
    } else {
      Axios.post(
        `${getBase(api.CLAIM)}${id}/accept`,
        {},
        {
          headers: {
            Authorization: "Token " + localStorage.token,
            "content-type": "application/json",
          },
        }
      )
        .then((res) => {
          this.setState({
            isAcceptLoading: false,
            acceptedBy: userInfo.username,
          });
          putClaimData(id, userInfo.username);
          db.ref()
            .child(`${userInfo.id}`)
            .update(updates);
        })
        .catch((err) => console.log(err));
    }
  }
  getFCUserName(data) {
    if (data.factchecker_id === (undefined || "")) {
      return data.old_factchecker_id;
    }
    return data.factchecker_id;
  }
  getModUserName(data) {
    if (data.moderator_id === (undefined || "")) {
      return data.old_moderator_id;
    }
    return data.moderator_id;
  }
  getDurationData(start, completed) {
    if (start && completed) {
      return (
        <span>
          {start && completed && (
            <small>
              <b>| </b> Duration <b> {getTimeDifference(start, completed)}</b>
            </small>
          )}
        </span>
      );
    } else {
      if (start) {
        return (
          <span>
            <small>
              <b>| </b> Duration <b> {getTimeFrom(start)} </b>
            </small>
          </span>
        );
      }
    }
  }
  render() {
    const { classes, images, userHashTable } = this.props;
    const { isAcceptLoading, acceptedBy, data } = this.state;
    return (
      <Card
        style={{
          height: images ? 250 : 300,
          boxShadow: "none",
          borderRadius: 20,
          border: `2px solid ${priorityTextColors[data.priority]}`,
        }}>
        <CardContent>
          <Grid container>
            <Grid item md={3}>
              {data.recheck_image ? (
                <Typography component='p'>
                  <span style={{ color: "black", fontWeight: "600" }}>
                    {data._id}
                  </span>
                </Typography>
              ) : (
                <Typography component='p'>
                  <span style={{ color: "black", fontWeight: "600" }}>
                    {data.tracking_id}
                  </span>
                </Typography>
              )}
            </Grid>
            <Grid item md={3}>
              {data.lang_code && (
                <Typography
                  style={{
                    fontSize: "10px",
                    color: "black",
                    width: "100%",
                    textTransform: "uppercase",
                    textAlign: "right",
                    lineHeight: "20px",
                  }}
                  variant='body1'>
                  <span
                    style={{
                      padding: "3px",
                      borderRadius: "3px",
                      backgroundColor: "#f1f4f8",
                    }}>
                    {getLanguageByCode(data.lang_code).name}

                  </span>

                </Typography>
              )}
            </Grid>
            <Grid item md={3}>
              <Typography
                style={{
                  fontSize: "10px",
                  color: "black",
                  width: "100%",
                  textTransform: "uppercase",
                  textAlign: "center",
                  lineHeight: "20px",
                }}
                variant='body1'>
                <span
                  style={{
                    padding: "3px",
                    borderRadius: "3px",
                    backgroundColor: "#f1f4f8",
                  }}>
                  {data.claim_source}
                </span>
              </Typography>
            </Grid>
            {data.location && (
              <Grid item md={2} style={{ textAlign: "center" }}>
                <FlagIcon
                  code={getLocation(data.location)}
                  size={20}
                  style={{ paddingLeft: "auto", marginTop: 0 }}
                />
              </Grid>
            )}
            <Grid item md={12}>
              <div style={{ display: "flex", marginTop: 5 }}>
                <span
                  style={{
                    fontSize: "10px",
                    backgroundColor: priorityColors[data.priority],
                    textTransform: "uppercase",
                    lineHeight: "20px",
                    marginRight: "5px",
                    padding: "0 5px",
                    borderRadius: "3px",
                    fontWeight: "bold",
                  }}>
                  {data.priority}
                </span>
                <span
                  style={{
                    fontSize: "10px",
                    textTransform: "uppercase",
                    display: "flex",
                    marginTop: 2,
                  }}>
                  <ScheduleIcon color='disabled' style={{ height: 15 }} />
                  {formatDate(data.check_created_at)}
                </span>
              </div>
            </Grid>
            {!images && (
              <div
                style={{
                  border: "1px solid #e4ebf3",
                  width: "100%",
                  marginTop: 10,
                }}
              />
            )}
            {/* <Link to={"/claim/" + data._id}> */}
            <Link
              to={{
                pathname: "/claim/" + data._id,
                state: {
                  dataFromCard: data,
                },
              }}>

              <Grid item md={12}>
                <div className={classes.textDiv}>
                  {/* {console.log(data)} */}
                  {images ? (
                    <ProcessImage
                      image={data.recheck_image}
                      resize={{ width: "100%" }}
                      colors={{
                        mix: {
                          color: "mistyrose",
                          amount: 20,
                        },
                      }}
                      processedImage={(src, err) => this.setState({ src, err })}
                    />
                  ) : (
                    <Typography component='p' style={{ fontWeight: 600 }}>
                      {data.claim}
                    </Typography>
                  )}
                </div>
              </Grid>
            </Link>
            {!images && (
              <div
                style={{
                  border: "1px solid #e4ebf3",
                  width: "100%",
                  marginTop: 10,
                  marginBottom: 5,
                }}
              />
            )}
            <Grid item md={12}>
              {this.getFCUserName(data) && userHashTable && (
                <>
                  <span style={{ textTransform: "capitalize" }}>
                    <small>
                      FC by :{" "}
                      {userHashTable[this.getFCUserName(data)].username !==
                        undefined && (
                          <b>
                            {userHashTable[this.getFCUserName(data)].username}{" "}
                          </b>
                        )}
                    </small>
                  </span>
                  {this.getDurationData(
                    data.factcheck_started_at,
                    data.factcheck_completed_at
                  )}
                </>
              )}
            </Grid>
            <Grid item md={12}>
              {data.accepted_by || acceptedBy ? (
                <small>
                  Accepted by <b>{data.accepted_by || acceptedBy}</b>
                </small>
              ) : (
                <button
                  type='button'
                  style={{
                    padding: "5px",
                    backgroundColor: "#F1F4F8",
                    cursor: "pointer",
                    marginTop: 3,
                    border: 0,
                    borderRadius: "5px",
                  }}
                  onClick={() => this.handleAccept(data._id)}>
                  {isAcceptLoading ? "Loading..." : "Accept"}
                </button>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export function getCurrent(users, data) {
  let ret = false;
  for (var i = 0; i < users.length; i++) {
    if (users[i].user_id === data.factchecker_id) {
      ret = users[i];
    }
  }
  return ret;
}
export function getoldfactchecker(users, data) {
  let ret = false;
  for (var i = 0; i < users.length; i++) {
    if (users[i].user_id === data.old_factchecker_id) {
      ret = users[i];
    }
  }
  return ret;
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (data) => {
  return {
    userHashTable: data.userHashTable,
    claimed_Data: data.claim,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SimpleCard));
