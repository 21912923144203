import React, { Component } from "react";
import "./SearchFilter.css";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import DateFilter from "./DateFilter";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";

const verdicts = ["True", "False", "Misleading", "Unverifiable", "Partly_True"];
const userStatus = ["in progress", "pending authorisation", "resolved"];

class SearchFilter extends Component {
  state = {
    factCheckers: [],
    moderators: [],
    supervisors: [],
    dialogOpen: false,
    dateRange: {
      startDate: new Date(),
      endDate: new Date(),
      key: "dateRange",
    },
    checkedBoxes: false
  };

  componentDidMount() {
    this.sortByRole();
  }
  componentWillReceiveProps(nextProps) {
    // console.log('searchFilter',nextProps)
    if (nextProps.checkedBoxes !== this.state.checkedBoxes) {
      this.setState({ checkedBoxes: nextProps.checkedBoxes }, () => {
        this.props.dispatch({ type: 'CHECKED_BOXES', checkedBoxes: false })

      })
    }
    if (nextProps.isDateRangeActive !== this.state.isDateRangeActive) {
      this.setState({ isDateRangeActive: nextProps.isDateRangeActive })
    }
  }
  sortByRole = () => {
    const { allUsers } = this.props;
    let factCheckers = [];
    let moderators = [];
    let supervisors = [];
    allUsers.forEach((user) => {
      if (user.role === "factchecker") factCheckers.push(user);
      if (user.role === "moderator") moderators.push(user);
      if (user.role === "supervisor") supervisors.push(user);
    });
    factCheckers = this.sortAlpha(factCheckers);
    moderators = this.sortAlpha(moderators);
    supervisors = this.sortAlpha(supervisors);
    this.setState({ factCheckers, moderators, supervisors });
  };

  sortAlpha = (arr) => arr.sort((a, b) => a.username.localeCompare(b.username));

  renderFilterItems = (filterItems, type) => {
    const { onHandleDeleteFilterItem, userHashTable } = this.props;
    if (filterItems === undefined) return null;
    return (
      <div>
        {filterItems.map((item) => (
          <div key={item} className="filter-item">
            <Typography>{userHashTable[item].username}</Typography>
            {/* {console.log("userHashTable", userHashTable[item].username, item)} */}
            <button onClick={() => onHandleDeleteFilterItem(item, type)}>
              ×
            </button>
          </div>
        ))}
      </div>
    );
  };

  renderNonUserFilters = (filterItems, type) => {
    if (filterItems === undefined) return null;
    const { onHandleDeleteFilterItem } = this.props;
    return (
      <div>
        {filterItems.map((item) => (
          <div key={item} className="filter-item">
            <Typography>{item}</Typography>
            <button onClick={() => onHandleDeleteFilterItem(item, type)}>
              ×
            </button>
          </div>
        ))}
      </div>
    );
  };
  setFilters = (selectionRange) => {
    const { selectionRange: stateRange } = this.state;
    selectionRange = selectionRange || stateRange;
    this.props.setFilters({
      start_date: moment(selectionRange.startDate).format("YYYY-MM-DD"),
      end_date: moment(selectionRange.endDate).format("YYYY-MM-DD"),
    });
  };
  handleSelect2 = (ranges) => {
    this.props.getData2(ranges.selection);
    this.setState({
      selectionRange: {
        startDate: ranges.startDate,
        endDate: ranges.endDate,
        key: ranges.key,
      },
    });
    this.setFilters(ranges.selection);
  };

  handleSelect = (ranges) => {
    this.setState({ dateRange: ranges.dateRange });
  };

  handleDialogClose = () => {
    const { dateRange } = this.state;
    this.setState({ dialogOpen: false });
    if (this.props.onHandleRangeChange)
      this.props.onHandleRangeChange(dateRange);
  };

  handleOpenDateFilter = () => {
    this.setState({ dialogOpen: true });
  };

  handleToggleFilter = (e) => {
    this.props.dispatch({
      type: "TOGGLE_DATE_FILTER",
      isDateRangeActive: e.target.checked,
    });
  };

  handleCloseFilter = () => {
    this.props.dispatch({ type: "SEARCH_FILTER", searchFilter: false });
  };

  render() {
    const {
      factCheckers,
      moderators,
      supervisors,
      dialogOpen,
      dateRange,
    } = this.state;
    const {
      onHandleUserChange,
      supFilter,
      fcerFilter,
      modFilter,
      verdictFilter,
      userStatusFilter,
      languages,
      sources,
      countries,
      onHandleCheckBox,
      onHandleClearFilters,
      isDateRangeActive,
      from,
    } = this.props;
    return (
      <div
        className={
          from === "claims" ? "search-filter-claims" : "search-filter-container"
        }
      >
        {from === "claims" && (
          <CloseIcon
            onClick={this.handleCloseFilter}
            className="dialog-close filter-close"
          />
        )}
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className="filter-head"
        >
          <h3>Filters</h3>
          <button
            className="filter-clear-button"
            onClick={onHandleClearFilters}
            disabled={this.props.clearFilter}
          >
            CLEAR ALL
          </button>
        </Grid>
        <div className="option-filter">
          <h5>Filter by date</h5>
          <Grid container alignItems="center" justify="space-between">
            <div className="date-filter-toggle">
              <input
                type="checkbox"
                onChange={this.handleToggleFilter}
                checked={isDateRangeActive}
              />
              <p>activate filter</p>
            </div>
            <button
              className="save-button range-button"
              onClick={this.handleOpenDateFilter}
            >
              Add a range
            </button>
          </Grid>
          {dialogOpen && (
            <DateFilter
              dialogOpen={dialogOpen}
              onHandleDialogClose={this.handleDialogClose}
              dateRange={dateRange}
              onHandleSelect={this.handleSelect}
            />
          )}
        </div>
        <div className="option-filter">
          <h5>Filter by Fact checker</h5>
          <select name="fcerFilter" onChange={onHandleUserChange} value={fcerFilter.length === 0 ? "All" : parseInt(fcerFilter[fcerFilter.length - 1])}>
            <option value="All">All</option>
            {factCheckers.map((user) => (
              <option value={user.user_id} key={user.user_id}>
                {user.username}
              </option>
            ))}
          </select>
          {this.renderFilterItems(fcerFilter, "fcerFilter")}
        </div>
        <div className="option-filter">
          <h5>Filter by Moderator</h5>
          <select name="modFilter" onChange={onHandleUserChange} value={modFilter.length === 0 ? "All" : parseInt(modFilter[modFilter.length - 1])}>
            <option value="All">All</option>
            {moderators.map((user) => (
              <option value={user.user_id} key={user.user_id}>
                {user.username}
              </option>
            ))}
          </select>
          {this.renderFilterItems(modFilter, "modFilter")}
        </div>
        <div className="option-filter">
          <h5>Filter by Supervisor</h5>
          <select name="supFilter" onChange={onHandleUserChange} value={supFilter.length === 0 ? "All" : parseInt(supFilter[supFilter.length - 1])}>
            <option value="All">All</option>
            {supervisors.map((user) => (
              <option value={user.user_id} key={user.user_id}>
                {user.username}
              </option>
            ))}
          </select>
          {this.renderFilterItems(supFilter, "supFilter")}
        </div>
        <div className="option-filter">
          <h5>Filter by Status</h5>
          <select name="userStatusFilter" onChange={onHandleUserChange} value={userStatusFilter.length === 0 ? "All" : userStatusFilter[userStatusFilter.length - 1]}>
            <option value="All">All</option>
            {userStatus.map((status, index) => (
              <option
                key={index}
                value={status}
                style={{ textTransform: "capitalize" }}
              >
                {status}
              </option>
            ))}
          </select>
          {this.renderNonUserFilters(userStatusFilter, "userStatusFilter")}
        </div>
        <div className="option-filter" style={{ paddingBottom: "20px" }}>
          <h5>Filter by Verdict</h5>
          <select name="verdictFilter" onChange={onHandleUserChange} value={verdictFilter.length === 0 ? "All" : verdictFilter[verdictFilter.length - 1]}>
            <option value="All">All</option>
            {verdicts &&
              verdicts.map((verdict, index) => (
                <option key={index} value={verdict}>
                  {verdict}
                </option>
              ))}
          </select>
          {this.renderNonUserFilters(verdictFilter, "verdictFilter")}
        </div>
        <div className="check-filter">
          <h5>Filter by language</h5>
          {languages &&
            languages.map((language, index) => (
              <div className="filter-checkbox" key={index}>
                <input
                  type="checkbox"
                  checked={language.checked}
                  onChange={(e) => onHandleCheckBox("languages", index, e)}
                />{" "}
                {language.name}
              </div>
            ))}
        </div>
        <div className="check-filter">
          <h5>Filter by source</h5>
          {sources &&
            sources.map((source, index) => (
              <div className="filter-checkbox" key={index}>
                <input
                  type="checkbox"
                  checked={source.checked}
                  onChange={(e) => onHandleCheckBox("sources", index, e)}
                />{" "}
                {source.name}
              </div>
            ))}
        </div>
        <div className="check-filter">
          <h5>Filter by country</h5>
          {countries &&
            countries.map((country, index) => (
              <div className="filter-checkbox" key={index}>
                <input
                  type="checkbox"
                  checked={country.checked}
                  onChange={(e) => onHandleCheckBox("countries", index, e)}
                />{" "}
                {country.name}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    userHashTable: data.userHashTable,
    isDateRangeActive: data.isDateRangeActive,
    checkedBoxes: data.checkedBoxes,
    clearFilter: data.clearFilter
  };
};

export default connect(mapStateToProps)(SearchFilter);
