import { CircularProgress } from '@material-ui/core';
import React, { Component } from 'react'

export class LoadingCircular extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }
    handleRemoveClaim = () => {
        this.setState({ loading: true })
        setTimeout(() => {
            this.setState({ loading: false })
        }, 3000)
        this.props.handleRemoveClaim(this.props.id)
    }
    render() {
        const { loading } = this.state;
        if (loading) {
            return (
                <CircularProgress />
            )
        }
        return <button className="save-button w-max" onClick={() => this.handleRemoveClaim()}>
            Remove
        </button>;
    }
}

export class LoadingLine extends Component {
    render() {
        const { loading, children } = this.props;
        if (loading) {
            return (
                <CircularProgress />
            )
        }
        return children;
    }
}

export default LoadingCircular;