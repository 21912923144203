import firebase from "firebase";

let base = "https://cors-anywhere-dot-corenlp-processing.appspot.com/";
if (window.location.hostname === "localhost") { base = "https://cors-anywhere.herokuapp.com/"; }

export const baseurl = base + "http://104.196.194.242/api/";

export const apis = {
    allUserProjects: "v2/projects/user",
    project: "v2/projects",
    getClaim: "https://checker.logically.co.uk/api/website/resolved_claims?tracking_id=",
    addClaim: "v2/claim_request",
    getStatus: "v1/claims/status?id=",
    getClaims: "v1/claims",
    verifyClaim: "v1/claims/verify",
    verifyImage: "v2/image/verify",
    removeClaim: "v2/projects/remove_claim",
    getNotifications: 'api/v1/notifications',
    readNotifications: 'api/v1/notifications/mark_read',
    checkNotifications: 'api/v1/check_notifications',
}

export async function getHeaders() {
    let user = firebase.auth().currentUser;
    if (user) {
        let idToken = await user.getIdToken();
        return {
            headers: {
                'Authorization': idToken,
                "X-Requested-With": "XMLHttpRequest"
            }
        };
    } else {
        return {};
    }
}
