import React, { Component } from 'react'
import { IconButton, Input, TableCell, TableRow, Button, Menu, MenuItem, Typography } from '@material-ui/core'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { getAllLanguage } from '../utils/language'
import axios from 'axios';
import api, { getBase } from '../api'
import { toast } from "react-toastify";

export class ExpandableTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            topicInput: '',
            languageSelection: {},
            anchorEl: null
        }
    }
    handleToggle() {
        this.setState({ isOpen: !this.state.isOpen })
        this.props.handleSelectedTopic(this.props.singleTopic.parent_id)
    }
    addMultilanguageTopic(item) {
        const { topicInput, languageSelection } = this.state
        const dataToSend = {
            parent_id: item._id,
            topic: topicInput,
            language: languageSelection.code
        }

        axios.post(getBase(api.TOPICS_WITH_ASSOCIATED_FACTCHEKCS), dataToSend, {
            headers: { Authorization: "Token " + localStorage.token }
        }).then(res => {
            toast.success('New Multilingual Topic has added successfully', {
                position: toast.POSITION.TOP_RIGHT,
            });
            this.setState({ topicInput: '', languageSelection: {}, isOpen: false }, () => {
                this.props.refetchList()
            })
        }).catch(e => {
            toast.error('There was an error please try again', {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.log(e)
        })

    }
    handleDropdown = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleClose = (item) => {
        this.setState({ languageSelection: item }, () => {
            this.setState({ anchorEl: null });
        })
    }
    render() {
        const { singleTopic, extendedData } = this.props
        const { isOpen, topicInput, languageSelection, anchorEl } = this.state;
        return (
            <><TableRow >
                <TableCell>{singleTopic._id}</TableCell>
                <TableCell colSpan={2}>{singleTopic.topic}</TableCell>
                <TableCell colSpan={2}>{singleTopic.language}</TableCell>
                <TableCell colSpan={2}>
                    <IconButton onClick={() => this.handleToggle()}>{isOpen ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </TableCell>
            </TableRow>
                {isOpen && <><TableRow>
                    <TableCell colSpan={3}>
                        <div className='flex gap align-center'>
                            <Typography style={{ fontWeight: 'bold' }}>Topic:</Typography>
                            <Input value={topicInput} onChange={(e) => this.setState({ topicInput: e.target.value })} placeholder='Write your topic' />
                        </div>
                    </TableCell>
                    <TableCell colSpan={2}>
                        <div className='flex gap align-center'>
                            <Typography style={{ fontWeight: 'bold' }}>Select Language:</Typography>

                            <Button
                                aria-owns={anchorEl ? 'simple-menu' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleDropdown}
                                style={{ fontWeight: 600 }}
                            >
                                {languageSelection.code || "None"}
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                            >
                                {getAllLanguage().map(item => {
                                    return <MenuItem onClick={() => this.handleClose(item)} key={item.name}>
                                        {item.name}
                                    </MenuItem>
                                })}

                            </Menu>
                        </div>
                    </TableCell>
                    <TableCell colSpan={2}>
                        <button disabled={!topicInput && !languageSelection.code} onClick={() => this.addMultilanguageTopic(singleTopic)} className='save-button w-max'>Add Multilingual Topic</button>

                    </TableCell>
                </TableRow>
                    {extendedData.map((item, index) => {
                        if (index > 0) {
                            return <TableRow key={item._id}>
                                <TableCell>({item._id})</TableCell>
                                <TableCell colSpan={2}>{item.topic}</TableCell>
                                <TableCell colSpan={2}>{item.language}</TableCell>
                                <TableCell colSpan={2}></TableCell>
                            </TableRow>

                        }
                    })
                    }
                    <TableRow>
                        <TableCell style={{ borderColor: "#f62459" }} colSpan={7}>
                            <Typography style={{ fontWeight: 'bold', padding: 12 }}>Associated Factchecks</Typography>
                            <ol className='associatedFC'>
                                {singleTopic.associated_factchecks.length > 0 ? singleTopic.associated_factchecks.map((item, index) => {
                                    return <li key={index}><a href={`https://factcheck.logically.ai/claim/${item}`} target='_blank' rel='noopener noreferrer'>{item}</a>
                                    </li>

                                })
                                    :
                                    <li>No factchecks associated</li>
                                }
                            </ol>

                        </TableCell>
                    </TableRow>
                </>
                }
            </>
        )
    }
}

export default ExpandableTable