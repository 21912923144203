import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import api, { getUser } from "../../../../api";
import { Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { FlagIcon } from "react-flag-kit";
import { colours, getLocation } from "../../../../utils/common";
import StarRatings from "react-star-ratings";
import Tooltip from "@material-ui/core/Tooltip";
import Highlights from "../../Highlights/index";
import Modal from "react-modal";
import { connect } from "react-redux";
import { handleSort } from "../../NewClaims/NewClaims";

const theme = createMuiTheme();
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  paper1: {
    padding: theme.spacing.unit * 0,
    color: theme.palette.text.secondary,
  },
  fontFamily: ["Roboto"].join(","),
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
  codeText: {
    position: "relative",
  },
  paragraphText: {
    position: "relative",
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "15px",
    maxHeight: 3029,
  },
  chip: {
    position: "relative",
    fontWeight: 600,
    minWidth: `${40}%`,
    margin: 4,
  },
  butto: {
    margin: theme.spacing.unit,
    boxShadow: "none",
  },
  text: {
    color: "#FFFFFF",
    textTransform: "capitalize",
  },
});

const customStyles = {
  overlay: {
    zIndex: 1200,
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  content: {
    backgroundColor: "#F0F0F0",
  },
};

class HeroResolvedClaims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: this.props.loading,
      offset: 0,
      total: 0,
      claim_checked: [],
      disabled: false,
      isFilterApply: false,
      queryValue: "",
      pageNumbers: [],
      country: "",
      selectedHeroFC: [],
      dataSort: {
        verdict: "asc",
      },
    };
  }
  componentDidMount() {
    this.getData(this.props.queryValue);
    // window.addEventListener('scroll', this.handleScroll)
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.queryValue !== nextProps.queryValue ||
      this.state.country !== nextProps.country
    ) {
      let queryValue = nextProps.queryValue;
      this.setState(
        {
          country: nextProps.country,
          isFilterApply: nextProps.isFilterApply,
          queryValue,
        },
        () => {
          this.getData(queryValue);
        }
      );
    }
    if (nextProps.loading !== this.state.loading) {
      this.setState({ loading: nextProps.loading });
    }
  }
  getData = async (queryValue) => {
    const { country, isFilterApply } = this.state;
    console.log('state', country, isFilterApply)
    // this.setState({ loading: true });
    try {
      let url = "";
      let header = "";
      if (isFilterApply) {
        url = getUser(
          api.SEARCH_FACTCHECKS +
          "?tab=completed&location=GB&limit=100&offset=" +
          this.state.offset +
          "&" +
          queryValue
        );
        header = { headers: {} };
      } else {
        if (country) {
          url = getUser(
            api.HERO_FACTCHECKS +
            `?location=${country}&limit=100&offset=` +
            this.state.offset
          );
        } else {
          url = getUser(
            api.HERO_FACTCHECKS +
            "?limit=100&offset=" +
            this.state.offset
          );
        }
        header = { headers: {} };
      }
      // BELOW FNC CONDITION DOES NOT STATE TRUE
      const res = await axios.get(url, header);
      // this.setState({data: res.data.data},()=> this.setState({ loading: false }))
      // if (JSON.stringify(this.state.data) !== JSON.stringify(res.data.data)) {
      // this.props.setBadge(res.data.total_count, this.state.country);
      // console.log('Hero FC', res.data.data);
      // for (let i = 1; i <= Math.ceil(res.data.total_count / 100); i++) {
      //     this.state.pageNumbers.push(i);
      // }
      let allData = [];
      allData = this.state.data;
      allData = [];
      for (var i = 0; i < res.data.data.length; i++) {
        let done = false;
        for (var b = 0; b < allData.length; b++) {
          if (allData[b]._id === res.data.data[i]._id) {
            done = true;
          }
        }
        if (!done) {
          allData.push(res.data.data[i]);
        }
      }
      this.setState(
        {
          data: allData,
          // loading: false,
          total: res.data.total_count,
        },
        () => console.log("data", this.state.data)
      );
      this.props.dispatch({ type: "SET_LOADING", loading: false });
      let claim_checked = this.state.claim_checked;
      for (let i in res.data.data) {
        if (
          res.data.data[i].highly_rated === true &&
          moment(res.data.data[i].last_updated).format("YYYY-MM-DD")
        ) {
          claim_checked.push(res.data.data[i]._id);
        }
      }
      this.setState({
        claim_checked: claim_checked,
      });
    } catch (err) {
      // this.setState({ loading: false });
      this.props.dispatch({ type: "SET_LOADING", loading: false });
    }
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  handlePageClicks(offset) {
    this.setState({ offset }, () => {
      this.getData(this.props.queryValue);
    });
  }
  handleChangePriority = (event, id) => {
    let priorityFC = [...this.state.selectedHeroFC];
    let selectedData = [...this.state.data];
    priorityFC[event.target.value - 1] = id;
    this.setState({ selectedHeroFC: priorityFC });
    selectedData.map((claim) => {
      if (id === claim._id) {
        claim.priority = event.target.value;
      }
    });
    this.setState({ data: selectedData });
    console.log(this.state.selectedHeroFC);
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
    sessionStorage.clear();
  };
  handleChangeCheckBox = (index, id) => {
    const { onHandleSave } = this.props;
    const { selectedHeroFC, country } = this.state;
    let option = this.state.claim_checked;
    if (option.indexOf(id) === -1) {
      if (option.length === 10) {
        toast.error("10 Fact checks already selected", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        option.push(id);
      }
    } else {
      option.splice(option.indexOf(id), 1);
    }
    this.setState({
      claim_checked: option,
      Deselectchecked: false,
    });
    onHandleSave(selectedHeroFC, country, option);
    let selectedData = [...this.state.data];
    selectedData.map((claim) => {
      if (id === claim._id) {
        claim.selected = claim.selected ? false : true;
      }
    });
    this.setState({ data: selectedData });
  };
  getDataPreview = () => {
    let { country } = this.state;
    let url = "";
    let selectedValue = this.state.selectedHeroFC;
    let CheckedClaim = this.state.claim_checked;
    if (selectedValue) {
      let _id = selectedValue;
      let _ids = CheckedClaim;
      let datas = {
        _id: _id,
        _ids: _ids,
        location: country,
      };
      console.log("datas", datas);
      if (country) {
        url = getUser(
          api.HERO_FACTCHECKS +
          `?location=${country}&limit=100&offset=` +
          this.state.offset
        );
      } else {
        url = getUser(
          api.HERO_FACTCHECKS +
          "?location=IN" +
          "&limit=100&offset=" +
          this.state.offset
        );
      }
      axios
        .get(url)
        .then((res) => {
          let data = [...res.data.data];
          this.setState({
            dataPreview: data.filter((claim) => {
              if (claim.selected) {
                return claim;
              }
            }),
            // loading: false,
            modalIsOpen: true,
          });
          this.props.dispatch({ type: "SET_LOADING", loading: false });
          sessionStorage.setItem("invisible", "true");
        })
        .catch((err) => {
          toast.error("Error, could not Saved Changes", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      this.setState({
        // loading: false,
        modalIsOpen: true,
      });
      this.props.dispatch({ type: "SET_LOADING", loading: false });
    }
  };
  saveChanges = () => {
    console.log(this.state.selectedHeroFC);
    let CheckedClaim = this.state.selectedHeroFC;
    let datas = {
      ids: CheckedClaim,
      location: this.state.country,
    };
    if (
      CheckedClaim.length === 4 &&
      CheckedClaim[0] !== undefined &&
      CheckedClaim[1] !== undefined &&
      CheckedClaim[2] !== undefined &&
      CheckedClaim[3] !== undefined
    ) {
      axios
        .post(getUser(api.HERO_FACTCHECKS), datas, { headers: {} })
        .then((res) => {
          console.log("Save Changes" + JSON.stringify(res));
          let claim_checked = this.state.claim_checked;
          this.setState({
            claim_checked: claim_checked,
          });
          toast.success("Saved Changes successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((err) => {
          toast.error("Error, could not Save Changes", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      toast.error(
        "Warning, 4 new hero fact checks should be selected and prioritised",
        { position: toast.POSITION.TOP_RIGHT }
      );
    }
  };
  render() {
    const { loading, data, modalIsOpen, dataPreview, dataSort } = this.state;
    const { classes } = this.props;
    const { claim_checked } = this.state;
    // if (loading) {
    //   return (
    //     <div
    //       style={{
    //         position: "fixed",
    //         top: "50%",
    //         left: "50%",
    //         transform: "translate(-50%, -50%)",
    //       }}
    //     >
    //       <Loader type="ball-grid-pulse" color="#2d2d2d" />
    //     </div>
    //   );
    // }
    if (data === false) {
      return (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography>
            Oops, looks like something went wrong. Please try again later
          </Typography>
        </div>
      );
    }
    if (data.length === 0) {
      return <></>;
    }
    let user = JSON.parse(localStorage.user);
    let isSupervisor = true;
    if (user) {
      if (user.profile) {
        if (user.profile.fields) {
          isSupervisor = user.profile.fields.role === "supervisor";
        }
      }
    }
    let faster = {};
    let Complaints = {};

    return (
      <div className={classes.root}>
        <Grid container justify="flex-end" spacing={16}>
          <Grid item lg={4} md={4} style={{ marginTop: -60 }}>
            <Grid item lg={12} md={12}>
              <Grid container>
                <div style={{ flex: 1 }}></div>
                <Grid item style={{ paddingRight: 8 }}>
                  {isSupervisor && (
                    <div style={{ display: "flex" }}>
                      <Tooltip
                        title="To save any changes, previous selections must be removed first"
                        arrow
                      >
                        <Button
                          type="button"
                          variant="contained"
                          color="secondary"
                          onClick={() => this.saveChanges()}
                          className={classes.butto}
                          style={{ backgroundColor: "#4FA1FF" }}
                        >
                          <Typography className={classes.text}>
                            <b>Save Changes</b>
                          </Typography>
                        </Button>
                      </Tooltip>
                      <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        onClick={this.getDataPreview}
                        className={classes.butto}
                        style={{ backgroundColor: "#4FA1FF", marginLeft: 10 }}
                      >
                        <Typography className={classes.text}>
                          <b>Preview</b>
                        </Typography>
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Paper
            className={classes.paper1}
            elevation={0}
            style={{
              marginTop: 16,
              marginBottom: 16,
              width: "100%",
              padding: 8,
              paddingLeft: 16,
              paddingRight: 16,
              backgroundColor: faster.backgroundColor,
              border: Complaints.border,
              borderRadius: 10,
            }}
          >
            <Grid
              container
              spacing={16}
              alignItems="center"
              style={{ padding: 8 }}
            >
              <Grid
                item
                lg={12}
                md={12}
                style={{
                  backgroundColor: "#f1f4f8",
                  borderRadius: "10px",
                  paddingTop: 15,
                  paddingBottom: 15,
                }}
              >
                <Grid container alignItems="center">
                  <Grid item sm={1} md={1}>
                    <Typography>Details</Typography>
                  </Grid>
                  <Grid item sm={1} md={1}>
                    <Typography>Country</Typography>
                  </Grid>
                  <Grid item sm={4} md={4}>
                    <Typography>Claim</Typography>
                  </Grid>
                  <Grid item sm={2} md={2} style={{ textAlign: "center" }}>
                    <Typography>Rating</Typography>
                  </Grid>
                  <Grid item sm={2} md={2} style={{ textAlign: "center" }}>
                    < Typography
                      style={
                        {
                          cursor: "pointer"
                        }
                      }
                      onClick={
                        () => {
                          this.setState({
                            dataSort: {
                              ...dataSort,
                              verdict: dataSort.verdict === "desc" ?
                                "asc" : "desc",
                              data: handleSort(
                                "verdict",
                                dataSort.verdict,
                                data
                              ),
                            },
                          });
                        }
                      } >
                      Verdict {
                        dataSort.verdict === "asc" ? (<
                          ArrowDropUpIcon style={
                            {
                              position: "relative",
                              top: 5
                            }
                          }
                          size="small"
                          fontSize="small" /
                        >
                        ) : (<
                          ArrowDropDownIcon style={
                            {
                              position: "relative",
                              top: 5
                            }
                          }
                          size="small"
                          fontSize="small" /
                        >
                        )
                      }</Typography>
                  </Grid>
                  {isSupervisor && (
                    <>
                      <Grid item lg={1} md={1} align="center">
                        <Typography>Hero FC's</Typography>
                      </Grid>
                      <Grid item lg={1} md={1} align="center">
                        <Typography>Priority</Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {data.map((claim, index) => {
              claim.faster_check
                ? (faster = { backgroundColor: "#fff" })
                : (faster = {});
              claim.has_complaint
                ? (Complaints = { border: "1px solid red" })
                : (Complaints = {});
              return (
                <Grid container spacing={16} key={index}>
                  <Grid item lg={10} md={10}>
                    <Grid container>
                      <Paper
                        className={classes.paper1}
                        elevation={1}
                        key={index}
                        style={{
                          marginBottom: 16,
                          width: "100%",
                          padding: 8,
                          backgroundColor: faster.backgroundColor,
                          border: Complaints.border,
                        }}
                      >
                        <Link to={"/claim/" + claim._id}>
                          <Grid container alignItems="center">
                            <Grid item sm={1} md={1}>
                              {/* <Typography>{getTimeDifference(claim.check_created_at, claim.factcheck_completed_at)}</Typography>
                                                    < Typography style={{ marginTop: 5, fontSize: '12px', color: '#fff', width: '100%', textTransform: 'uppercase' }} variant="body1">
                                                        {claim.lang_code == 'en' && <span style={{ padding: '3px 6px', borderRadius: '4px', backgroundColor: '#8560a8' }}>English</span>}
                                                        {claim.lang_code == 'mr' && <span style={{ padding: '3px 6px', borderRadius: '4px', backgroundColor: '#07ac58' }}>Marathi</span>}
                                                        {claim.lang_code == 'hi' && <span style={{ padding: '3px 6px', borderRadius: '4px', backgroundColor: '#c82a3d' }}>Hindi</span>}
                                                    </Typography> */}
                              <Typography style={{ marginTop: 5 }}>
                                {claim.tracking_id}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              sm={1}
                              md={1}
                              style={{ textAlign: "center" }}
                            >
                              <Typography
                                component="p"
                                className={classes.codeText}
                              >
                                <FlagIcon
                                  code={getLocation(claim.location)}
                                  size={44}
                                />
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              sm={6}
                              md={6}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Tooltip title={claim.modified_claim}>
                                <Typography
                                  component="p"
                                  className={
                                    classes.paragraphText + " maxlines2"
                                  }
                                >
                                  {claim.modified_claim}
                                </Typography>
                              </Tooltip>
                              {/* {claim.claim_source === "extension" && <Tooltip title="This claim is from the extension"><ExtensionIcon style={{margin: "10px 0 10px 10px"}} /></Tooltip>}
                                                    {claim.claim_source === "whatsapp" && <Tooltip title="This claim is from the whatsapp"><WhatsAppIcon style={{margin: "10px 0 10px 10px"}} /></Tooltip>} */}
                            </Grid>

                            <Grid
                              item
                              sm={2}
                              md={2}
                              style={{ textAlign: "center" }}
                            >
                              {claim.rating > 0 && (
                                <StarRatings
                                  rating={Number(claim.rating)}
                                  starDimension="20px"
                                  starSpacing="1px"
                                  starRatedColor="#ffd700"
                                />
                              )}
                            </Grid>
                            <Grid item sm={2} md={2}>
                              {claim.verdict !== "" && (
                                <div
                                  style={{
                                    backgroundColor: colours[claim.verdict],
                                    padding: "8px 14px",
                                    maxWidth: "100%",
                                    borderRadius: 4,
                                    marginLeft: "auto",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: "600",
                                      textTransform: "uppercase",
                                      textAlign: "center",
                                      color:
                                        colours[claim.verdict] === "#e9ff00"
                                          ? "#000"
                                          : "#fff",
                                    }}
                                  >
                                    {claim.verdict.replace("_", " ")}
                                  </Typography>
                                </div>
                              )}
                            </Grid>
                            {claim.editorColumn && (
                              <Grid item sm={2} md={2}>
                                {claim.editorColumn.tags.map((tag, index) => {
                                  return (
                                    <Chip
                                      label={tag}
                                      className={classes.chip}
                                      variant="outlined"
                                      key={index}
                                    />
                                  );
                                })}
                              </Grid>
                            )}
                          </Grid>
                        </Link>
                      </Paper>
                    </Grid>
                  </Grid>
                  {isSupervisor && (
                    <Grid item lg={1} md={1}>
                      <Grid
                        container
                        style={{ width: "100%", marginTop: 10 }}
                        justify="center"
                      >
                        <Checkbox
                          checked={claim.selected}
                          key={index}
                          onChange={() =>
                            this.handleChangeCheckBox(index, claim._id)
                          }
                          value={
                            claim_checked.indexOf(claim._id) !== -1
                              ? "true"
                              : "false"
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                  {isSupervisor && claim.selected && (
                    <Grid item lg={1} md={1}>
                      <Grid
                        container
                        style={{ width: "100%", marginTop: 25 }}
                        justify="center"
                      >
                        <select
                          onChange={(event) =>
                            this.handleChangePriority(event, claim._id)
                          }
                          value={claim.priority}
                        >
                          <option value=""></option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Paper>
        </Grid>
        {isSupervisor && (
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
          >
            <Button
              onClick={this.closeModal}
              style={{
                marginLeft: "auto",
                width: "fit-content",
                display: "block",
              }}
            >
              <b>X</b>
            </Button>
            <form>
              <Highlights
                classes={classes}
                dataPreview={dataPreview}
                heroFc={true}
              />
            </form>
          </Modal>
        )}

        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Pagination
            limit={100}
            offset={this.state.offset}
            total={this.state.total}
            onClick={(e, offset) => this.handlePageClicks(offset)}
          />
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    navpoints: state.navpoints,
    searchFilter: state.searchFilter,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(HeroResolvedClaims));
