import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Typography from "@material-ui/core/Typography";

const RadialProgress = ({ value, bg, type, pathColour }) => {
  return (
    <div className="summary-item">
      <CircularProgressbarWithChildren
        value={value}
        strokeWidth={2}
        styles={buildStyles({
          pathTransitionDuration: 0.5,
          pathColor: pathColour,
          trailColor: bg,
        })}
      >
        <div
          className="progress-percent-container"
          style={{ backgroundColor: bg }}
        >
          <strong style={{ color: pathColour }}>{value}%</strong>
        </div>
      </CircularProgressbarWithChildren>
      <Typography className="summary-type">{type}</Typography>
    </div>
  );
};

export default RadialProgress;
