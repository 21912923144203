/** @format */

import React, { Component } from "react";
import Loader from "react-loaders";
import SignIn from "./User/SignIn";
import Header from "./components/Header";
import axios from "axios";
import api, { getBase, getUser } from "./api";
import { Router, Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import FactCheck from "./FactCheck";
import Profile from "./User/Profile";
import InitProfile from "./User/ProfileComponents/InitProfile";
import { ToastContainer, toast } from "react-toastify";
import Users from "./Users";
import { createBrowserHistory } from "history";
import Referrals from "./referrals";
import CreateClaim from "./CreateClaim";
import OverviewDash from "./OverviewDash";
import GeneralInfo from "./Dashboard/components/GeneralInfo/GeneralInfo";
import "./App.css";
import SearchResult from "./Dashboard/components/SearchResult/SearchResult";
import pagenotfound from "./pagenotfound/pagenotfound.js";
import NetworkDetector from "./HOC/NetworkDetector.js";
import { connect } from "react-redux";
import * as firebase from "firebase";
import SmallScreen from './pagenotfound/SmallScreen'
import { getAllLanguage } from "./utils/language";
import Admin from "./Admin";
import Topics from "./Admin/Topics";
import ClaimFlow from "./Admin/ClaimFlow";
import AdminUsers from "./Admin/AdminUsers";
import ClaimPublishing from "./Admin/ClaimPublishing";
import Verdicts from "./Admin/Verdicts";
import Sources from "./Admin/Sources";

let config = {
  apiKey: "AIzaSyBlQ53tZTHTjxY1SKcyV1p3w4zrfGiDd0U",
  authDomain: "logically-dd101.firebaseapp.com",
  databaseURL: "https://logically-dd101.firebaseio.com",
  projectId: "logically-dd101",
  storageBucket: "logically-dd101.appspot.com",
  messagingSenderId: "897764926083",
  appId: "1:897764926083:web:b98e9dad712370d822f1b6",
  measurementId: "G-Z736LXFP67",
};

const app2 = firebase.initializeApp(
  {
    databaseURL: "https://logically-dd101-47cf9.firebaseio.com/",
  },
  "app2"
);
export const db = firebase.database(app2);

const app3 = firebase.initializeApp(
  {
    ...config,
    databaseURL: "https://logically-dd101-d5823.firebaseio.com/",
  },
  "claims"
);
export const db2 = firebase.database(app3);

export function putClaimData(calimID, username) {
  db2.ref("/claims").update({
    [calimID]: username,
  });
}
const app4 = firebase.initializeApp(
  {
    ...config,
    databaseURL: "https://logically-dd101-d5823.firebaseio.com/",
  },
  "language"
);
export const db3 = firebase.database(app4);
// how to write the data
export function getTopLanguage(text) {
  db3.ref("/language").update({
    language: text,
    date: new Date(),
  });
}
const history = createBrowserHistory();

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      loading: this.props.loading,
      error: false,
      user: false,
      currentScreen: "initProfile",
      searchVal: "",
      searchData: [],
      imgSearchData: [],
      isSearchLoading: true,
      allUsers: [],
      isAllUserLoading: true,
      fcerFilter: [],
      modFilter: [],
      supFilter: [],
      userStatusFilter: [],
      verdictFilter: [],
      notifications: [],
      factCheckers: [],
      moderators: [],
      supervisors: [],
      selected_user: "",
      isFirstLogin: true,
      languages: [...getAllLanguage()],
      sources: [
        { name: "App", checked: false, code: "app" },
        { name: "Browser Extension", checked: false, code: "extension" },
        { name: "FC Dash", checked: false, code: "dashboard" },
        { name: "FCaaS", checked: false, code: "fcaas" },
        { name: "WhatsApp", checked: false, code: "whatsapp" },
        { name: "Logically Intelligence", checked: false, code: "li_client" },
      ],
      countries: [
        { name: "India", checked: false, code: "IN" },
        { name: "USA", checked: false, code: "US" },
        { name: "UK", checked: false, code: "GB" },
      ],
      currentSearchTab: 0,
      totalCount: 0,
      listCount: 0,
      searchPageOffset: 0,
      alertUser: 0,
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: "dateRange",
      },
      viewFilter: false,
      clearFilter: 0,
      navClaims: "URL claims",
      history: {},
      screenWidth: window.innerWidth,
    };
  }
  updateDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  fillUserHash = (allUsers) => {
    let userHashTable = {};
    if (allUsers.length > 0) {
      allUsers.forEach((user) => {
        userHashTable[user.user_id] = user;
      });
      this.props.dispatch({ type: "ADD_USER_HASH_TABLE", userHashTable });
    }
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.navClaims !== nextProps.navClaims) {
      this.setState({ navClaims: nextProps.navClaims });
      this.searchClaim(this.state.currentSearchTab, 0);
    }

    if (this.state.loading !== nextProps.loading) {
      this.setState({ loading: nextProps.loading });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser !== null) {
      this.props.dispatch({ type: "SET_LOADING", loading: true });
      this.setState({
        loggedIn: true,
        isFirstLogin: false,
        isAllUserLoading: false,
        user: JSON.parse(localStorage.user),
      });
      this.getUsers();
    }
    if (localStorage.token) {
      axios
        .get(getBase(api.USER), {
          headers: { Authorization: "Token " + localStorage.token },
        })
        .then((res2) => {
          this.getRealtimeData(res2.data.data);
          this.getRealtimeData2();
          axios
            .get(getBase(api.PROFILE), {
              headers: { Authorization: "Token " + localStorage.token },
            })
            .then((res) => {
              axios.put(
                getBase(api.USER + "/status"),
                { status: "available" },
                { headers: { Authorization: "Token " + localStorage.token } }
              );
              window.onbeforeunload = () => {
                axios.put(
                  getBase(api.USER + "/status"),
                  { status: "offline" },
                  { headers: { Authorization: "Token " + localStorage.token } }
                );
              };
              res2.data.data.profile = res.data.data;
              this.setState({ user: res2.data.data }, () => {
                this.handleProfileComplete(res2.data.data);
              });
              localStorage.user = JSON.stringify(res2.data.data);
              this.getVerdicts()
            })
            .catch((err) => {
              console.log(err);
              this.setState({ user: res2.data.data });
              localStorage.user = JSON.stringify(res2.data.data);
            });
          this.setState(
            { loading: false, loggedIn: true }
            // () =>
            // this.props.dispatch({ type: "SET_LOADING", loading: true })
          );
          this.getUsers();
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } else {
      this.props.dispatch({ type: "SET_LOADING", loading: false });
    }
  }
  getVerdicts = () => {
    axios.get(getBase(api.VERDICTS), {
      headers: { Authorization: "Token " + localStorage.token }
    }).then(res => {
      let verdictByCode = res.data.data[0].verdictByCode
      this.props.dispatch({ type: 'VERDICT_BY_CODE', verdictByCode })
    }).catch(e => {
      toast.error('There was an error loading verdicts please try again', {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log(e)
    })
  }
  handleSubmit = (user) => {
    this.handleProfileComplete(user.data);
    localStorage.token = user.data.token;
    localStorage.user = JSON.stringify(user.data);
    this.setState(
      {
        loggedIn: true,
        loading: false,
        error: false,
        user: user.data,
      },
      () => this.componentDidMount()
    );
  };

  logout = () => {
    axios.put(
      getBase(api.USER + "/status"),
      { status: "offline" },
      { headers: { Authorization: "Token " + localStorage.token } }
    );
    axios.get(getBase(api.LOGOUT), {
      headers: { Authorization: "Token " + localStorage.token },
    });
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    this.setState({ loading: false, loggedIn: false });
    window.location.replace("/");
  };

  handleSearch = (e) => {
    const { timer } = this.state;
    const { pathname } = history.location;
    const value = e.target.value;
    this.setState({ searchVal: value }, () => {
      if (value.length > 0) {
        if (pathname !== "/search") history.push("/search");
        this.debouncedSearch();
      } else {
        clearTimeout(timer);
        history.goBack();
        this.handleClearFilters(null, true);
      }
    });
  };

  debouncedSearch = () => {
    const { timer, currentSearchTab } = this.state;
    clearTimeout(timer);
    const newTimer = setTimeout(this.searchClaim, 500, currentSearchTab, 0);
    this.setState({ timer: newTimer, isSearchLoading: true });
  };

  extractCodes = (arr) => {
    let extractedArr = [];
    arr.forEach((item) => {
      if (item.checked) extractedArr.push(item.code);
    });
    return extractedArr;
  };

  getPostableDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth();
    return `${date.getFullYear()}-${month < 9 ? "0" + (month + 1) : month + 1
      }-${day < 10 ? "0" + day : day}`;
  };

  searchClaim = async (currentSearchTab, offset) => {
    const {
      searchVal,
      languages,
      sources,
      countries,
      fcerFilter,
      modFilter,
      supFilter,
      verdictFilter,
      userStatusFilter,
      dateRange,
      navClaims,
    } = this.state;
    const { isDateRangeActive, activeTab } = this.props;
    let inTabFilter = window.location.search.split("?")[1];
    if (inTabFilter !== 'tab=rejected') {
      if (inTabFilter === 'tab=complaints') {
        inTabFilter += activeTab === "Closed complaints" ? '&resolved=1' : '&resolved=0'
      } else {
        inTabFilter += activeTab !== "URL claims" ? '&type=multimedia' : ''
      }
    }
    let url =
      api.SEARCH_FACTCHECKS +
      "?query=" +
      searchVal +
      "&limit=25&offset=" +
      offset;
    if (currentSearchTab === 1)
      url =
        api.SEARCH_FACTCHECKS +
        "?query=" +
        searchVal +
        "&limit=20&offset=" +
        offset +
        "&type=image";
    if (inTabFilter && searchVal === "")
      url =
        api.SEARCH_FACTCHECKS +
        "?query=" +
        searchVal +
        `&${inTabFilter}` +
        "&limit=20&offset=" +
        offset;
    if (navClaims === "Multimedia claims") {
      url =
        api.SEARCH_FACTCHECKS +
        "?query=" +
        searchVal +
        `&${inTabFilter}` +
        "&limit=20&offset=" +
        offset +
        "&type=image";
    }
    let postBody = {
      factchecker_id: fcerFilter,
      moderator_id: modFilter,
      supervisor_id: supFilter,
      status: userStatusFilter,
      verdict: verdictFilter,
      lang_code: this.extractCodes(languages),
      claim_source: this.extractCodes(sources),
      location: this.extractCodes(countries),
      start_date: isDateRangeActive
        ? this.getPostableDate(dateRange.startDate)
        : "",
      end_date: isDateRangeActive
        ? this.getPostableDate(dateRange.endDate)
        : "",
    };
    const { data } = await axios.post(getUser(url), postBody);
    if (currentSearchTab === 0)
      this.setState(
        {
          searchData: data.data,
          isSearchLoading: false,
          totalCount: data.total_count,
          listCount: data.list_count,
        },
        () => {
          // this.state.clearFilter = 0;
          this.setState({ clearFilter: 0 });
          this.props.dispatch({ type: "SEARCH_DATA", searchData: data.data });
          this.props.dispatch({ type: "CLEAR_FILTER", clearFilter: false });
          this.props.dispatch({ type: "SET_LOADING", loading: false });

        }
      );
    else
      this.setState(
        {
          imgSearchData: data.data,
          isSearchLoading: false,
          totalCount: data.total_count,
          listCount: data.list_count,
        },
        () => {
          // this.state.clearFilter = 0;
          this.setState({ clearFilter: 0 });
          this.props.dispatch({ type: "SEARCH_DATA", searchData: data.data });
          this.props.dispatch({ type: "CLEAR_FILTER", clearFilter: false });
          this.props.dispatch({ type: "SET_LOADING", loading: false });

        }
      );
  };

  handlePageChange = (page) => {
    const { currentSearchTab } = this.state;
    const offset = (page - 1) * 20;
    this.setState({ searchPageOffset: offset }, () => {
      this.searchClaim(currentSearchTab, offset);
    });
  };

  getUsers = async () => {
    const { isAllUserLoading } = this.state;
    if (isAllUserLoading) {
      const { data } = await axios.get(getBase(api.USERS), {
        headers: { Authorization: "Token " + localStorage.token },
      });
      this.fillUserHash(data.data);
      this.setState({ allUsers: data.data, isAllUserLoading: false });
    }
  };

  getRealtimeData = (user) => {
    let userRole = "";
    const { selected_user } = this.state;
    const notificationsRef = db.ref(`/${user.id}/notifications`);
    notificationsRef.limitToFirst(15).on("value", (snap) => {
      const { notifications } = this.state;
      const newNotifications = snap.val();
      if (notifications && newNotifications) {
        if (newNotifications.length - notifications.length > 0) {
          this.setState({
            notifications: newNotifications,
            alertUser: newNotifications.length - notifications.length,
          });
        } else this.setState({ notifications: newNotifications });
      }
      newNotifications && this.props.dispatch({ type: "NOTIFICATIONS", notifications: newNotifications })
      if (selected_user) {
        // let userName = "";
        const user = JSON.parse(localStorage.getItem("user"))
        userRole = user.profile.fields.role;
        if (userRole === "supervisor") {
          this.setState({ isSupervisor: true })
        }

      }
    });
  };

  getRealtimeData2 = () => {
    db2.ref("/claims").on("value", (snap) => {
      const data = snap.val();
      this.props.dispatch({ type: "CLAIMED_DATA", claim: data });
    });
  };

  handleFilterUserChange = (e) => {
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    this.props.dispatch({ type: "CLEAR_FILTER", clearFilter: false });
    const { name, value } = e.target;
    const { currentSearchTab } = this.state;
    let filterType = [...this.state[name]];
    const index = filterType.indexOf(value);
    if (value === 'All') {
      filterType = []
    } else {
      if (index > -1) filterType.splice(index, 1);
      else filterType.push(value);
    }
    this.setState({ [name]: filterType }, () =>
      this.searchClaim(currentSearchTab, 0)
    );
  };

  handleCheckbox = (type, index, e) => {
    const { [type]: filterType, currentSearchTab } = this.state;
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    this.props.dispatch({ type: "CLEAR_FILTER", clearFilter: false });
    this.props.dispatch({ type: "CHECKED_BOXES", checkedBoxes: true });
    filterType[index].checked = !filterType[index].checked;
    this.setState({ [type]: filterType, searchPageOffset: 0 }, () =>
      this.searchClaim(currentSearchTab, 0)
    );
  };

  handleClearFilters = (e, isEmpty) => {
    this.props.dispatch({ type: "SEARCH_DATA", searchData: [] });
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    this.props.dispatch({ type: "CLEAR_FILTER", clearFilter: true });
    let { languages, sources, countries, currentSearchTab } = this.state;
    languages = this.clearArrayFilters(languages);
    sources = this.clearArrayFilters(sources);
    countries = this.clearArrayFilters(countries);
    this.setState(
      {
        clearFilter: Math.random(),
        supFilter: [],
        fcerFilter: [],
        modFilter: [],
        verdictFilter: [],
        userStatusFilter: [],
        languages,
        sources,
        countries,
        searchData: [],

      },
      () => {
        if (!isEmpty) {
          if (!window.location.search.split("?")[1]) {
            this.searchClaim(currentSearchTab, 0);
          }
        }
      }
    );
  };

  clearArrayFilters = (arr) => {
    arr.forEach((item) => {
      item.checked = false;
    });
    return arr;
  };

  switchTabs = (index) => {
    this.setState({ currentSearchTab: index, isSearchLoading: true });
    this.searchClaim(index, 0);
  };

  handleDeleteFilterItem = (item, type) => {
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    this.props.dispatch({ type: "CLEAR_FILTER", clearFilter: false });
    const { currentSearchTab } = this.state;
    const filterItems = this.state[type].filter((i) => i !== item);
    this.setState({ [type]: filterItems }, () =>
      this.searchClaim(currentSearchTab, 0)
    );
  };

  handleDateRangeChange = (dateRange) => {
    const { currentSearchTab } = this.state;
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    this.props.dispatch({ type: "CLEAR_FILTER", clearFilter: false });
    this.props.dispatch({
      type: "TOGGLE_DATE_FILTER",
      isDateRangeActive: true,
    });
    this.setState({ dateRange }, () => this.searchClaim(currentSearchTab, 0));
  };

  handleProfileComplete = (user) => {
    let hasIntrests = false;
    if (user) {
      if (user.profile.fields) {
        hasIntrests = user.profile.fields.interests.split(",").length > 0;
        this.setState({ isFirstLogin: !hasIntrests });
      } else {
        hasIntrests = false;
        this.setState({ isFirstLogin: hasIntrests });
      }
    }
  };

  handleNotificationOpen = () => {
    this.setState({ alertUser: 0 });
  };

  onHandleFilter = () => {
    this.setState({ viewFilter: !this.state.viewFilter });
  };

  renderRoutes = (
    loggedIn,
    user,
    searchVal,
    searchData,
    isSearchLoading,
    allUsers,
    isAllUserLoading,
    isFirstLogin,
    notifications
  ) => {
    let isSupervisor =
      ["admin", "supervisor", "moderator"].indexOf(getRole(user)) !== -1;
    const {
      supFilter,
      fcerFilter,
      modFilter,
      userStatusFilter,
      verdictFilter,
      languages,
      sources,
      countries,
      currentSearchTab,
      imgSearchData,
      totalCount,
      listCount,
      searchPageOffset,
      alertUser,
      viewFilter,
      loading,
      screenWidth
    } = this.state;
    if (loggedIn) {
      if (!isAllUserLoading && user)
        if (!isFirstLogin) {
          return (
            <div style={{ backgroundColor: user && "#F1F4F8" }}>
              <Router history={history}>
                <div className="app-container">
                  {loading && (
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 999,
                      }}
                    >
                      <Loader type="ball-grid-pulse" color="#2d2d2d" />
                    </div>
                  )}
                  <GeneralInfo />
                  <div className="app-right">
                    <Header
                      logout={this.logout}
                      user={user}
                      history={history}
                      isSupervisor={isSupervisor}
                      searchVal={searchVal}
                      onHandleSearchChange={this.handleSearch}
                      notifications={notifications}
                      alertUser={alertUser}
                      OnHandleNotificationOpen={this.handleNotificationOpen}
                      onHandleFilter={this.onHandleFilter}
                    />
                    <div style={{ backgroundColor: "#F1F4F8" }}>
                      <Switch>
                        <Route
                          path="/"
                          exact
                          render={(props) => (
                            <Dashboard
                              getrender={props}
                              supFilter={supFilter}
                              fcerFilter={fcerFilter}
                              modFilter={modFilter}
                              userStatusFilter={userStatusFilter}
                              verdictFilter={verdictFilter}
                              languages={languages}
                              sources={sources}
                              searchData={this.state.searchData}
                              allUsers={allUsers}
                              onHandleUserChange={this.handleFilterUserChange}
                              onHandleCheckBox={this.handleCheckbox}
                              onHandleClearFilters={this.handleClearFilters}
                              clearFilter={this.state.clearFilter}
                              countries={countries}
                              searchPageOffset={searchPageOffset}
                              onHandleDeleteFilterItem={
                                this.handleDeleteFilterItem
                              }
                              onHandleFilter={this.onHandleFilter}
                              onHandleRangeChange={this.handleDateRangeChange}
                            />
                          )}
                        />
                        {checkAdmin(user, true) && (
                          <Route path="/users" component={Users} />
                        )}
                        <Route
                          path="/admin"
                          exact
                          render={(props) => (
                            <Admin />
                          )}
                        />
                        <Route
                          path="/admin/topics"
                          exact
                          render={(props) => (
                            <Topics />
                          )}
                        />
                        <Route
                          path="/admin/claim"
                          exact
                          render={(props) => (
                            <ClaimFlow />
                          )}
                        />
                        <Route
                          path="/admin/users"
                          exact
                          render={(props) => (
                            <AdminUsers />
                          )}
                        />
                        <Route
                          path="/admin/publishing"
                          exact
                          render={(props) => (
                            <ClaimPublishing />
                          )}
                        />
                        <Route
                          path="/admin/verdicts"
                          exact
                          render={(props) => (
                            <Verdicts />
                          )}
                        />
                        <Route
                          path="/admin/sources"
                          exact
                          render={(props) => (
                            <Sources />
                          )}
                        />
                        <Route
                          path="/overview"
                          render={(props) => (
                            <OverviewDash user={user} allUsers={allUsers}{...props} />
                          )}
                        />
                        <Route
                          path="/claim/:id"
                          render={(props) => <FactCheck {...props} />}
                        />
                        <Route
                          path="/me"
                          component={(props) => (
                            <Profile user={user} {...props} />
                          )}
                        />
                        <Route path="/referrals" component={Referrals} />
                        <Route path="/create/claim" component={CreateClaim} />
                        <Route
                          path="/search"
                          render={(props) => (
                            <SearchResult
                              searchFilter={viewFilter}
                              searchVal={searchVal}
                              searchData={searchData}
                              isSearchLoading={isSearchLoading}
                              allUsers={allUsers}
                              isAllUserLoading={isAllUserLoading}
                              onHandleUserChange={this.handleFilterUserChange}
                              supFilter={supFilter}
                              fcerFilter={fcerFilter}
                              modFilter={modFilter}
                              userStatusFilter={userStatusFilter}
                              verdictFilter={verdictFilter}
                              languages={languages}
                              sources={sources}
                              countries={countries}
                              onHandleCheckBox={this.handleCheckbox}
                              onHandleClearFilters={this.handleClearFilters}
                              currentTab={currentSearchTab}
                              switchTabs={this.switchTabs}
                              imgSearchData={imgSearchData}
                              totalCount={totalCount}
                              listCount={listCount}
                              onHandlePageChange={this.handlePageChange}
                              searchPageOffset={searchPageOffset}
                              onHandleDeleteFilterItem={
                                this.handleDeleteFilterItem
                              }
                              onHandleRangeChange={this.handleDateRangeChange}
                              {...props}
                            />
                          )}
                        />
                        <Route component={pagenotfound} />
                      </Switch>
                    </div>
                  </div>
                </div>
              </Router>
            </div>
          );
        } else
          return (
            <>
              <InitProfile
                user={user}
                onHandleProfileComplete={this.handleProfileComplete}
              />
            </>
          );
    } else return null;
  };

  render() {
    const {
      loggedIn,
      error,
      user,
      searchVal,
      searchData,
      isSearchLoading,
      allUsers,
      isAllUserLoading,
      isFirstLogin,
      notifications,
      screenWidth
    } = this.state;

    return screenWidth < 1000 ? <SmallScreen /> : (
      <div
        style={{
          backgroundColor: !loggedIn && `#050E2B`,
          height: "100vh",
        }}
      >
        {!loggedIn && (
          <div style={{ backgroundColor: "red" }}>
            <div
              style={{
                position: "fixed",
                top: "30%",
                left: "15%",
                transform: "translateY(-50%)",
              }}
            >
              {!loggedIn && (
                <SignIn
                  user={user}
                  allUsers={allUsers}
                  getRealtimeData={this.getRealtimeData}
                  getRealtimeData2={this.getRealtimeData2}
                  ref={(ref) => (this.signIn = ref)}
                  error={error}
                  done={this.handleSubmit}
                  loading={(val, err) =>
                    this.props.dispatch({ type: "SET_LOADING", loading: false })
                  }
                />
              )}
            </div>
          </div>
        )}

        <ToastContainer />
        {this.renderRoutes(
          loggedIn,
          user,
          searchVal,
          searchData,
          isSearchLoading,
          allUsers,
          isAllUserLoading,
          isFirstLogin,
          notifications
        )}
      </div>
    );
  }
}

export function checkAdmin(user, supervisor) {
  let ret = false;
  if (user.profile) {
    if (user.profile.fields) {
      if (user.profile.fields.role === "admin") {
        ret = true;
      }
      if (supervisor && user.profile.fields.role === "supervisor") {
        ret = true;
      }
    }
  }
  return ret;
}

export function getRole(user) {
  let ret = false;
  if (user.profile) {
    if (user.profile.fields) {
      ret = user.profile.fields.role;
    }
  }
  return ret;
}

const mapStateToProps = (data) => {
  return {
    isDateRangeActive: data.isDateRangeActive,
    navClaims: data.navClaims,
    loading: data.loading,
    offline: data.offline,
    activeTab: data.activeButton,
  };
};

export default connect(mapStateToProps)(NetworkDetector(App));
