import React from "react";

const UserIcon = ({ fill, styles }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.2 27"
      style={styles}
    >
      <path
        d="M17.7 16.7H6.6C3 16.7 0 19.6 0 23.3V26c0 .6.4 1 1 1s1-.4 1-1v-2.8c0-2.5 2-4.6 4.6-4.6h11.1c2.5 0 4.6 2 4.6 4.6V26c0 .6.4 1 1 1s1-.4 1-1v-2.8c-.1-3.6-3-6.5-6.6-6.5zm-5.6-3.6c3.6 0 6.6-2.9 6.6-6.6 0-3.6-2.9-6.6-6.6-6.6-3.6.1-6.5 3-6.5 6.7 0 3.6 2.9 6.5 6.5 6.5zm0-11.1c2.5 0 4.6 2 4.6 4.6 0 2.5-2 4.6-4.6 4.6s-4.6-2-4.6-4.6S9.6 2 12.1 2z"
        fill={fill}
      />
    </svg>
  );
};

export default UserIcon;
