import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { DateRangePicker } from "react-date-range";
import CloseIcon from "@material-ui/icons/Close";

const DateFilter = ({
  dateRange,
  dialogOpen,
  onHandleDialogClose,
  onHandleSelect,
}) => {
  return (
    <Dialog onClose={onHandleDialogClose} open={dialogOpen}>
      <DialogTitle>Date Filter</DialogTitle>
      <CloseIcon className="dialog-close" onClick={onHandleDialogClose} />
      <DateRangePicker ranges={[dateRange]} onChange={onHandleSelect} showMonthAndYearPickers={true} minDate={new Date('2017-12-17T03:24:00')} maxDate={new Date()}/>
    </Dialog>
  );
};

export default DateFilter;
