import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";

class Skills extends Component {
  state = {};
  
  render() {
    const {
      isInit,
      skills,
      skill,
      interests,
      onHandleAddSkill,
      onHandleDeleteSkill,
      onHandleAddInterest,
      onHandleChange,
      onHandleSkillSave,
      onNext,
    } = this.props;
    return (
      <div className="skills-container">
        <div className="skill-interest-container">
          <Typography>Skills</Typography>
          <div className="skill-input">
            <input
              type="text"
              name="skill"
              placeholder="E.g content writing, journalism, news media etc"
              onChange={onHandleChange}
              value={skill}
            />
            {skill.trim().length > 0 && (
              <button
                className="save-button add-tag"
                onClick={() => onHandleAddSkill(skill)}
              >
                +
              </button>
            )}
          </div>
          {skills.length !== 0 && (
            <div className="skills-list">
              {`${skills}`.split(',').map((skill, index) => (
                <div className="skill-item" key={index}>
                  <Typography>{skill}</Typography>
                  <button onClick={() => onHandleDeleteSkill(skill)}>×</button>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="skill-interest-container">
          <Typography>Select your interests</Typography>
          <div className="interest-toggles-container">
            {interests.map((interest, index) => (
              <button
                key={index}
                onClick={() => onHandleAddInterest(index)}
                className={
                  interest.isInterested
                    ? "interest-button is-interested"
                    : "interest-button"
                }
              >
                {interest.interest}
              </button>
            ))}
          </div>
        </div>
        {isInit ? (
          <button
            className="save-button"
            onClick={() => onNext("passwordChange")}
          >
            Next
          </button>
        ) : (
          <button className="save-button" onClick={()=>onHandleSkillSave()}>
            Save
          </button>
        )}
      </div>
    );
  }
}

export default Skills;
