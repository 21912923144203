import React, { Component } from "react";
import axios from "axios";
import api, { getBase, getUser } from "./../../../api";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";
import Button from "@material-ui/core/Button";

export default class Highlights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.dataPreview,
      loading: true,
      getDate: new Date(),
      date: new Date(),
      location: "",
      current: -1,
    };
  }

  handleChange = (val, index) => {
    this.setState({
      location: val,
      current: index,
    });
    let location = val;
    let url = "";
    if (this.props.heroFc) {
      if (location !== "") {
        url = getUser(api.HERO_FACTCHECKS + `?location=${location}`);
        axios
          .get(url)
          .then((res) => {
            let data = [...res.data.data];
            this.setState({
              data: data.filter((claim) => {
               
                  return claim.selected && claim;
               
              }),
              loading: false,
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    } else {
      if (location !== "") {
        let date = moment().format("YYYY-MM-DD");
        axios
          .get(
            getBase(api.GET_TEN_FACT_CHECKS + date + "/?location=" + location),
            { headers: { Authorization: "Token " + localStorage.token } }
          )
          .then((res) => {
            this.setState({
              data: res.data.data,
              loading: false,
            });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    }
  };

  render() {
    const { data, location, current } = this.state;
    const { classes, heroFc } = this.props;
    let loc = [
      { name: "India", code: "IN" },
      { name: "UK", code: "GB" },
      { name: "US", code: "US" },
    ];
    let user = JSON.parse(localStorage.user);
    let fc = user.profile.fields.role;

    return (
      <div>
        <Grid container>
          <Grid container spacing={16}>
            <Grid item md={9} style={{ display: "flex", alignItems: "center" }}>
              <h1 style={{ color: "black" }}>Top Fact Checks</h1>
            </Grid>
            <Grid
              item
              md={3}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {loc.map((item, index) => {
                return (
                  <Button
                    onClick={(e) => {
                      this.handleChange(item.code, index);
                    }}
                    key={index}
                    variant="outlined"
                    style={{
                      height: 35,
                      marginLeft: 20,
                      backgroundColor:
                        current === index ? "#000" : "transparent",
                      color: current === index ? "#fff" : "#000",
                    }}
                  >
                    {item.name}
                  </Button>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <hr
          style={{
            margin: "10px 0 40px",
            border: "none",
            background: "#ddd",
            height: "1px",
          }}
        />

        {data && (
          <div className={classes.root}>
            <Grid container spacing={8 * 5}>
              {data.map((claim, index) => {
                return (
                  <Grid item xs={12} sm={4} md={3} key={index}>
                    {fc === "supervisor" && (
                      <Grid item lg={12}>
                        <Grid container>
                          <Link
                            to={"/claim/" + claim._id}
                            style={{ position: "relative" }}
                          >
                            {claim.highest_rated === true && (
                              <img
                                src="./Images/star.png"
                                alt=""
                                style={{
                                  position: "absolute",
                                  width: 35,
                                  height: 35,
                                  right: -17,
                                  top: -17,
                                }}
                              />
                            )}
                            <Card className={classes.card}>
                              <CardContent>
                                {claim.sharable_image_url.length > 0 && (
                                  <img
                                    src={claim.sharable_image_url}
                                    alt=""
                                    style={{ width: "100%" }}
                                    ref={(ref) => (this.shareable_image = ref)}
                                  />
                                )}
                              </CardContent>
                            </Card>
                          </Link>
                        </Grid>
                      </Grid>
                    )}
                    {fc !== "supervisor" && (
                      <Grid item lg={12}>
                        <Grid container style={{ position: "relative" }}>
                          {claim.highest_rated === true && (
                            <img
                              src="./Images/star.png"
                              alt=""
                              style={{
                                position: "absolute",
                                width: 35,
                                height: 35,
                                right: -17,
                                top: -17,
                              }}
                            />
                          )}
                          <Card className={classes.card}>
                            <CardContent>
                              {claim.sharable_image_url.length > 0 && (
                                <img
                                  src={claim.sharable_image_url}
                                  alt=""
                                  style={{ width: "100%" }}
                                  ref={(ref) => (this.shareable_image = ref)}
                                />
                              )}
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
        {!heroFc && (
          <>
            {location === "" && (
              <Typography
                component="p"
                style={{
                  fontSize: 25,
                  color: "#6B5F5F",
                  marginLeft: "auto",
                  width: "fit-content",
                  marginRight: "auto",
                  marginTop: 100,
                }}
              >
                <b>SELECT COUNTRY</b>
              </Typography>
            )}
          </>
        )}
        {data === "" && (
          <Typography
            component="p"
            style={{
              fontSize: 25,
              color: "#6B5F5F",
              marginLeft: "auto",
              width: "fit-content",
              marginRight: "auto",
              marginTop: 100,
            }}
          >
            <b>DATA NOT AVAILABLE</b>
          </Typography>
        )}
      </div>
    );
  }
}
