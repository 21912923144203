import React, { Component } from "react";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import { colours } from "../../../utils/common";
import moment from "moment";
import bg from "./imgs/bg.jpg";
import "./factfinder.css";
import { formatDate } from "../EvidenceRetieval/formatData";
import { connect } from "react-redux";
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import MoodBadOutlinedIcon from '@material-ui/icons/MoodBadOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import Paper from "@material-ui/core/Paper";

const styles = {
  evidenceBanner: {
    backgroundImage: `url(${bg})`,
    backgroundSize: `100%`,
    padding: "10px",
    borderRadius: "3px",
    marginBottom: "10px"
  },
  feedback: {
    color: "#B0B4C5",
    paddingRight: "5px"

  },
  feedbackSelected: {
    color: "#006FF9",
    paddingRight: "5px"
  },
  addUrl: {
    color: "#006FF9",
    fontWeight: "bold",
    paddingLeft: "8px"
  },
  helpIcon: {
    color: "#006FF9",
    paddingLeft: "8px",
    height: '20px'
  },
  dialogTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    fontstyle: "bold",
    paddingBottom: "20px"
  },
  dialogTitle2: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  dialogTitle3: {
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: "20px",
  },
  dialogUrlText: {
    fontSize: "17px",
    fontWeight: "bold",
    fontstyle: "bold",
    paddingTop: "20px"
  },
  addUrltext: {
    fontSize: "18px",
    fontWeight: "bold",
    fontstyle: "bold",
    paddingBottom: "0px",
    color: "#006FF9",
    cursor: "pointer"
  },
  dialogButtonCancel: {
    minWidth: "fit-content",
    marginRight: "3%",
    height: 45,
    textTransform: "none",
    color: "#006FF9",
    border: "1px solid #006FF9 "
  },
  dialogButtonSave: {
    minWidth: "fit-content",
    marginRight: "3%",
    height: 45,
    textTransform: "none",
    backgroundColor: "#006FF9",
    color: "white"
  },
  input: {
    height: 45
  },
  highQualitySelected: {
    color: "#006FF9",
    fontSize: 40,
    paddingTop: "10px",
    paddingLeft: "5px",
    cursor: "pointer",
  },
  highQualityNotSelected: {
    color: "grey",
    fontSize: 40,
    paddingTop: "10px",
    paddingLeft: "5px",
    cursor: "pointer",
  },
  paperFact: {
    backgroundColor: "#fff",
    textAalign: "center",
    padding: 5,
    boxShadow: "none",
    borderRadius: 15,
    marginBottom: 15,
  },
  wrapicon: {
    display: "flex",
    paddingRight: '8px',
    cursor: "pointer"
  }
};
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    width: 120,
    fontSize: 15,
    padding: 15
  },
}))(Tooltip);


class FactFinder extends Component {
  state = {
    data: {},
    currentClaim: {},
    matchingFacts: [],
    filteredMatches: [],
    matchLoading: true,
    selectedFact: {},
    previousSelect: {},
    NextSelect: {},
    prevScreen: "",
    showFacts: false,
    count: 1,
    openFeedbackUrls: false,
    cancelDialog: false,
    colour: "#B0B4C5",
    feedbackUrls: [{ url: "", high_quality: false, ai_component: "fact_finder" }],
    backupFeedbackUrls: [],
    otherUsersUrls: [],
    selectedFactFeedback: "",
    rank: 0,
  };

  getEnv = () => {
    return process.env.NODE_ENV
  }

  handleOpenFeebackUrl = () => {
    let fbUrls = this.state.feedbackUrls
    fbUrls = fbUrls.map(url => {
      delete url.error
      return url
    })
    this.setState({ openFeedbackUrls: true, backupFeedbackUrls: JSON.parse(JSON.stringify(fbUrls)), feedbackUrls: fbUrls });
  };

  getFeedbackUrlsText = () => {
    const { feedbackUrls } = this.state
    let filteredUrls = JSON.parse(JSON.stringify(feedbackUrls))
    filteredUrls = filteredUrls.filter((item) => item.url !== "")
    if (filteredUrls.length === 1) {
      return `${filteredUrls.length} URL added`
    }
    else if (filteredUrls.length > 0) {
      return `${filteredUrls.length} URLs added`
    } else {
      return "Add URLs"
    }
  }

  isValidURL(string) {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };

  handleCloseFeebackUrl = () => {
    const { backupFeedbackUrls, feedbackUrls } = this.state
    let differenceUrls = []
    if (feedbackUrls.length >= backupFeedbackUrls.length) {
      differenceUrls = feedbackUrls.filter(({ url: url1, high_quality: quality1 }) => !backupFeedbackUrls.some(({ url: url2, high_quality: quality2 }) => (url2 === url1 && quality1 === quality2)));
    } else {
      differenceUrls = backupFeedbackUrls.filter(({ url: url1, high_quality: quality1 }) => !feedbackUrls.some(({ url: url2, high_quality: quality2 }) => (url2 === url1 && quality1 === quality2)));
    }
    const areArraysLengthEqual = feedbackUrls.length === backupFeedbackUrls.length
    if (differenceUrls.length > 0 || !areArraysLengthEqual) {
      this.setState({ openFeedbackUrls: false, cancelDialog: true })
    } else {
      this.setState({ openFeedbackUrls: false });
    }
  };

  handleCancelDialog = (cancelDialog, openFeedbackUrls) => {
    this.setState({ cancelDialog, openFeedbackUrls });
  };

  componentDidMount() {
    let claim;
    if (this.props.data.modified_claim) {
      claim = this.props.data.modified_claim;
    } else {
      claim = this.props.data.recheck_message;
    }
    const ClaimUnderInvestigation = {
      claim: claim,
      _id: this.props.data._id,
    };
    this.findSimilarFCs(ClaimUnderInvestigation);
  }

  componentDidUpdate(prevProps) {
    const { selectedFact } = this.state
    if (prevProps.feedback !== this.props.feedback && selectedFact) {
      this.getSelectedFactFeedback(selectedFact._id);
    }
  }

  findSimilarFCs = async (claimObj) => {
    try {
      const { data } = await axios.post(
        "https://thelogicallydata.co.uk/api/v1/fact/matching",
        {
          _id: claimObj._id,
          claim: claimObj.claim,
        }
      );
      if (data.data.results.length > 0) {
        let filteredMatches = data.data.results.filter(
          this.getEnv() === 'production' ? (evi) => evi.link_works === true && evi.fm_label !== "No Match" : (evi) => evi.link_works === true


          //enable below line for prod; disable for staging
          // (evi) => evi.link_works === true && evi.fm_label !== "No Match"
          //enable below line for staging; disable for prod
          // (evi) => evi.link_works === true
        );
        let firstRank = filteredMatches.length !== 0 && data.data.results.findIndex(claim => claim._id === filteredMatches[0]._id)
        this.setState({
          filteredMatches,
          matchingFacts: data.data.results,
          matchLoading: false,
          showFacts: true,
          rank: firstRank,
          rightScreen: "factDetails",
          selectedFact: filteredMatches ? filteredMatches[0] : ""
        });
        filteredMatches.length > 0 && this.getSelectedFactFeedback(filteredMatches[0]._id)
      } else {
        this.setState({
          matchLoading: false,
          showEvidence: true,
          rightScreen: "working",
        });
      }
    } catch (error) {
      console.log(error)
      !this.props.offline && toast.error("Something went wrong. Please try again", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  getFeedbackUrls = () => {
    const feedback = JSON.parse(JSON.stringify(this.props.feedback))
    let filteredFeedbacks = feedback.feedback_urls && feedback.feedback_urls.filter(fb => {
      return fb.ai_component === "fact_finder"
    }
    )
    if (filteredFeedbacks) {
      this.setState({
        feedbackUrls: filteredFeedbacks.length > 0 ?
          filteredFeedbacks
          :
          [{ url: "", high_quality: false, ai_component: "fact_finder" }]
      })
    }
  }

  getSelectedFactFeedback = (selectedFactId) => {
    const { feedback } = this.props
    const { id: userId } = JSON.parse(localStorage.user);
    let selectedFactFeedback = ""
    if (feedback.feedback_collected) {
      selectedFactFeedback = feedback.feedback_collected.find(item => item._id === `${userId}_fact_finder_${selectedFactId}`)
    }
    this.getFeedbackUrls()
    return this.setState({ selectedFactFeedback })
  }

  dateConvert = (date) => {
    moment(date).format("Do MMM YYYY");
  }

  selectedFact = (_selectedData, index) => {
    this.setState({ selectedFact: _selectedData, count: index });
    this.getSelectedFactFeedback(_selectedData._id)
  };

  NextSelect = () => {
    if (this.state.count < this.state.filteredMatches.length) {
      let count = this.state.count;
      if (count === 0) {
        count++;
      }
      let selected = this.state.filteredMatches[count++];
      const newRank = this.state.matchingFacts.findIndex(claim => claim._id === selected._id)
      this.selectedFact(selected, count++);
      this.setState({ rank: newRank })
    }
  };

  previousSelect = () => {
    if (this.state.count > 0) {
      let count = this.state.count;
      let newRank;
      if (count === this.state.filteredMatches.length) {
        count = count - 1;
        let selected = this.state.filteredMatches[count - 1];
        newRank = this.state.matchingFacts.findIndex(claim => claim._id === selected._id)
        this.selectedFact(selected, count);
      } else {
        count = count - 1;
        let selected = this.state.filteredMatches[count - 1];
        newRank = this.state.matchingFacts.findIndex(claim => claim._id === selected._id)
        this.selectedFact(selected, count);
      }
      this.setState({ rank: newRank })
    }
  };

  handleAddUrl = (val, index) => {
    let feedbackUrls1 = this.state.feedbackUrls[index];
    feedbackUrls1.url = val
    if (feedbackUrls1.error) delete feedbackUrls1.error
    let allUrls = this.state.feedbackUrls
    allUrls[index] = feedbackUrls1
    this.setState({ feedbackUrls: allUrls })
  }

  handleHighQuality = (item, index) => {
    const { id: userId } = JSON.parse(localStorage.user);
    if (item.url === "") return
    if (!item.user_details || item.user_details.id.toString() === userId.toString()) {
      let feedbackUrls1 = this.state.feedbackUrls[index];
      feedbackUrls1.high_quality = !feedbackUrls1.high_quality
      let allUrls = this.state.feedbackUrls
      allUrls[index] = feedbackUrls1
      this.setState({ feedbackUrls: allUrls })
    }
    else return
  }

  handleUrlDelete = (index, userDetails) => {
    const { id: userId } = JSON.parse(localStorage.user);
    if (!userDetails || userDetails.id.toString() === userId.toString()) {
      let fbUrls = [...this.state.feedbackUrls]
      fbUrls.splice(index, 1)
      if (fbUrls.length === 0) {
        fbUrls.push({ url: "", high_quality: false, ai_component: "fact_finder" })
      }
      return this.setState({ feedbackUrls: fbUrls })
    } else return
  }

  handlePostFeedback = (type, value) => {
    const { selectedFact, feedbackUrls, rank } = this.state
    const { postFeedbacks, feedback } = this.props;
    const user = JSON.parse(localStorage.user);
    const userDetails = { "id": user.id, "username": user.username }
    if (type === "urls") {
      if (this.errorUrls(feedbackUrls)) return this.setState({ feedbackUrls: this.errorUrls(feedbackUrls) })
    }
    let newFeedback
    const existingFb = feedback.feedback_collected && selectedFact && feedback.feedback_collected.find(item => item._id === `${user.id}_fact_finder_${selectedFact._id}`)
    if (existingFb && type !== "urls") {
      newFeedback = {
        ...existingFb
      }
      if (type === "feedback") {
        newFeedback.feedback_given = {
          feedback_label: value
        }
      }
    } else {
      if (type !== "urls") {
        newFeedback = {
          _id: `${user.id}_fact_finder_${selectedFact._id}`,
          user_details: userDetails,
          ai_component: "fact_finder",
          rank: rank,
          submitted_date: moment().format("YYYY-MM-DD"),
          feedback_for: selectedFact,
          feedback_given: {
            feedback_label: value
          }
        }
      }
    }
    feedbackUrls.map(url => {
      if (url.user_details) return
      url.user_details = userDetails
    })
    postFeedbacks(newFeedback, feedbackUrls, rank)
    this.setState({ openFeedbackUrls: false })
  }

  errorUrls = (urls, type) => {
    //No validation for first URL as it will always be blank
    if (urls.length === 1 && urls[0].url === "" && type !== "addUrl") {
      return
    }
    let urlList = []
    urls.map(url => {
      urlList.push(url.url) //getting all URLS to find duplicates
    })
    urls.map(url => {
      const repetetion = urlList.reduce((n, x) => n + (x === url.url), 0) //finds the no. of times an element is repeated in array
      if ((!url.user_details) && repetetion > 1) { //no user_details implies its a new entry
        return urls[urlList.lastIndexOf(url.url)].error = "This URL is already submitted"
      }
      if (this.isValidURL(url.url)) {
        return delete url.error
      } else {
        url.error = "Please enter a valid URL"
      }
    })
    const errorUrls = urls.filter(url => url.error)
    if (errorUrls.length > 0) return urls
  }

  addAnotherUrl = () => {
    let urls = this.state.feedbackUrls
    if (this.errorUrls(urls, "addUrl")) return this.setState({ feedbackUrls: this.errorUrls(urls, "addUrl") })
    urls.push({ url: "", high_quality: false, ai_component: "fact_finder" })
    this.setState({ feedbackUrls: urls })
  }

  handleDiscardUrls = () => {
    this.setState({ openFeedbackUrls: false, cancelDialog: false, feedbackUrls: this.state.backupFeedbackUrls })
  }

  isUrlEditable = (userDetails) => {
    const { id: userId } = JSON.parse(localStorage.user);
    if (!userDetails) {
      return false
    }
    if (userDetails.id.toString() === userId.toString()) {
      return false
    }
    else return true
  }

  render() {
    const { matchingFacts, selectedFact, showFacts, selectedFactFeedback, feedbackUrls, filteredMatches } = this.state;
    const { classes } = this.props;
    let feedbackValue = ""
    if (selectedFactFeedback) {
      feedbackValue = selectedFactFeedback.feedback_given.feedback_label
    }

    const longText = `Add URLs of any fact checks that were missed by our Fact finder but helped with your research`
    return (
      <><Typography style={{ fontWeight: "bold", marginBottom: "8px" }}>{`Fact finder ${matchingFacts.length > 0 ? `(${matchingFacts.length})` : ""}`}</Typography>
        <Grid container>
          {!showFacts && (
            <Grid container style={styles.evidenceBanner} alignItems="center">
              <Typography style={{ color: "white", fontWeight: "bold", }}>
                Finding Fact checker
              </Typography>
            </Grid>
          )}
          {selectedFact === undefined && (
            <Grid container style={styles.evidenceBanner} alignItems="center">
              <Typography style={{ color: "white", fontWeight: "bold" }}>
                We could not find any related fact checks in our database.
              </Typography>
            </Grid>
          )}

          {showFacts && selectedFact !== undefined && (
            <Paper className={classes.paperFact}>

              <Grid container>
                <Grid item xs={8}>
                  <div style={{ padding: 10 }}>
                    <div container className="factfinder-top-split">
                      <div className="factfinder-top-split" >
                        <div style={{ width: "20%" }}>
                          {selectedFact.textual_judgement && (
                            <div
                              style={{
                                backgroundColor: colours[
                                  selectedFact.textual_judgement
                                ]
                                  ? colours[selectedFact.textual_judgement]
                                  : "#000",
                                padding: "4px 10px",
                                borderRadius: 5,
                                width: "fit-content",
                              }}
                            >
                              <Typography
                                style={{
                                  textTransform: "uppercase",
                                  color:
                                    colours[selectedFact.textual_judgement] ===
                                      "#e9ff00"
                                      ? "#000"
                                      : "#fff",
                                  fontSize: 14,
                                  borderRadius: 10,
                                  fontWeight: "bold",
                                }}
                              >
                                {selectedFact.textual_judgement === "Partly_True"
                                  ? selectedFact.textual_judgement.replace("_", " ")
                                  : selectedFact.textual_judgement}
                              </Typography>
                            </div>
                          )}
                        </div>

                        <div style={{ paddingLeft: "8px" }}>
                          <Typography
                            className="fact-cred">
                            {selectedFact.fm_label}!
                          </Typography>
                        </div>
                      </div>
                      <div className="factfinder-top-split">
                        <div>
                          <Typography style={{ fontSize: 14, fontWeight: "600" }}>
                            {selectedFact.date
                              ? formatDate(selectedFact.date)
                              : 'unknown'}
                          </Typography>
                        </div>
                        <div style={{ paddingLeft: "5px" }}>
                          <Typography
                            style={{
                              fontSize: 14,
                              paddingLeft: "50",
                              borderRadius: 10,
                              color: "#3360F3",
                              fontWeight: "bold",
                            }}
                          >
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={selectedFact.link}
                            >
                              {" "}
                              View Source Here{" "}
                            </a>
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <hr style={{ color: "#F1F4F8" }}></hr>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontstyle: "Mulish",
                        color: "#050E2B",
                        opacity: "100%",
                        fontWeight: "bolder",
                        marginBottom: 10,
                      }}

                    >
                      <b>{selectedFact.claim} </b>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 14,
                        marginBottom: 10,
                        fontstyle: "Mulish",
                        minHeight: 150,
                        maxHeight: 200,
                        overflowY: "auto",
                        scrollBehavior: "smooth",
                        scrollbarWidth: "none",
                      }}

                    >
                      <b> {selectedFact.conclusion}</b>
                    </Typography>
                  </div>

                </Grid >
                <Grid item xs={4}>
                  <div className="container">
                    {matchingFacts.map((similarFact, index) => (
                      //enable below line for staging; disable for prod
                      similarFact.link_works &&
                      this.getEnv() === 'production' && similarFact.link_works && similarFact.fm_label !== "No Match" &&
                      //enable below line for prod; disable for staging
                      // similarFact.link_works && similarFact.fm_label !== "No Match" &&
                      <div
                        className="factfinder-right-split"
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            similarFact._id === selectedFact._id
                              ? "#F1F4F8"
                              : "#FFFFFF",
                        }}
                        onClick={() => {
                          this.selectedFact(similarFact, index + 1)
                          this.setState({ rank: index })
                        }}
                      >
                        {similarFact.textual_judgement && (
                          <div
                            style={{
                              backgroundColor: colours[
                                similarFact.textual_judgement
                              ]
                                ? colours[similarFact.textual_judgement]
                                : "#000",
                              padding: "3px 6px",
                              borderRadius: 5,
                              maxWidth: "300px",
                              width: "95%",
                              height: "18px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <Typography
                              noWrap
                              style={{
                                textTransform: "uppercase",
                                color:
                                  colours[similarFact.textual_judgement] ===
                                    "19B5FE"
                                    ? "#000"
                                    : "#fff",
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                            >
                              {similarFact.textual_judgement === "Partly_True"
                                ? similarFact.textual_judgement.replace("_", " ")
                                : similarFact.textual_judgement}
                            </Typography>
                          </div>
                        )}
                        <Typography>
                          <b> {similarFact.claim}</b>
                        </Typography>
                      </div>
                    ))}
                  </div>
                </Grid>
                <hr style={{ color: "#F1F4F8", backgroundColor: "#F1F4F8" }} />
                <Grid container>
                  <Grid item xs={12}>
                    <Grid>
                      <div
                        className="hamlet-main-div">
                        <div
                          className="hamlet-sub-div">
                          <Typography style={{ paddingRight: "8px" }}>How good is this match ?</Typography>

                          <div className={classes.wrapicon} onClick={() => this.handlePostFeedback("feedback", "Good")}>
                            <ThumbUpOutlinedIcon className={feedbackValue === "Good" ? classes.feedbackSelected : classes.feedback} />
                            <Typography>Good</Typography>
                          </div>
                          <div className={classes.wrapicon} onClick={() => this.handlePostFeedback("feedback", "Partial")}>
                            <MoodBadOutlinedIcon className={feedbackValue === "Partial" ? classes.feedbackSelected : classes.feedback} />
                            <Typography>Partial</Typography>
                          </div>
                          <div className={classes.wrapicon} onClick={() => this.handlePostFeedback("feedback", "Bad")}>
                            <ThumbDownOutlinedIcon className={feedbackValue === "Bad" ? classes.feedbackSelected : classes.feedback} />
                            <Typography>Bad</Typography>
                          </div>

                        </div>

                        <div className="factfinder-bottom-split" >
                          <Divider variant="middle" />
                          <p style={{ color: "black" }}>
                            {this.state.count} of {filteredMatches.length}
                          </p>
                          <Button
                            style={{
                              ...styles.button,
                              backgroundColor: "#F1F4F8",
                              marginBlock: "10px",
                            }}
                            disabled={this.state.count === 1}
                            onClick={() => this.previousSelect()}
                          >
                            <span> Previous</span>
                          </Button>
                          <Button
                            style={{
                              ...styles.button,
                              backgroundColor: "#F1F4F8",
                              fontSize: "small",
                              marginBlock: "10px",
                            }}
                            disabled={
                              this.state.count === filteredMatches.length
                            }
                            onClick={() => this.NextSelect()}
                          >
                            <span> Next</span>
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid >
            </Paper >
          )
          }
          <Dialog
            open={this.state.openFeedbackUrls}
            onClose={this.handleCloseFeebackUrl}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle>
              <Typography
                className={classes.dialogTitle}>
                {longText}
              </Typography>
              <Divider />
            </DialogTitle>
            <DialogContent>
              <Typography
                className={classes.dialogTitle2}> Press on the star icon if you want to mark this URL as high quality.</Typography>
              {feedbackUrls.map((item, index) => {
                return (<div key={index}>
                  <Typography
                    className={classes.dialogUrlText}>
                    {`URL ${index + 1}`}
                  </Typography>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <TextField
                      id="outlined-bare"
                      margin="dense"
                      variant="outlined"
                      size="small"
                      value={item.url}
                      onChange={(e) => this.handleAddUrl(e.target.value, index)}
                      InputProps={{
                        className: classes.input
                      }}
                      style={{ flexGrow: 1, width: "98%" }}
                      disabled={this.isUrlEditable(item.user_details)}
                      error={item.error}
                      helperText={item.error}
                    />
                    <StarBorderIcon onClick={() => this.handleHighQuality(item, index)}
                      className={item.high_quality === true ? classes.highQualitySelected : classes.highQualityNotSelected}
                      fontSize="large"
                    />
                    <ClearIcon onClick={() => this.handleUrlDelete(index, item.user_details)}
                      className={classes.highQualityNotSelected}
                      fontSize="large"
                    />
                  </div>
                </div>)
              })}
              <Typography onClick={this.addAnotherUrl}
                className={classes.addUrltext}
              > + Add another URL </Typography>
              <div style={{ marginTop: "20px" }}>
                <Grid item style={{ display: "flex", marginRight: "5%" }}>
                  <Button
                    fullWidth={false}
                    size="medium"
                    variant={"outlined"}
                    className={classes.dialogButtonCancel}
                    color="#006FF9"
                    onClick={this.handleCloseFeebackUrl}
                  >
                    Cancel
                  </Button>
                  <Button
                    fullWidth={false}
                    size="medium"
                    variant={"contanied"}
                    className={classes.dialogButtonSave}
                    onClick={() => this.handlePostFeedback("urls")}
                  >
                    Save
                  </Button>
                </Grid>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            open={this.state.cancelDialog}
            onClose={() => this.handleCancelDialog(false, true)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle>
              <Typography
                className={classes.dialogTitle}>
                Are you sure you want to cancel without saving?
              </Typography>
              <Divider />
            </DialogTitle>
            <DialogContent>
              <Typography
                className={classes.dialogTitle3}> If you cancel, all of the changes you've already did, wont be saved.</Typography>
              <Divider />
              <div style={{ marginTop: "20px" }}>
                <Grid item style={{ display: "flex", marginRight: "5%" }}>
                  <Button
                    fullWidth={false}
                    size="medium"
                    variant={"outlined"}
                    className={classes.dialogButtonCancel}
                    color="#006FF9"
                    onClick={() => this.handleCancelDialog(false, true)}
                  >
                    Back to editing
                  </Button>
                  <Button
                    fullWidth={false}
                    size="medium"
                    variant={"contanied"}
                    className={classes.dialogButtonSave}
                    onClick={this.handleDiscardUrls}
                  >
                    Yes, cancel
                  </Button>
                </Grid>
              </div>
            </DialogContent>
          </Dialog>
        </Grid>
        <div className="hamletUrls">
          <Typography
            className={classes.addUrl}
            onClick={this.handleOpenFeebackUrl} style={{ textDecoration: this.getFeedbackUrlsText() === "Add URLs" ? "inherit" : "underline", cursor: "pointer" }}>{this.getFeedbackUrlsText()}</Typography>

          <LightTooltip title={longText}>
            <HelpRoundedIcon
              className={classes.helpIcon} />
          </LightTooltip>
        </div>
      </>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    offline: state.offline
  }
}

export default connect(mapStateToProps)(withStyles(styles)(FactFinder));
