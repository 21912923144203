import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Tab, Typography, Chip } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import "./button.css";
import Activity from "../../../OverviewDash/components/Activity";
import { connect } from "react-redux";
import { createMuiTheme } from "@material-ui/core/styles";
import tipofweek from "../../../assets/tipofweek.png";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withRouter } from 'react-router-dom'
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

let tabs = [];

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1bb3af",
    },
    secondary: {
      main: "#e52329",
    },
  },
});

const styles = (theme) => ({
  tabresize: {
    fontSize: 15,
    paddingLeft: 0,
    borderBottom: "4px solid #e8e8e8",
    textTransform: "none",
  },
  tabcolor: {
    indicatorColor: "#F62459",
    textColor: "primary",
    variant: "fullWidth",
  },
  paper: {
    backgroundColor: "#fff",
    width: "100%",
    // paddingLeft: 10,
    marginTop: 8,
    paddingTop: 5,
    boxShadow: "none",
  },
  dialog: {
    position: "absolute",
    right: 0,
    margin: 0,
    maxWidth: "100%",
    overflowY: "unset",
    maxHeight: "100%",
    height: "100%",
  },
  textField: {
    position: "relative",
  },
  tiplabel: {
    position: "relative",
    color: "#3360F3",
    font: "Mulish",
    fontWeight: "700",
    fontSize: "15px",
    minWidth: 50,
    paddingTop: "8px"
  },

  showbutton: {
    position: "relative",
    color: "#3360F3",
    font: "Mulish",
    fontWeight: "700",
    fontSize: "14px",
    minWidth: 50,
    padding: 8,
    paddingTop: 10,
    alignItems: "right",
  },
  colorcustom: {
    background: "#F1F4F8"
  },
});

class ButtonSection extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      tiptitle: "",
      tiplink: "",
      tipborder: false,
      users: [],
      point: this.props.claimsActiveTab,
      loader: this.props.loads,
      activeTab: props.claimsActiveTab - 1,
      activeButton: this.props.activeButton,
      tip: false,
      updatelink: "",
      notifications: []
    };

    let user = JSON.parse(localStorage.user);
    let isFactChecker = false;
    if (user) {
      if (user.profile) {
        if (user.profile.fields) {
          isFactChecker = user.profile.fields.role === "factchecker";
          if (isFactChecker) {
            tabs = [
              "New Claims",
              "Rejected Claims",
              "Approval Pending",
              "Completed",
              "Top 4 FC",
            ];
          } else {
            tabs = [
              "New claims",
              "Conducting Research",
              "Making judgement",
              "Finalize",
              "Completed",
              "Complaints",
              "Rejected",
            ];
          }
        }
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.notifications) {
      this.setState({ notifications: nextProps.notifications })
    }
    if (nextProps.activeButton !== this.state.activeButton) {
      this.setState({ activeButton: nextProps.activeButton });
    }
    if (nextProps.claimsActiveTab !== this.state.point) {
      this.setState({
        point: nextProps.claimsActiveTab,
        activeTab: nextProps.claimsActiveTab - 1,
      });
    }
  }

  componentDidMount() {
    this.tipofweek();
    setTimeout(() => {
      this.setState({ loader: !this.state.loader });
      this.getLoading();
    }, 100);
  }
  getLoading = () => {
    this.props.handleLoader(this.state.loader);
  };
  checkAuth = () => {
    let user = JSON.parse(localStorage.user);
    if (user) {
      if (user.profile) {
        if (user.profile.fields) {
          return user.profile.fields.role;
        }
      }
    }
  };

  handleCheck = (point) => {
    if (this.props.navpoints === point) {
      return;
    }
    this.props.dispatch({ type: "SWITCH_CLAIMS_TABS", tab: point });
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    this.props.dispatch({
      type: "SET_LOADING_SPIN",
      loading_spin: true,
    });

    this.props.checkHandle(point);

    if (this.checkAuth() === "supervisor") {
      if (
        this.state.activeButton === "Hero fact checks" ||
        this.state.activeButton === "Open complaints" ||
        this.state.activeButton === "Closed complaints"
      ) {
        this.props.dispatch({
          type: "VIEW_BUTTON",
          activeButton: "URL claims",
        });
      }
    } else {
      if (
        this.state.activeButton === "India" ||
        this.state.activeButton === "UK" ||
        this.state.activeButton === "USA"
      )
        this.props.dispatch({
          type: "VIEW_BUTTON",
          activeButton: "URL claims",
        });
    }
  };

  renderTab = (activeTab) => {
    const { users } = this.state;
    if (activeTab === 0) return <Activity users={users} />;
  };
  handleChange = (event, newValue) => {
    if (newValue) {
      this.setState({ activeTab: newValue });
    }
  };

  handleChange1 = (e) => {
    this.setState({ updatelink: e.target.value });
  };

  handleClick1 = () => {
    console.log("link", this.state.updatelink);
    this.setState({ tip: false });
  };

  tipofweek = async () => {
    axios.get("/notion").then((res) => {
      console.log("Result", res.data)
      const tip = res.data.results[0]
      console.log(tip.url.substring(22))
      let tipUrl = "https://logicallyfctip.notion.site/" + tip.url.substring(22)
      this.setState({ tiplink: tipUrl, tiptitle: tip.properties["Tip name"].title[0].plain_text, tipborder: tip.properties.Highlight.checkbox, })
    }).catch(err => console.log("ERR", err))
  }
  handleReadNotifiction = (item, index) => {
    const { notifications } = this.state
    // const index1 = notifications.indexOf(index);
    if (index > -1) {
      notifications.splice(index, 1);
    }
    console.log('e', item, index)
  }
  render() {
    const { classes, offline, history } = this.props;
    const { activeTab, tip, tiptitle, tipborder, tiplink, notifications } = this.state;
    let user = JSON.parse(localStorage.user);
    let isSupervisor = true;
    if (user) {
      if (user.profile) {
        if (user.profile.fields) {
          isSupervisor = user.profile.fields.role === "supervisor";
        }
      }
    }

    return (
      <div style={{ marginLeft: "3%", marginBottom: "1.5%", width: "94%" }}>
        <Grid
          container
          alignItems='center'
          className='dash-user-greet'
          style={{ height: 60, }}>
          <h2>Hello, {user.username}</h2>
          <img
            src='/static/media/wave.fb87d0a5.png'
            style={{ marginLeft: 3 }}
            alt='wave'></img>
        </Grid>
        {tiptitle && <Grid
          container
          lignItems='center'
          className='dash-user-greet'
          style={{
            backgroundColor: "#D2E5FF",
            borderRadius: 8,
            height: "50px",
            color: "#3360F3",
            paddingBottom: 4,
            paddingTop: 4,
            display: "flex",
            border: tipborder ? "2px solid #3360F3" : "",
          }}>
          <Grid item xs={1}>
            <img
              src={tipofweek}
              style={{ margin: 7, paddingLeft: "25px" }}
              alt='tipofweek'
            />
          </Grid>
          <Grid item xs={9} style={{ display: "flex" }}>
            <Typography className={classes.tiplabel}>
              Language problem of the week:{" "}
              <span
                style={{
                  color: "black",
                  marginBottom: "10px",
                  font: "Mulish",
                  fontWeight: "700",
                  fontSize: "14px",
                }}>
                {tiptitle}
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "15px",
            }}>
            <a
              href={
                tiplink
              }
              rel='noopener noreferrer'
              target='_blank'
              className={classes.showbutton}>
              Show me
            </a>
          </Grid>
        </Grid>}

        <div style={{ paddingTop: 20 }}>
          <div>
            <Tabs
              value={activeTab}
              classes={classes.tabcolor}
              variant="fullWidth"
              centered
            >
              {tabs.map((item, index) => (
                <Tab
                  key={`${item}${index}`}
                  className={classes.tabresize}
                  label={item}
                  onClick={this.handleCheck.bind(this, index + 1)}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    color: activeTab === index ? "#F62459" : "grey",
                    fontWeight: "700",
                    fontSize: "15px",
                    minWidth: 50,
                  }}
                >
                  {" "}
                </Tab>
              ))}
            </Tabs>
            {this.props.loading_spin &&
              !this.props.loading &&
              this.props.claimsActiveTab !== 1 &&
              this.props.claimsActiveTab !== 5 && (
                <div>
                  <span
                    style={{
                      position: "relative",
                      top: "-2px",
                      height: "2px",
                    }}
                  >
                    <LinearProgress
                      className={classes.colorcustom}
                      color="secondary"
                    />
                  </span>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (data) => {
  return {
    activeButton: data.activeButton,
    claimsActiveTab: data.claimsActiveTab,
    loading_spin: data.loading_spin,
    loading: data.loading,
    offline: data.offline,
    navpoints: data.navpoints,
    notifications: data.notifications
  };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(ButtonSection)));

