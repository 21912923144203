import React, { Component } from 'react'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons'
import ReviewClaim from './ReviewClaim';

class SEO extends Component {
    constructor(props) {
        super(props);
        const { data } = this.props
        this.state = {
            "@context": "http://schema.org",
            "@type": "ClaimReview",
            datePublished: '',
            author: {
                "@type": "Organization",
                "name": "Logically Facts",
                "url": "https://www.logicallyfacts.com/"
            },
            claimReviewed: data.claim,
            itemReviewed: {
                "@type": "Claim",
                appearance: [
                    {
                        "@type": "CreativeWork",
                        url: ""
                    },
                ],
                author: {
                    "@type": "Person",
                    name: ""
                },
                datePublished: ""
            },
            reviewRating: {
                "@type": "Rating",
                alternateName: "",
            }
        };
    }
    componentDidMount() {
        const { data } = this.props
        const { itemReviewed } = this.state
        let appearance = [];
        let datePublished = '';
        let author = { ...itemReviewed.author };
        let reviewRating = { ...this.state.reviewRating };
        let claimReviewed = this.state.claimReviewed
        if (data.seo && data.seo.itemReviewed && data.seo.itemReviewed.appearance) {
            appearance = data.seo.itemReviewed.appearance;
            datePublished = data.seo.itemReviewed.datePublished;
            author = data.seo.itemReviewed.author;
            reviewRating = data.seo.reviewRating;
            claimReviewed = data.seo.claimReviewed
        } else {
            data.editor_provenance_urls.forEach((element, index) => {
                appearance.push({
                    "@type": "CreativeWork",
                    url: element.url
                },)
            });
        }
        this.setState({
            itemReviewed: {
                ...itemReviewed,
                appearance,
                datePublished: datePublished,
                author: author
            }
        }, () => {
            this.setState({
                reviewRating: reviewRating,
                claimReviewed: claimReviewed
            })
        })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);
        console.log('form', e.target)
        const { data } = this.props
        const outputFormat = {
            "@context": "https://schema.org",
            "@type": "ClaimReview",
            "url": "",
            "claimReviewed": data.claim,
            "itemReviewed": {
                "@type": "Claim",
                "author": {
                    "@type": formData.get('author.@type'),
                    "name": formData.get('author.name'),
                    "sameAs": formData.get('author.sameAs')
                },
                "datePublished": "",
                "appearance": {
                    "@type": formData.get('appearance.@type'),
                    "url": formData.get('appearance.url'),
                    "headline": formData.get('appearance.headline'),
                    "datePublished": "2016-06-22",
                    "author": {
                        "@type": formData.get('appearance.author.@type'),
                        "name": formData.get('appearance.author.name')
                    },
                    "image": "https://example.com/photos/1x1/photo.jpg",
                    "publisher": {
                        "@type": formData.get('appearance.publisher.@type'),
                        "name": formData.get('appearance.publisher.name'),
                        "logo": {
                            "@type": "ImageObject",
                            "url": formData.get('appearance.publisher.logo.url')
                        }
                    }
                }
            },
            "author": {
                "@type": formData.get('author.@type'),
                "name": formData.get('author.name')
            },
            "reviewRating": {
                "@type": "Rating",
                "ratingValue": formData.get('reviewRating.ratingValue'),
                "bestRating": formData.get('reviewRating.bestRating'),
                "worstRating": formData.get('reviewRating.worstRating'),
                "alternateName": formData.get('reviewRating.alternateName')
            }
        }
        console.log(outputFormat)

    }
    handleOnChange = (data) => {
        this.setState((prev) => ({ ...prev, ...data }))
    }
    handleOnChangeItemReviewed = (items) => {
        this.setState({ itemReviewed: items }, () => {
            this.handleSendToTop()
        })
    }
    handleSendToTop = () => {
        this.props.handleChange({ ...this.props.data, seo: { ...this.state } })
    }
    handleOnChangereviewRating = (items) => {
        this.setState({ reviewRating: items }, () => {
            this.handleSendToTop()
        })
    }
    render() {
        const { itemReviewed, reviewRating, claimReviewed, } = this.state
        // console.log('statedata', this.state)
        // const { data } = this.props
        return (<ExpansionPanel style={{ marginTop: 24, borderRadius: 8, boxShadow: 'none' }}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                SEO Details
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <form>
                    {/* <div style={{ border: '1px solid #ccc', padding: 8, borderRadius: 4 }}> */}
                    {/* {claimReviews.map((item, index) => {
                        return <ReviewClaim key={index} index={index} removeClaimReview={() => this.setState({ claimReviews: [...claimReviews].splice(index, 1) })}
                            handleOnChange={(e) => this.handleOnChange(e)}
                            claimReviewed={claimReviewed}
                        />
                    }) */}
                    <ReviewClaim
                        // handleOnChange={(e) => this.handleOnChange(e)}
                        claimReviewed={claimReviewed}
                        index={0}
                        itemReviewed={itemReviewed}
                        handleOnChangeItemReviewed={(e) => this.handleOnChangeItemReviewed(e)}
                        addAppearance={(appearance
                        ) => this.setState({
                            itemReviewed: {
                                ...itemReviewed,
                                appearance: appearance

                            }
                        }, () => this.handleSendToTop())}
                        reviewRating={reviewRating}
                        handleOnChangereviewRating={(e) => this.handleOnChangereviewRating(e)}
                        authorName={(authorName) => this.setState({
                            itemReviewed: {
                                ...itemReviewed,
                                author: { ...itemReviewed.author, name: authorName }

                            }
                        }, () => this.handleSendToTop())}
                        handleChangeOnReviewRating={(rating) => this.setState({
                            reviewRating: { ...reviewRating, alternateName: rating }
                        }, () => {
                            this.handleSendToTop()
                        })}
                        handleChangeOnClaimsReview={(rating) => this.setState({
                            claimReviewed: rating
                        }, () => {
                            this.handleSendToTop()
                        })}
                    />

                    {/* </div> */}
                    {/* <div style={{ display: 'flex', gap: 16, marginTop: 16, marginBottom: 16 }}>
                        <Button type='button' color='secondary' onClick={(data) => this.setState({ claimReviews: [...claimReviews, data] })}>+Add claim review</Button>
                        <Button color='secondary'>+Add media review</Button>

                    </div> */}
                </form>
            </ExpansionPanelDetails>
        </ExpansionPanel>)
    }
}





export default SEO