import * as firebase from "firebase";
let config = {
  apiKey: "AIzaSyBlQ53tZTHTjxY1SKcyV1p3w4zrfGiDd0U",
  authDomain: "logically-dd101.firebaseapp.com",
  databaseURL: "https://logically-dd101.firebaseio.com",
  projectId: "logically-dd101",
  storageBucket: "logically-dd101.appspot.com",
  messagingSenderId: "897764926083",
  appId: "1:897764926083:web:b98e9dad712370d822f1b6",
  measurementId: "G-Z736LXFP67",
};
const app = firebase.initializeApp(config);
const db = firebase.database(app);

export { app, db, };
