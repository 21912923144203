/** @format */

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { getDurationRounded, timer } from "./../../../utils/time";
import { formatDate } from "../../../utils/fomratDate";
import axios from "axios";
import api, { getBase, getUser } from "./../../../api";
import { toast } from "react-toastify";
import moment from "moment";
import { FlagIcon } from "react-flag-kit";
import NativeSelect from "@material-ui/core/NativeSelect";
import { getLocation } from "./../../../utils/common";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Filter from "../Filter/filter";
import Paper from "@material-ui/core/Paper";
import SimpleCard from "../simpleSectionCard/simpleSectionCard";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import HtmlTooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import { db, putClaimData } from "../../../App";

const theme = createMuiTheme();

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  line: {
    border: "none",
    background: "#ddd",
    height: "1px",
  },
  paper: {
    padding: theme.spacing.unit * 2,
    backgroundColor: "#f8f8f8",
    "&:hover": {
      backgroundColor: "#f00",
    },
    color: theme.palette.text.secondary,
  },
  fontFamily: ["Roboto"].join(","),
  "&:focus": {
    borderRadius: 4,
    borderColor: "#80bdff",
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
  },
  capheading: {
    color: "black",
    position: "relative",
  },
  title: {
    color: "black",
    fontWeight: "bold",
  },
  expandicon: {
    position: "relative",
    top: `${0.3}rem`,
    height: 18,
  },
  card: {
    borderRadius: "10px",
    background: "#f1f1f1",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  smallAvatar: {
    width: 30,
    height: 30,
  },
  bigAvatar: {
    width: 35,
    height: 35,
  },
  assignTo: {
    position: "relative",
  },
  assignMenu: {
    position: "relative",
  },
  button: {
    textTransform: "none",
  },
  pDropdowns: {
    position: "relative",
  },
  button1: {
    position: "relative",
    float: "right",
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  btnNext: {
    color: "#0b040c",
    float: "right",
    textTransform: "none",
    marginLeft: 5,
  },
  claimIcon: {
    borderRadius: 0,
  },
  subheader: {
    color: "#2d2d2d",
    fontSize: "14px",
  },
  reject: {
    fontSize: "13px",
    width: "auto",
    color: "white",
    textTransform: "uppercase",
    fontWeight: 600,
    position: "relative",
    padding: "4px 7px",
    borderRadius: "6px",
    margin: "17px 0px",
    display: "inline-block",
    letterSpacing: "-0.5px",
  },
  tr: {
    background: "white",
    "&:hover": {
      background: "#F1F4F8",
    },
  },
});

class NewClaims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: this.props.loading,
      users: [],
      expanded: false,
      User_Details: "",
      queryValue: "",
      isFilterApply: false,
      open: false,
      filterTabs: this.props.activeTab,
      showAcceptedClaims: false,
      country: false,
      offset: 0,
      total: 0,
      pageCount: 0,
      clearFilter: false,
      claimed_Data: this.props.claimed_Data,
      sort: {
        country: "asc",
        source: "asc",
        time: "asc",
        status: "asc",
      },
      offline: this.props.offline,
      timer: 30,
      autoRefresh: true
    };
  }

  clearedFilter() {
    if (this.props.clearFilter === 0) {
      if (this.props.searchData.length === 0) {
        this.setState({ clearFilter: this.props.clearFilter }, () => {
          if (this.state.data.length === 0) {
            if (this.props.queryValue === "") {
              this.getData(null);
            }
          }
        });
      }
    } else {
      if (this.props.queryValue === "") {
        this.getData(null);
      }
    }
  }

  componentDidMount() {
    if (!window.location.search.toString().includes('tab=received')) {
      window.location.search = '?tab=received';
    }
    this.props.dispatch({ type: "SET_LOADING", loading: true });
    this.props.onHandleClearFilters()
    this.getData();
    this.autoRefresh(true);
    const t = this.state.timer
    setInterval(() => {
      if (this.state.autoRefresh) {
        let x = this.state.timer
        if (x === 0) {
          x = t
          this.getData();
        }
        else x = x - 1
        this.setState({ timer: x })
      } else {
        this.setState({ timer: 30 })
      }
    }, 1000)

  }

  autoRefresh = (autoRefresh) => {
    this.setState({ autoRefresh: autoRefresh })
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.state;
    const { claimed_Data, searchData, clearFilter, searchFilter } = nextProps;
    if (searchFilter) {
      this.autoRefresh(false);
    }
    if (searchData !== this.props.searchData && searchFilter && !clearFilter) {
      this.setState({ data: searchData }, () => {
        this.props.dispatch({ type: "SET_LOADING", loading: false });
      }
      );
    }
    if (clearFilter && !this.props.clearFilter) {
      this.props.dispatch({ type: "SET_LOADING", loading: true })
      this.getData();
    }
    if (
      JSON.stringify(claimed_Data) !== JSON.stringify(this.state.claimed_Data)
    ) {
      for (let i = 0; i < data.length; i++) {
        if (Object.keys(claimed_Data).includes(data[i]._id)) {
          data[i].accepted = true;
          data[i].accepted_by = claimed_Data[data[i]._id];
        }
      }
      this.setState({ data: data }, () => {
        this.setState({ claimed_Data: claimed_Data });
      });
    }
    if (nextProps.activeTab !== this.state.filterTabs) {
      this.setState({ filterTabs: nextProps.activeTab });
    }
    if (nextProps.loading !== this.state.loading) {
      this.setState({ loading: nextProps.loading });
    }
    if (nextProps.offline !== this.state.offline) {
      this.setState({ offline: nextProps.offline });
    }
    if (!nextProps.offline && this.state.offline) {
      this.getData();
    }
  }

  reject(claim) {
    axios
      .post(getBase(api.REJECT + claim._id + "/rubbish"), { headers: {} })
      .then((res) => {
        toast.success("Rejected Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ open: false });
        this.getData(this.state.queryValue);
      })
      .catch((err) => {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ open: false });
      });
  }

  getData = async (queryValue) => {
    let {
      showAcceptedClaims,
      isFilterApply,
      filterTabs,
      offset,
      data,
    } = this.state;
    try {
      let url = "";
      let header = "";
      if (isFilterApply) {
        url = getUser(
          api.SEARCH_FACTCHECKS +
          `?tab=received&limit=100&&offset=${offset}&` +
          queryValue
        );
        header = { headers: {} };
        this.props.dispatch({ type: "SET_LOADING", loading: true });
      } else {
        if (filterTabs === "Multimedia claims") {
          if (showAcceptedClaims) {
            url = getBase(
              api.NEW_CLAIMS +
              `?type=image&accepted=ok&limit=25&offset=${offset}`
            );
          } else {
            url = getBase(
              api.NEW_CLAIMS + `?type=image&limit=25&offset=${offset}`
            );
          }
        } else {
          if (showAcceptedClaims) {
            url = getBase(
              api.NEW_CLAIMS + `?accepted=ok&limit=100&offset=${offset}`
            );
          } else {
            url = getBase(api.NEW_CLAIMS + `?limit=100&offset=${offset}`);
          }
        }
        header = { headers: { Authorization: "Token " + localStorage.token } };
      }
      const res = await axios.get(url, header);
      if (JSON.stringify(data) !== JSON.stringify(res.data.data)) {
        this.props.setBadge(res.data.total_count);
        const xData = [...res.data.data];
        const { claimed_Data } = this.state;
        for (let i = 0; i < xData.length; i++) {
          if (Object.keys(claimed_Data).includes(xData[i]._id)) {
            xData[i].accepted = true;
            xData[i].accepted_by = claimed_Data[xData[i]._id];
          }
        }
        this.setState({ data: xData }, () =>
          this.props.dispatch({ type: "SET_LOADING", loading: false })
        );
      } else if (this.props.loading) {
        this.props.dispatch({ type: "SET_LOADING", loading: false });
      }
    } catch (err) {
      if (
        (err.message === "Network Error" ||
          err.message === "Request failed with status code 401") &&
        !this.props.offline
      ) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.replace("/");
      }
      this.props.dispatch({ type: "SET_LOADING", loading: false });
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  handleClicktime = (data) => {
    let started_at = moment().format("YYYY/MM/DD HH:mm [GMT+5:30]");
    let user = JSON.parse(localStorage.user);

    user = user.profile.fields.role;

    if (user) {
      if (data.factcheck_started_at === "" && user === "moderator") {
        data.factcheck_started_at = started_at;
        axios
          .put(getBase(api.CLAIM + data._id), data, {
            headers: {
              Authorization: "Token " + localStorage.token,
              "content-type": "application/json",
            },
          })
          .then((res) => {
            if (JSON.stringify(res.data.data)) {
              this.props.dispatch({ type: "SET_LOADING", loading: false });
            } else if (this.state.loading) {
              this.props.dispatch({ type: "SET_LOADING", loading: false });
            }
          })
          .catch((err) => {
            this.props.dispatch({ type: "SET_LOADING", loading: false });
          });
      }
      if (data.factcheck_started_at === "" && user === "supervisor") {
        data.factcheck_started_at = started_at;
        axios
          .put(getBase(api.CLAIM + data._id), data, {
            headers: {
              Authorization: "Token " + localStorage.token,
              "content-type": "application/json",
            },
          })
          .then((res) => {
            if (JSON.stringify(res.data.data)) {
              this.props.dispatch({ type: "SET_LOADING", loading: false });
            } else if (this.state.loading) {
              this.props.dispatch({ type: "SET_LOADING", loading: false });
            }
          })
          .catch((err) => {
            this.props.dispatch({ type: "SET_LOADING", loading: false });
          });
      }
    }
  };

  getUserDetails = (user_id) => {
    axios
      .get(getUser(api.USER_PROFILE_APP + "?user_id=" + user_id), {
        headers: {},
      })
      .then((res) => {
        if (JSON.stringify(res.data.data)) {
          this.setState({
            User_Details: res.data.data,
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.props.dispatch({ type: "SET_LOADING", loading: false });
      });
  };

  rejectClaim(claim) {
    this.setState({ open: claim });
  }

  ifError() {
    const { data } = this.state;
    const { classes } = this.props;
    if (data.length === 0) {
      return (
        <div className={classes.root}>
          <Typography>No records found!</Typography>
        </div>
      );
    }
    if (data === false) {
      return (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography>
            Oops, looks like something went wrong. Please try again later
          </Typography>
        </div>
      );
    }
    return null;
  }

  handleShowAcceptedClaims(val) {
    this.setState({ showAcceptedClaims: val }, () => {
      this.getData(null);
    });
  }

  handleAcceptClaim(claim, index) {
    const { data } = this.state;
    data[index].isAcceptLoading = true;
    this.setState({ data });
    const updates = { status: "working", time_in_status: new Date() };
    const userInfo = JSON.parse(localStorage.user);
    db.ref()
      .child(`${userInfo.id}`)
      .update(updates);
    axios
      .post(
        `${getBase(api.CLAIM)}${claim._id}/accept`,
        {},
        {
          headers: {
            Authorization: "Token " + localStorage.token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        data[index].accepted = true;
        data[index].accepted_by = userInfo.username;
        data[index].isAcceptLoading = false;
        putClaimData(claim._id, userInfo.username);
        this.setState({ data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleAsendingSort(data) {
    data.sort(function (a, b) {
      return b.claim_source - a.claim_source;
    });
  }

  multiFilteredData = (val) => {
    const { searchData } = this.props;
    if (searchData.length > 0) {
      this.props.dispatch({ type: "NAVCLAIMS", navClaims: val });
    } else {
      this.getData(null);
    }
  };

  handleClicks(offset) {
    this.setState({ offset }, () => {
      this.getData(this.state.querValue);
    });
  }

  handleTest = () => {
    const updates = { status: "available", time_in_status: new Date() };
    db.ref()
      .child("34")
      .update(updates);
  };

  render() {
    const {
      classes,
      users,
      isSidebar,
      filterOptions,
      filters,
      searchVal,
      datapoint,
      loading,
      searchFilter,
    } = this.props;
    const { buttons } = filterOptions;
    let { data, open, sort } = this.state;
    let faster = {};
    let Complaints = {};
    return (
      <div className={classes.root}>
        <Filter
          datapoint={datapoint}
          length={data.length}
          buttons={buttons.split(",")}
          activeIndex={this.state.filterTabs}
          filterbutton={(val) => {
            this.setState({ filterTabs: val }, () => this.getData(null));
          }}
          countrySelected={(country) => {
            if (country !== "All") {
              this.getData(null, country);
            } else {
              this.getData(null);
            }
          }}
          checkbox={"show accepted claims"}
          showingClaims={true}
          checkboxValue={(val) => this.handleShowAcceptedClaims(val)}
          searchFilter={searchFilter}
          autoRefresh={(val) => this.autoRefresh(val)}
          timer={this.state.timer}
        />
        {this.ifError()}
        {this.state.filterTabs === "URL claims" && !loading && data.length > 0 && (
          <>
            <Grid container>
              <Paper
                hover="true"
                elevation={0}
                className={classes.paper1}
                style={{
                  marginBottom: 16,
                  padding: 8,
                  width: "100%",
                  paddingLeft: 16,
                  paddingRight: 16,
                  backgroundColor: faster.backgroundColor,
                  border: Complaints.border,
                  borderRadius: 10,
                }}
              >
                <Grid
                  container
                  spacing={16}
                  alignItems="center"
                  style={{
                    padding: 8,
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    style={{
                      backgroundColor: "#f1f4f8",
                      borderRadius: "10px",
                      paddingTop: 15,
                      paddingBottom: 15,
                    }}
                  >
                    <Grid container alignItems="center">
                      <Grid item sm={1} md={1}>
                        <Typography>Claim ID</Typography>
                      </Grid>
                      <Grid item sm={6} md={6} style={{ paddingLeft: 20 }}>
                        <Typography>Claim</Typography>
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        md={1}
                        style={{ textAlign: "center", cursor: "pointer" }}
                        onClick={() => {
                          this.setState({
                            sort: {
                              ...sort,
                              country: sort.country === "desc" ? "asc" : "desc",
                              data: handleSort("country", sort.country, data),
                            },
                          });
                        }}
                      >
                        <Typography>
                          Country
                          {sort.country === "asc" ? (
                            <ArrowDropUpIcon
                              style={{ position: "relative", top: 5 }}
                              size="small"
                              fontSize="small"
                            />
                          ) : (
                            <ArrowDropDownIcon
                              style={{ position: "relative", top: 5 }}
                              size="small"
                              fontSize="small"
                            />
                          )}
                        </Typography>
                      </Grid>

                      <Grid item sm={1} md={1} style={{ textAlign: "center" }}>
                        <Typography
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              sort: {
                                ...sort,
                                source: sort.source === "desc" ? "asc" : "desc",
                                data: handleSort("source", sort.source, data),
                              },
                            });
                          }}
                        >
                          Source
                          {sort.source === "asc" ? (
                            <ArrowDropUpIcon
                              style={{ position: "relative", top: 5 }}
                              size="small"
                              fontSize="small"
                            />
                          ) : (
                            <ArrowDropDownIcon
                              style={{ position: "relative", top: 5 }}
                              size="small"
                              fontSize="small"
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item sm={2} md={2} style={{ textAlign: "center" }}>
                        <Typography
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              sort: {
                                ...sort,
                                time: sort.time === "desc" ? "asc" : "desc",
                                data: handleSort("time", sort.time, data),
                              },
                            });
                          }}
                        >
                          Received time{" "}
                          {sort.time === "asc" ? (
                            <ArrowDropUpIcon
                              style={{ position: "relative", top: 5 }}
                              size="small"
                              fontSize="small"
                            />
                          ) : (
                            <ArrowDropDownIcon
                              style={{ position: "relative", top: 5 }}
                              size="small"
                              fontSize="small"
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item sm={1} md={1} style={{ textAlign: "center" }}>
                        <Typography
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              sort: {
                                ...sort,
                                status: sort.status === "desc" ? "asc" : "desc",
                                data: handleSort("status", sort.status, data),
                              },
                            });
                          }}
                        >
                          Status{" "}
                          {sort.status === "asc" ? (
                            <ArrowDropUpIcon
                              style={{ position: "relative", top: 5 }}
                              size="small"
                              fontSize="small"
                            />
                          ) : (
                            <ArrowDropDownIcon
                              style={{ position: "relative", top: 5 }}
                              size="small"
                              fontSize="small"
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {data !== undefined &&
                  data.map((claim, index) => {
                    claim.faster_check
                      ? (faster = { backgroundColor: "#fff" })
                      : (faster = {});
                    claim.has_complaint
                      ? (Complaints = { border: "1px solid red" })
                      : (Complaints = {});
                    return (
                      <Grid container spacing={16} key={index}>
                        <Grid item lg={12} md={12}>
                          <Grid container>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Typography color="inherit">
                                    {claim.modified_claim}
                                  </Typography>
                                  {claim.message && (
                                    <Typography style={{ color: "white" }}>
                                      <b> User Message:</b> {claim.message}
                                    </Typography>
                                  )}
                                </React.Fragment>
                              }
                            >
                              <Paper
                                className={(classes.paper1, classes.tr)}
                                elevation={1}
                                key={index}
                                style={{
                                  marginBottom: 16,
                                  width: "100%",
                                  padding: 8,
                                  border: Complaints.border,
                                }}
                              >
                                <Grid
                                  container
                                  alignItems="center"
                                  className={classes.tr}
                                >
                                  <Grid item sm={1} md={1}>
                                    <Typography style={{ marginTop: 5 }}>
                                      {claim.tracking_id}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    sm={6}
                                    md={6}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      paddingLeft: 20,
                                    }}
                                  >
                                    <Link to={"/claim/" + claim._id}>
                                      <Typography
                                        component="p"
                                        className={
                                          classes.paragraphText + " maxlines2"
                                        }
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {claim.modified_claim}
                                      </Typography>
                                    </Link>
                                  </Grid>
                                  <Grid
                                    item
                                    sm={1}
                                    md={1}
                                    style={{ textAlign: "center" }}
                                  >
                                    <Typography
                                      component="p"
                                      className={classes.codeText}
                                    >
                                      <FlagIcon
                                        code={getLocation(claim.location)}
                                        size={44}
                                      />
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    sm={1}
                                    md={1}
                                    style={{ textAlign: "center" }}
                                  >
                                    <p>
                                      {claim.claim_source === "dashboard"
                                        ? "FC Dash"
                                        : claim.claim_source}
                                    </p>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    sm={2}
                                    md={2}
                                    alignItems="center"
                                  >
                                    <small>
                                      <p style={{ margiBottom: 8 }}>
                                        {formatDate(claim.created)}
                                      </p>
                                    </small>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    md={1}
                                    alignItems="center"
                                  >
                                    {!claim.accepted ? (
                                      <button
                                        type="button"
                                        style={{
                                          border: 0,
                                          padding: 5,
                                          backgroundColor: "#F1F4F8",
                                          cursor: "pointer",
                                          borderRadius: "5px",
                                        }}
                                        onClick={() =>
                                          this.handleAcceptClaim(claim, index)
                                        }
                                      >
                                        {claim.isAcceptLoading
                                          ? "Loading.."
                                          : "Accept"}
                                      </button>
                                    ) : (
                                      <span>
                                        <small>{claim.accepted_by}</small>
                                      </span>
                                    )}
                                  </Grid>
                                </Grid>
                              </Paper>
                            </HtmlTooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Paper>
            </Grid>
          </>
        )}
        {this.state.filterTabs === "Multimedia claims" && !loading && (
          <Grid container spacing={24}>
            {filter(data, filters, searchVal).map((claim, index) => {
              return (
                claim.recheck_image && (
                  <Grid
                    item
                    xs={12}
                    sm={isSidebar ? 6 : 4}
                    md={isSidebar ? 4 : 3}
                    key={index}
                  >
                    <SimpleCard
                      data={claim}
                      users={users}
                      newClaimsMultimedia={true}
                      images={claim.recheck_image}
                      count={index + 1}
                    />
                  </Grid>
                )
              );
            })}
          </Grid>
        )}
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Pagination
            limit={100}
            offset={this.state.offset}
            total={this.state.total}
            onClick={(e, offset) => this.handleClicks(offset)}
          />
        </MuiThemeProvider>
        <Dialog
          onClose={() => this.setState({ open: false })}
          open={open !== false}
        >
          <DialogTitle>
            <Typography
              style={{ fontSize: 18, fontWeight: 700, letterSpacing: 0 }}
            >
              Are you sure you want to reject and delete this claim?
            </Typography>
          </DialogTitle>
          <DialogActions style={{ marginBottom: 20 }}>
            <Button
              variant="contained"
              style={{ boxShadow: "none", color: "#fff" }}
              onClick={(e) => this.reject(open)}
              color="secondary"
            >
              Reject
            </Button>
            <Button
              color="primary"
              onClick={() => this.setState({ open: false })}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export function handleSort(type, sort, data) {
  let sortedData;
  switch (type) {
    case "country":
      sortedData = data.sort(
        sort === "asc"
          ? (a, b) => a.location.localeCompare(b.location)
          : (a, b) => b.location.localeCompare(a.location)
      );
      break;
    case "source":
      sortedData = data.sort(
        sort === "asc"
          ? (a, b) => a.claim_source.localeCompare(b.claim_source)
          : (a, b) => b.claim_source.localeCompare(a.claim_source)
      );
      break;
    case "time":
      sortedData = data.sort(
        sort === "asc"
          ? (a, b) => (a.created > b.created ? 1 : -1)
          : (a, b) => (a.created > b.created ? -1 : 1)
      );
      break;
    case "status":
      let sortedBoolData = data.sort(
        sort === "asc"
          ? (a, b) => {
            return a.accepted === b.accepted ? 0 : a.accepted ? 1 : -1;
          }
          : (a, b) => {
            return a.accepted === b.accepted ? 0 : a.accepted ? -1 : 1;
          }
      );
      sortedData = sortedBoolData.sort(
        sort === "asc"
          ? (a, b) => {
            if (a.accepted_by && b.accepted_by) {
              return a.accepted_by.localeCompare(b.accepted_by);
            }
          }
          : (a, b) => {
            if (a.accepted_by && b.accepted_by) {
              return b.accepted_by.localeCompare(a.accepted_by);
            }
          }
      );
      break;
    case "type":
      sortedData = data.sort(
        sort === "asc"
          ? (a, b) => a.complaint_type.localeCompare(b.complaint_type)
          : (a, b) => b.complaint_type.localeCompare(a.complaint_type)
      );
      break;
    case "verdict":
      sortedData = data.sort(
        sort === "asc"
          ? (a, b) => a.verdict.localeCompare(b.verdict)
          : (a, b) => b.verdict.localeCompare(a.verdict)
      );
      break;
    case "reason":
      let sortedBool = data.sort(
        sort === "asc"
          ? (a, b) => {
            return a.reject_message === b.reject_message
              ? 0
              : a.reject_message
                ? 1
                : -1;
          }
          : (a, b) => {
            return a.reject_message === b.reject_message
              ? 0
              : a.reject_message
                ? -1
                : 1;
          }
      );
      break;
    case "fcDate":
      sortedData = data.sort(
        sort === "asc" ?
          (a, b) => a.created.localeCompare(b.created) :
          (a, b) => b.created.localeCompare(a.created)
      );


      sortedData = sortedBool.sort(
        sort === "asc"
          ? (a, b) => {
            if (a.reject_message && b.reject_message) {
              return a.reject_message.localeCompare(b.reject_message);
            }
          }
          : (a, b) => {
            if (a.reject_message && b.reject_message) {
              return b.reject_message.localeCompare(a.reject_message);
            }
          }
      );
      break;
    default:
      return data;
  }
  return sortedData;
}

export function filter(data, filters, searchVal, sort) {
  if (filters.duration) {
    let duration = filters.duration;
    if (duration === "Infinity") {
      duration = Infinity;
    }
    if (duration === "over 2 hours") {
      data = data.filter((claim) => {
        return (
          getDurationRounded(new Date(claim.check_created_at), new Date())
            .seconds > 7200
        );
      });
    } else {
      if (duration !== Infinity) {
        duration = parseInt(filters.duration, 10);
      }
      data = data.filter((claim) => {
        return (
          getDurationRounded(new Date(claim.check_created_at), new Date())
            .seconds < duration
        );
      });
    }
  }
  if (filters.judgement) {
    data = data.filter((claim) => {
      return filters.judgement.indexOf(claim.verdict) !== -1;
    });
  }
  if (filters.corrections === true) {
    data = data.filter((claim) => {
      return claim.small_correction_made;
    });
  }
  if (filters.updates === true) {
    data = data.filter((claim) => {
      return claim.correction_made;
    });
  }
  if (filters.language) {
    data = data.filter((claim) => {
      return filters.language.indexOf(claim.lang_code) !== -1;
    });
  }

  if (filters.tags) {
    data = data.filter((claim) => {
      let ret = filters.tags.length === 0;
      for (var i = 0; i < filters.tags.length; i++) {
        if (claim.tags.indexOf(filters.tags[i].topic) !== -1) {
          ret = true;
        }
      }
      return ret;
    });
  }
  if (filters.users) {
    if (filters.users.length > 0) {
      data = data.filter((claim) => {
        let all_ids = [];
        for (var i = 0; i < filters.users.length; i++) {
          all_ids.push(filters.users[i].toString());
        }
        return all_ids.indexOf(claim.factchecker_id) !== -1;
      });
    }
  }
  if (searchVal) {
    data = data.filter((claim) => {
      let ret = false;
      if (claim.type === "image") {
        if (claim._id.toLowerCase().search(searchVal.toLowerCase()) !== -1) {
          ret = true;
        }
        return ret;
      } else {
        if (
          claim.tracking_id.toLowerCase().search(searchVal.toLowerCase()) !== -1
        ) {
          ret = true;
        }
        if (claim.claim.toLowerCase().search(searchVal.toLowerCase()) !== -1) {
          ret = true;
        }
        return ret;
      }
    });
  }
  if (filters.date) {
    data = data.filter((claim) => {
      if (claim.factcheck_completed_at) {
        if (
          new Date(claim.factcheck_completed_at) >=
          new Date(filters.date[0].startDate) &&
          new Date(claim.factcheck_completed_at) <=
          new Date(filters.date[0].endDate)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          new Date(claim.created) >= new Date(filters.date[0].startDate) &&
          new Date(claim.created) <= new Date(filters.date[0].endDate)
        ) {
          return true;
        } else {
          return false;
        }
      }
    });
  }
  if (sort) {
    if (sort.sort_key === "date") {
      if (sort.sort === "descending") {
        data = data.sort((a, b) => {
          if (a.supervisor_completed_at) {
            return (
              new Date(b.supervisor_completed_at) -
              new Date(a.supervisor_completed_at)
            );
          } else if (a.factcheck_completed_at) {
            return (
              new Date(b.factcheck_completed_at) -
              new Date(a.factcheck_completed_at)
            );
          } else {
            return new Date(b.created) - new Date(a.created);
          }
        });
      } else {
        data = data.sort((a, b) => {
          if (a.supervisor_completed_at) {
            return (
              new Date(b.supervisor_completed_at) -
              new Date(a.supervisor_completed_at)
            );
          } else if (a.factcheck_completed_at) {
            return (
              new Date(a.factcheck_completed_at) -
              new Date(b.factcheck_completed_at)
            );
          } else {
            return new Date(a.created) - new Date(b.created);
          }
        });
      }
    }
    if (sort.sort_key === "rating") {
      if (sort.sort === "descending") {
        data = data.sort((a, b) => {
          return (parseInt(b.rating, 10) || 0) - (parseInt(a.rating, 10) || 0);
        });
      } else {
        data = data.sort((a, b) => {
          return (
            (parseInt(a.rating, 10) || 500) - (parseInt(b.rating, 10) || 500)
          );
        });
      }
    }
  }
  return data;
}

export class Assign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "",
      priority: "low",
    };
    this.timer = false;
  }
  handleAssign = () => {
    let mod_started_at = moment().format("YYYY/MM/DD HH:mm [GMT+5:30]");
    if (this.timer === false) {
      this.timer = true;
      let data;
      if (this.props.data.recheck_image) {
        data = {
          claim_id: this.props.data._id,
          user_id: this.state.current,
          priority: this.state.priority,
          type: "image",
        };
      } else {
        data = {
          claim_id: this.props.data._id,
          user_id: this.state.current,
          priority: this.state.priority,
        };
      }
      if (
        this.props.data.assigned_by &&
        this.props.data.moderator_started_at != null
      ) {
        this.props.data.moderator_completed_at = mod_started_at;
        this.props.data.old_moderator_id = this.props.data.moderator_id;
        this.props.data.moderator_id = "";
      }
      if (this.props.data.recheck_image) {
        this.props.data.type = "image";
      }
      axios
        .put(getBase(api.CLAIM + this.props.data._id), this.props.data, {
          headers: {
            Authorization: "Token " + localStorage.token,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          axios
            .post(getBase(api.ASSIGN_CLAIM), data, {
              headers: {
                Authorization: "Token " + localStorage.token,
                "content-type": "application/json",
              },
            })
            .then((res) => {
              toast.success(" Assigned claim to user", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            })
            .catch((err) => {
              toast.error(
                err.response ? err.response.message : "Something went wrong",
                { position: toast.POSITION.TOP_RIGHT }
              );
            });
        })
        .catch((err) => {
          toast.error("Something went wrong,", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  render() {
    const { users } = this.props;
    const { current, priority } = this.state;
    return (
      <div>
        <Grid container spacing={24} alignItems="center">
          <Grid item md={6}>
            <Typography
              style={{ marginBottom: 3, color: "#b79f9f", fontSize: "12px" }}
            >
              Assign To:
            </Typography>
            <NativeSelect
              value={current}
              onChange={(e) => this.setState({ current: e.target.value })}
              className="nounderline"
              style={{
                width: "100%",
                paddingLeft: "8px",
                border: "1px solid #ced4da",
                borderRadius: "5px",
                boxSizing: "border-box",
              }}
            >
              <option value="">None</option>
              {users.map((usr, index) => {
                if (usr.role !== "factchecker") {
                  return null;
                }
                return (
                  <option key={index} value={usr.user_id}>
                    {usr.first_name.length > 0
                      ? usr.first_name + " " + usr.last_name
                      : usr.username}
                  </option>
                );
              })}
            </NativeSelect>
          </Grid>
          <Grid item md={6}>
            <Typography
              style={{ marginBottom: 3, color: "#b79f9f", fontSize: "12px" }}
            >
              Priority:
            </Typography>
            <NativeSelect
              value={priority}
              onChange={(e) => this.setState({ priority: e.target.value })}
              className="nounderline"
              style={{
                width: "100%",
                paddingLeft: "8px",
                border: "1px solid #ced4da",
                borderRadius: "5px",
                boxSizing: "border-box",
              }}
            >
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </NativeSelect>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={this.handleAssign}
          style={{ marginTop: 16, boxShadow: "none" }}
        >
          Assign
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    userHashTable: data.userHashTable,
    navClaims: data.navClaims,
    claimed_Data: data.claim,
    activeTab: data.activeButton,
    loading: data.loading,
    offline: data.offline,
    clearFilter: data.clearFilter,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(NewClaims));
